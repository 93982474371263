import { gql } from "@apollo/client";
import { QueryClient } from "../..";
import { UsersPermissionsUserEntityResponseCollection } from "../../../generated/graphql";
import { User } from "../../../shared/interfaces/user.interface";

export const EXISTS_USER = gql`
  query ExistsUser($where: UsersPermissionsUserFiltersInput) {
    usersPermissionsUsers(filters: $where) {
      data {
        id
      }
    }
  }
`;

export const existsUser = (where: {
  field: "cpf" | "email";
  value: string;
}) => {
  const variables = { where: { [where.field]: { startsWith: where.value } } };
  return QueryClient<{
    usersPermissionsUsers: UsersPermissionsUserEntityResponseCollection;
  }>(EXISTS_USER, variables);
};
