import { useContext, useEffect, useState } from "react";
import { Banner } from "../../../shared/components/banner-home";
import Container from "../../../shared/components/Container";
import { UserContext } from "../../../context/UserContext";
import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../hooks/useBannerApi";
import {
  loadBanner,
  Banner as BannerType,
} from "../../../graphql/collections/queries/banner.query";
import { isMobile } from "react-device-detect";

export default function BannerAbaixoCabecalho() {
  const [banners, setBanners] = useState<BannerType[]>([]);
  const { user } = useContext(UserContext);

  const carregarBanners = async () => {
    try {
      const bannersResult = await loadBanner({
        homePage: true,
      });
      setBanners(bannersResult);
    } catch (err) {
      setBanners([]);
    }
  };

  useEffect(() => {
    carregarBanners();
  }, []);

  const bannerRodape = banners.filter(
    (a) =>
      a.areaPagina === TIPO_AREA_PAGINA.ABAIXO_CABECALHO &&
      a.tipoPagina === TIPO_PAGINA.HOME &&
      a.field === (isMobile ? "ImagemMobile" : "ImagemDesktop") &&
      ((!!user?.id && a.ocultarLogado === false) || !user?.id),
  );

  if (bannerRodape && bannerRodape.length > 0) {
    return (
      <Container className="py-10 md:py-20">
        <Banner height={90} banners={bannerRodape} />
      </Container>
    );
  } else {
    return <></>;
  }
}
