import React from "react";

import { VariantProps, tv } from "tailwind-variants";
import { ResidenciaMedicaEntity } from "../../../generated/graphql";
import { useHistory } from "react-router";

interface CardSwiperItemResidenciaMedicaProps {
  item: ResidenciaMedicaEntity;
  loading?: boolean;
}

const div = tv({
  base: "flex flex-col w-full rounded-2xl bg-[#6d6e70]",
});

type CardSwiperItemResidenciaMedicaVariantProps = VariantProps<typeof div>;

export const CardSwiperItemResidenciaMedica: React.FC<
  CardSwiperItemResidenciaMedicaProps &
    CardSwiperItemResidenciaMedicaVariantProps
> = ({ item, loading }) => {
  const history = useHistory();

  const percentual = Math.min(
    Number(
      (
        (item?.attributes?.quantidadeModulos === 0
          ? 0
          : item?.attributes?.progresso! /
            item?.attributes?.quantidadeModulos!) * 100
      ).toFixed(0)
    ),
    100
  );

  if (loading) {
    return (
      <div className="overflow-hidden relative space-y-5 rounded-2xl bg-slate-100 h-full ">
        <div className="w-full h-full  rounded-2xl -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
      </div>
    );
  } else {
    return (
      <>
        <div
          style={{
            background: item.attributes?.background_css,
          }}
          className={`flex p-[30px] relative flex-col rounded-[9.958px] h-full w-full cursor-pointer`}
          onClick={() => history.push(`/prepara-einstein/${Number(item?.id)}`)}
        >
          <div className={`flex gap-3 h-full}`}>
            <img
              src={item?.attributes?.icone?.data?.attributes?.url}
              className="bg-cover  w-[64px] h-[64px]"
              alt={item?.attributes?.icone?.data?.attributes?.name}
            />
            <span className="text-lg font-semibold leading-6 text-white">
              {item.attributes?.titulo}
            </span>
          </div>
          <div className="flex w-full flex-col mt-4">
            <div className="flex w-full justify-between text-xs font-medium leading-normal text-white">
              <span className="">
                {item.attributes?.carga_horaria
                  ? item.attributes?.carga_horaria + " horas"
                  : ""}
              </span>
              <span className="">
                {item?.attributes?.progresso}/
                {item?.attributes?.quantidadeModulos}
              </span>
            </div>
            <div className="flex flex-row gap-4 items-center mt-[7px] justify-center">
              <div className="relative bg-[#CED1D4] shadow-inner w-full h-2 rounded-2xl flex box-content">
                <div
                  style={{
                    width: `${percentual}%`,
                    boxShadow: "3px 4px 5px rgba(0, 0, 0, 0.10)",
                  }}
                  className={`absolute h-2 rounded-2xl flex left-0 bg-[#FFFFFF]  `}
                ></div>
              </div>
              <span className="text-[9.958px] font-semibold leading-none text-white inline-block text-center">
                {percentual}%
              </span>{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
};
