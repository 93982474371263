import { Button } from "../ui/button";
import * as Icon from "../../../assets/svgs";
import { useHistory } from "react-router";
import { ModalService } from "../Modal";

export function ModalPlaylistOnBoard() {
  const history = useHistory();

  return (
    <div className="bg-white z-50 flex h-[28.68rem] w-[20rem] flex-col items-center justify-center rounded-3xl px-8 md:h-auto md:w-[450px] md:px-12 md:py-12 ">
      <div>
        <Icon.onBoardHome className="w-[270px] md:w-[350px]" />
      </div>
      <div className="flex flex-col text-center">
        <h3 className="text-sm font-medium leading-22 text-[#008BC9]">
          Novidade na Academia!
        </h3>
        <h2 className="text-1.5xl font-bold normal-case leading-22 text-[#004F92]">
          Acesse e crie playlists
        </h2>
        <p className=" mt-3 text-center text-sm font-normal leading-22 text-black-900 md:text-base">
          Agora você pode explorar nossas playlists sugeridas e criar as suas
          próprias para aprender do seu jeito.
        </p>
      </div>
      <div className="mt-3 flex gap-3">
        <Button
          data-testid="btn-delete-playlist"
          className="h-[50px]"
          onClick={() => {
            history.push("/playlists");
            localStorage.setItem("onBoardPlaylists", "true");
            ModalService.hide();
          }}
        >
          Ir para playlists
        </Button>
      </div>
    </div>
  );
}
