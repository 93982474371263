import "./styles.scss";
import React, { useEffect, useState } from "react";

import { Grid, CardContainer } from "../../../../shared/components";

import {
    CategoriaFragment,
    RotuloEntity,
    useGetCategoriasLazyQuery,
  } from "../../../../generated/graphql";

import { Link } from "react-router-dom";

interface CategoriasConteudoProps {}

const CategoriasConteudo: React.FC<CategoriasConteudoProps> = (props) => {
  const [lista, setLista] = useState<RotuloEntity[]>([]);
  const [GetCategorias, { data }] =
    useGetCategoriasLazyQuery({
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        setLista(data?.rotulos?.data as RotuloEntity[]);
      },
      onError: () => {
      },
    });

  useEffect(() => {
    GetCategorias();

    if (!!data) {
      setLista(data?.rotulos!.data as RotuloEntity[]);
    }

  }, [GetCategorias, data]);

    return (
        <>
        <div data-categorias-conteudo>
        <Grid cols={4} gap={4}>
            {lista
              .filter((item) => !!item.attributes?.ImagemFundo)
              .map((item, i: number) => (
                <Link
                  key={i}
                  to={`/categoria/${item.attributes?.titulo?.toLowerCase().replace(/[" "]/g, "-")}`}
                  style={{
                    pointerEvents: item.attributes?.ObjetosEducacionais?.data.length
                      ? "inherit"
                      : "none",
                  }}
                  className="cardItem"
                >
                  <CardContainer shadow>
                    <div
                      data-card-content
                      style={{
                        background: `url(${item.attributes?.ImagemFundo?.data?.attributes?.url}) 0 0 no-repeat`,
                      }}
                    >
                      <h4>{item.attributes?.titulo}</h4>
                    </div>
                  </CardContainer>
                  <div data-card-footer>
                        <p>{item.attributes?.descricao}</p>
                    </div>
                </Link>
                
              ))}
          </Grid>
        </div>
        </>
    );

};

export default CategoriasConteudo;