import { isMobile } from "react-device-detect";
import {
  ObjetoEducacionalEntity,
  ResidenciaMedicaEntity,
} from "../../../generated/graphql";
import CardPrincipalResidencia from "../CardPrincipalResidencia";
import ModulosResidencia from "../ModulosResidencia";
import ObjetoEducacionalModulo from "../ObjetoEducacionalModulo";
import { useState } from "react";
import React from "react";

export default function PainelResidencia({
  residenciaMedicas,
  handleModuleToggle,
  expandedModules,
  handleSetObjetoSelecionado,
  objetoEducacionalSelecionado,
  _user,
  loading,
}: {
  residenciaMedicas: ResidenciaMedicaEntity;
  handleModuleToggle: (index: number) => void;
  expandedModules: number[];
  handleSetObjetoSelecionado: (
    objeto: ObjetoEducacionalEntity,
    concluido: boolean,
  ) => void;
  objetoEducacionalSelecionado: ObjetoEducacionalEntity | null;
  _user: any;
  loading: boolean;
}) {
  const [showAllCards, setShowAllCards] = useState(false);

  const handleShowMore = () => {
    setShowAllCards(true);
  };

  const residenciaNew =
    residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.map(
      (modulo) => {
        return {
          ...modulo,
          quantidadeObjetos:
            modulo?.attributes?.objeto_educacionals?.data?.length,
        };
      },
    );

  return (
    <div className="w-auto" key={residenciaMedicas?.id}>
      <div className="max-h-[700px] w-full flex-col overflow-auto rounded-xl md:min-h-[462px] md:w-[346px] md:pr-5">
        <CardPrincipalResidencia residenciaMedicas={residenciaMedicas} />
        {residenciaMedicas?.attributes?.residencia_medica_modulos?.data
          ?.slice(0, isMobile ? (showAllCards ? 100 : 4) : 1000)
          .filter(a => (a.attributes?.objeto_educacionals?.data?.length || 0) > 0)
          .map((modulo, index) => (
            <React.Fragment key={index}>
              <ModulosResidencia
                key={index}
                modulo={modulo}
                index={index}
                handleModuleToggle={handleModuleToggle}
                expandedModules={expandedModules}
                residenciaMedicaQtdModulos={
                  residenciaMedicas?.attributes?.residenciaMedicaQtdModulo?.find(
                    (item) => item?.id?.toString() === modulo?.id,
                  )!
                }
              />

              {expandedModules.includes(index) && (
                <>
                  {modulo?.attributes?.objeto_educacionals?.data.map(
                    (objeto, index) => (
                      <ObjetoEducacionalModulo
                        objeto={objeto}
                        index={index}
                        handleObjetoSelecionado={handleSetObjetoSelecionado}
                        objetoEducacional={
                          objetoEducacionalSelecionado || undefined
                        }
                        objetosConsumidos={
                          residenciaMedicas?.attributes?.residenciaMedicaQtdModulo?.find(
                            (item) => item?.id?.toString() === modulo?.id,
                          )?.oes || []
                        }
                        loading={loading}
                      />
                    ),
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        {!showAllCards &&
          isMobile &&
          residenciaMedicas?.attributes?.residencia_medica_modulos?.data
            ?.length! > 4 && (
            <button
              data-testid="btn-ver-mais-painel-residencia"
              className="text-white mt-2 h-[40px] w-full rounded-[0.75rem] bg-[#004F92] p-2"
              onClick={handleShowMore}
            >
              Ver mais
            </button>
          )}
      </div>
    </div>
  );
}
