
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import {
  ArrowBackOutlined,
  Fullscreen,
  GetApp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PrintOutlined,
  Search,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import { useHistory } from "react-router";

import type {
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";

import pt_PT from "@react-pdf-viewer/locales/lib/pt_PT.json";


interface FeedbackPDFQuizProps {
  pdfUrl: string;
}

const FeedbackPDFQuiz: React.FC<FeedbackPDFQuizProps> = ({ pdfUrl }) => {
  const encodedPdfUrl = `${process.env.REACT_APP_ROOT}/api/objeto-educacional/get-pdf/${encodeURIComponent(pdfUrl)}`;

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const { print } = toolbarPluginInstance.printPluginInstance;
  const { ShowSearchPopover } = toolbarPluginInstance.searchPluginInstance;
  const { Download } = toolbarPluginInstance.getFilePluginInstance;
  const { EnterFullScreen } = toolbarPluginInstance.fullScreenPluginInstance;
  const { GoToPreviousPage, GoToNextPage } =
    toolbarPluginInstance.pageNavigationPluginInstance;
  const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance;
  const history = useHistory();


  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    ZoomIn: (props) => (
      <ZoomIn>
        {(props) => (
          <Tooltip title="Ampliar">
            <IconButton onClick={props.onClick}>
              <ZoomInIcon htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ZoomIn>
    ),
    ZoomOut: (props) => (
      <ZoomOut>
        {(props) => (
          <Tooltip title="Afastar">
            <IconButton onClick={props.onClick}>
              <ZoomOutIcon htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ZoomOut>
    ),
    GoToPreviousPage: (props) => (
      <GoToPreviousPage>
        {(props) => (
          <Tooltip title="Página anterior">
            <IconButton onClick={props.onClick}>
              <KeyboardArrowUp htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </GoToPreviousPage>
    ),
    GoToNextPage: (props) => (
      <GoToNextPage>
        {(props) => (
          <Tooltip title="Próxima Página">
            <IconButton onClick={props.onClick}>
              <KeyboardArrowDown htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </GoToNextPage>
    ),
    EnterFullScreen: (props) => (
      <EnterFullScreen>
        {(props) => (
          <Tooltip title="Tela Cheia">
            <IconButton onClick={props.onClick}>
              <Fullscreen htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </EnterFullScreen>
    ),
    ShowSearchPopover: (props) => (
      <ShowSearchPopover {...props}>
        {(props) => (
          <Tooltip title="Pesquisar">
            <IconButton onClick={props.onClick}>
              <Search htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ShowSearchPopover>
    ),
    Download: (props) => (
      <Download>
        {(props) => (
          <Tooltip title="Download">
            <IconButton onClick={props.onClick}>
              <GetApp htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </Download>
    ),
    Print: (props) => {
      return (
        <Tooltip title="Imprimir">
          <IconButton onClick={print}>
            <PrintOutlined htmlColor="#FFFFFF" />
          </IconButton>
        </Tooltip>
      );
    },
  });
  return (
    <div style={{ width: '100%', marginTop: '10px' }}>
      <h4 className="text-[#004F92] font-semibold leading-5 !mt-5 mb-2">
        Feedback:
      </h4>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js`}>
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              padding: "0.25rem",
            }}
          >
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </div>
          <div
            onClick={() => {
              history.goBack();
            }}
            style={{
              display: "flex",
              position: "absolute",
              marginLeft: "6rem",
              top: 301,
            }}
          >
            <ArrowBackOutlined fontSize="large" />
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              paddingLeft: "calc((100% - 1246px) / 2)",
              paddingRight: "calc((100% - 1246px) / 2)",
              height: "90vh",
            }}
          >
            <Viewer fileUrl={encodedPdfUrl} />
          </div>
        </div>
      </Worker>
    </div>
  );
};

export default FeedbackPDFQuiz;


