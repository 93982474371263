import React, { useState, useEffect, useContext, forwardRef } from "react";
import { useDispatch } from "react-redux";

import RememberIconBlue from "../../../assets/svgs/remember-icon.svg";
import RememberIconWhite from "../../../assets/svgs/remember-icon-white.svg";
import unRememberIconWhite from "../../../assets/svgs/unremember-icon-white.svg";
import unRememberIconBlue from "../../../assets/svgs/unremember-icon.svg";

import { HEADER_ACTION_TYPE } from "../../constants/action-types";

import {
  ObjetoEducacionalEntity,
  useLembrarMutation,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import { UserContext, updateUserContext } from "../../../context/UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

import * as Icon from "../../../assets/svgs";

import "./styles.scss";
import { ModalService } from "../Modal";
import { ModalLembreteAtivado } from "../../../pages/OEPage/components/ModalLembreteAtivado/ModalLembreteAtivado";
import { CircleCheckBig } from "lucide-react";

/**
 * Como a única função do componente é a de FAVORITAR, deixaremos neste mesmo
 * arquivo a mutation que executa a ação de curtir.
 */

/**
 * Para curtir um Objeto, este componete receberá
 * o ID do usuário e o ID da OE para que seja executada
 * a mutation de maneira adequada.
 * O componente também receberá um atributo 'lembrar', de maneira
 * a mudar sua apresentação caso o conteúdo já tenha sido curtido.
 */

interface ItemProps {
  id: string;
  titulo: string;
}

interface ILembrarProps {
  idOe?: string;
  item: ItemProps;
  color?: string | undefined;
  active?: boolean;
}

export const Lembrar = forwardRef<HTMLDivElement, ILembrarProps>(
  (props, ref) => {
    const { user, setUser } = useContext(UserContext);

    const [lembrarMutation] = useLembrarMutation({
      onCompleted: (response) => {
        setLembrar(!!response.updateUsersPermissionsUser.data?.attributes?.OesLembrete?.data.some((oeUser) =>
          ehOe(oeUser, props.idOe)
        ))
        if (!!response.updateUsersPermissionsUser.data?.attributes?.OesLembrete?.data.some((oeUser) =>
          ehOe(oeUser, props.idOe)
        )) {
          setUser({
          id: user?.id || "",
          attributes: {
            ...user?.attributes!,
            OesLembrete: {
              data: response.updateUsersPermissionsUser.data?.attributes?.OesLembrete?.data,
            },
          },
          });
          toast.success(
            <div className="flex flex-row gap-3 mx-2 items-center">
              <CircleCheckBig className="h-5 w-5" />
              <div className="flex flex-col">
                <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Lembrete ativado!</div>
                <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Enviaremos e-mail antes do evento.</div>
              </div>
            </div>, {
            icon: false,
            closeButton: false,
            style: {
              backgroundColor: '#21AB27',
              color: 'white',
              borderRadius: '35px',
            }
          });
        }
      },
    });

    const [currentIconStyle, setCurrentIconStyle] = useState<any | null>(null);

    const dispatch = useDispatch();

    const [lembrar, setLembrar] = useState<boolean>(false);

    useEffect(() => {
      if (user) {
        setLembrar(!!user.attributes?.OesLembrete?.data.some((oeUser) =>
        ehOe(oeUser, props.idOe)
      ))
      }
    }, [user])

    const gtm = useGtm();

    const iconStyles: any = {
      blue: {
        iconTrue: RememberIconBlue,
        iconFalse: unRememberIconBlue,
      },
      gray: {
        iconTrue: RememberIconBlue,
        iconFalse: unRememberIconBlue,
      },
      white: {
        iconTrue: RememberIconWhite,
        iconFalse: unRememberIconWhite,
      },
      yellow: {
        iconTrue: RememberIconWhite,
        iconFalse: unRememberIconWhite,
      },
    };

    const ehOe = (oeUser: any, id: string | undefined) => {
      if (!oeUser || !id) {
        return false;
      }

      return oeUser.id.toString() === id.toString();
    };

    const isLembrar = () => {
      if (!user || !props.idOe) return false;

      return user.attributes?.OesLembrete?.data.some((oeUser) =>
        ehOe(oeUser, props.idOe)
      );
    };

    const setIconStyle = () => {
      const colorIcon = (props.color as any) || "blue";
      const icon = iconStyles[colorIcon];
      setCurrentIconStyle(isLembrar() ? icon["iconTrue"] : icon["iconFalse"]);
    };

    useEffect(() => {
      setIconStyle();
    }, [props.idOe, setIconStyle, user]);

    const toggleLembrar = async (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      ev.stopPropagation();

      const userNew = {
        ...user!,
        id: user?.id!,
        attributes: {
          ...user?.attributes,
          OesLembrete: { data: [] },
        },
      } as UsersPermissionsUserEntity;

      if (!userNew) {
        dispatch({
          type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
          payload: true,
        });
        return;
      }

      if (!isLembrar()) {
        gtm.push(
          EDataLayerEventCategory.ADE_OE_LEMBRAR,
          EDataLayerEventAction.CLIQUE,
          {
            label: props.item.titulo || "",
            id: props.item.id,
          }
        );
      }

      const otherLembrete = (
        userNew.attributes?.OesLembrete?.data as ObjetoEducacionalEntity[]
      ).filter((oeUser) => !ehOe(oeUser, props.idOe));

      const newLembrete = !isLembrar()
        ? [...otherLembrete, { id: props.idOe! }]
        : [...otherLembrete];

      await lembrarMutation({
        variables: {
          id: user?.id!,
          input: {
            OesLembrete: (newLembrete as any[]).map((a) => a.id.toString()),
          },
        },
      });

      setLembrar(!isLembrar());

      updateUserContext({
        ...userNew,
        attributes: {
          ...userNew.attributes!,
          OesLembrete: {
            data: newLembrete as any[],
          },
        },
      });
      setUser({
        ...userNew,
        attributes: {
          ...userNew.attributes!,
          OesLembrete: {
            data: newLembrete as any[],
          },
        },
      });
    };

    return (
      <>
        <ToastContainer />
        <div
          className="remember-container"
          data-lembrar
          onClick={toggleLembrar}
          title={!lembrar ? "Lembrar" : "Não Lembrar"}
          ref={ref}
        >
          {!lembrar ? <Icon.Remember /> : <Icon.unRemember />}
        </div>
      </>
    );
  }
);
