export interface Estado {
    id: string;
    name: string
}

export const obterTodosEstados = () => {
    const estados: Estado[] = [];

    estados.push({id: "AC", name: "AC" });
    estados.push({id: "AL", name: "AL" });
    estados.push({id: "AM", name: "AM" });
    estados.push({id: "AP", name: "AP" });
    estados.push({id: "BA", name: "BA" });
    estados.push({id: "CE", name: "CE" });
    estados.push({id: "DF", name: "DF" });
    estados.push({id: "ES", name: "ES" });
    estados.push({id: "GO", name: "GO" });
    estados.push({id: "MA", name: "MA" });
    estados.push({id: "MG", name: "MG" });
    estados.push({id: "MS", name: "MS" });
    estados.push({id: "MT", name: "MT" });
    estados.push({id: "PA", name: "PA" });
    estados.push({id: "PB", name: "PB" });
    estados.push({id: "PE", name: "PE" });
    estados.push({id: "PI", name: "PI" });
    estados.push({id: "PR", name: "PR" });
    estados.push({id: "RJ", name: "RJ" });
    estados.push({id: "RN", name: "RN" });
    estados.push({id: "RO", name: "RO" });
    estados.push({id: "RR", name: "RR" });
    estados.push({id: "RS", name: "RS" });
    estados.push({id: "SC", name: "SC" });
    estados.push({id: "SE", name: "SE" });
    estados.push({id: "SP", name: "SP" });
    estados.push({id: "TO", name: "TO" });

    return estados;
}