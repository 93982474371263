"use client";
import { twMerge } from "tailwind-merge";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import React from "react";
import Autoplay from "embla-carousel-autoplay";
import { Banner as BannerType } from "../../graphql/collections/queries/banner.query";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

interface BannerProps {
  height: number;
  banners: BannerType[];
}

export function Banner({ height, banners }: BannerProps) {
  const plugin = React.useRef(
    Autoplay({ delay: 5000, playOnInit: true, stopOnFocusIn: true }),
  );

  const gtm = useGtm();

  return (
    <div className="relative flex w-full justify-center">
      <div className="absolute -top-6 left-0 right-0 mx-auto  flex w-[170px] justify-center md:-top-6">
        <span className="text-sm font-normal text-black-900 md:text-xs">
          Patrocinado
        </span>
      </div>
      <Carousel
        plugins={[plugin.current]}
        className="w-full md:w-[970px]"
        opts={{
          loop: true,
        }}
      >
        <CarouselContent className="">
          {banners?.map((item, index) => (
            <CarouselItem key={index}>
              <div
                className={twMerge(
                  ` flex w-full cursor-pointer rounded-xl md:w-[970px]`,
                  height === 90 ? "h-[90px]" : "h-[179px]",
                )}
                onClick={() => {
                  // Evento mapeado para o GTM
                  gtm.push(
                    EDataLayerEventCategory.ADE_BANNER,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: `Código do Banner: ${item.id}${
                        item?.nomePatrocinador
                          ? ` ; Patrocinador: ${item.nomePatrocinador}`
                          : ""
                      }`,
                      idBanner: item.id,
                      tituloBanner: "",
                      patrocinador: item?.nomePatrocinador
                        ? item?.nomePatrocinador
                        : "",
                      tipoPagina: item.tipoPagina,
                      areaPagina: item.areaPagina,
                    },
                  );
                  window.open(item.linkFuga || "#", "_blank");
                }}
                style={{
                  background: `url("${item.url}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            </CarouselItem>
          ))}
        </CarouselContent>
        {/* <CarouselPrevious height={90} className="hidden md:block" />
        <CarouselNext height={90} className="hidden md:block" /> */}
        <CarouselBullets className="absolute left-0 right-0 mx-auto " />
      </Carousel>
    </div>
  );
}
