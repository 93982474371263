import ResidenciaMedicaBanner from "../_HomePage/components/ResidenciaMedica";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { Loader } from "../../shared/components";
import { useContext } from "react";
import { UserDetalheContext } from "../../context/UserPreparaEinsteinContext";

function SobreResidenciaMedicaPage() {
  const scrollTop = useScrollTop();
  const { loading } = useContext(UserDetalheContext);

  scrollTop();

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
    <div className="h-auto py-8">
      <ResidenciaMedicaBanner page="residencia" />
      </div>
      </>
  );
}

export default SobreResidenciaMedicaPage;
