import React, { useState, useEffect, forwardRef, useContext } from "react";
import "./styles.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icon from "../../../../assets/svgs";

import HelmetMetaData from "../../../../shared/components/HelmetMetaData";

import { Share2 } from "lucide-react";

import ShareIcon from "../../../../assets/svgs/share-icon.svg";
import ShareIconWhite from "../../../../assets/svgs/share-icon-white.svg";

import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { isMobile } from "react-device-detect";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import { fetchApi } from "../../../../shared/services/rest-dotnet.service";
import { UserContext } from "../../../../context/UserContext";

interface ItemProps {
  titulo: string;
  descricao: string;
  url: string;
  id?: string;
}

export interface CompartilharProps {
  item: ItemProps;
  color?: "white" | "blue";
  shareUrl?: string;
  newIcon?: boolean;
  clickExt?: boolean;
  anchorElExt?: any;
  setAnchorElExt?: any;
}

interface FacebookShared {
  url: string;
  title: string;
  description: string;
  image: string | undefined;
  type: string;
  locale: string;
}

interface SocialShareProvider {
  shareUrl: string;
}
async function criarRegistroCompartilhamento(data: {
  objetoEducacionalId: number;
  userId: number;
  metodoCompartilhamento: string
 }): Promise<any> {
  const response = await fetchApi("objeto-compartilhado", "POST", JSON.stringify(data)).then(
    (res) => {
      if (res.ok) {
        return true;
      } else {
        return false;
      }
    },
  );

  return response;
}

export const Compartilhar = forwardRef<HTMLAnchorElement, CompartilharProps>(
  (props, ref) => {
    const [dados, setDados] = useState<FacebookShared | null>(null);
    const [anchorEl, setAnchorEl] = useState(
      props.clickExt ? props.anchorElExt : null,
    );
    const [url, setUrl] = useState(
      `${process.env.REACT_APP_ROOT}/api/oe/share/${props.item.id}`,
    );
    const { user } = useContext(UserContext);
    const gtm = useGtm();

    useEffect(() => {
      setUrl(`${process.env.REACT_APP_ROOT}/api/oe/share/${props.item.id}`);
    }, [props.item.id]);

    useEffect(() => {
      if (props.clickExt) {
        setAnchorEl(props.anchorElExt);
      }
    }, [props.anchorElExt, props.clickExt]);

    useEffect(() => {
      if (props.item) {
        setDados({
          url: `${url}`,
          title: props.item?.titulo!,
          description: props.item?.descricao!,
          image: props.item?.url,
          type: "website",
          locale: "pt_br",
        });
      }
    }, [props.item]);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      props.setAnchorElExt?.(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
      props.setAnchorElExt?.(null);
    };

    const handleShare = () => {
      if (navigator.share) {
        // Chama a função share() da API do Web Share
        navigator
          .share({
            title: dados?.title,
            text: dados?.description,
            url: url, // URL para compartilhar
          })
          .then(() => {
            console.log("Compartilhado com sucesso.");
          })
          .catch(() => {});
      } else {
        console.log("A API do Web Share não é suportada neste navegador.");
      }
    };

    const renderizarHelmetFacebook = () => {
      return (
        <>
          {dados && (
            <HelmetMetaData
              quote={`
              Saiba mais sobre "${dados.title}" na Academia Digital Einstein. \n
              A nova geração do conhecimento em saúde!
            `}
              title={dados.title}
              description={dados.description}
              image={dados.image}
              hashtag="#AcademiaDigitalEinstein"
            ></HelmetMetaData>
          )}
        </>
      );
    };

    const getIcon = () => {
      switch (props.color) {
        case "white":
          return ShareIconWhite;
        case "blue":
          return ShareIcon;
        default:
          return ShareIcon;
      }
    };

    return (
      <>
        {dados && (
          <>
            {/* {renderizarHelmetFacebook()}  */}
            <div className="flex">
              <ToastContainer />

              <span
                ref={ref}
                divria-controls="shared-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {!props.newIcon && <Icon.Share />}
              </span>
              {Boolean(anchorEl) && (
                <div className="fixed inset-0 z-[99998] flex items-center justify-center">
                  <div className="absolute inset-0 bg-[#000000] opacity-60"></div>

                  <div className="bg-white relative z-[99998] flex h-[139px] w-[320px] flex-col justify-center rounded-xl px-8">
                    <Icon.NewClose
                      onClick={handleClose}
                      className="absolute right-4 top-4"
                    />

                    <span className="text-center text-xs font-semibold leading-4 text-[#111316]">
                      Compartilhar
                    </span>
                    <div className="mt-2 flex w-full justify-between">
                      <div className="flex cursor-pointer items-center justify-center">
                        <FacebookShareButton
                          url={url}
                          onClick={ async () => {
                            gtm.push(
                              EDataLayerEventCategory.ADE_OE_FACEBOOK,
                              EDataLayerEventAction.CLIQUE,
                              {
                                label: `${dados.title}`,
                              },
                            );
                            await criarRegistroCompartilhamento({
                              metodoCompartilhamento: 'Facebook',
                              objetoEducacionalId: Number(props.item.id),
                              userId: Number(user?.id!),
                            })
                          }}
                          quote={`
                                Saiba mais sobre "${dados.title}" na Academia Digital Einstein. \n
                                A nova geração do conhecimento em saúde!
                              `}
                          hashtag={"#AcademiaDigitalEinstein"}
                          className="wrapper-share-button wrapper-facebook-share-button"
                        >
                          <Icon.ShareFacebook />
                        </FacebookShareButton>
                      </div>

                      <div className="flex cursor-pointer items-center justify-center">
                        <LinkedinShareButton
                          url={url}
                          className="wrapper-share-button wrapper-linkedin-share-button"
                          onClick={async () => {
                            gtm.push(
                              EDataLayerEventCategory.ADE_OE_LINKEDIN,
                              EDataLayerEventAction.CLIQUE,
                              {
                                label: `${dados.title}`,
                              },
                            );
                            await criarRegistroCompartilhamento({
                              metodoCompartilhamento: 'Linkedin',
                              objetoEducacionalId: Number(props.item.id),
                              userId: Number(user?.id!),
                            })
                          }}
                        >
                          <Icon.ShareLindekin />
                        </LinkedinShareButton>
                      </div>
                      <div
                        onClick={handleClose}
                        className="flex cursor-pointer items-center justify-center"
                      >
                        <div
                          className="wrapper-share-button wrapper-link-share-button"
                          onClick={async () => {
                            toast.success("Link copiado!", {
                              position: "top-right",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });

                            gtm.push(
                              EDataLayerEventCategory.ADE_OE_COPIARLINK,
                              EDataLayerEventAction.CLIQUE,
                              {
                                label: `${dados.title}`,
                              },
                            );

                            await criarRegistroCompartilhamento({
                              metodoCompartilhamento: 'Copiar Link',
                              objetoEducacionalId: Number(props.item.id),
                              userId: Number(user?.id!),
                            })

                            navigator.clipboard.writeText(url);
                          }}
                        >
                          <Icon.ShareCopy />
                        </div>
                      </div>

                      {isMobile && (
                        <div
                          onClick={handleClose}
                          className="flex cursor-pointer items-center justify-center"
                        >
                          <div
                            className="wrapper-share-button wrapper-linkedin-share-button"
                            onClick={handleShare}
                          >
                            <Share2 size={10} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  },
);

export default Compartilhar;
