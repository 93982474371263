import React, { useState } from "react";
import CardObjetoEducacional from "../../shared/components/oe-card";
import Container from "../../shared/components/Container";
import { Search } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../shared/components/ui/accordion";
import { Pagination } from "../../shared/components";

interface TypesenseResponse {
  facet_counts: FacetCount[];
  found: number;
  hits: Hit[];
  out_of: number;
  page: number;
  request_params: RequestParams;
  search_cutoff: boolean;
  search_time_ms: number;
}

interface FacetCount {
  counts: Count[];
  field_name: string;
  sampled: boolean;
  stats: {
    total_values: number;
  };
}

interface Count {
  count: number;
  highlighted: string;
  value: string;
}

export interface Hit {
  document: Document;
  highlight: Highlight;
  highlights: HighlightDetail[];
  text_match: number;
  text_match_info: TextMatchInfo;
}

interface Document {
  authors: string[];
  average_duration: string;
  field_of_knowledge: string[];
  has_certificate: boolean;
  id: string;
  id_ob: string;
  is_sponsored: boolean;
  keywords: string[];
  label_description: string[];
  label_id: number[];
  maturity_level: string[];
  media_id: number;
  media_url: string;
  object_type: string;
  object_type_id: number;
  preview_image_description: string;
  preview_image_url: string;
  production_date: number;
  subfield_of_knowledge: string[];
  summary: string;
  target_audience: string[];
  target_audience_id: number[];
  title: string;
  updated_at: number;
}

export interface Highlight {
  keywords?: MatchedSnippet[];
  subfield_of_knowledge?: MatchedSnippet[];
  summary?: MatchedSnippet;
  title?: MatchedSnippet;
}

interface MatchedSnippet {
  matched_tokens: string[];
  snippet: string;
}

interface HighlightDetail {
  field: string;
  matched_tokens: string[];
  snippet: string;
  indices?: number[];
}

interface TextMatchInfo {
  best_field_score: string;
  best_field_weight: number;
  fields_matched: number;
  num_tokens_dropped: number;
  score: string;
  tokens_matched: number;
  typo_prefix_score: number;
}

interface RequestParams {
  collection_name: string;
  first_q: string;
  per_page: number;
  q: string;
}

export interface ObjetoEducacional {
  id: number;
  titulo: string;
  tempoMedioDuracao: string;
  idRotulo: number;
  descricaoRotulo: string;
  tipoObjetoId: number;
  imagemPreviewUrl: string;
  dataProducao: string;
  isPatrocinado: boolean;
  possuiCertificado: boolean;
  finalizado: any;
  resumo: string;
  tipoOe: string;
  idVideoAudio: number;
}

const key = process.env.REACT_APP_X_TYPESENSE_API_KEY;

const SuperBusca: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<TypesenseResponse>();
  const [isLoading, setIsLoading] = useState(false);

  function formatUnixTimestamp(unixTimestamp: number): string {
    // Convert Unix timestamp (in seconds) to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Format the date with uppercase month abbreviation as "dd MMM yyyy"
    const day = date.toLocaleDateString("pt-BR", { day: "2-digit" });
    const month = date.toLocaleDateString("pt-BR", { month: "short" });
    const year = date.toLocaleDateString("pt-BR", { year: "numeric" });

    return `${day} ${month} ${year}`;
  }

  const [facetCounts, setFacetCounts] = useState<FacetCount[]>([]);
  const [page, setPage] = useState<number>(1);

  const handleSearch = async (e: React.FormEvent, currentPage = page) => {
    e.preventDefault();
    setIsLoading(true);

    const params = new URLSearchParams({
      q: searchTerm,
      preset: "ade_adaptive_full_text_search_v1",
      page: currentPage.toString(),
      // facet_by: "object_type,field_of_knowledge,authors",
      per_page: "15",
    });

    try {
      const response = await fetch(
        `https://0vbgn4uizxqa2td6p-1.a1.typesense.net/collections/ade_default_docs_2/documents/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            "X-TYPESENSE-API-KEY": key || "",
          },
        },
      );

      const data: TypesenseResponse = await response.json();
      setResults(data);
      setFacetCounts(data.facet_counts); // Store the facet counts
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event: any, newPage: number) => {
    event.preventDefault();

    setPage(newPage);
    handleSearch(new Event("submit") as unknown as React.FormEvent, newPage); // Pass the new page number
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <Container>
        <span className="text-xs text-gray-600">
          Página provisória para testes
        </span>
        <form onSubmit={handleSearch} className="mb-6 flex">
          <input
            type="text"
            placeholder="o que você está buscando?"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow rounded-l-md border border-gray-300 p-3 focus:outline-none"
          />
          <button
            type="submit"
            className="text-white rounded-r-md bg-blue-500 px-4 py-3 font-semibold hover:bg-blue-600"
          >
            <Search />
          </button>
        </form>
        {facetCounts.length > 0 && <FacetCard facets={facetCounts} />}
        {isLoading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <>
            {results?.found && (
              <span className="mb-3 inline-block w-[127px] !text-sm !font-semibold !leading-18 text-[#111316] md:w-auto">
                {results?.found + " conteúdos disponíveis"}
              </span>
            )}
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
              {results?.hits?.map((hit, index) => (
                <>
                  <CardObjetoEducacional
                    hit={hit}
                    superbusca={true}
                    objetoEducacional={{
                      titulo: hit.document.title,
                      resumo: hit.document.summary,
                      tempoMedioDuracao: hit.document.average_duration,
                      descricaoRotulo: hit.document.label_description[0],
                      tipoOe: hit.document.object_type,
                      imagemPreviewUrl: hit.document.preview_image_url,
                      dataProducao: formatUnixTimestamp(
                        hit.document.production_date,
                      ),
                      isPatrocinado: hit.document.is_sponsored,
                      possuiCertificado: hit.document.has_certificate,
                      id: parseInt(hit.document.id_ob),
                      idRotulo: hit.document.label_id[0],
                      idVideoAudio: hit.document.media_id,
                      finalizado: hit.document.updated_at,
                      tipoObjetoId: hit.document.object_type_id,
                    }}
                  />
                </>
              ))}
            </div>
          </>
        )}{" "}
        <Pagination
          count={Math.ceil((results?.found ?? 0) / 15)}
          page={page}
          onChange={handlePageChange}
        />
      </Container>
    </div>
  );
};

export default SuperBusca;

interface FacetCardProps {
  facets: FacetCount[];
}

const FacetCard: React.FC<FacetCardProps> = ({ facets }) => {
  function formatUnixTimestamp(unixTimestamp: number): string {
    // Convert Unix timestamp (in seconds) to milliseconds
    const date = new Date(unixTimestamp * 1000);

    // Format the date with uppercase month abbreviation as "dd MMM yyyy"
    const day = date.toLocaleDateString("pt-BR", { day: "2-digit" });
    const month = date.toLocaleDateString("pt-BR", { month: "short" });
    const year = date.toLocaleDateString("pt-BR", { year: "numeric" });

    return `${day} ${month} ${year}`;
  }

  return (
    <Accordion type="single" collapsible className="mb-3 w-full">
      <AccordionItem value="item-1 ">
        <AccordionTrigger className="mb-2 rounded-md border bg-white-200 px-4 py-3">
          Resultados
        </AccordionTrigger>
        <AccordionContent className="">
          <div className="bg-white shadow mb-6 rounded-md p-4">
            {facets.map((facet) => (
              <div key={facet.field_name} className="mb-4">
                <h3 className="font-semibold capitalize text-gray-700">
                  {facet.field_name}
                </h3>
                <div className="mt-2 flex flex-wrap">
                  {facet.counts.map((count) => (
                    <div
                      key={count.value}
                      className="mb-2 mr-2 flex h-[42px] items-center justify-center rounded-xl border px-2 py-1 text-sm text-gray-700"
                    >
                      {count.value} ({count.count})
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
