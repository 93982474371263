import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Icon from "../../../assets/svgs";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  If,
  AuthorBox,
  CardContainer,
  Loader,
  CardSection,
  EmptyMessage,
} from "../../../shared/components";

import {
  useGetAuthorByIdLazyQuery,
  useGetObjetoEducacionaisByAuthorLazyQuery,
  PerfilDeAutorEntity,
  ObjetoEducacionalEntity,
} from "../../../generated/graphql";

import { AreasDeInteresse } from "../../_HomePage/components";

import "./styles.scss";
import { AreasDeInteresseAutor } from "../../AreasInteresseAutorPage/components/AreasDeInteresse";
import LinkCustom from "../../../shared/components/Link";
import Container from "../../../shared/components/Container";
const AutorPage: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean | null>(null);
  const [author, setAuthor] = useState<PerfilDeAutorEntity | undefined>(
    undefined,
  );
  const [oes, setOes] = useState<ObjetoEducacionalEntity[]>([]);

  const [knowledgesArea, setKnowledgesArea] = useState<any[]>([]);
  const [id, setId] = useState("");

  const params = useParams<{ id: string }>();

  const [GetAuthor] = useGetAuthorByIdLazyQuery({
    variables: {
      id: id || "0",
    },
    onCompleted: (data) => {
      setLoading(false);
      setAuthor(data.perfilDeAutor?.data as PerfilDeAutorEntity);
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  const [GetOes] = useGetObjetoEducacionaisByAuthorLazyQuery({
    variables: {
      authorId: id || "0",
    },
    onCompleted: (data) => {
      setOes([
        ...(data.perfilDeAutor?.data?.attributes?.Publicacoes
          ?.data as ObjetoEducacionalEntity[]),
      ]);
    },
    onError: (err) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    setId(params.id);
  }, [params.id]);

  useEffect(() => {
    if (loading === null) {
      setLoading(true);
      GetAuthor();
      GetOes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!!author) {
      const areas = author.attributes?.Publicacoes?.data.reduce((acc, item) => {
        item.attributes?.SubAreaConhecimento?.data.forEach((area) => {
          if (
            area.attributes?.area_de_conhecimento?.data?.id &&
            !(area.attributes.area_de_conhecimento.data?.id! in acc) &&
            !!area.attributes.area_de_conhecimento?.data?.attributes?.icone
          ) {
            acc[area.attributes?.area_de_conhecimento.data?.id!] = {
              ...area.attributes.area_de_conhecimento.data.attributes,
              id: area.attributes.area_de_conhecimento.data.id,
            };
          }
        }, {});

        return acc;
      }, {} as any);

      const _ = Object.keys(areas).map((key) => areas[key]);
      setKnowledgesArea(_);
    }
  }, [author]);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <HeaderSection>
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/central-de-autorias">
            Central de Autoria
          </BreadcrumbItem>
          <If test={!!author}>
            <BreadcrumbItem active>
              {author?.attributes?.nome_exibicao}
            </BreadcrumbItem>
          </If>
        </Breadcrumb>
      </HeaderSection>

      <If test={!!author}>
        <Container className="!py-5">
          <section>
            <CardContainer shadow>
              <AuthorBox author={author!} isDetailPage={true} />
            </CardContainer>
          </section>

          <section data-author-knowledge-area className="mt-5">
            <div className="flex flex-row justify-between">
              <LinkCustom to={`/autor/${params.id}/detalhes/areas-interesse`}>
                <h3 className="text-base font-semibold md:text-xxl">
                  Áreas de Interesse
                </h3>
              </LinkCustom>
              {knowledgesArea.length > 3 && (
                <LinkCustom
                  to={`/autor/${params.id}/detalhes/areas-interesse`}
                  className="card-section__link"
                >
                  VER TUDO <Icon.ArrowRight />
                </LinkCustom>
              )}
            </div>
            <AreasDeInteresseAutor
              idAutor={params.id}
              areas={knowledgesArea.map((a) => ({
                icone: {
                  url: a?.icone?.data?.attributes?.url!,
                },
                id: a.id!,
                titulo: a?.titulo!,
              }))}
            />

            <If test={!knowledgesArea.length}>
              <EmptyMessage message="Não existe nenhuma área de interesse relacionada."></EmptyMessage>
            </If>
          </section>

          <section data-author-multimedia-contents className="py-10">
            <CardSection
              title="Conteúdos do Autor"
              items={oes}
              notLimit={true}
              linkUrl={`/autor/${params.id}/detalhes/oe`}
              showAllSee={true}
            ></CardSection>
          </section>
        </Container>
      </If>
    </>
  );
};

export default AutorPage;
