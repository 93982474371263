import { useLocalStorage } from "./useLocalStorage";

export const useNovoUsuario = <T,>() => {
  const KEY = "he_novo_usuario";

  const ls = useLocalStorage();

  const save = (value: any) => {
    return new Promise((resolve) => {
      let cloned: T = {
        ...value,
      };

      ls.setValue(KEY, cloned);
      resolve(undefined);
    });
  };

  const clean = () => {
    ls.remove(KEY);
  };

  const load = async () => {
    const _user = await ls.getById<T>(KEY);
    return _user;
  };

  return { save, clean, load };
};
