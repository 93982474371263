import React, { useEffect, useState } from "react";
import {
  ObjetoEducacionalEntity,
  QuizEntity,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import OeTesteConhecimentoPreview from "./OeTesteConhecimentoPreview";

import OeQuizPage from "../OeQuizPage";
import { useParams } from "react-router";
import { loadOePageAction } from "../../../redux/oe/oe.actions";
import { GetRespostasQuestionario } from "../../../graphql/collections/queries/quizz.query";

interface OeTesteConhecimento {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}

export const OeTesteConhecimento: React.FC<OeTesteConhecimento> = (props) => {
  const [isPreview, setPreview] = useState<boolean>(true);
  const [isTesteConhecimento, setIsTesteConhecimento] =
    useState<boolean>(false);
  const params = useParams<{ quizId: string }>();

  const verificarSeJaFoiRespondido = async (quiz: QuizEntity | null) => {
    if (quiz == null) {
      return;
    }
    if (params && params.quizId) {
      return;
    }

    const quizData = await loadOePageAction(parseInt(quiz.id!));

    const respostaData = await GetRespostasQuestionario(
      parseInt(quiz.id!),
      parseInt(props.user?.id!)
    ).toPromise();

    if (
      quizData.payload.data[0].perguntas.length <= (respostaData?.length || 0)
    ) {
      setPreview(false);
      window.location.href = `/oe/${props.oe.id}/quiz/${quiz.id}`;
    }
  };

  useEffect(() => {
    if (
      props.oe.attributes?.Rotulos?.data.find((item) => {
        return (
          item.attributes?.titulo?.toUpperCase() === "TESTE SEUS CONHECIMENTOS"
        );
      })
    ) {
      setIsTesteConhecimento(true);
    } else {
      setIsTesteConhecimento(false);
    }
    if (!props.oe) return;
    setPreview(true);
    verificarSeJaFoiRespondido(props.oe.attributes?.Quizzes?.data[0] || null);
    if (!!params && !!params.quizId) {
      const _quizAtual = props.oe.attributes?.Quizzes?.data.find(
        (item) => item.id === params.quizId
      );

      if (!_quizAtual) {
        window.location.href = "/";
        return;
      }

      setPreview(false);
    }
  }, [props.oe, params, window.location.pathname]);

  return (
    <>
      {isPreview && isTesteConhecimento ? (
        <OeTesteConhecimentoPreview oe={props.oe} user={props.user} />
      ) : (
        <OeQuizPage oe={props.oe} user={props.user} />
      )}
    </>
  );
};
