import React, { useEffect, useRef, useState } from "react";

import {
  PerfilDeAutor,
  useGetAuthorAllLazyQuery,
  useGetAuthorsV2LazyQuery,
} from "../../../generated/graphql";
import {
  AuthorBox,
  HeaderSection,
  If,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  SelectField,
  InputSearch,
  Loader,
} from "../../../shared/components";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import { isMobile } from "react-device-detect";
import "./styles.scss";
import { useScrollTop } from "../../../shared/hooks/useScrollTop";
import Container from "../../../shared/components/Container";
import { nfkd } from "unorm";
import {
  InputSearchProps,
  InputSearchRef,
} from "../../../shared/components/InputSearch";
import HeaderPageBreadcrumb from "../../../shared/components/HeaderPageBreadcrumb";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../shared/components/ui/select";
import { Label } from "../../../shared/components/ui/label";
import { Input } from "../../../shared/components/ui/input";
import { Search } from "lucide-react";

const AutoresPage: React.FC = () => {
  const [authors, setAuthors] = useState<PerfilDeAutor[]>([]);

  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(
    `quantidade desc, lower(unaccent(trim(nome_exibicao))) COLLATE "C" asc`,
  );

  const [page, setPage] = useState(1);
  const [start, setStart] = useState(0);
  const [offset, setOffset] = useState(isMobile ? 5 : 10);
  const [totalBullets, setTotalBullets] = useState(0);
  const gtm = useGtm();
  const scrollTop = useScrollTop();
  const [textSearch, setTextSearch] = useState("");

  const inputSearchRef = useRef<InputSearchRef | null>();

  const [GetAutores, { data: autores }] = useGetAuthorAllLazyQuery({
    variables: {
      where: {
        Publicacoes: {
          id: {
            notNull: true,
          },
        },
      },
    },
  });

  const [GetAuthors, { loading }] = useGetAuthorsV2LazyQuery({
    variables: {
      start,
      limit: offset,
      sort,
      search: search || "",
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.perfilDeAutorsConnectionV2) {
        const { values, aggregate } = data.perfilDeAutorsConnectionV2;

        const { count, totalCount } = aggregate!;

        const _count = totalCount! > count! ? count! : totalCount!;

        const bulletCount = _count ? Math.abs(Math.ceil(_count / offset)) : 0;

        setTotalBullets(bulletCount);
        setAuthors((values?.map((item) => item!) || []) as PerfilDeAutor[]);
        inputSearchRef.current?.clearInput();
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    scrollTop();
    GetAutores();
    GetAuthors();

    // GTM doc linha 72
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "pagina_central_autoria",
      },
    );
  }, []);

  const handlePageChange = (event: any, page: number) => {
    event.preventDefault();

    scrollTop();

    const LIMIT = isMobile ? 5 : 10;

    setStart(page - 1 === 0 ? 0 : page * LIMIT - LIMIT);
    setOffset(LIMIT);
    setPage(page);

    GetAuthors();
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.target;
    if (event.key === "Enter" || event.key === "click") {
      setStart(0);
      setPage(1);
      setSearch(value);
      GetAuthors();
    }
  };

  const handleClick = (value: any) => {
    setSearch(value);
    GetAuthors();
  };

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setTextSearch(value);
    if (event.key === "Enter") {
      setSearch(value);
      setStart(0);
    }
  };

  // useEffect(() => {
  //   GetAuthors();
  // }, [GetAuthors]);

  const data_autores = (
    autores?.perfilDeAutors?.data.map(
      (data) => data.attributes?.nome_completo!,
    ) || []
  ).filter((a) => {
    const nomeCompletoNormalizado = nfkd(a).replace(/[\u0300-\u036f]/g, "");
    const textSearchNormalizado = nfkd(textSearch).replace(
      /[\u0300-\u036f]/g,
      "",
    );

    return nomeCompletoNormalizado
      .toLowerCase()
      .includes(textSearchNormalizado.toLowerCase());
  });
  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <HeaderPageBreadcrumb
        itens={[{ to: "/", title: "Home" }, { title: "Central de Autoria" }]}
        title="Autores"
      />

      <section>
        <Container className="py-4">
          <div className="flex justify-end  gap-4  py-6">
            <div className="space-y-2 content-end">
              <Select
                name="selectSort"
                value={sort}
                onValueChange={(e) => {
                  setSort(e);
                  setSearch("");
                }}
              >
                <SelectTrigger className="w-full bg-[#FAFAFC]">
                  <SelectValue placeholder="Selecione uma ordenação" />
                </SelectTrigger>
                <SelectContent id="PerfilProfissao" className="">
                  <SelectGroup>
                    {[
                      {
                        value: `quantidade desc, lower(unaccent(trim(nome_exibicao))) COLLATE "C" asc`,
                        label: "Selecione uma ordenação",
                      },
                      {
                        value: `lower(unaccent(trim(nome_exibicao))) COLLATE "C" asc`,
                        label: "Ordenar por: Ordem alfabética A-Z",
                      },
                      {
                        value: `lower(unaccent(trim(nome_exibicao))) COLLATE "C" desc`,
                        label: "Ordenar por: Ordem alfabética Z-A",
                      },
                    ].map((item) => (
                      <SelectItem key={item.value} value={item.value}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="w-[400px]">
              <InputSearch
                handleChange={handleSearch}
                handleKeyDown={handleKeyDown}
                handleClick={handleClick}
                placeHolder="Busque por nome"
                suggestions={data_autores || []}
                ref={(inputSearch) => {
                  inputSearchRef.current = inputSearch;
                }}
              />
            </div>
          </div>
          <Pagination
            count={totalBullets}
            page={page}
            onChange={handlePageChange}
            disabled={loading}
          />

          <div role="list" className="mt-9  grid grid-cols-1 grid-rows-3 gap-4 md:grid-cols-2 tablet:grid-cols-1">
            {authors.map((item, i: number) => (
              <div key={i} className="flex rounded-lg bg-[#F5F8FF] shadow-md">
                <AuthorBox
                  author={{
                    id: item.id,
                    attributes: item,
                  }}
                />
              </div>
            ))}
          </div>
          <Pagination
            count={totalBullets}
            page={page}
            onChange={handlePageChange}
            disabled={loading}
          />
        </Container>
      </section>
      {/* <HeaderSection>
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem active>Central de Autoria</BreadcrumbItem>
        </Breadcrumb>

        <div
          data-flex
          data-width="100"
          data-justify-content="sb"
          data-align-items="center"
          data-header-authors
        >
          <h3 className="text-base text-white font-semibold leading-none md:text-xxl text-base">
            Autores
          </h3>

          <div
            data-flex
            data-wrap
            data-align-items="center"
            data-authors-filters
          >
            <div data-flex>
              <SelectField
                color="white"
                name="selectSort"
                value={sort}
                label="Ordenar por: Ordem alfabética A-Z"
                items={[
                  {
                    value: `quantidade desc, lower(unaccent(trim(nome_exibicao))) COLLATE "C" asc`,
                    label: "Selecione uma ordenação",
                  },
                  {
                    value: `lower(unaccent(trim(nome_exibicao))) COLLATE "C" asc`,
                    label: "Ordenar por: Ordem alfabética A-Z",
                  },
                  {
                    value: `lower(unaccent(trim(nome_exibicao))) COLLATE "C" desc`,
                    label: "Ordenar por: Ordem alfabética Z-A",
                  },
                ]}
                handleChange={(ev) => {
                  setSort(`${ev.target.value}`);
                  setSearch("");
                }}
                size="sm"
              />
            </div>
            <div>
              <InputSearch
                handleChange={handleSearch}
                handleKeyDown={handleKeyDown}
                handleClick={handleClick}
                placeHolder="Busque por nome"
                suggestions={data_autores || []}
                ref={(inputSearch) => {
                  inputSearchRef.current = inputSearch;
                }}
              />
            </div>
          </div>
        </div>
      </HeaderSection>

      <section>
        <Container>
          <div data-authors-container>
            <If test={!authors.length && !loading}>
              <div data-flex className="flex-jc-c">
                Não existem autores cadastrados.
              </div>
            </If>

            <If test={loading}>
              <div data-flex className="flex-jc-c">
                Carregando...
              </div>
            </If>

            <If test={!!authors.length && !loading}>
              <Pagination
                count={totalBullets}
                page={page}
                onChange={handlePageChange}
                disabled={loading}
              />

              {authors.map((item, idx) => (
                <div data-card key={idx}>
                  <AuthorBox
                    author={{
                      id: item.id,
                      attributes: item,
                    }}
                  />
                </div>
              ))}
              <Pagination
                count={totalBullets}
                page={page}
                onChange={handlePageChange}
                disabled={loading}
              />
            </If>
          </div>
        </Container>
      </section> */}
    </>
  );
};

export default AutoresPage;
