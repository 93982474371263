import React, { ReactNode } from "react";
import cx from "classnames";
import usePagination from "./usePagination";
import { PaginationItem, PaginationItemProps } from "./index";

import "./Pagination.scss";
import { twMerge } from "tailwind-merge";

interface Props {
  /** Total de resultados, corresponde ao PageSize / Total de Resultados*/
  count: number;
  /** Pagina inicial ex: 1*/
  page: number;
  /** Bullet click handler*/
  onChange?: any;
  /** Desabilita todos os bullets, pode ser usado em combinação com loading state*/
  disabled?: boolean;
  /** Renderiza custom pagination render bullets */
  render?: (items: PaginationItemProps) => ReactNode;

  className?: string;
}

const Pagination = ({
  count,
  page,
  onChange,
  render,
  className,
  ...rest
}: Props) => {
  const { items } = usePagination({
    count,
    onChange,
    page,
    ...rest,
  });

  return (
    <nav className={twMerge("ade-pagination", className)}>
      <ul>
        {items.map((item, index) => (
          <li key={index} className={cx({ active: item.selected })}>
            {(render && render(item)) || <PaginationItem {...item} />}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;
