import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import {
  EtapasCadastroV2,
  AreasInteresseV2,
  DadosPessoaisV2,
  DadosProfissionaisV2,
} from "./components";
import { useLocalStorage } from "../../hooks/useLocalStorage";

import "./styles.scss";
import Container from "../../shared/components/Container";

export const NovoUsuarioPageV2: React.FC = () => {
  const location = useLocation();
  const [originState, setOriginState] = useState<string>(
    (location?.state as { origin: string })?.origin!,
  );
  const [path, setPath] = useState("");
  const ls = useLocalStorage();
  const history = useHistory();

  useEffect(() => {
    const _path = window.location.pathname;
    setPath(_path);
  }, [window.location.pathname]);

  useEffect(() => {
    ls.getById("he_token").then((data) => {
      if (!!data) {
        history.push("/");
      }
    });
  }, []);

  return (
    <>
      <Container className="py-4">
        <>
          {path === "/novo-usuario-v2/dados-pessoais" && (
            <>
              <EtapasCadastroV2 formStep={1} />
              <DadosPessoaisV2 />
            </>
          )}
          {path === "/novo-usuario-v2/dados-profissionais" && (
            <>
              <EtapasCadastroV2 formStep={2} />
              <DadosProfissionaisV2 />
            </>
          )}
          {path === "/novo-usuario-v2/areas-de-interesse" && (
            <>
              <EtapasCadastroV2 formStep={3} />
              <AreasInteresseV2 origin={originState} />
            </>
          )}
        </>
      </Container>
    </>
  );
};
