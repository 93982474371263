interface Props {
  width?: string;
  height?: string;
  color?: string;
}

function IconVideo({
  width = "1rem",
  height = "1rem",
  color = "currentColor",
  ...props
}: Props) {
  return (
    <svg
      stroke={color}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.215 7.647a.706.706 0 00-.724.035l-3.138 2.087V7.56a1.412 1.412 0 00-1.412-1.412H2.824A1.412 1.412 0 001.412 7.56v9.882a1.412 1.412 0 001.412 1.412H16.94a1.412 1.412 0 001.412-1.412v-2.206l3.138 2.092a.705.705 0 00.392.114.706.706 0 00.705-.706v-8.47a.706.706 0 00-.373-.618zm-5.274 9.794H2.824V7.56H16.94v9.882zm4.236-2.025l-2.824-1.882v-2.068l2.824-1.878v5.828z" />
    </svg>
  );
}

export default IconVideo;
