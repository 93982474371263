import Container from "../../shared/components/Container";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { ObjetoEducacional } from "../../models/ObjetoEducacional";

async function getContinueAssistindo() {
  const response = await fetchApi(
    "objeto-educacional/get-objeto-educacional-card-assistidos",
    "GET",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function ContinueAssistindoOe() {
  const [assistidos, setAssistidos] = useState<ObjetoEducacional[]>();
  const scrollTop = useScrollTop();

  const location = useLocation();

  const loadContinueAssistindo = async () => {
    setAssistidos(await getContinueAssistindo());
  };

  useEffect(() => {
    loadContinueAssistindo();
  }, []);

  scrollTop();

  return (
    <>
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-9 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/continue-assistindo">
                  Continue assistindo
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-1.5xl font-semibold normal-case text-white-200">
            Continue assistindo
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen py-9 pb-9">
        <div className="flex flex-col gap-10">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            {assistidos?.map((_: ObjetoEducacional, index: number) => (
              <div key={index} className="p-1">
                <CardObjetoEducacional objetoEducacional={_} />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}
