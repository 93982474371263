import React, { useEffect } from "react";
import Container from "../../../../shared/components/Container";
import { CardSection, Loader } from "../../../../shared/components";
import { BreadCrumbQuiz } from "./BreadCrumb";
import { RotuloDesktopQuiz } from "./RotuloDesktop";
import ProgressoTesteConhecimento from "../../components/ProgressoTesteConhecimento";
import { PercentualTesteConhecimento } from "./PercentualTesteConhecimento";
import PercepcaoQuestionario from "../../components/PercepcaoQuestionario";
import { ModalConfirmacao } from "./ModalConfirmacao";
import { useObjetoEducacionalQuery } from "../../../../generated/graphql";
import { useOE } from "../../../../hooks/useOe";

export interface FeedbackAcertoQuizProps {
  loading: boolean;
  user?: any;
  oe: any;
  idQuiz: string;
  qtdAcertada: number;
  perguntasTotais: number;
  percentualUsuario: number;
  percentualOutros: number;
  showModalConfirmacao: boolean;
  onResetQuiz: () => void;
  onCloseModal: () => void;
  onClickRefazer: () => void;
  isResidenciaMedica?: boolean;
}

const FeedbackAcertoQuiz: React.FC<FeedbackAcertoQuizProps> = ({
  loading,
  user,
  oe,
  idQuiz,
  qtdAcertada,
  perguntasTotais,
  percentualUsuario,
  percentualOutros,
  showModalConfirmacao,
  isResidenciaMedica,
  onResetQuiz,
  onCloseModal,
  onClickRefazer,
}) => {
  const { buscarOes, resultadoOE } = useOE();

  useEffect(() => {
    buscarOes(0, "", {
      Rotulos: {
        titulo: {
          eq: oe.attributes?.Rotulos.data[0]?.attributes?.titulo,
        },
      },
      id: {
        ne: oe.id,
      },
    });
  }, [user.id, oe.id]);

  return (
    <Container>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <section className="quizz mx-auto flex w-full flex-col md:w-[752px]">
        <header>
          {!isResidenciaMedica && <BreadCrumbQuiz />}
          <RotuloDesktopQuiz oe={oe} />
          {/* O codigo enviado em progressoValue definira qual imagem e text aparecera */}
          <ProgressoTesteConhecimento
            progressoValue={(qtdAcertada / perguntasTotais) * 100}
            qtdAcertada={qtdAcertada}
            qtdPerguntas={perguntasTotais}
          />
        </header>
        <div className="my-7 flex w-full justify-center gap-2 md:mt-8">
          <button
            data-testid="btn-refazer"
            style={{
              border: "2px solid #004F92",
              display: "",
            }}
            className="!bottom-2 flex h-[52px] w-[164px] flex-shrink-0 items-center justify-center rounded-3xl border-[#004f92] px-[14.046px] py-[11.954px]
            !text-base font-medium leading-4 text-[#004f92] hover:bg-[#005bab50]"
            onClick={() => onClickRefazer()}
          >
            {"Refazer"}
          </button>
        </div>
        <PercentualTesteConhecimento
          percentualUsuario={percentualUsuario}
          percentualOutros={percentualOutros}
        />
        <div>
          <PercepcaoQuestionario
            idQuestionario={idQuiz.toString()!}
            idUsuario={user?.id!}
          />
        </div>
        <ModalConfirmacao
          show={showModalConfirmacao}
          setShow={() => onCloseModal()}
          resetQuiz={() => onResetQuiz()}
        />
      </section>
      {!isResidenciaMedica && resultadoOE?.length > 0 && (
        <CardSection
          title="Faça outros testes como esse"
          items={resultadoOE || []}
          certificados={false}
          showAllSee={false}
        />
      )}
    </Container>
  );
};

export default FeedbackAcertoQuiz;
