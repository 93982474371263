import React from "react";
import usePercepcaoQuestionario from "../../../../hooks/usePercepcaoQuestionario";
import { Enum_Percepcaoquestionario_Percepcao } from "../../../../generated/graphql";

const PercepcaoQuestionario: React.FC<{
  idUsuario: string;
  idQuestionario: string;
}> = ({ idUsuario, idQuestionario }) => {
  const { data, savePercepcaoQuestionario } = usePercepcaoQuestionario(
    idUsuario,
    idQuestionario,
  );

  return (
    <div className="!mt-6 flex w-full flex-col border-y-[1px] border-[#B5B6B9] py-3">
      <span className="text-center text-xs font-semibold text-[#111316]">
        O que você achou desse teste?
      </span>
      <div className="mt-3 flex justify-center md:justify-center">
        <button
          data-testid="btn-facil-percepcao"
          className={`flex h-16 w-16 items-center justify-center rounded-full !border-2 !border-[#00B193] text-sm font-bold ${
            data?.percepcao === Enum_Percepcaoquestionario_Percepcao.Facil
              ? "text-white bg-[#00B193df]"
              : ""
          }`}
          style={{ margin: "0 10px", border: "1px solid #00B193" }}
          onClick={() =>
            savePercepcaoQuestionario({
              id: data?.id,
              percepcao: Enum_Percepcaoquestionario_Percepcao.Facil,
              idQuestionario: idQuestionario,
              idUsuario,
            })
          }
        >
          Fácil
        </button>
        <button
          data-testid="btn-medio-percepcao"
          className={`flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#107B95] text-sm font-bold ${
            data?.percepcao === Enum_Percepcaoquestionario_Percepcao.Medio
              ? "text-white bg-[#107B95df]"
              : ""
          }`}
          style={{ margin: "0 10px", border: "1px solid #107B95" }}
          onClick={() =>
            savePercepcaoQuestionario({
              id: data?.id,
              percepcao: Enum_Percepcaoquestionario_Percepcao.Medio,
              idQuestionario: idQuestionario,
              idUsuario,
            })
          }
        >
          Médio
        </button>
        <button
          data-testid="btn-dificil-percepcao"
          className={`flex h-16 w-16 items-center justify-center rounded-full border-2 border-[#E62565] text-sm font-bold ${
            data?.percepcao === Enum_Percepcaoquestionario_Percepcao.Dificil
              ? "bg-[#E62565df] text-white-200"
              : ""
          }`}
          style={{ margin: "0 10px", border: "1px solid #E62565" }}
          onClick={() =>
            savePercepcaoQuestionario({
              id: data?.id,
              percepcao: Enum_Percepcaoquestionario_Percepcao.Dificil,
              idQuestionario: idQuestionario,
              idUsuario,
            })
          }
        >
          Difícil
        </button>
      </div>
    </div>
  );
};

export default PercepcaoQuestionario;
