import React from "react";

import "./styles.scss";
import { ArrowLeft } from "lucide-react";
import { useHistory } from "react-router";

export const OEBackButton: React.FC = () => {
  const history = useHistory();

  return (
    <button
      data-testid="btn-volta-pagina-anterior"
      style={{ display: history.length ? "flex" : "none" }}
      onClick={() => history.goBack()}
      aria-label="Voltar"
    >
      <ArrowLeft className="size-10 text-white-200" />
    </button>
  );
};
