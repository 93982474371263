import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import {
  ObjetoEducacionalEntity,
  useGetOeVisualizedByUserLazyQuery,
} from "../generated/graphql";

const useOeHistory = () => {
  const [data, setData] = useState<ObjetoEducacionalEntity[]>([]);
  const [tipoId, setTipoId] = useState<string>("");
  const { user } = useContext(UserContext);

  const [GetOeVisualizedByUser, { loading }] =
    useGetOeVisualizedByUserLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (response) => {
        const _tipoData = [...response.usersPermissionsUser?.data?.attributes?.OesVisualizados?.data!];

        if (!!_tipoData) {
          setData([
            ...data,
            ..._tipoData as ObjetoEducacionalEntity[]
          ]);
        }
      },
    });

useEffect(() => {
  const regex =
    /\/meu-perfil\/historico\/(.*)\/(quizzes-e-desafios|audios|videos|textos-e-artigos|imagens-e-infograficos)/;
  let path = window.location.pathname;
  path = path.replace(regex, "$2");

  const tipoObjeto: any = {
    "1": "quizzes-e-desafios",
    "2": "imagens-e-infograficos",
    "3": "audios",
    "4": "textos-e-artigos",
    "5": "quizzes-e-desafios",
    "6": "videos",
  };

  const _tiposIds = Object.keys(tipoObjeto).reduce<string[]>((acc, item) => {
    if (path.includes(tipoObjeto[item])) {
      acc.push(item);
    }

    return acc;
  }, []);

  if (!!_tiposIds && !!_tiposIds.length) {
    for (const item of _tiposIds) {
      setTipoId(item);
      GetOeVisualizedByUser({
        variables: {
          id: user?.id!,
          tipo: item,
        },
      });
    }
  }
}, []);

return { data, loading };
};

export default useOeHistory;
