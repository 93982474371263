import React from "react";
import PersonalData from "./components/PersonalData";
import ProfessionalData from "./components/ProfessionalData";
import UserPreferences from "./components/UserPreferences";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import Container from "../../../../shared/components/Container";

interface IEditProfileProps {
  path: string;
  user: any
}

const EditProfile: React.FC<IEditProfileProps> = ({ path, user }) => {
  let active = 1;
  const isMobile = document.body.offsetWidth <= 480 ? true : false;

  const gtm = useGtm();

  const switchComponent = () => {
    switch (path) {
      case "/meu-perfil/informacoes-pessoais":
        active = 1;
        return <PersonalData user={user} />;

      case "/meu-perfil/informacoes-profissionais":
        active = 2;
        return <ProfessionalData user={user} />;

      case "/meu-perfil/preferencias-de-conteudo":
        active = 3;
        return <UserPreferences user={user} />;
    }
  };

  const renderAll = () => {
    return (
      <>
        <PersonalData user={user} />
        <ProfessionalData user={user}  />
        <UserPreferences user={user}  />
      </>
    );
  };

  return (
    <section data-edit-profile>
      {/* <Container> */}
      <section>
        <h4>Editar Perfil</h4>
        {!isMobile ? switchComponent() : renderAll()}
      </section>
      <nav className="flex min-w-[300px] text-end text-sm font-normal">
        <a
          href="/meu-perfil/informacoes-pessoais"
          className={active === 1 ? "active " : ""}
        >
          Informações
          <br />
          Pessoais
        </a>
        <a
          href="/meu-perfil/informacoes-profissionais"
          className={active === 2 ? "active" : ""}
        >
          Informações
          <br />
          Profissionais
        </a>
        <a
          href="/meu-perfil/preferencias-de-conteudo"
          className={active === 3 ? "active" : ""}
          onClick={(ev) => {
            ev.stopPropagation();
            gtm.push(
              EDataLayerEventCategory.ADE_MEU_PERFIL,
              EDataLayerEventAction.CLIQUE,
              {
                label: "editar-perfil_area",
              },
            );
          }}
        >
          Preferências
          <br />
          de Conteúdo
        </a>
      </nav>
      {/* </Container> */}
    </section>
  );
};

export default EditProfile;
