import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../../lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center  whitespace-nowrap rounded-4xl tablet:!text-[13.09px] !text-sm md:text-base font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "text-white-200 hover:bg-[#0077CF] bg-[#004F92]",
        disabled: "text-[#B6BCC9] shadow-sm bg-[#E7E9ED]",
        outline:
          "border border-input shadow-sm hover:border-[#0A589B] text-[#0A589B] border-[#0A589B] border-[1px]",
        link: "text-primary underline-offset-4 hover:underline",
        pagination:
          "text-[#0A589B] rounded-full border bg-transparent hover:bg-[#0A589B] hover:text-white-200",
        paginationSelected:
          "text-[#0A589B] rounded-full border bg-transparent hover:bg-[#09487f] hover:text-white-200",
      },
      size: {
        default:
          "h-12.5 tablet:h-[47.74px] rounded-5xl !py-[0.625rem] !px-6 tablet:px-8 md:px-9 md:py-4",
        sm: "h-9.5 rounded-5xl py-3 px-7 text-[13.09px]",
        lg: "h-14.5 rounded-5xl px-10 py-5 ",
        icon: "rounded-full px-8 h-12 py-3",
        pagination: "h-9 w-9 ",
        none: "-ml-1",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  "data-testid": string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
