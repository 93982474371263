import { cn } from "../../utils/utils";
import { ModalService } from "../Modal";
import { Button } from "../ui/button";

export interface ModalPlaylistProps {
  imagem: string;
  titulo: string;
  descricao: string;
  url: string;
}

export default function ModalPlaylist({ imagem, url, titulo, descricao }: ModalPlaylistProps) {
  return (
    <div className="bg-white flex flex-col justify-center gap-6 rounded-xl p-9 text-center md:p-12">
      <div className="flex items-center justify-center rounded-xl">
        <div className="flex flex-col w-full items-center justify-center max-w-[350px]">
          <img
              data-testid="img-prepara"
              src={imagem}
              alt="PlayList"
              className={cn(
                "h-[200px] w-[350px] cursor-pointer object-cover object-center",
              )}
          />
          <span className="text-sm font-opensans font-medium leading-22 text-[#008BC9] mt-6">Conheça também</span>
          <span className="text-xxl font-opensans font-bold leading-22 text-[#004F92]">{titulo}</span>
          <span className="text-base font-opensans font-normal leading-22 text-[#111316] mt-6">{descricao}</span>
          <Button
          data-testid="btn-conheca-tb"
          onClick={() => {
            window.open(url, '_blank');
            ModalService.hide()
          }}
          className="relative mt-4 h-[50px] w-auto rounded-[26px] bg-[#004F92] px-4 !text-base font-semibold text-[#FFFFFF] hover:bg-[#3777af]	md:mt-12 "
        >
          Confira o curso
        </Button>
        </div>
      </div>
    </div>
  );
}
