import { Component } from "react";

import { If } from "..";

import "./styles.scss";

interface KeywordsProps {
  data: string;
}

interface KeywordsState {
  data: string;
}

export class Keywords extends Component<KeywordsProps, KeywordsState> {
  constructor(props: KeywordsProps) {
    super(props);

    this.state = {
      data: "",
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  render() {
    return (
      <If test={!!this.state.data && this.state.data.length > 0}>
        <div data-keywords>
          Palavras-chave:{" "}
          {this.state.data
            .split(", ")
            .map(
              (keyword, index) =>
                `${keyword}${index !== this.state.data.length - 1 ? ", " : ""}`
            )}
        </div>
      </If>
    );
  }
}
