import { MessageCircleQuestion } from "lucide-react";

import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../components/ui/card";
import { useHistory } from "react-router";

export default function ModalEmailEnviado({
  email,
  handleClose,
}: {
  email: string;
  handleClose: any;
}) {
  const history = useHistory();
  return (
    <Card className="w-[90vw] sm:w-[450px]">
      <CardHeader className="text-lg font-bold text-[#004F92]">
        Link de verificação
      </CardHeader>
      <CardContent className="flex flex-col gap-3 px-10">
        <span className="text-base font-semibold text-[#111316]">
          Verificação por e-mail
        </span>
        <span className="text-[15px] font-normal text-[#313335]">
          {`Enviamos um link para o e-mail ${email}`}
        </span>
        <div className="flex flex-col items-start justify-start gap-3 rounded-xl bg-[#F5F8FF] p-4 text-start">
          <div>
            <MessageCircleQuestion color="#004F92" className="size-5 " />
          </div>
          <span className="text-sm font-normal text-[#313335]">
            Não recebeu o e-mail?
          </span>
          <span className="text-sm font-bold text-[#313335]">
            Entre em contato com nosso suporte.
          </span>
          <a
            className="text-sm font-normal text-[#313335] underline"
            href="mailto:academia.digital@einstein.br"
          >
            academia.digital@einstein.br
          </a>
          <button className="text-sm font-normal text-[#313335]">
            (11) 2151-1001
          </button>
        </div>
      </CardContent>
      <CardFooter className="flex justify-center">
        <Button
          className="h-[58px] w-full max-w-[224px]"
          data-testid="voltar-para-home"
          onClick={() => {
            handleClose();
            history.push("/");
          }}
        >
          Voltar para home
        </Button>
      </CardFooter>
    </Card>
  );
}
