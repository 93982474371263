import fetchApi from "../../services/rest-dotnet.service";
import { useContext } from "react";
import { AlertContext } from "../../../context/UseAlert";
import { Button } from "../ui/button";
import * as Icon from "../../../assets/svgs";
import { ModalService } from "../Modal";

export function ModalPlaylistConfirmDelete({
  idPlaylist,
}: {
  idPlaylist: number;
}) {
  const { showMessage } = useContext(AlertContext);

  async function deletePlaylist() {
    fetchApi(`playlists/remove-playlist?playlistId=${idPlaylist}`, "POST")
      .then(async () => {
        showMessage("Playlist removida com sucesso!", "SUCESS");
        ModalService.hide();
        window.location.reload();
      })
      .catch(() => {
        showMessage("Erro ao remover a playlist.", "ERRO");
      });
  }

  return (
    <div className="bg-white z-50 flex h-[25.68rem] w-[20rem] flex-col items-center justify-center gap-3 rounded-3xl px-8 md:h-auto md:w-[450px] md:px-12 md:py-12 ">
      <Icon.modalConfirm className="w-[160px]" />
      <div className="flex flex-col gap-3 text-center">
        <h2 className="text-base font-bold normal-case leading-22 text-[#004F92]">
          Você tem certeza que deseja apagar esta playlist?
        </h2>
        <p className=" text-center text-sm font-normal leading-5 text-black-900">
          Ao clicar em apagar playlist, ela será completamente removida e não
          será possível recuperá-la.{" "}
        </p>
      </div>
      <div className="flex gap-2">
        <Button
          data-testid="btn-cancel-delete-playlist"
          variant="outline"
          onClick={(ev) => {
            ev.stopPropagation();
            ModalService.hide();
          }}
        >
          Cancelar
        </Button>
        <Button
          data-testid="btn-delete-playlist"
          onClick={() => deletePlaylist()}
        >
          Apagar playlist
        </Button>
      </div>
    </div>
  );
}
