import React, { useState, useEffect } from "react";

import { SelectField } from "../../../shared/components";
import {
  useGetOesFiltersListLazyQuery,
  useGetCategoriasLazyQuery,
  TipoOeEntity,
  RotuloEntity,
} from "../../../generated/graphql";

import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../shared/components/Breadcrumb";

import "./styles.scss";
import Tags from "../../../shared/components/Tags";
import { PublicoAlvoModel } from "../../../models/PublicoAlvoModel";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";

export interface OeFiltroPesquisa {
  palavraChave?: string;
  areasAtuacao?: any;
  metodoEnsino?: any;
  tipoMidia?: any;
  possuiDeclaracao?: any;
}
interface OePesquisaCabecalhoProps {
  total: number;
  search: string | null;
  handleChange: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, value: any
  ) => void;
  handleRemove: (
    valor: any, name: string
  ) => void;
  filtros: OeFiltroPesquisa;
  ordem: string;
  categoria?: string;
  areaInteresse?: string;
  title?: string;
}

interface ListasFiltros {
  tiposOe: TipoOeEntity[];
}

async function getPublicosAlvos(): Promise<PublicoAlvoModel[]> {
  const response = await fetchApi("publico-alvo/get-publicos-alvos", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });
  return response;
}

const OePesquisaCabecalho: React.FC<OePesquisaCabecalhoProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { search, total, handleChange, filtros, ordem, areaInteresse } =
    props;

  const [listasFiltros, setListasFiltros] = useState<ListasFiltros>({
    tiposOe: [],
  });

  const loadPublicosAlvos = async () => {
    setLoading(true);
    setAreasAtuacao(await getPublicosAlvos());
    setLoading(false);
  };

  useEffect(() => {
    loadPublicosAlvos();
  }, []);

  const [areasAtuacao, setAreasAtuacao] = useState<
    PublicoAlvoModel[]
  >([]);

  const [metodosEnsino, setMetodosEnsino] = useState<RotuloEntity[]>([]);


  const [CarregarFiltros] = useGetOesFiltersListLazyQuery({
    onCompleted: (data) => {
      setListasFiltros({
        tiposOe: [...(data.tipoOes?.data || [])],
      });
    },
  });


  const [getCategorias] = useGetCategoriasLazyQuery({
    onCompleted: (data) => {
      if (data && data?.rotulos) {
        setMetodosEnsino(data?.rotulos.data as RotuloEntity[]);
      }
    },
  });

  useEffect(() => {
    getCategorias();
  }, [])

  useEffect(() => {
    if (loading) {
      CarregarFiltros();
      setLoading(false);
    }
  }, [CarregarFiltros, loading]);


  return (
    <>
      <section className="oe-search-result-filters">

        <div className="oe-search-form">
          <div data-title>
            {!!areaInteresse && (
              <>
                <Breadcrumb>
                  <BreadcrumbItem to="/">Home</BreadcrumbItem>
                  <BreadcrumbItem active to="/areas-de-conhecimento">
                    ÁREAS DE CONHECIMENTO
                  </BreadcrumbItem>
                </Breadcrumb>
                <h3>{areaInteresse?.toUpperCase()}</h3>
              </>
            )}
          </div>


          {!areaInteresse && (
            <form name="oe-form-filter" className="oe-form-filter" >
              <strong>Filtrar por:</strong>
              <div className="oe-search-form-wrapper">
                <div>
                  <SelectField
                    color="dark"
                    name="areasAtuacao"
                    value={filtros.areasAtuacao}
                    label="Profissão"
                    items={[
                      ...[{ label: "Profissão", value: "" }],
                    ].concat(
                      areasAtuacao?.map(
                        (item) =>
                        ({ label: item?.titulo, value: item.id.toString() } as {
                          label: string;
                          value: string;
                        })
                      )
                    )}
                    handleChange={(e) => handleChange(e, areasAtuacao.find(a => a.id.toString() == e.target.value))}
                    size="md"
                  />
                </div>
                <div>
                  <SelectField
                    color="dark"
                    name="metodoEnsino"
                    value={filtros.metodoEnsino}
                    label="Métodos de ensino"
                    items={[...[{ label: "Métodos de ensino", value: "" }]].concat(
                      metodosEnsino?.map(
                        (item) => ({
                          label: item.attributes?.titulo, value: item.id
                        }) as {
                          label: string;
                          value: string;
                        }
                      )
                    )}
                    handleChange={(e) => handleChange(e, metodosEnsino?.find(a => a.id == e.target.value))}
                    size="md"
                  />
                </div>
                <div>
                  <SelectField
                    color="dark"
                    name="tipoMidia"
                    value={filtros.tipoMidia}
                    label="Tipo de mídia"
                    items={[...[{ label: "Tipo de mídia", value: "" }]].concat(
                      listasFiltros?.tiposOe.map(i => ({
                        label: i.attributes?.titulo!,
                        value: i.id!
                      }))
                    )}
                    handleChange={(e) => handleChange(e, listasFiltros?.tiposOe.find(a => a.id == e.target.value))}
                    size="md"
                  />
                </div>
                <div className="oe-search-form--filter__w-30">
                  <SelectField
                    color="dark"
                    name="selectSort"
                    value={ordem}
                    label="Ordenar por: Ordem alfabética A-Z"
                    items={[
                      {
                        value: "createdAt:desc",
                        label: "Ordenar por: Data de criação mais recente",
                      },
                      {
                        value: "createdAt:asc",
                        label: "Ordenar por: Data de criação mais antigo",
                      },
                      {
                        value: "Nome:asc",
                        label: "Ordenar por: Ordem alfabética A-Z",
                      },
                      {
                        value: "Nome:desc",
                        label: "Ordenar por: Ordem alfabética Z-A",
                      },
                    ]}
                    handleChange={(e) => handleChange(e, null)}
                    size="md"
                  />
                </div>
              </div>
              <div className="oe-search-form-wrapper">
                <label htmlFor="PossuiDeclaracao">
                  <input
                    type="checkbox"
                    name="possuiDeclaracao"
                    id="PossuiDeclaracao"
                    defaultChecked={filtros.possuiDeclaracao}
                    onChange={(e) => handleChange(e, !filtros.possuiDeclaracao)}
                  />{" "}
                  Exibir apenas conteúdo com declaração de participação
                </label>
              </div>
              <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                {filtros.areasAtuacao?.map((item: any, index: number) => (
                  <Tags key={index} title={`${item.attributes.titulo} (${item.qtd || 0})`} onClose={() => props.handleRemove(item, 'areasAtuacao')} />
                ))}
                {filtros.metodoEnsino?.map((item: any, index: number) => (
                  <Tags key={index} title={`${item.attributes.titulo} (${item.qtd || 0})`} onClose={() => props.handleRemove(item, 'metodoEnsino')} />
                ))}
                {filtros.tipoMidia?.map((item: any, index: number) => (
                  <Tags key={index} title={`${item.attributes.titulo} (${item.qtd || 0})`} onClose={() => props.handleRemove(item, 'tipoMidia')} />
                ))}
              </div>
              <strong >{`${total} conteúdos`}</strong>
            </form>
          )}
        </div>
      </section>
    </>
  );
};

export default OePesquisaCabecalho;
