import React, { useContext, useEffect, useRef, useState } from "react";
import { ComponentTextoSecaoPdf, ObjetoEducacionalEntity, UsersPermissionsUserEntity } from "../../../generated/graphql";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router";
import OePDFWatch from "./OePDFWatch";
import { OePreviewDesktop, TypeOE } from "../Preview/OePreviewDesktop";
import { OePreviewMobile } from "../Preview/OePreviewMobile";
import { UserContext } from "../../../context/UserContext";
import useOeConsume from "../../../hooks/useOeConsume";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface OePDFProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  oesRelacionadas?: ObjetoEducacionalEntity[];
}

export const OePDFPage: React.FC<OePDFProps> = (props) => {
  const [isPreview, setPreview] = useState<boolean>(true);
  const [pdfAtual, setPdfAtual] = useState<ComponentTextoSecaoPdf | undefined>();
  const params = useParams<{ pdfId: string }>();

  const { user } = useContext(UserContext);
  const consume = useOeConsume();
  const handleTimeUpdateRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === props.oe.id) !== undefined
        );

      if ((oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration) {
        return;
      }

      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: props.oe.id,
        user: user?.id,
      });
    });

    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };

  }, [user])

  const updateTimeInProgress = (page: number, pageTotal: number) => {
    handleTimeUpdateRef.current({
      partialDuration: page,
      totalDuration: pageTotal,
    })
  }


  useEffect(() => {
    if (!props.oe) return;

    setPdfAtual(undefined);
    setPreview(true);
    if (!!params && !!params.pdfId) {
      const _pdfAtual = props.oe.attributes?.PDF?.find(
        (item) => item.id === params.pdfId
      );

      if (!_pdfAtual) {
        window.location.href = "/";
        return;
      }

      setPreview(false);
      setPdfAtual(_pdfAtual);
    }
  }, [props.oe, params, window.location.pathname]);

  const _oeLido = props.user?.attributes?.OesConsumidosParcialmente?.data.find(
    (item) =>
      !!item?.attributes?.PDFId &&
      item?.attributes?.PDFId.toString() === pdfAtual?.id
  );


  return (
    <>
      {isPreview ? (!isMobile ? (
        <OePreviewDesktop
          oe={props.oe}
          user={props.user}
          type={TypeOE.PDF}
        />
      ) : (
        <OePreviewMobile
          oe={props.oe}
          user={props.user}
          type={TypeOE.PDF}
        />
      )) : (
        <OePDFWatch
          pdf={pdfAtual}
          idOe={props.oe.id!}
          onChangePage={updateTimeInProgress}
      />
      )}
    </>
  );
};
