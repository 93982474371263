import Container from "../../shared/components/Container";

import React, { useContext, useEffect, useState } from "react";
import {
  UserResidenciaDetalheInput,
  useCriarDetalheUsuarioMutation,
  useGetEspecialidadesResidenciaQuery,
  useGetInstituicaoResidenciaComboQuery,
} from "../../generated/graphql";
import { useFormik } from "formik";
import * as yup from "yup";
import { getUserContext } from "../../context/UserContext";
import { useHistory } from "react-router";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { Input } from "../../shared/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../shared/components/ui/select";
import { twMerge } from "tailwind-merge";
import { UserDetalheContext } from "../../context/UserPreparaEinsteinContext";
import { Button } from "../../shared/components/ui/button";

function ResidenciaMedicaFormulario() {
  const user = getUserContext();
  const history = useHistory();
  const gtm = useGtm();
  const { loadUser } = useContext(UserDetalheContext);

  const schema = yup.object({
    is_estudante_medicina: yup.string().required("Campo Obrigatório!"),
  });

  const [criarDetalhe, { loading }] = useCriarDetalheUsuarioMutation({
    onCompleted: (reposta) => {
      loadUser();
      history.push("/prepara-einstein");
    },
    onError: (error) => {},
  });

  useEffect(() => {
    gtm.push(
      EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_FORMULARIO_VIEWS,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "prepara_einstein_formulario_views",
      },
    );
  }, []);

  const formik = useFormik<UserResidenciaDetalheInput>({
    initialValues: {
      ano_graduacao: "",
      especialidade_residencia: undefined,
      instituicao_residencia: undefined,
      is_estudante_medicina: undefined,
      is_prova_residencia_ano: undefined,
      usuario: undefined,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      criarDetalhe({
        variables: {
          input: {
            ...values,
            especialidade_residencia:
              values.especialidade_residencia !== ""
                ? values.especialidade_residencia
                : undefined,
            instituicao_residencia:
              values.instituicao_residencia !== ""
                ? values.instituicao_residencia
                : undefined,
            is_estudante_medicina: values.is_estudante_medicina,
            is_prova_residencia_ano: isProvaResidenciaAno,
            usuario: user?.id,
          },
        },
      });
    },
  });

  const { data: dataInstituicoes } = useGetInstituicaoResidenciaComboQuery({
    fetchPolicy: "network-only",
  });

  const { data: dataEspecialidaes } = useGetEspecialidadesResidenciaQuery({
    fetchPolicy: "network-only",
  });

  const instituicoes = [
    ...(dataInstituicoes?.instituicaoResidencias?.data?.filter(
      (a) => a.attributes?.nome !== "Outros",
    ) || []),
    ...(dataInstituicoes?.instituicaoResidencias?.data?.filter(
      (a) => a.attributes?.nome === "Outros",
    ) || []),
  ];

  const especialidades = [
    ...(dataEspecialidaes?.especialidadeResidencias?.data?.filter(
      (a) => a.attributes?.nome !== "Outra",
    ) || []),
    ...(dataEspecialidaes?.especialidadeResidencias?.data?.filter(
      (a) => a.attributes?.nome === "Outra",
    ) || []),
  ];

  const isMedicina = formik.values.is_estudante_medicina?.toString() === "SIM";
  const isOutros =
    formik.values.is_estudante_medicina?.toString() === "NAO SOU DE OUTRA AREA";
  const isNotMedicina =
    formik.values.is_estudante_medicina?.toString() ===
    "NAO JA CONCLUI A GRADUACAO EM MEDICINA";
  const isProvaResidenciaAno =
    formik.values.is_prova_residencia_ano?.toString() === "true";

  return (
    <Container className="py-14">
      <div className="mx-auto flex max-w-[405px] flex-col justify-center">
        <span className="flex text-center text-xl font-semibold leading-6 text-[#004F92]">
          Antes de acessar os conteúdos queremos saber mais sobre você!
        </span>
        <div className="mt-8 flex flex-col gap-3">
          <SelectResidencia
            label="Você é estudante de Medicina?*"
            name="is_estudante_medicina"
            onChange={formik.handleChange}
            value={formik.values.is_estudante_medicina}
            options={[
              { value: "SIM", label: "Sim" },
              {
                value: "NAO JA CONCLUI A GRADUACAO EM MEDICINA",
                label: "Não, já concluí a graduação em medicina",
              },
              {
                value: "NAO SOU DE OUTRA AREA",
                label: "Não, sou de outra área",
              },
            ]}
          />

          {isMedicina && (
            <SelectResidencia
              label="Se sim, em qual ano da graduação você está?"
              name="ano_graduacao"
              onChange={formik.handleChange}
              value={formik.values.ano_graduacao}
              options={[
                { value: "1", label: "1º ano" },
                { value: "2", label: "2º ano" },
                { value: "3", label: "3º ano" },
                { value: "4", label: "4º ano" },
                { value: "5", label: "5º ano" },
                { value: "6", label: "6º ano" },
              ]}
            />
          )}

          {(isMedicina || isNotMedicina) && (
            <SelectResidencia
              label="Você vai prestar prova de Residência este ano?"
              name="is_prova_residencia_ano"
              onChange={formik.handleChange}
              value={formik.values.is_prova_residencia_ano}
              options={[
                { value: "true", label: "Sim" },
                { value: "false", label: "Não" },
              ]}
            />
          )}

          {(isMedicina || isNotMedicina) && isProvaResidenciaAno && (
            <SelectResidencia
              label="Se sim, em qual instituição?"
              name="instituicao_residencia"
              onChange={formik.handleChange}
              value={formik.values.instituicao_residencia}
              options={[
                ...(instituicoes.map((a) => ({
                  label: a.attributes?.nome,
                  value: a.id,
                })) || []),
              ]}
              error={formik.errors.instituicao_residencia}
            />
          )}

          {(isMedicina || isNotMedicina) && isProvaResidenciaAno && (
            <SelectResidencia
              label="Qual especialidade?"
              name="especialidade_residencia"
              onChange={formik.handleChange}
              value={formik.values.especialidade_residencia}
              options={[
                ...(especialidades.map((a) => ({
                  label: a.attributes?.nome,
                  value: a.id,
                })) || []),
              ]}
              error={formik.errors.instituicao_residencia}
            />
          )}
        </div>
        {(isMedicina || isNotMedicina || isOutros) && (
          <Button
            data-testid="btn-acessar-todos-os-conteudos"
            type="button"
            size="sm"
            disabled={
              loading ||
              formik.isSubmitting ||
              !formik.isValid ||
              (!isOutros &&
                formik.values.is_prova_residencia_ano === undefined) ||
              (!isOutros &&
                isProvaResidenciaAno &&
                formik.values.instituicao_residencia?.trim() === "") ||
              (isProvaResidenciaAno &&
                formik.values.especialidade_residencia?.trim() === "")
            }
            className="button button-primary mt-4 !w-72 self-center px-2 text-base font-semibold"
            onClick={() => {
              gtm.push(
                EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_FORMULARIO_ACESSAR,
                EDataLayerEventAction.CLIQUE,
                {
                  label: "prepara_einstein_formulario_acessar",
                },
              );
              formik.handleSubmit();
            }}
          >
            Acessar todos os conteúdos
          </Button>
        )}
      </div>
    </Container>
  );
}

export default ResidenciaMedicaFormulario;

export interface SelectResidenciaProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label: string;
  options: { value: any; label: any }[];
  value?: any;
  error?: string;
  onChange: (event: any) => void;
}

export function SelectResidencia({
  label,
  options,
  value,
  error,
  onChange,
  ...rest
}: SelectResidenciaProps) {
  return (
    <div>
      <label className="mb-1 max-w-xs text-xs font-normal text-[#111316] md:text-sm">
        {label}
      </label>
      <div className="relative">
        <Select
          defaultValue={value}
          value={value}
          onValueChange={(e) => {
            onChange({
              target: {
                name: rest.name,
                value: e,
              },
            });
          }}
          name={rest.name}
        >
          <SelectTrigger
            className={twMerge(
              "w-full border-red-600 !text-sm font-semibold md:min-w-[360px]",
              error ? "border-[1px] border-red-500" : "border-input",
            )}
          >
            <SelectValue placeholder="Escolha uma opção:" />
          </SelectTrigger>
          <SelectContent id={rest.id} className="!text-sm font-semibold">
            <SelectGroup className="!text-sm font-semibold">
              <SelectLabel>Escolha uma opção:</SelectLabel>
              {options?.map((option, index) => (
                <SelectItem key={index} value={option.value.toString()}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {error && <p className="mt-1 text-xs text-red-500">{`${error}`}</p>}
    </div>
  );
}

export interface InputResidenciaProps
  extends React.SelectHTMLAttributes<HTMLInputElement> {
  label: string;
  value?: any;
}

export function InputResidencia({
  label,
  value,
  onChange,
  ...rest
}: InputResidenciaProps) {
  const [inputValue, setInputValue] = useState<any>();

  const handleOptionChange = (event: any) => {
    setInputValue(event.target.value);
    onChange?.(event);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <label className="text-sm font-normal text-[#111316]">{label}</label>
      <Input
        {...rest}
        value={inputValue}
        onChange={handleOptionChange}
        className="h-[50px] w-[401.522px] appearance-none rounded-[30px] bg-[#FAFAFC] bg-transparent !p-4 text-sm font-semibold leading-4 text-[#004F92]"
        style={{
          border: "0.8px solid #E7E9ED",
        }}
      />
    </>
  );
}
