import { useEffect, useState } from "react";
import { ObjetoEducacional } from "../../../models/ObjetoEducacional";
import LinkCustom from "../../../shared/components/Link";
import Container from "../../../shared/components/Container";
import CardObjetoEducacional from "../../../shared/components/oe-card";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";

import VerTudoIcon from "../../../assets/svgs/icons/ver-tudo.svg";
import { AreaConhecimentoModel } from "../../../models/AreaConhecimentoModel";
import { ObjetoEducacionalModel } from "../../../models/ObjetoEducacionalModel";

async function getConteudosInteresse(): Promise<AreaConhecimentoModel[]> {
  const response = await fetchApi(
    "area-conhecimento/get-area-conhecimento-oes-recentes-by-user",
    "POST",
    JSON.stringify({
      metodosDeEnsino: [],
      niveisMaturidade: [],
      tipoMidia: [],
      ordernar: 0,
    }),
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });
  return response;
}

export default function ConteudosInteresse() {
  const [conteudosInteresse, setConteudosInteresse] =
    useState<ObjetoEducacional[]>();

  const loadConteudosInteresse = async () => {
    const itens = await getConteudosInteresse();
    const conteudos = itens.reduce((prev: any, curr: any) => {
      const ant: ObjetoEducacional[] = prev || [];
      if (Array.isArray(curr.objetosEducacionais)) {
        ant.push(...curr.objetosEducacionais);
      }
      return ant;
    }, []);
    setConteudosInteresse(conteudos ?? []);
  };
  useEffect(() => {
    loadConteudosInteresse();
  }, []);

  return (
    <Container className="flex flex-col  gap-6 py-9 md:py-10">
      <div className="flex justify-between">
        <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
          Mais recentes do seu interesse
        </h2>
        <LinkCustom
          to={"/novos-conteudos-interesse"}
          className="flex min-w-[80px] cursor-pointer flex-nowrap items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
        >
          VER TUDO
          <img
            src={VerTudoIcon}
            alt="icon-video"
            width={24}
            height={24}
            className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
          />
        </LinkCustom>
      </div>
      {conteudosInteresse && conteudosInteresse.length > 0 ? (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {conteudosInteresse?.map((_: any, index: number) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <CardObjetoEducacional objetoEducacional={_} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-conteudos-interesse"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-conteudos-interesse"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto " />
        </Carousel>
      ) : (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {Array.from({ length: 3 }).map((_, index) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <div className="min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                  <div className="flex h-[187.5px] w-full animate-[shimmer_2s_infinite] bg-gradient-to-r  from-transparent via-gray-400/20 to-transparent md:h-[227.66px]" />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      )}
    </Container>
  );
}
