import React from "react";
import { PerguntaModel } from "../../../../models/PerguntaModel";

export interface LegendaImagemQuizProps {
  perguntaAtiva: PerguntaModel;
  color: string;
}

export const LegendaImagemQuiz: React.FC<LegendaImagemQuizProps> = ({
  perguntaAtiva,
  color,
}) => {
  return (
    <>
      {perguntaAtiva?.imagemUrls?.length > 0 && (
        <span
          className={`font-medium text-[${color || "#111316"
            }] text-xs leading-4 inline-block`}
        >
          {perguntaAtiva?.legendaImagem}
        </span>
      )}
    </>
  );
};
