import Container from "../../../../shared/components/Container";
import * as Icon from "../../../../assets/svgs";
import { useContext, useEffect, useState } from "react";
import ResidenciaMedicaBanner from "../ResidenciaMedicaBanner";
import { useHistory } from "react-router";
import { UserContext } from "../../../../context/UserContext";
import { mostrarModalLogin } from "../../../../shared/modals/ModalLogin";
import { useScrollTop } from "../../../../shared/hooks/useScrollTop";
import ApresentacaoResidencia from "../../../../assets/images/apresentacao-residencia.png";
import ThumbResidencia from "../../../../assets/svgs/residencia/thumb-residencia.svg";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import Vimeo from "@u-wave/react-vimeo";
import { twMerge } from "tailwind-merge";

export default function ResidenciaMedica({ page }: { page: string }) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const gtm = useGtm();

  const scrollToTop = useScrollTop();

  // useEffect(() => {
  //   scrollToTop();
  // }, []);

  useEffect(() => {
    gtm.push(
      EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_VIEWS,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "home_prepara_einstein_views",
      }
    );
  }, []);

  const handleAcessarResidencia = (page: string) => {
    if (!user && page !== "home") {
      mostrarModalLogin(true);
    } else {
      page === "home"
        ? history.push("/prepara-einstein")
        : history.push("/prepara-einstein/formulario");
    }
  };

  const [play, setPlay] = useState(false);

  return (
    <>
      <Container>
        {/* banner */}
        <ResidenciaMedicaBanner
          page="residencia"
          handleAcessarResidencia={(page) => {
            gtm.push(
              EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_QUERO_COMECAR_INICIO,
              EDataLayerEventAction.CLIQUE,
              {
                label: "ir_para_inicio_prepara_einstein_inicio",
              }
            );
            handleAcessarResidencia(page);
          }}
        />
        {/* cards */}
        <div className="flex w-full gap-6 justify-center  items-center flex-col md:flex-row">
          <div className="flex flex-col w-[16rem] p-8 bg-white rounded-xl justify-center items-center shadow-md">
            <Icon.Devices />
            <div className="flex flex-col !mt-5">
              <span className="w-[207px] text-center text-neutral-900 text-base font-semibold leading-snug">
                Experiência Einstein
              </span>
              <span className="w-[207px] text-center text-neutral-600 text-[13px] font-normal leading-[18px] mt-3">
                Aprenda com Professores <br />e Preceptores do Einstein.
                <br />
                <br />
              </span>
            </div>
          </div>

          <div className="flex flex-col w-[16rem] p-8 bg-white rounded-xl justify-center items-center shadow-md">
            <Icon.Metodologia />
            <div className="flex flex-col !mt-5">
              <span className="w-[207px] text-center text-neutral-900 text-base font-semibold leading-snug">
                Metodologia Inovadora
              </span>
              <span className="w-[207px] text-center text-neutral-600 text-[13px] font-normal leading-[18px] mt-3">
                Diferentes formatos de conteúdo para facilitar a aprendizagem.
                <br />
              </span>
            </div>
          </div>

          <div className="flex flex-col w-[16rem] p-8 bg-white rounded-xl justify-center items-center shadow-md">
            <Icon.Conteudo />
            <div className="flex flex-col !mt-5">
              <span className="w-[207px] text-center text-neutral-900 text-base font-semibold leading-snug">
                Conteúdos Semanais
              </span>
              <span className="w-[207px] text-center text-neutral-600 text-[13px] font-normal leading-[18px] mt-3">
                Mantenha-se atualizado com materiais relevantes e casos
                clínicos.
                <br />
              </span>
            </div>
          </div>

          <div className="flex flex-col w-[16rem] p-8 bg-white rounded-xl justify-center items-center shadow-md">
            <Icon.Gratuito />
            <div className="flex flex-col !mt-5">
              <span className="w-[207px] text-center text-neutral-900 text-base font-semibold leading-snug">
                Gratuito
              </span>
              <span className="w-[207px] text-center text-neutral-600 text-[13px] font-normal leading-[18px] mt-3">
                Livre acesso à uma Medicina de excelência.
                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
      </Container>

      {/* video */}
      <div className="bg-[#F5F8FF] !mt-16 py-10">
        <Container>
          <div className="bg-[#F5F8FF] w-full md:h-[574px] h-auto flex justify-center items-center text-center flex-col">
            <span className="text-[#111316] text-xl md:w-full md:text-2xl font-medium leading-8 tracking-[-0.24px]">
              Tudo sobre Residência Médica:
              <br />
              <span>
                Conteúdos{" "}
                <span className="text-[#107B95] font-bold">Essenciais</span> e{" "}
                <span className="text-[#107B95] font-bold">
                  Raciocínio Clínico em Foco
                </span>
              </span>
            </span>

            <div className="flex w-full justify-center flex-col md:flex-row md:!mt-10 items-center gap-8 md:gap-16">
              <div className="flex items-start justify-start rounded-[7.406px] overflow-hidden md:mt-8 mt-0 md:w-[462px] w-full h-auto">
                <div className="flex md:w-[462px] w-full h-full">
                  <img
                    onClick={() => setPlay(true)}
                    src={ThumbResidencia}
                    alt="Prepara Residência Médica"
                    className={twMerge(
                      "w-[462px] mt-8 md:mt-0 object-cover object-center cursor-pointer",
                      play ? "hidden" : "w-[462px]"
                    )}
                  />
                  <Vimeo
                    video={"https://vimeo.com/916034818/baab1471b9"}
                    responsive
                    paused={!play}
                    className={twMerge(
                      "h-full w-full vp-center mt-8 md:mt-0 md:w-[462px]",
                      play ? "block" : "hidden"
                    )}
                  />
                </div>
              </div>

              <div className="flex w-full md:w-[461.992px] flex-col">
                <div className="flex flex-row text-start">
                  <div className="h-full w-[32px] mr-5">
                    <Icon.Target />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-semibold leading-5 text-[#111316]">
                      Foco na Residência Médica
                    </span>
                    <span className="text-[13px] font-normal leading-4 text-[#626466] mt-[10px]">
                      Conteúdos prevalentes nas provas de Residência
                      <br />
                      Médica sobre Clínica Médica, Cirurgia Geral, Pediatria,{" "}
                      <br />
                      Ginecologia e Obstetrícia e Medicina Preventiva e Social.
                    </span>
                  </div>
                </div>

                <div className="flex flex-row text-start !mt-5">
                  <div className="h-full w-[32px] mr-5">
                    <Icon.Especialista />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-base font-semibold leading-5 text-[#111316]">
                      Especialistas em Ação
                    </span>
                    <span className="text-[13px] font-normal leading-4 text-[#626466] mt-[10px]">
                      O raciocínio clínico de especialistas resolvendo questões.
                    </span>
                  </div>
                </div>

                <div
                  className={`px-[40px] py-[20px] bg-[#0A589B] hover:bg-[#0a579bd8] self-center md:self-start rounded-[30px] mt-8 cursor-pointer ${
                    page === "home" ? "w-[169px]" : "w-[208px]"
                  }`}
                  onClick={() => {
                    gtm.push(
                      EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_QUERO_COMECAR_FIM,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: "ir_para_inicio_prepara_einstein_fim",
                      }
                    );
                    handleAcessarResidencia(page);
                  }}
                >
                  <span className="text-base font-semibold text-white">
                    {page === "home" ? "Saiba mais" : "Quero começar"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
