import axios from "axios";
import URL from "../constants/url.const";
import { getTokenFromCookie } from "./localStorage.service";

export const request = (endPoint: string) => {
  const _ = `${URL.BASE_DOTNET}/${endPoint}`;
  return axios.get(_);
};

export const fetchApi = async (
  endPoint: string,
  method: "GET" | "POST" | "DELETE",
  body?: string,
  jwt?: string,
  maxRetries = 3,
): Promise<Response> => {
  const token = getTokenFromCookie();

  const headers: { "content-type": string; Authorization?: string } = {
    "content-type": "application/json",
  };

  const URL = `${process.env.REACT_APP_ROOT_DOTNET}/api/${endPoint}`;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  let attempts = 0;
  let response = {
    ok: false,
    status: 400,
    statusText: "",
  } as Response;

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  while (attempts < maxRetries) {
    attempts++;
    try {
      response = await fetch(URL, {
        method,
        headers,
        body,
      });

      if (
        !response.ok &&
        (response.status === 400 || response.status === 403)
      ) {
        if (attempts < maxRetries) {
          await delay(1000); // Aguarda 1 segundo antes de tentar novamente
          continue; // Tenta novamente
        } else {
          throw new Error(`Erro ${response.status}: ${await response.statusText}`);
        }
      }

      return response; // Sucesso
    } catch (error) {
      if (attempts >= maxRetries) {
        throw new Error(`${await response.status}`); // Lancar o response; // Lança o erro após atingir o número máximo de tentativas
      }
    }
  }

  return response;
};

export const fetchApiNoRetry = async (
  endPoint: string,
  method: "GET" | "POST" | "DELETE",
  body?: string,
  jwt?: string,
  maxRetries = 3
): Promise<any> => {
  const token = getTokenFromCookie();

  const headers: { "content-type": string; Authorization?: string } = {
    "content-type": "application/json",
  };

  const URL = `${process.env.REACT_APP_ROOT_DOTNET}/api/${endPoint}`;

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await fetch(URL, {
      method,
      headers,
      body,
    });

    // Verifica se a resposta não foi bem-sucedida
    if (!response.ok) {
      // Tenta analisar o corpo da resposta como JSON
      const errorData = await response.json().catch(() => ({}));
      throw {
        status: response.status,
        statusText: response.statusText,
        error: errorData,
      };
    }

    // Retorna o JSON da resposta em caso de sucesso
    return await response.json();
  } catch (error) {
    console.error(`Erro na chamada para ${endPoint}:`, error);
    throw error; // Propaga o erro para que o chamador trate
  }
};


export default fetchApi;
