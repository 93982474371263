import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { ReactComponent as TelefoneGreen } from "../../assets/svgs/telefone-green.svg";

import {
  PaginaAtendimento,
  useGetAtendimentoLazyQuery,
  useCreateContatoMutation,
  PaginaAtendimentoEntity,
} from "../../generated/graphql";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
} from "../../shared/components";

import { MyTextField } from "../MeuPerfilPage/components/EditProfile/components/PersonalData";
import { MyFormControl } from "../MeuPerfilPage/components/EditProfile/components/ProfessionalData";

import { ModalService } from "../../shared/components/Modal";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import "./styles.scss";
import { Button } from "../../shared/components/ui/button";
import HeaderPageBreadcrumb from "../../shared/components/HeaderPageBreadcrumb";

interface AtendimentoFormData {
  nome: string;
  email: string;
  tipo: string;
  mensagem: string;
}

export const AtendimentoPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [singlePageData, setSinglePageData] =
    useState<PaginaAtendimentoEntity>();
  const scrollTop = useScrollTop();
  useEffect(() => {
    scrollTop();
  }, []);

  const [criarContato] = useCreateContatoMutation();
  const [GetAtendimento] = useGetAtendimentoLazyQuery({
    onCompleted: (resposta) => {
      setLoading(false);
      resposta.paginaAtendimento &&
        setSinglePageData(
          resposta.paginaAtendimento.data as PaginaAtendimentoEntity,
        );
    },
  });

  const renderizarModalSucesso = () => {
    return (
      <div data-modal-atendimento-sucesso>
        <h3>Recebemos sua mensagem com sucesso!</h3>

        <div data-row>
          <p>Logo entraremos em contato.</p>
        </div>

        <div data-row data-flex-row-arround>
          <Button
            data-testid="btn-ok"
            type="button"
            variant="primary"
            onClick={() => ModalService.hide()}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const renderizarModalErro = () => {
    return (
      <div data-modal-atendimento-erro>
        <h3>Ops. Parece que ocorreu um erro!</h3>

        <div data-row>
          <p>Por favor, entre em contato por um dos nosso outros contatos.</p>
        </div>

        <div data-row data-flex-row-arround>
          <Button
            type="button"
            data-testid="btn-ok"
            variant="primary"
            onClick={() => ModalService.hide()}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const formik = useFormik<AtendimentoFormData>({
    initialValues: {
      nome: "",
      email: "",
      tipo: "0",
      mensagem: "",
    },
    validationSchema: yup.object().shape({
      nome: yup
        .string()
        .required("Campo obrigatório")
        .max(300, "Máximo de 300 caracteres")
        .test({
          message: "Campo obrigatório",
          test: (value) => {
            return !!value && value.trim() !== "";
          },
        }),
      email: yup
        .string()
        .required("Campo obrigatório")
        .email("Email inválido")
        .max(300, "Máximo de 300 caracteres"),
      tipo: yup
        .string()
        .required("Campo obrigatório")
        .test({
          message: "Campo obrigatório",
          test: (value) => {
            return value !== "0";
          },
        }),
      mensagem: yup
        .string()
        .required("Campo obrigatório")
        .max(1000, "Máximo de 1000 caracteres"),
    }),
    onSubmit: (values) => {
      criarContato({
        variables: {
          input: {
            email: values.email,
            mensagem: values.mensagem,
            nome: values.nome,
            tipo_atendimento: values.tipo,
          },
        },
      })
        .then(() => {
          formik.resetForm();
          ModalService.show({
            content: renderizarModalSucesso(),
          });
        })
        .catch(() => {
          ModalService.show({
            content: renderizarModalErro(),
          });
        });
    },
  });

  useEffect(() => {
    GetAtendimento();
  }, []);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <main data-atendimento-page>
        <HeaderPageBreadcrumb
          itens={[{ to: "/", title: "Home" }, { title: "Atendimento" }]}
          title="Atendimento"
        />

        <section>
          <aside>
            <div data-atendimento-titulo>
              <h3>{singlePageData?.attributes?.titulo}</h3>
              <p>{singlePageData?.attributes?.descricao}</p>
            </div>

            {singlePageData?.attributes?.ListaTelefonesAtendimento && (
              <div data-atendimento-telefone>
                <h4>Ligue para nós:</h4>
                <label>Telefone</label>
                <ul>
                  {singlePageData?.attributes?.ListaTelefonesAtendimento?.map(
                    (item, idx) => (
                      <li key={idx}>
                        <TelefoneGreen
                          height="14px"
                          style={{ height: "14px" }}
                        />
                        <a href={`tel:${item.telefone}`}>{item.telefone}</a>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            )}

            {singlePageData?.attributes?.ListaHorariosAtendimento && (
              <div data-atendimento-horario>
                <h4>Horário de Atendimento</h4>
                <ul>
                  {singlePageData?.attributes?.ListaHorariosAtendimento?.map(
                    (item, idx) => (
                      <li key={idx}>
                        {item.dia_semana}{" "}
                        <b>{item.horario !== "-" && `das ${item.horario}`}</b>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            )}

            {singlePageData?.attributes?.ListaEmailsAtendimento && (
              <div data-atendimento-email>
                <h4>Envie e-mail para:</h4>
                <ul>
                  {singlePageData?.attributes?.ListaEmailsAtendimento?.map(
                    (item, idx) => (
                      <li key={idx}>
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            )}
          </aside>

          <form
            data-card="shadow"
            autoComplete="off"
            name="atendimento-request-form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <h4>Formulário</h4>

            <MyTextField
              required
              type="text"
              id="nome"
              name="nome"
              value={formik.values.nome}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              label="NOME"
              error={Boolean(formik.touched && formik.errors.nome)}
              helperText={formik.errors.nome}
            />

            <MyTextField
              required
              type="text"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              label="EMAIL"
              error={Boolean(formik.touched && formik.errors.email)}
              helperText={formik.errors.email}
            />

            <MyFormControl>
              <InputLabel error={Boolean(formik.touched && formik.errors.tipo)}>
                ASSUNTO
              </InputLabel>
              <Select
                id="tipo"
                name="tipo"
                value={formik.values.tipo}
                onChange={formik.handleChange}
              >
                <MenuItem value={"0"} className="">
                  Selecione o assunto
                </MenuItem>
                {singlePageData?.attributes?.tipo_atendimentos &&
                  singlePageData.attributes?.tipo_atendimentos.data.map(
                    (item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.id as string}
                        className="!normal-case"
                      >
                        {item?.attributes?.titulo}
                      </MenuItem>
                    ),
                  )}
              </Select>
              <FormHelperText
                error={Boolean(formik.touched && formik.errors.tipo)}
              >
                {formik.errors.tipo}
              </FormHelperText>
            </MyFormControl>

            <MyTextField
              required
              type="text"
              id="mensagem"
              name="mensagem"
              data-testid="mensagem"
              multiline
              rows={8}
              rowsMax={8}
              value={formik.values.mensagem}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              label="SUA MENSAGEM"
              error={Boolean(formik.touched && formik.errors.mensagem)}
              helperText={formik.errors.mensagem}
            />
            <div>
              <span className="charCount">
                {1000 - formik.values.mensagem.length} / 1000
              </span>
            </div>

            <div data-buttons>
              <Button
                data-testid="btn-limpar"
                type="button"
                onClick={() => formik.resetForm()}
                variant="outline"
              >
                Limpar
              </Button>

              <Button type="submit" data-testid="btn-enviar" variant="primary">
                Enviar
              </Button>
            </div>
          </form>
        </section>
      </main>
    </>
  );
};
