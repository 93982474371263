import { Button } from "../ui/button";
import { cn } from "../../utils/utils";
import { useHistory } from "react-router";
import CursosPagosResidencia from "../../../assets/images/cursos-pagos.png";
import { ModalService } from "../Modal";

export default function ModalCursosPagos() {
  async function onClick() {
    const data = await localStorage.getItem("PREPARA_EINSTEIN");
    localStorage.setItem(
      "PREPARA_EINSTEIN",
      JSON.stringify({
        ...JSON.parse(data || "{}"),
        data_acesso_cursos: new Date().toLocaleDateString(),
      }),
    );
    window.open(
      "https://ensino.einstein.br/curta-duracao/atualizacao/simulacao-realistica/simula%C3%A7%C3%A3o%20realistica?PS=12&utm_source=academia&utm_medium=banner&utm_campaign=pde_aws_mkt_academiadigital_simulacao_amplo&utm_content=imagem--20240628",
      "_blank",
    );
    window.location.reload();
    ModalService.hide();
  }

  return (
    <div className="bg-white flex w-[320px] flex-col justify-center gap-6 rounded-xl p-9 text-center md:w-[450px] md:p-12">
      <div className="flex w-full items-center justify-center">
        <img
          data-testid="img-prepara"
          src={CursosPagosResidencia}
          alt="Modal cursos pagos"
          className={cn(
            " mt-3 w-full cursor-pointer object-cover object-center md:w-[551.47px] tablet:w-[404.68px]",
          )}
        />
      </div>
      <div>
        <p className="w-full text-sm font-medium leading-22 text-[#008BC9]">
          Aprenda na Prática
        </p>
        <p className="text-lg font-bold leading-22 text-[#004F92] md:text-[22px]">
          Centro de Simulação Realística do Einstein
        </p>
      </div>
      <div className="text-balance text-center text-base font-normal leading-22 text-[#111316]">
        Simulações que preparam você para prestar assistência ao paciente com
        práticas realistas, recriando situações do ambiente hospitalar. Venha
        para o Ensino Einstein e esteja pronto para qualquer desafio na área da
        saúde!
      </div>
      <div className="flex justify-center">
        <Button
          size="default"
          className="h-[50px] w-[194px]"
          data-testid="button-conheca-agora"
          onClick={onClick}
        >
          Confira os cursos
        </Button>
      </div>
    </div>
  );
}
