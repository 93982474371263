import { Loader } from "../../shared/components";
import Container from "../../shared/components/Container";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { useContext, useEffect, useState } from "react";
import PlaylistPersonalizada from "./PlaylistPersonalizada";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../shared/components/ui/carousel";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import { UserContext } from "../../context/UserContext";
import { useHistory } from "react-router";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

interface PlaylistRecomendada {
  id: number;
  titulo: string;
  imagem: string | null;
}

async function getCategoriasPlaylists() {
  const response = await fetchApi("playlists/categorias", "GET");
  return response.json();
}

export default function Playlists() {
  const [loading, setLoading] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const scrollTop = useScrollTop();
  const { user, loadingUser } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user === undefined && !loadingUser) {
      mostrarModalLogin(true, () => {
        history.push("/");
      });
    }
  }, [user, loadingUser]);

  const gtm = useGtm();

  useEffect(() => {
    scrollTop();
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "pagina_playlists",
      },
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    getCategoriasPlaylists()
      .then((data) => {
        if (data.status === 401) {
          setPlaylists([]);
          setLoading(false);
          return;
        }
        setPlaylists(data);
        setLoading(false);
      })
      .catch(() => {
        setPlaylists([]);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Loader show={loading} />
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-10 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/playlists">Playlists</BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-1.5xl font-semibold normal-case text-white-200">
            Playlists
          </h2>
        </Container>
      </div>

      <Container className="flex h-auto flex-col items-start py-10">
        <div className="flex flex-col gap-3">
          <h3 className="text-base font-semibold leading-22 text-[#107B95] md:text-xxl">
            Playlists da Academia
          </h3>
          <span className="text-sm font-normal leading-22 text-black-900 md:text-base">
            Conheça as playlists que criamos para você e facilite seu
            aprendizado com nossas trilhas!
          </span>
        </div>

        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="mt-6 w-full"
        >
          <CarouselContent className="">
            {playlists?.map((playlist: PlaylistRecomendada, index) => (
              <CarouselItem
                className="tablet:1/4 md:basis-1/2 lg:basis-1/4"
                key={index}
              >
                <div
                  onClick={() => {
                    window.location.href = `/playlists/${playlist.id}`;
                    gtm.push(
                      EDataLayerEventCategory.ADE_PLAYLISTS,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: playlist.titulo,
                      },
                    );
                  }}
                  role="link"
                  key={playlist.id}
                  className="relative flex h-[304px] w-full cursor-pointer flex-col items-center justify-center gap-6 overflow-hidden rounded-lg bg-[#F5F8FF] p-6 text-center hover:border md:h-[304px]"
                >
                  <img
                    src={playlist?.imagem || ""}
                    aria-hidden="true"
                    className="h-[100px] w-[142px]"
                    alt={`Imagem ilustrativa representando ${playlist.titulo}`}
                  />
                  <span className="text-base font-bold leading-22 text-black-900 ">
                    {playlist.titulo}
                  </span>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="banner hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="banner hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto" />
        </Carousel>
      </Container>

      <PlaylistPersonalizada />
    </>
  );
}
