import { Button } from "../ui/button";
import PreparaResidenciaMedica from "../../../assets/svgs/residencia/banner-residencia-medica.svg";
import { cn } from "../../utils/utils";
import { useHistory } from "react-router";
import { ModalService } from "../Modal";

export default function ModalPreparaEinstein() {
  const history = useHistory();

  async function onClick() {
    const data = localStorage.getItem("PREPARA_EINSTEIN");
    await localStorage.setItem(
      "PREPARA_EINSTEIN",
      JSON.stringify({
        ...JSON.parse(data || "{}"),
        data_acesso_modal: new Date().toLocaleDateString(),
        data_acesso_banner: new Date().toLocaleDateString(),
      }),
    );
    await ModalService.hide();
    await history.push("/prepara-einstein");
  }

  return (
    <div className="bg-white flex w-[320px] flex-col justify-center gap-6 rounded-xl p-9 text-center md:w-[450px] md:p-12">
      <div className="flex h-[200px] w-[248px] items-center justify-center rounded-xl bg-[#F5F8FF] md:w-[350px]">
        <div className="flex w-full items-center justify-center md:w-[257px] ">
          <img
            data-testid="img-prepara"
            src={PreparaResidenciaMedica}
            alt="Prepara Residência Médica"
            className={cn(
              " w-[182.1px] cursor-pointer object-cover object-center md:w-[551.47px] tablet:w-[404.68px]",
            )}
          />
        </div>
      </div>
      <div>
        <p className="w-full text-sm font-medium leading-22 text-[#008BC9]">
          Novidade na Academia
          <br className="block md:hidden" /> Digital Einstein
        </p>
        <p className="text-lg font-bold leading-22 text-[#004F92] md:text-[22px]">
          Prepara Einstein para Residência Médica
        </p>
      </div>
      <div className="text-center text-base font-normal leading-22 text-[#111316]">
        Uma Jornada de Estudos com foco em preparar você para as provas{" "}
        <br className="hidden md:flex" />
        da <span className="font-semibold">Residência Médica</span>.
      </div>
      <div className="flex justify-center">
        <Button
          size="default"
          className="h-[50px] w-[194px]"
          data-testid="button-conheca-agora"
          onClick={onClick}
        >
          Conheça agora
        </Button>
      </div>
    </div>
  );
}
