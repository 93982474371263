import { useContext, useState, useEffect } from "react";
import {
  upsertUserContext,
  UserContext,
} from "../context/UserContext";
import {
  ObjetoEducacionalEntity,
  useGetOeAssistidoByIdLazyQuery,
  useUpdateUserMutation,
} from "./../generated/graphql";
import ResetUserContext from "./ResetUserContext";
import fetchApi from "../shared/services/rest-dotnet.service";

async function getVizualizadoreOeByUserAndObjetoEducacional({
  userId,
  objetoEducacionalId,
}: {
  userId: number,
  objetoEducacionalId: number
}) {
  const response = await fetchApi(
    `visualizadores-oe/get-by-user-and-objeto-educacional/${userId}/${objetoEducacionalId}`,
    "GET"
  );

  return response.json();
}

const useOeVisualized = () => {
  const { user } = useContext(UserContext);

  const { resetUserContext } = ResetUserContext();
  const [oeId, setOeId] = useState("");

  const [update] = useUpdateUserMutation();

  const getOeAssistidoOeAndUser = async (oeId: string) => {

    if (!user) {
      return;
    }

    const data: any[] = await getVizualizadoreOeByUserAndObjetoEducacional({
      objetoEducacionalId: Number(oeId),
      userId: Number(user?.id!)
    })

    if (!data || (data && data?.length > 0)) {
        updateOeVisualized(oeId.toString()).then(() => {
          const oes = (user?.attributes?.OesVisualizados!.data?.map((item) => ({ id: item.id })) || []);
          const userCloned: any = { ...user };
          userCloned.OesVisualizados = [...oes, { id: oeId }];
          resetUserContext(userCloned);
          upsertUserContext({ user: userCloned });
        });
      }
  }

  const updateOeVisualized = (id: string) => {

    if (!user) {
      throw new Error("Usuario nao logado para atualizar visualizacao");
    }

    return update({
      variables: {
        id: user?.id!,
        input: {
          OesVisualizados: [
            id,
            ...(user.attributes?.OesVisualizados?.data.map((item) => item.id!) || []),
          ],
        }
      },
    });
  };

  const registerOeVisualized = (id: string) => {
    if (!user || !user) {
      return;
    }

    if (user.attributes?.OesVisualizados?.data?.some((item) => item.id === id)) {
      return;
    }

    setOeId(id);
  };

  useEffect(() => {
    if (!oeId) {
      return;
    }

    getOeAssistidoOeAndUser(oeId);
  }, [oeId]);

  return { registerOeVisualized };
};

export default useOeVisualized;
