import { useEffect, useContext, useState } from "react";
import * as Yup from "yup";

import { UserContext } from "../context/UserContext";
import { useGetUserByIdLazyQuery, UsersPermissionsUserEntity } from "../generated/graphql";

export const useValidacaoDadosUsuario = () => {
  const { user: userToken } = useContext(UserContext);
  const [validadoDadosUsuario, setValidadoDadosUsuario] = useState(true);

  const [ GetUserById ] = useGetUserByIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const data = response.findMe;
      if (data) {
        validarDados(data! as UsersPermissionsUserEntity);
      } else {
        validarDados(data! as UsersPermissionsUserEntity);
      }
    },
  });


  useEffect(() => {
    if (!userToken) {
      return;
    }

    GetUserById();
  }, [userToken]);

  const validarDados = (user?: UsersPermissionsUserEntity) => {
    const schema = Yup.object().shape({
      nome_completo: Yup.string()
        .required("Campo obrigatório")
        .min(3, "Quantidade mínima de caracteres não atingida")
        .max(255, "Quantidade máxima de caracteres ultrapassada")
        .test({
          message: "O campo possui caracteres inválidos",
          test: (value) => {
            return (
              !!value &&
              !/[0-9<>/"'&]/.test(value) // Impede números e caracteres de HTML
            );
          },
        })
        .test({
          name: "nome_completo",
          message: "Nome e sobrenome são obrigatórios",
          test: (value) => {
            return !!value && value.trim().split(" ").length >= 2;
          },
        }),
      email: Yup.string().required("Campo Obrigatorio"),
      cpf: Yup.string().when("toogleDocumentType", {
        is: false,
        then: Yup.string().required("*Campo obrigatório"),
      }),
      celular: Yup.string().required("Campo Obrigatorio"),
      PerfilProfissao: Yup.object().shape({
        data: Yup.array().test({
          message: "Campo Obrigatorio",
          test: (value: any) => value && !!value.length,
        }),
      }),
      area_de_conhecimentos: Yup.object().shape({
        data: Yup.array().test({
          message: "Campo Obrigatorio",
          test: (value: any) => value && !!value.length,
        }),
      }),
      possui_deficiencia: Yup.string().required("Campo Obrigatorio"),
    });

    const userData = !user
      ? undefined
      : {
          ...user,
          toogleDocumentType: !user.attributes?.cpf,
          documentNumber: !user.attributes?.documentNumber
            ? ""
            : user.attributes?.documentNumber,
          documentType: !user.attributes?.documentType
            ? ""
            : user.attributes?.documentType,
        };

    schema
      .isValid(userData?.attributes)
      .then((response) => {
        setValidadoDadosUsuario(response);
      })
      .catch((error) => {
      });
  };

  return { validadoDadosUsuario };
};
