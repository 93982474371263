import { useRef } from "react";
import { SwiperSlide } from "swiper/react";

import EnsinoEinstein1 from "../../../assets/svgs/ensino-einstein-1.svg";
import EnsinoEinstein2 from "../../../assets/svgs/ensino-einstein-2.svg";
import EnsinoEinstein3 from "../../../assets/svgs/ensino-einstein-3.svg";
import EnsinoEinstein4 from "../../../assets/svgs/ensino-einstein-4.svg";
import EnsinoEinstein5 from "../../../assets/svgs/ensino-einstein-5.svg";
import EnsinoEinstein6 from "../../../assets/svgs/ensino-einstein-6.svg";
import Container from "../../../shared/components/Container";
import CustomSwiper from "../../../shared/components/CustomSwiper";

const Info = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const refPagination = useRef<HTMLDivElement>(null);

  const renderItens = () => {
    return [
      <div key={1} className="flex-column m-0 flex h-40 w-52 items-center">
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein1}
            alt="Ícone sala de aula"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          Mais de <span className="font-bold">30 anos de experiência</span> no
          Ensino em Saúde
        </span>
      </div>,

      <div
        key={2}
        className="flex-column box-border flex h-40 w-52 items-center"
      >
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein2}
            alt="Ícone de dialogo"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          <span className="font-bold">Corpo docente </span>
          <span>formado por </span>
          <span className="font-bold">profissionais do Einstein</span>
        </span>
      </div>,
      <div
        key={3}
        className="flex-column box-border flex h-40 w-52 items-center"
      >
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein3}
            alt="Ícone aperto de mão"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          <span className="font-bold">70% dos alunos </span>
          <span className="">foram </span>
          <span className="font-bold">contratados </span>
          <span className="">pela instituição</span>
        </span>
      </div>,
      <div
        key={4}
        className="flex-column box-border flex h-40 w-52 items-center"
      >
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein4}
            alt="Ícone duas pessoas lado a lado"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          Mais de <span className="font-bold">30 mil alunos ativos</span>
        </span>
      </div>,
      <div
        key={5}
        className="flex-column box-border flex h-40 w-52 items-center"
      >
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein5}
            alt="Ícone simbolizando idéias"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          <span className="font-bold">Cursos </span>
          para profissionais de
          <span className="font-bold"> Assistência e Gestão</span>
        </span>
      </div>,
      <div
        key={6}
        className="flex-column box-border flex h-40 w-52 items-center"
      >
        <h2 className="text-3xl font-bold normal-case text-[#004F92]">
          {" "}
          <img
            src={EnsinoEinstein6}
            alt="Ícone hospital e médico"
            className="h-20 w-20"
          />
        </h2>
        <span className="mt-6 text-center text-base font-normal leading-5">
          Mais de
          <span className="font-bold"> 40 unidades </span>
          de saúde para
          <span className="font-bold"> estágios</span>
        </span>
      </div>,
    ];
  };

  return (
    <>
      <div className="bg-white">
        <Container>
          <div className="mt-4 flex justify-center">
            <div className="my-10 hidden max-w-3xl flex-row flex-wrap items-center justify-center gap-4 md:flex lg:flex xl:flex">
              {renderItens()}
            </div>
            <div className=" h-full w-full md:hidden ">
              <CustomSwiper
                className="h-[190px] w-auto rounded-xl"
                slidesPerView={1}
                spaceBetween={1}
                pagination={true}
                navigation={false}
                previousRef={prevRef}
                nextRef={nextRef}
                refPagination={refPagination}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
                quantidade={renderItens().length}
              >
                {renderItens().map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex h-auto w-full justify-center">
                      {item}
                    </div>
                  </SwiperSlide>
                ))}
              </CustomSwiper>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Info;
