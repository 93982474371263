import React from "react";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import "./styles.scss";
import { useHistory } from "react-router";
import { PublicoAlvoModel } from "../../../../models/PublicoAlvoModel";

interface ProfissoesCardsProps {
  publicosAlvos: PublicoAlvoModel;
  profissaoSelect: number;
  setProfissaoSelect: any;
}

export const ProfissaoCard: React.FC<ProfissoesCardsProps> = (props) => {
  const history = useHistory();
  const gtm = useGtm();

  function handlePublicoAlvo(publicoAlvo: PublicoAlvoModel) {
    gtm.push(
      EDataLayerEventCategory.ADE_PROFISSOES,
      EDataLayerEventAction.CLIQUE,
      {
        label: publicoAlvo.titulo!,
      },
    );
    history.push({
      pathname: `/profissoes/${publicoAlvo.id}`,
      state: {
        publicoAlvo: publicoAlvo,
      },
    });
  }

  return (
    <>
      <li>
        <button
          className="border-1 bg-white flex h-full min-h-[9.625rem] w-[171px] min-w-[8.625rem]
              cursor-pointer
              flex-col items-center justify-center rounded-lg border-slate-100 py-1 text-center shadow-md
              transition-all duration-200 hover:!bg-[#F5F8FF] hover:!border-[#004F92] focus:!border-[#004F92] focus:!bg-[#F5F8FF] md:min-h-[10rem]"
          onClick={() => handlePublicoAlvo(props.publicosAlvos)}
          aria-label={`Navegar para ${props.publicosAlvos.titulo}`}
        >
          <img
            height="75px"
            style={{ height: "75px" }}
            src={props?.publicosAlvos?.iconeUrl}
            alt={`Imagem ilustrativa representando ${props.publicosAlvos?.titulo}`}
            loading="lazy"
            aria-hidden="true"
          />
          <span className="mt-2 text-center text-ssm font-medium leading-normal text-[#000000]">
            {props.publicosAlvos?.titulo}
          </span>
        </button>
      </li>
    </>
  );
};
