import Container from "../../../shared/components/Container";
import Rotulo from "../../../shared/components/rotulos";
import {
  formatCustomDate,
  formatarDuracao,
} from "../../../shared/utils/format";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
} from "../../../shared/utils/tipo-objeto";
import CertificadoIcon from "../../../assets/svgs/icons/certificado.svg";
import TimeInfo from "../../../assets/svgs/new/time-info.svg";
import Calendar from "../../../assets/svgs/new/calendar.svg";
import React from "react";
import { DestaqueSemanaModel } from "../../../models/DestaqueSemana";
import { SmartLinkNovaHome } from "../../../shared/components/SmartLinkNovaHome";

const ObjetoEducacionalBanner: React.FC<DestaqueSemanaModel> = (area) => {
  return (
    <>
      <SmartLinkNovaHome
        oe={area}
        onShowCertificate={() => {}}
        isListCertificados={false}
      >
        <div className="relative h-[24.125rem] w-full md:h-[25rem] tablet:h-[16.25rem]">
          <img
            alt={area.titulo}
            src={
              area.previewDestaqueSemanaUrl
                ? area.previewDestaqueSemanaUrl
                : area.imagemPreviewUrl
            }
            width={1920}
            height={400}
            className="absolute h-full w-full object-cover object-center"
          />
          <Container className="relative flex h-full items-center md:pl-20 lg:pl-20 tablet:pl-0 tablet:pr-0 ">
            <div className="destaque-query flex cursor-pointer  flex-col gap-6 md:max-w-[25.31rem] tablet:ml-10">
              <div className="flex gap-1.5 md:gap-3 tablet:gap-2">
                <Rotulo rotuloName={area.descricaoRotulo} id={area.idRotulo} />
                {area.possuiCertificado && !area.dataHoraLive && (
                  <div className="flex h-[1.562rem] items-center justify-center rounded-full border-[1px] border-white-200 px-4 py-2 text-center md:h-7 tablet:h-[1.125rem]">
                    <span className="flex items-center gap-1 text-xxs font-bold leading-none text-white-200">
                      <img
                        src={CertificadoIcon}
                        alt="ícone certificado"
                        width={14.45}
                        height={14.45}
                      />
                      COM DECLARAÇÃO
                    </span>
                  </div>
                )}
              </div>

              <span className="text-lg font-medium leading-6 text-white-200 md:text-2xl  md:leading-7 tablet:text-sm tablet:leading-18 ">
                {area.titulo}
              </span>

              <div className="flex flex-wrap gap-[6px] md:gap-1">
                <div className="flex h-[23px] items-center justify-center rounded-full bg-black-900/40  px-3 py-2 text-center md:h-[28px]">
                  <span className="flex items-center gap-[5.92px] text-center text-xxs font-bold uppercase leading-none text-white-200 md:gap-1 md:text-xs">
                    {getIconeTipoObjeto(area?.tipoObjetoId, "white", 20)}
                    {getDescricaoTipoObjeto(area.tipoObjetoId)}
                  </span>
                </div>
                <div className="flex h-[23px] items-center justify-center rounded-full bg-black-900/40  px-3 py-2 text-center md:h-[28px]">
                  <span className="flex items-center gap-[5.92px] text-center text-xxs font-bold uppercase leading-none text-white-200 md:gap-1 md:text-xs">
                    <img
                      src={Calendar}
                      alt="icone calendario"
                      width={17.76}
                      height={17.76}
                    />
                    {formatCustomDate(area.dataProducao)}
                  </span>
                </div>

                {!area.dataHoraLive && (
                  <div className="flex h-[23px] items-center justify-center rounded-full bg-black-900/40  px-3 py-2 text-center md:h-[28px]">
                    <span className="flex items-center gap-[5.92px] text-center text-xxs font-bold uppercase leading-none text-white-200 md:gap-1 md:text-xs">
                      <img
                        src={TimeInfo}
                        alt="icon-video"
                        width={17.76}
                        height={17.76}
                      />
                      {formatarDuracao(parseInt(area.tempoMedioDuracao))}
                    </span>
                  </div>
                )}
              </div>

              {area.isPatrocinado && (
                <div>
                  <span className="text-xxs font-semibold leading-6 text-[#AAACAF] md:text-xs">
                    CONTEÚDO PATROCINADO
                  </span>
                </div>
              )}
            </div>
          </Container>
        </div>
      </SmartLinkNovaHome>
    </>
  );
};

export default ObjetoEducacionalBanner;
