import React from "react";
import Close from "@mui/icons-material/Close";

import { Badge } from "../ui/badge";
import { cn } from "../../utils/utils";

interface TagsProps {
  title: string;
  onClose?: () => void;
  isSearchable?: boolean;
  className?: string;
}

export const Tags: React.FC<TagsProps> = ({
  title,
  onClose,
  isSearchable,
  className,
}) => {
  const divStyle = {
    cursor: isSearchable ? "pointer" : "default",
  };

  const handleSearch = () => {
    if (isSearchable && /^[^<>\\&'"\x00-\x1F\x7F-\x9F]*$/.test(title)) {
      window.location.href = `/oe/pesquisa?search=${title}`;
    }
  };

  return (
    <Badge
      onClick={handleSearch}
      style={divStyle}
      className={cn(
        `flex h-[23px] !w-auto truncate rounded-full border-0 bg-[#313335] px-3  py-2 text-center hover:brightness-75 md:h-[28px]`,
        className,
      )}
    >
      <span className={cn("text-xs font-bold uppercase text-[#FDFEFF]")}>
        {title.toUpperCase()}
        {onClose && (
          <Close
            width={8}
            htmlColor="#107B95"
            style={{ cursor: "pointer", width: "14px", marginLeft: "4px" }}
            onClick={onClose}
          />
        )}
      </span>
    </Badge>
  );
};

export default Tags;
