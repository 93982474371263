import { OE_ACTION_TYPE } from "../../shared/constants/action-types";
import GetQuiz from "../../graphql/collections/queries/quizz.query";

export const loadOePageAction = async (oe: number) => {
  const result = await GetQuiz(oe).toPromise();

  return {
    type: OE_ACTION_TYPE.LOAD_FULL_CONTENT,
    payload: result ?? null,
  };
};
