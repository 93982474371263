import { gql } from "@apollo/client";
import { from } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import { MutateClient, QueryClient } from "../..";
import { QuizEntity } from "../../../generated/graphql";

export const GET_OE_BY_ID = gql`
  query GetOeById($oe: ID!) {
    objetoEducacional(id: $oe) {
      data {
        id
        attributes {
          createdAt
          publishedAt
          __typename
          Titulo
          Rotulos {
            data {
              id
              attributes {
                titulo
              }
            }
          }
          Quizzes {
            data {
              id
              attributes {
                createdAt
                publishedAt
                updatedAt
                nome
                feedback
              }
            }
          }
          palavras_chave {
            data {
              id
              attributes {
                titulo
              }
            }
          }
        }
      }
    }
  }
`;

export const MUTATION_RESPOSTA = gql`
  mutation CreateRespostasQuestionario(
    $questionario: ID
    $questao: ID
    $usuario: ID
    $resposta_questao: Int
    $resposta_correta_questao: Int
  ) {
    createRespostaQuestao(
      data: {
        questionario: $questionario
        questao: $questao
        usuario: $usuario
        resposta_questao: $resposta_questao
        resposta_correta_questao: $resposta_correta_questao
      }
    ) {
      data {
        id
        attributes {
          questao {
            data {
              id
            }
          }
          usuario {
            data {
              id
            }
          }
          resposta_questao
          resposta_correta_questao
          questionario {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const MUTATION_DELETE_RESPOSTA = gql`
  mutation DeleteRespotaQuestao($id: ID!) {
    deleteRespostaQuestao(id: $id) {
      data {
        id
        attributes {
          questao {
            data {
              id
            }
          }
          usuario {
            data {
              id
            }
          }
          resposta_questao
          resposta_correta_questao
          questionario {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_RESPOSTAS = gql`
  query GetRespostasQuestionario($questionario: ID, $usuario: ID) {
    respostaQuestaos(
      filters: {
        questionario: { id: { eq: $questionario } }
        usuario: { id: { eq: $usuario } }
      }
    ) {
      data {
        id
        attributes {
          questao {
            data {
              id
            }
          }
          usuario {
            data {
              id
            }
          }
          resposta_questao
          resposta_correta_questao
          questionario {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_QUIZZES = gql`
  query GetQuizzes($ids: [ID!]!) {
    quizzes(filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          createdAt
          publishedAt
          updatedAt
          nome
          feedback
          perguntas {
            ... on ComponentPerguntaQuizzPerguntas {
              pergunta {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PERCENTUAL_QUIZ = gql`
  query GetPercentualQuiz($questionarioId: ID!, $usuarioId: ID!) {
    getPercentualResposta(
      questionarioId: $questionarioId
      usuarioId: $usuarioId
    ) {
      percentual_usuario
      percentual_outros
    }
  }
`;

export const GET_PERGUNTAS = gql`
  query GetPerguntas($ids: [ID!]!) {
    perguntaQuizs(filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          imagem {
            data {
              attributes {
                url
              }
            }
          }
          legenda_imagem
          enunciado
          PDF {
            id
            arquivo {
              data {
                attributes {
                  url
                }
              }
            }
            LinkPDF
            __typename
          }
          feedback_acerto
          feedback_erro
          feedback_quiz
          respostas {
            id
            opcao
            opcao_correta
            contador
          }
          url_vimeo
        }
      }
    }
  }
`;

export default function GetQuiz(id: number) {
  let quizzes: any[] = [];

  return from(QueryClient<any>(GET_OE_BY_ID, { oe: id })).pipe(
    switchMap((result) => {
      const quizIds: string[] | undefined =
        result?.data.objetoEducacional.data?.attributes?.Quizzes?.data.map(
          (component: QuizEntity) => {
            return component.id;
          },
        );
      if (!quizIds) {
        return [];
      }
      return QueryClient<any>(GET_QUIZZES, { ids: quizIds });
    }),
    switchMap((result) => {
      const perguntasIds: any[] = (result?.data.quizzes.data as any[]).map(
        (quiz) => {
          quizzes.push(Object.assign({}, quiz));
          return quiz.attributes.perguntas?.map(
            (pergunta: any) => pergunta.pergunta?.data?.id || "",
          );
        },
      );
      return QueryClient<any>(GET_PERGUNTAS, { ids: perguntasIds[0] });
    }),
    map((result) => {
      quizzes.forEach((quiz) => {
        quiz.perguntas = quiz.attributes.perguntas
          .map((pergunta: any) => {
            return result?.data.perguntaQuizs.data.filter((_pergunta: any) => {
              return pergunta?.pergunta?.data?.id === _pergunta?.id;
            });
          })
          .flat();
      });

      return { data: quizzes };
    }),
  );
}

export const GetPercentualRespostas = (
  idQuestionario: number,
  idUsuario: number,
) => {
  return from(
    QueryClient<any>(GET_PERCENTUAL_QUIZ, {
      questionarioId: idQuestionario,
      usuarioId: idUsuario,
    }),
  ).pipe(
    map((result) => {
      return result.data.getPercentualResposta;
    }),
  );
};

export const GetRespostasQuestionario = (
  idQuestionario: number,
  idUsuario: number,
) => {
  return from(
    QueryClient<any>(GET_RESPOSTAS, {
      questionario: idQuestionario,
      usuario: idUsuario,
    }),
  ).pipe(
    map((result) => {
      const quizzes: any[] | undefined = result?.data?.respostaQuestaos?.data;
      return quizzes;
    }),
  );
};

export const GravarRespostaQuestionario = (
  idQuestionario: number,
  idUsuario: number,
  idResposta: number,
  idRespostaCorreta: number,
  idQuestao: number,
) => {
  return from(
    MutateClient<any>(MUTATION_RESPOSTA, {
      questionario: idQuestionario,
      questao: idQuestao,
      usuario: idUsuario,
      resposta_questao: idResposta,
      resposta_correta_questao: idRespostaCorreta,
    }),
  ).pipe(
    map((result) => {
      const quizzes: any | undefined =
        result?.data?.createRespostaQuestao?.data;
      return quizzes;
    }),
  );
};

export const deleteRespostaQuestionario = (idResposta: number) => {
  return from(
    MutateClient<any>(MUTATION_DELETE_RESPOSTA, {
      id: idResposta,
    }),
  ).pipe(
    map((result) => {
      const quizzes: any | undefined =
        result?.data?.deleteRespostaQuestao?.data;
      return quizzes;
    }),
  );
};
