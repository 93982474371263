export const splitOeTitle = (title: string, total?: number) => {
  if (!title) {
    return title;
  }

  total = total || 120;

  if (title.length > total) {
    title = title.substring(0,total);
    title += "...";
  }

  return title;
};
