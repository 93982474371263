import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../../shared/components/ui/breadcrumb";

interface BreadCrumbQuizProps {
  isTesteConhecimento?: boolean;
}
export const BreadCrumbQuiz: React.FC<BreadCrumbQuizProps> = ({
  isTesteConhecimento,
}) => {
  return (
    <div className="flex !pt-5">
      <Breadcrumb className="flex justify-start !text-xxs font-normal  !text-[#111316] md:text-xs">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink
              className="text-[#111316] hover:font-semibold hover:text-[#111316] focus:font-semibold focus:text-[#111316] "
              href="/"
            >
              Home
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              className="text-[#111316] hover:font-semibold hover:text-[#111316] focus:font-semibold focus:text-[#111316]"
              href="/metodos-ensino"
            >
              Métodos de ensino
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              className="text-[#111316] hover:font-semibold hover:text-[#111316] focus:font-semibold focus:text-[#111316]"
              href="/metodos-ensino"
            >
              {isTesteConhecimento
                ? "Teste seus conhecimentos"
                : "Fast Challenge"}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  );
};
