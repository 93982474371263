import { useState } from "react";
import {
  useCreateOeAssistidoPorUsuarioMutation,
  useUpdateOeAssistidoPorUsuarioMutation,
} from "../generated/graphql";
import ResetUserContext from "./ResetUserContext";

export interface ContentConsumedState {
  totalDuration?: number;
  partialDuration?: number;
  id?: number | string;
  oe?: number | string;
  videoAudioId?: number | undefined;
  infograficoId?: number | undefined;
  leituraId?: number | undefined;
  user?: string;
}

const useOeConsume = () => {
  const [state, setState] = useState<ContentConsumedState | null>(null);

  const [createOeAssistidoPorUsuario] =
    useCreateOeAssistidoPorUsuarioMutation();

  const [updateOeAssistidoPorUsuario] =
    useUpdateOeAssistidoPorUsuarioMutation();

  const { resetUserContext } = ResetUserContext();

  const _isValid = (data: ContentConsumedState) =>
    !!data &&
    !!data.oe &&
    !!data.user;

  const saveOeConsume = async (dataState?: ContentConsumedState) => {
    const _state: ContentConsumedState = {
      ...(state ? state : ({} as ContentConsumedState)),
      ...(dataState ? dataState : ({} as ContentConsumedState)),
    };

    setState(_state);

    if (!_isValid(_state)) {
      return false;
    }

    let data: any = {
      Oes_Assistidos: [_state?.oe!],
      duracao: _state?.totalDuration,
      segundos: _state?.partialDuration,
      ConsumidoresParciais: [_state?.user],
    };

    if (!_state.id) {
      if (!!_state?.videoAudioId) {
        data = {
          ...data,
          VideoAudioId: parseInt(_state.videoAudioId.toString()),
        };
      }

      if (!!_state.infograficoId) {
        data = {
          ...data,
          ImagemOeId: parseInt(_state.infograficoId.toString()),
        };
      }

      if (!!_state.leituraId) {
        data = {
          ...data,
          LeituraOeId: parseInt(_state.leituraId.toString()),
        };
      }

      const response = await createOeAssistidoPorUsuario({
        variables: {
          input: {
            ...data,
            publishedAt: new Date(),
          },
        },
      });

      setState({
        ...(_state ? _state : {}),
        id: response.data?.createOEsAssistidosPorUsuario?.data?.id!,
      });
    } else {
      await updateOeAssistidoPorUsuario({
        variables: {
          id: _state.id.toString(),
          input: {
            ...data,
          },
        },
      });
    }

    resetUserContext(_state.user);

    return true;
  };

  const updateOeConsume = (data: ContentConsumedState) => {
    setState({
      ...state,
      ...data,
    });
  };

  const getCurrentTime = () => {
    return state?.partialDuration;
  };

  return {
    getCurrentTime,
    updateOeConsume,
    saveOeConsume,
    resetUserContext,
  };
};

export default useOeConsume;
