import React from "react";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

import "./styles.scss";
import { isMobile } from "react-device-detect";

interface BannerProps {
  item: any;
  gtm?: any;
  fixo?: Boolean;
}

export const BannerComponentHOC: React.FC<BannerProps> = (props) => {
  const gtm = useGtm();

  return <BannerComponent {...{ ...props, gtm }} />;
};

const BannerComponent: React.FC<BannerProps> = ({ item, gtm, fixo }) => {
  return (
    <>
      {item && !isMobile && (
        <a
          data-banner-desktop
          className={fixo ? "!rounded-none" : ""}
          target="blank"
          href={item.linkFuga || "#"}
          onClick={() => {
            // Evento mapeado para o GTM
            gtm.push(
              EDataLayerEventCategory.ADE_BANNER,
              EDataLayerEventAction.CLIQUE,
              {
                label: `Código do Banner: ${item.id}${
                  item?.nomePatrocinador
                    ? ` ; Patrocinador: ${item.nomePatrocinador}`
                    : ""
                }`,
                idBanner: item.id,
                tituloBanner: "",
                patrocinador: item?.nomePatrocinador
                  ? item?.nomePatrocinador
                  : "",
                tipoPagina: item.tipoPagina,
                areaPagina: item.areaPagina,
              },
            );
          }}
        >
          <img
            src={item?.url}
            className={fixo ? "!rounded-none" : ""}
            alt="Imagem do banner"
          />
        </a>
      )}
      {item && isMobile && (
        <a
          data-banner-mobile
          target="blank"
          href={item?.linkFuga || "#"}
          onClick={() => {
            // Evento mapeado para o GTM
            gtm.push(
              EDataLayerEventCategory.ADE_BANNER,
              EDataLayerEventAction.CLIQUE,
              {
                label: `Código do Banner: ${item.id}${
                  item?.nomePatrocinador
                    ? ` ; Patrocinador: ${item.nomePatrocinador}`
                    : ""
                }`,
                idBanner: item.id,
                tituloBanner: "",
                patrocinador: item?.nomePatrocinador
                  ? item?.nomePatrocinador
                  : "",
                tipoPagina: item.tipoPagina,
                areaPagina: item.areaPagina,
              },
            );
          }}
        >
          <img
            className={fixo ? "!rounded-none" : ""}
            src={item?.url}
            alt="Imagem do banner"
          />
        </a>
      )}
    </>
  );
};
