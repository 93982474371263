import { useEffect, useState } from "react";
import Container from "../../../shared/components/Container";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";

export interface Contadores {
  quantidadeOes: string;
  quantidadeAutores: string;
  quantidadeUser: string;
}

async function getContadores() {
  const response = await fetchApi("quantidade/get", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return {
        quantidadeOes: "0",
        quantidadeAutores: "0",
        quantidadeUser: "0",
      };
    }
  });
  return response;
}

export default function Contadores() {
  const [contadores, setContadores] = useState<any>();

  const loadContadores = async () => {
    setContadores(await getContadores());
  };

  useEffect(() => {
    loadContadores();
  }, []);

  return (
    <div
      className=""
      style={{
        background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
      }}
    >
      <Container className="py-9 md:py-10 ">
        <div className="flex flex-col justify-center gap-3 md:flex-row md:gap-6">
          <div className="flex h-[103px] w-full flex-col items-center justify-center gap-2 rounded-xl  md:h-[140px] md:w-[298px]">
            <h3 className="text-1.5xl font-semibold text-white-200 md:text-3xl">
              + {contadores?.quantidadeOes?.toLocaleString()}
            </h3>
            <p className="max-w-[260px] text-center text-xs font-normal leading-4  text-white-200  md:!text-base md:leading-5">
              <span className="font-bold">
                Conteúdos disponíveis e gratuitos
              </span>{" "}
              para você acessar quando quiser.
            </p>
          </div>

          <div className="flex h-[103px] w-full flex-col items-center justify-center gap-2 rounded-xl  md:h-[140px] md:w-[298px]">
            <h3 className="text-1.5xl font-semibold text-white-200  md:text-3xl">
              + {contadores?.quantidadeUser?.toLocaleString()}
            </h3>
            <p className="max-w-[260px] text-center text-xs font-normal leading-4  text-white-200  md:text-base md:leading-5 ">
              <span className="font-bold">Membros cadastrados</span> com acesso
              aos conteúdos da Academia.
            </p>
          </div>

          <div className="flex h-[103px] w-full flex-col items-center justify-center gap-2 rounded-xl  md:h-[140px] md:w-[298px]">
            <h3 className="text-1.5xl font-semibold text-white-200  md:text-3xl">
              + {contadores?.quantidadeAutores?.toLocaleString()}
            </h3>
            <p className="max-w-[260px] text-center text-xs font-normal leading-4  text-white-200  md:text-base md:leading-5">
              <span className="font-bold">Autores na Academia Digital</span>{" "}
              produzindo conteúdos gratuitos semanalmente.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
