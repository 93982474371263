import ReactInputMask from "react-input-mask";
import { useFormik } from "formik";
import { useModalDegustacaoPos } from "../../../hooks/useModalDegustacaoPos";
import { UserContext } from "../../../context/UserContext";
import { useContext, useState } from "react";
import { fetchApi } from "../../services/rest-dotnet.service";
import { Button } from "../ui/button";
import { getTokenFromCookie } from "../../services/localStorage.service";

export default function ModalComplementaCadastro() {
  const [loading, setLoading] = useState(false);

  const [possuiCPF, setPossuiCPF] = useState(true);
  const modalDegustacaoPos = useModalDegustacaoPos();

  const formik = useFormik({
    initialValues: {
      cpf: "",
    },
    validate: (values) => {
      const errors: any = {};

      if (!possuiCPF) return true;

      if (!values.cpf) {
        errors.cpf = "Campo obrigatório";
      } else if (!validarCPf(values.cpf)) {
        errors.cpf = "CPF inválido";
      }
      return errors;
    },
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);

      const token = getTokenFromCookie();

      await fetchApi(
        "degustacaopos/criarDegustacaoPos",
        "POST",
        JSON.stringify({
          documento: values.cpf,
          documentoInternacional: possuiCPF ? false : true,
        }),
        token,
      )
        .then((resultado: any) => {
          if (resultado.ok) {
            setLoading(false);
            modalDegustacaoPos.mostrar();
          } else {
            alert("Erro ao criar degustação, tente novamente");
            setLoading(false);
          }
        })
        .catch((error) => {
          alert("Erro ao criar degustação, tente novamente");
          setLoading(false);
        });
    },
  });

  const validarCPf = (Cpf: string | undefined) => {
    Cpf = !!Cpf ? Cpf.replace(/(\.|-)/g, "") : "0";

    if (Cpf === "") {
      return false;
    }

    if (Cpf.length < 11) {
      return false;
    }

    if (
      Cpf.length !== 11 ||
      Cpf === "00000000000" ||
      Cpf === "11111111111" ||
      Cpf === "22222222222" ||
      Cpf === "33333333333" ||
      Cpf === "44444444444" ||
      Cpf === "55555555555" ||
      Cpf === "66666666666" ||
      Cpf === "77777777777" ||
      Cpf === "88888888888" ||
      Cpf === "99999999999"
    ) {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(Cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(Cpf.charAt(9))) {
      return false;
    }

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(Cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(Cpf.charAt(10))) {
      return false;
    }

    return true;
  };

  return (
    <div className="bg-white z-50 flex h-[327px]  w-[450px] items-center justify-center rounded-3xl p-6 md:py-8 ">
      <div className="flex w-full flex-col items-center">
        <h3 className="text-center !text-base font-bold leading-[20px] text-[#004F92]">
          Para começar a sua Experiência
          <br /> conosco, informe o seu CPF
        </h3>
        <div className="mt-6 flex w-full flex-col gap-1">
          <label className="text-[10px] font-normal md:text-sm">
            {possuiCPF ? "CPF" : "Número documento internacional"}
          </label>
          {possuiCPF ? (
            <ReactInputMask
              mask="999.999.999-99"
              onChange={formik.handleChange}
              value={formik.values.cpf}
            >
              {(inputProps) => (
                <input
                {...inputProps}
                id="cpf"
                name="cpf"
                type="text"
                autoComplete="off"
                onChange={formik.handleChange}
                value={formik.values.cpf}
                className="flex-full flex h-[50px] rounded-[30px]  border bg-[#FAFAFC] px-[18px] py-4"
              />
              )}
            </ReactInputMask>
          ) : (
            <input
              id="cpf"
              name="cpf"
              maxLength={80}
              min={5}
              type="text"
              onChange={formik.handleChange}
              value={formik.values.cpf}
              className="flex-full flex h-[50px] rounded-[30px]  border bg-[#FAFAFC] px-[18px] py-4"
            />
          )}
          <label className="text-xs font-bold text-red-500">
            {formik.values.cpf && formik.errors.cpf ? formik.errors.cpf : ""}
          </label>
          {/* <label
            onClick={() => {
              setPossuiCPF(!possuiCPF);
              formik.setFieldValue("cpf", "");
            }}
            className="font-normal mb-8 text-[10px] md:text-sm self-end text-[#004F92] underline cursor-pointer"
          >
            {possuiCPF ? "Não possuo CPF" : "Possuo CPF"}
          </label> */}
        </div>

        <Button
          data-testid="btn-atualizar-cadastro"
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
          disabled={loading}
          className=" text-white mt-4 h-[50px] w-[220px] cursor-pointer rounded-[30px] bg-[#004F92] font-bold hover:bg-[#005EA6]"
        >
          Atualizar cadastro
        </Button>
      </div>
    </div>
  );
}
