import React from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SwiperSlide } from "swiper/react";
import CustomSwiper from "../CustomSwiper";
import { CardData } from "../CardAreaResidenciaMedica";
import { CardSwiperItemResidenciaMedica } from "../CardSwiperItemResidenciaMedica";
import { ResidenciaMedicaEntity } from "../../../generated/graphql";

export interface CardSwiperResidenciaMedicaProps {
    items: ResidenciaMedicaEntity[];
    loading?: boolean;
}

export const CardSwiperResidenciaMedica: React.FC<CardSwiperResidenciaMedicaProps> = ({
    items,
    loading
}) => {

    const CardLoading = () => {
        const fakeArray = Array.from(Array(4).keys());

        return fakeArray.map((_, idx) => (
            <SwiperSlide key={idx}>
                <div className="overflow-hidden relative space-y-5 rounded-xl bg-slate-100 h-[260px]">
                    <div className="w-full h-full rounded-xl -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
                </div>
            </SwiperSlide>
        ));
    };

    const CardNoLoading = () => {
        return (
            <>
                {items.map((item: any, idx) => (
                    <SwiperSlide key={idx}>
                        <CardSwiperItemResidenciaMedica
                            loading={loading}
                            item={item}
                        />
                    </SwiperSlide>
                ))}
            </>
        );
    };

    return (
        <CustomSwiper
            className="rounded-2xl box-border"
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                },
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                },
            }}
            navigation={true}
            pagination={true}
            spaceBetween={15}
            quantidade={items.length}
        >
            {loading ? CardLoading() : CardNoLoading()}
        </CustomSwiper>
    );
};
