import { useContext, useEffect, useRef, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import { cn } from "../../../lib/utils";
import * as Icon from "../../../assets/svgs";
import { twMerge } from "tailwind-merge";
import Container from "../Container";
import React from "react";
import useOeConsume from "../../../hooks/useOeConsume";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { UserContext } from "../../../context/UserContext";

export default function Flashcard({
  objetoEducacional,
}: {
  objetoEducacional: ObjetoEducacionalEntity;
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [clicked, setClicked] = useState<number[]>([]);

  const [flashcards, setFlashcards] = useState<any[]>();

  const { user } = useContext(UserContext);
  const consume = useOeConsume();
  const handleTimeUpdateQuizRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateQuizRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    const oeAssistido = user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item) =>
        !!item?.attributes?.Oes_Assistidos &&
        item.attributes.Oes_Assistidos.data.find(
          (i) => i.id === objetoEducacional.id,
        ) !== undefined,
    );

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo

      if (
        (oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration
      ) {
        return;
      }

      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: objetoEducacional.id,
        user: user?.id,
      });
    });

    if (oeAssistido && oeAssistido?.attributes?.segundos) {
      setCurrent(oeAssistido?.attributes?.segundos || 0);
    } else {
      setCurrent(1);
    }

    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };
  }, [user, objetoEducacional]);

  const updateTimeInProgress = (page: number, pageTotal: number) => {
    handleTimeUpdateQuizRef.current({
      partialDuration: page,
      totalDuration: pageTotal,
    });
  };

  useEffect(() => {
    if (objetoEducacional) {
      const flashcards = objetoEducacional.attributes?.flashcards?.data?.map(
        (flashcard) => {
          return {
            ...flashcard,
            color: generateRandomColor(),
          };
        },
      );

      setFlashcards(flashcards);
    }
  }, [objetoEducacional]);

  const [showTutorialModal, setShowTutorialModal] = useState(false);

  useEffect(() => {
    const tutorialShown = localStorage.getItem("flashcardTutorial");
    if (!tutorialShown) {
      setShowTutorialModal(true);
      localStorage.setItem("flashcardTutorial", "true");
    }
  }, []);

  function handleCloseModal() {
    setShowTutorialModal(false);
  }

  let nextColorIndexState = 0;

  function generateRandomColor() {
    const baseColors = [
      "bg-[#004F92]",
      "bg-[#107B95]",
      "bg-[#009688]",
      "bg-[#8CC152]",
      "bg-[#673FB4]",
      "bg-[#9A0B83]",
      "bg-[#E62565]",
      "bg-[#FC5830]",
      "bg-[#FD9727]",
    ];

    let nextColorIndex = nextColorIndexState || 0;

    const nextColor = baseColors[nextColorIndex];
    nextColorIndex = (nextColorIndex + 1) % baseColors.length;

    nextColorIndexState = nextColorIndex;

    return nextColor;
  }

  useEffect(() => {
    if (!api) {
      return;
    }

    api.on("select", () => {
      setClicked(
        clicked.filter((item) => item !== api.selectedScrollSnap() + 1),
      );

      setCurrent(api.selectedScrollSnap() + 1);
      updateTimeInProgress(
        api.selectedScrollSnap() + 1,
        flashcards?.length || 0,
      );
    });
  }, [api]);

  function handleClick(index: number) {
    if (clicked.includes(index)) {
      setClicked(clicked.filter((item) => item !== index));
    } else {
      setClicked([...clicked, index]);
    }
  }

  return (
    <>
      <div className="mb-6 flex gap-2 overflow-y-hidden overflow-x-scroll whitespace-nowrap pb-2 md:justify-center md:overflow-x-hidden">
        {flashcards?.map((flashcard: any, index) => (
          <div
            key={index + 1}
            className={`flex  h-20 min-w-[57px] cursor-pointer flex-col items-center  justify-center gap-2 rounded-[2.99px] p-[8px] md:w-16 ${
              index === current - 1
                ? `shadow-md ${flashcard?.color}`
                : `${flashcard?.color} opacity-40`
            }`}
            onClick={() => api?.scrollTo(index)}
          >
            <div className="text-white text-center text-[4.98px] font-bold">
              Flashcards Einstein
            </div>
            <div className="bg-white flex h-[28px] w-[28px] items-center justify-center rounded-full">
              <span className="text-center text-[15.27px]  font-semibold">
                {index + 1}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className=" flex flex-col items-center justify-center">
        {showTutorialModal && (
          <div
            className={cn(
              "data-[state=open]:animate-in  data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  fixed inset-0 z-[9999] backdrop-blur-[4px]",
            )}
            onClick={handleCloseModal}
          >
            <div
              className="absolute bottom-0  left-0 right-0 top-0 mx-auto
            my-auto flex h-[54px] w-[342px] cursor-pointer items-center justify-center  rounded-xl bg-[#F5F8FF] p-4
            text-sm font-medium text-[#004F92] shadow-lg"
            >
              <Icon.IconClickIcon className="mr-2" />
              Clique no card para ver a resposta
            </div>
          </div>
        )}

        <Carousel
          opts={{
            loop: true,
          }}
          setApi={setApi}
          initialSlide={current - 1}
          className="w-full max-w-xs"
        >
          <CarouselContent className="">
            {flashcards?.map((flashcard: any, index) => (
              <CarouselItem key={index} className="flex justify-center">
                <div className="group [perspective:1000px]">
                  <div
                    onClick={() => handleClick(index)}
                    className={twMerge(
                      "relative flex h-[320px] w-[267px] cursor-pointer items-center justify-center  rounded-xl p-4",
                      flashcard?.color,
                    )}
                    style={{
                      transformStyle: "preserve-3d",
                      transition: "all 0.5s",
                      transform: `${
                        index === clicked.filter((item) => item === index)[0]
                          ? "rotateY(180deg)"
                          : ""
                      }`,
                    }}
                  >
                    <span className="text-white absolute left-4 top-4 text-xxs font-normal">
                      Flashcards <span className="font-bold">Einstein</span>
                    </span>
                    <div className="bg-white absolute right-4 top-4 flex  h-5 w-5 items-center justify-center rounded-full">
                      <span className="text-center text-[15.27px]  font-semibold">
                        {index + 1}
                      </span>
                    </div>
                    <div className="text-white text-center text-sm font-normal">
                      {flashcard?.attributes?.pergunta}
                    </div>
                    <div
                      className={`absolute cursor-pointer ${flashcard?.color} inset-0 flex items-center  justify-center  rounded-xl p-4 [backface-visibility:hidden] [transform:rotateY(180deg)]`}
                      style={{
                        transform: "rotateY(180deg)",
                        backfaceVisibility: "hidden",
                      }}
                    >
                      <div className="absolute  left-4 top-4 flex items-center justify-between">
                        <span className="text-white text-xxs font-normal">
                          Flashcards <span className="font-bold">Einstein</span>
                        </span>
                      </div>
                      <div className="bg-white absolute right-4 top-4 flex h-5 w-5 items-center justify-center rounded-full">
                        <span className="text-center text-xs  font-semibold">
                          {index + 1}
                        </span>
                      </div>
                      <div className="text-white absolute  p-4  text-center text-sm font-normal ">
                        {flashcard?.attributes?.resposta}
                      </div>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-flashcard"
            className="-ml-20 mt-40 shadow-none"
            color="white"
            isShowButton
          />
          <CarouselNext
            data-testid="btn-next-flashcard"
            className="-mr-20 mt-40 shadow-none"
            color="white"
            isShowButton
          />
        </Carousel>
      </div>
    </>
  );
}
