import { InputAdornment, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { from, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, take } from "rxjs/operators";
import { UserContext } from "../../../../../../context/UserContext";
import {
  UsersPermissionsUser,
  UsersPermissionsUserEntity,
  useUpdateUserMutation,
} from "../../../../../../generated/graphql";
import useAreasSelectedValidator from "../../../../../../validators/area-selected.validator";
import ResetUserContext from "../../../../../../hooks/ResetUserContext";
import useSelectPreference, {
  AreaConhecimentoWithSelected,
} from "../../../../../../hooks/useSelectPreference";
import "./styles.scss";
import { Button } from "../../../../../../shared/components/ui/button";
import { ToastContainer, toast } from "react-toastify";
import { CircleCheckBig } from "lucide-react";

const styles = {
  root: {
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      fontWeight: "600",
      color: "#0A589B",
    },
    "& .MuiInput-underline:hover:before": {
      borderColor: "#0A589B",
    },
    "& .MuiInput-underline:before": {
      borderColor: "rgba(112, 112, 112, .3)",
    },
    "& .MuiInput-underline:after": {
      borderColor: "#0A589B",
    },
    "& .MuiInputBase-input": {
      color: "#69737C",
      fontFamily: "Open Sans",
      fontSize: "14px",
    },
  },
};

const MyTextField = withStyles(() => styles)(TextField);

const UserPreferences: React.FC<{ user: any }> = ({ user }) => {
  const [formData, setFormData] = useState<UsersPermissionsUserEntity | null>(
    null,
  );
  const {
    list: areas,
    setList: setAreas,
    loadingAreas,
    selectPreference: _selectPreference,
    updateAreasWithUser,
  } = useSelectPreference();
  //const { user } = useContext(UserContext);

  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);
  const [acceptShareData, setAcceptShareData] = useState(false);

  const [updateUser] = useUpdateUserMutation();
  const { resetUserContext } = ResetUserContext();

  useEffect(() => {
    if (!!user) {
      setFormData(user!);
      setAcceptShareData(Boolean(user.attributes?.acceptSharePersonData));

      if (!!areas && !!areas.length) {
        updateAreasWithUser(user!);
      }
    }
  }, [loadingAreas, user]);

  // adicionando o event de filtro com debouceTime para delay
  // de filtragem apenas quando usuário parar a digitação
  const keyUp = new Subject<
    React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  >();

  keyUp
    .pipe(
      map((e) => e.target.value),
      debounceTime(500),
      distinctUntilChanged(),
    )
    .subscribe((word) => {
      const _areas = areas.reduce<AreaConhecimentoWithSelected[]>(
        (acc, item) => {
          item.display = "none";

          const formatNfd = (value: string) =>
            value
              .toLocaleLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "");

          if (formatNfd(item.titulo!).indexOf(formatNfd(word)) !== -1) {
            item.display = "block";
          }

          acc.push(item);

          return acc;
        },
        [],
      );

      setAreas(_areas);
    });

  const handleFilterValueChange = (e: any) => {
    keyUp.next(e);
  };

  const selectPreference = (id: string) => {
    const _listSelect = _selectPreference(id, formData);

    const _: UsersPermissionsUserEntity = {
      ...(formData! as UsersPermissionsUserEntity),
      attributes: {
        ...(formData?.attributes as UsersPermissionsUser),
        area_de_conhecimentos: {
          data:
            _listSelect.map((a) => ({
              id: a.toString(),
              attributes: undefined,
            })) || [],
        },
      },
    };

    setFormData(_);
  };

  const validate = () => {
    let isPermitied = true;

    if (
      !areas.filter((item) => item.selected).map((item) => item.id!.toString())
        .length
    ) {
      isPermitied = false;
    }

    return isPermitied;
  };

  const showUpdateSucceedModal = () => {
    toast.success(
      <div className="flex flex-row gap-3 mx-2 items-center">
        <CircleCheckBig className="h-5 w-5" />
        <div className="flex flex-col">
          <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Atualização feita com sucesso!</div>
        </div>
      </div>, {
      icon: false,
      closeButton: false,
      style: {
        backgroundColor: '#21AB27',
        color: 'white',
        borderRadius: '35px',
      }
    });
  };

  /**
   * Atualiza as preferências de conteúdo do usuário
   */
  const handleUpdateUser = () => {
    if (!user || !validate()) {
      return;
    }

    from(
      updateUser({
        variables: {
          id: user.id!,
          input: {
            area_de_conhecimentos: areas
              .filter((item) => item.selected)
              .map((item) => item.id!.toString()),
            acceptSharePersonData: acceptShareData,
          },
        },
      }),
    )
      .pipe(take(1))
      .subscribe(() => {
        resetUserContext();
        showUpdateSucceedModal();
      });
  };

  const {
    render: renderErrors,
    errors: errorAreasSelected,
    setAreas: setAreasValidator,
  } = useAreasSelectedValidator();

  const hasAreasSelected =
    areas.filter((area) => area.selected === true).length > 0;

  function sendEmail(emailAddress: string) {
    window.location.href = `mailto:${emailAddress}`;
  }

  return (
    <section data-preferences-data>
      <ToastContainer />
      <header>
        <div className="wrapper-content">
          <p className="text-sm font-normal">
            Selecione até 5 opções. Você poderá alterar as opções selecionadas
            na sua área de perfil.
          </p>

          <span className="error-message">
            {!hasAreasSelected && renderErrors()}
          </span>
        </div>

        <MyTextField
          name="ConfirmacaoSenha"
          label="Busque por área"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={handleFilterValueChange}
        />
      </header>
      <section className="flex max-h-96 flex-wrap gap-2 overflow-y-auto rounded-lg border px-2 py-2 md:h-auto">
        {!areas.length && <b>carregando...</b>}
        {!areas.length && <b>Não foram encontrados resultados para a busca</b>}
        {areas
          ?.filter((item) => item.display === "block")
          .map((item) => (
            <Button
              key={item?.id}
              data-id={item.id}
              data-testid="btn-area-conhecimento"
              className={
                item.selected
                  ? "text-white flex h-10 w-auto items-center justify-center rounded-3xl bg-blue-900 px-4 py-3"
                  : "flex h-10 w-auto items-center justify-center rounded-3xl border bg-transparent px-4 py-3 text-blue-900"
              }
              onClick={() => selectPreference(item.id!)}
              variant="primary"
            >
              {item?.titulo}
            </Button>
          ))}
      </section>
      <span className="mt-4 flex w-full flex-col text-sm font-normal md:flex-row ">
        <span className="mr-2 flex-nowrap font-bold">Áreas selecionadas:</span>{" "}
        {areas
          .filter((area) => area.selected)
          .map((area) => area.titulo)
          .join(", ")}
      </span>
      <div className="mt-4 flex w-full text-sm font-normal">
        <div className="flex items-start gap-2">
          <input
            type="checkbox"
            id="acceptShareData"
            name="acceptShareData"
            className="mt-1 w-16  md:h-auto md:w-auto"
            checked={acceptShareData}
            onChange={() => setAcceptShareData(!acceptShareData)}
          />
          <div>
            Aceito fornecer meus dados pessoais a fim de receber informativos e
            contato do Ensino Einstein e seus parceiros, por meio de seus canais
            digitais de relacionamento
          </div>
        </div>
      </div>
      <div className="mt-4 flex flex-col justify-between gap-4 md:flex-row">
        <Button
          type="button"
          data-testid="btn-salvar-alteracoes"
          variant="primary"
          onClick={() => {
            setShowAllErrorsMessages(true);
            handleUpdateUser();
          }}
        >
          Salvar Alterações
        </Button>

        <Link
          to="/meu-perfil"
          data-testid="btn-voltar-para-inicio"
          className="button button-secondary-blue hide-for-mobile rounded-5xl !border-[#0a589b] !text-[#0a589b] !border-2 justify-center items-center p-2 px-4"
        >
          Voltar para o início
        </Link>
      </div>
      <span className="mt-4 text-center text-xs font-bold leading-4 text-[#111316] md:text-start">
        Problemas no cadastro? Envie email para{" "}
        <span
          className="cursor-pointer text-[#004F92] underline"
          onClick={() => sendEmail("academia.digital@einstein.br")}
        >
          academia.digital@einstein.br
        </span>
      </span>
    </section>
  );
};

export default UserPreferences;
