import { ModalService } from "../shared/components/Modal";
import ModalComplementaCadastro from "../shared/components/ModalComplementaCadastro";

export const useModalComplementoCadastro = () => {
  const mostrar = () => {
    ModalService.show({
      content: <ModalComplementaCadastro />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
    });
  };

  return { mostrar };
};
