import React, { useEffect, useState } from "react";

import "./styles.scss";

import { SmartIcon, TimeView, Favoritar } from "../..";

import { getUserContext } from "../../../../context/UserContext";

import CertificadoCard from "../../CertificadoCard";
import Compartilhar from "../../../../pages/OEPage/components/Compartilhar";
import { Lembrar } from "../../Lembrar";
import { useUtils } from "../../../utils";

export type ColorStyleType = "blue" | "green" | "white" | "yellow" | "gray";

interface ItemProps {
  id: string;
  titulo: string;
  TipoObjeto: {
    titulo: string;
  }[];
  Descricao: string;
  TempoMedioDuracao: string;
  PossuiCertificado: boolean;
  imagemPreview: string;
  VideoAudio: {
    id: number;
  }[];
  SecoesTexto: {
    id: number;
  }[];
  Infografico: {
    id: number;
  }[];
  VideoId: number;
}

interface OeMediaFooterProps {
  item: ItemProps;
  color: ColorStyleType;
  padding: number[];
  quiz?: boolean;
  gradient?: boolean;
  nonAbsolute?: boolean;
  favoriteColor?: ColorStyleType;
  shareUrl?: string;
  estaAoVivo?: boolean;
}

enum EstadoUsoOe {
  NAO_UTILIZADO,
  VISUALIZADO,
  FINALIZADO,
}

const OeMediaFooterComponent: React.FC<OeMediaFooterProps> = (props) => {
  const [estadoUsoOe, setEstadoUsoOe] = useState<EstadoUsoOe>(
    EstadoUsoOe.NAO_UTILIZADO
  );
  const [estaAoVivo, setEstaAovivo] = useState<boolean>(
    props.estaAoVivo || false
  );

  const { formatTitle } = useUtils();

  useEffect(() => {
    if (props.estaAoVivo) {
      setEstaAovivo(props.estaAoVivo);
    }
  }, [props.estaAoVivo]);

  const itemVideo = props.item;

  useEffect(() => {
    const user = getUserContext();
    if (!!user) {
      if (
        props.item.id &&
        ((props.item.VideoAudio &&
          props.item.VideoAudio.length &&
          user?.attributes?.OesConsumidosParcialmente?.data.some(
            (item) =>
              itemVideo?.VideoId?.toString() ===
                item?.attributes?.VideoAudioId?.toString() &&
              item.attributes?.duracao &&
              item.attributes?.segundos &&
              item.attributes?.duracao === item.attributes?.segundos
          )) ||
          (props.item.SecoesTexto &&
            props.item.SecoesTexto.length &&
            props.item.SecoesTexto.some((leitura) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (item) =>
                  leitura?.id.toString() ===
                    item.attributes?.LeituraOeId?.toString() &&
                  item.attributes?.duracao &&
                  item.attributes?.segundos &&
                  item.attributes?.duracao === item.attributes?.segundos
              )
            )) ||
          (props.item.Infografico &&
            props.item.Infografico.length &&
            props.item.Infografico.some((imagem) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (item) =>
                  imagem?.id.toString() ===
                    item.attributes?.ImagemOeId?.toString() &&
                  item.attributes?.duracao &&
                  item.attributes?.segundos &&
                  item.attributes?.duracao === item.attributes?.segundos
              )
            )))
      ) {
        setEstadoUsoOe(EstadoUsoOe.FINALIZADO);
        return;
      }

      if (
        props.item.id &&
        props.item.VideoAudio &&
        props.item.VideoAudio.length &&
        user?.attributes?.OesConsumidosParcialmente?.data.some(
          (item) =>
            itemVideo?.VideoId?.toString() ===
              item?.attributes?.VideoAudioId?.toString() &&
            item.attributes?.duracao &&
            item.attributes?.segundos &&
            item.attributes?.duracao !== item.attributes?.segundos
        )
      ) {
        setEstadoUsoOe(EstadoUsoOe.VISUALIZADO);
        return;
      }
    }
  }, [props.item]);

  return (
    props.item && (
      <footer>
        <div
          className={`rounded-b-2xl w-full left-0 bottom-0 absolute h-11 shadow-sm
          ${
            props.quiz ? "border-slate-300 border-t px-8" : "pb-4 px-[1.405rem]"
          }
           flex items-center justify-between`}
          // data-oe-footer={props.color}
          style={{
            background: !props.gradient
              ? "none"
              : `linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) `,
          }}
        >
          <div className="flex items-center gap-2">
            <SmartIcon
              item={{
                tipoObjeto: props.item.TipoObjeto[0]?.titulo!,
              }}
              color={props.color}
            />
            {!!props.item.TipoObjeto?.find((p) => p.titulo !== "Live") && (
              <>
                <span
                  className={`h-[13px] w-[2px] ${
                    props.color === "blue" ? "bg-[#0A589B]" : "bg-white"
                  }
                  text-center`}
                />
                <TimeView
                  time={props.item.TempoMedioDuracao}
                  color={props.color}
                />
              </>
            )}

            {/* {(props.item.PossuiCertificado ||
              estadoUsoOe !== EstadoUsoOe.NAO_UTILIZADO) && (
              <span
                className={`h-[13px] w-[2px] ${
                  props.color === "blue" ? "bg-[#0A589B]" : "bg-white"
                }
               ml-2 mr-3 text-center`}
              ></span>
            )} */}

            <CertificadoCard
              possuiCertificado={props.item.PossuiCertificado}
              color={props.color}
            />

            {/* {(estadoUsoOe !== EstadoUsoOe.NAO_UTILIZADO && !props.estaAoVivo) && (
              <>
                <div data-visualized data-color={props.color} className="font-sans font-normal text-xs">
                  {estadoUsoOe === EstadoUsoOe.VISUALIZADO && "Visualizado"}
                  {estadoUsoOe === EstadoUsoOe.FINALIZADO && "Finalizado"}
                </div>
              </>
            )} */}
          </div>

          <div className="flex w-auto items-end gap-2">
            {!estaAoVivo && (
              <Favoritar
                color={props.favoriteColor || props.color}
                idOe={props.item.id}
                item={{
                  id: props.item.id!,
                  titulo: formatTitle(props.item.titulo!),
                }}
              ></Favoritar>
            )}

            {props.shareUrl && (
              <div data-clickable>
                <Compartilhar
                  {...props}
                  item={{
                    descricao: props.item.Descricao!,
                    titulo: formatTitle(props.item.titulo!),
                    url: props.item.imagemPreview,
                    id: props.item.id,
                  }}
                  color="white"
                />
              </div>
            )}

            {!estaAoVivo &&
              !!props.item.TipoObjeto.find((p) => p.titulo === "Live") && (
                <Lembrar
                  color={props.favoriteColor || props.color}
                  idOe={props.item.id}
                  item={{
                    id: props.item.id!,
                    titulo: formatTitle(props.item.titulo!),
                  }}
                />
              )}
          </div>

          {/* <div className="flex justify-between bg-red-500 h-auto w-[100px]">
            <SmartIcon
              item={{
                tipoObjeto: props.item.TipoObjeto[0]?.titulo!,
              }}
              color={props.color}
            />
            {!!props.item.TipoObjeto?.find((p) => p.titulo !== "Live") && (
              <>
                <span data-metadata-oe-separator={props.color}></span>
                <TimeView
                  time={props.item.TempoMedioDuracao}
                  color={props.color}
                />
              </>
            )}

            {(props.item.PossuiCertificado ||
              estadoUsoOe !== EstadoUsoOe.NAO_UTILIZADO) && (
              <span data-metadata-oe-separator={props.color}></span>
            )}

            <CertificadoCard
              possuiCertificado={props.item.PossuiCertificado}
              color={props.color}
            />

            {estadoUsoOe !== EstadoUsoOe.NAO_UTILIZADO && (
              <>
                <div data-visualized data-color={props.color}>
                  {estadoUsoOe === EstadoUsoOe.VISUALIZADO && "Visualizado"}
                  {estadoUsoOe === EstadoUsoOe.FINALIZADO && "Finalizado"}
                </div>
              </>
            )}
          </div>
          <div data-icons-shared-remember>
            {!estaAoVivo && (
              <Favoritar
                color={props.favoriteColor || props.color}
                idOe={props.item.id}
                item={{
                  id: props.item.id!,
                  titulo: props.item.titulo!,
                }}
              ></Favoritar>
            )}
            <div data-clickable>
              {props.shareUrl && (
                <Compartilhar
                  {...props}
                  item={{
                    descricao: props.item.Descricao!,
                    titulo: props.item.titulo!,
                    url: props.item.imagemPreview,
                    id: props.item.id,
                  }}
                  color="white"
                />
              )}
            </div>
            {!estaAoVivo &&
              !!props.item.TipoObjeto.find((p) => p.titulo === "Live") && (
                <Lembrar
                  color={props.favoriteColor || props.color}
                  idOe={props.item.id}
                  item={{
                    id: props.item.id!,
                    titulo: props.item.titulo!,
                  }}
                />
              )}
          </div> */}
        </div>
      </footer>
    )
  );
};

export default OeMediaFooterComponent;
