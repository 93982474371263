import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ComponentObjetoEducacionalInfogaficoInfografico,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../../generated/graphql";

import {
  Favoritar,
  OEBackButton,
  ZoomControls,
} from "../../../../../shared/components";
import { useModalConclusao } from "../../../components";

import { HEADER_ACTION_TYPE } from "../../../../../shared/constants/action-types";

import { getUserContext, UserContext } from "../../../../../context/UserContext";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../hooks/useGtm";
import useOeConsume from "../../../../../hooks/useOeConsume";

import "./styles.scss";
import { useUtils } from "../../../../../shared/utils";
import { Button } from "../../../../../shared/components/ui/button";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface OeInfograficoTextoProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}

const OeInfograficoTexto: React.FC<OeInfograficoTextoProps> = (props) => {
  const dispatch = useDispatch();
  const consume = useOeConsume();
  const [current, setCurrent] = useState(1);
  const userContext = getUserContext();
  const { mostrar: mostrarModalConclusao } = useModalConclusao({
    oe: props.oe,
  });
  const gtm = useGtm();
  const [isOeConcluida, setOeConcluida] = useState(false);
  const { formatTitle } = useUtils();
  const { user } = useContext(UserContext);
  const handleTimeUpdateRef = useRef<any>(null);


  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === props.oe.id) !== undefined
        );

      if ((oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration) {
        return;
      }

      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: props.oe.id,
        user: user?.id,
      });
    });

    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };

  }, [user])


  const handleScroll = () => {
    const scrollTop = window.scrollY; // Posição atual do scroll
    const windowHeight = window.innerHeight; // Altura visível da janela
    const fullHeight = document.documentElement.scrollHeight - 2500;

    const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === props.oe.id) !== undefined
        );

      if ((oeAssistido?.attributes?.segundos || 0) > (scrollTop + windowHeight)) {
        return;
      }

    handleTimeUpdateRef.current({
      partialDuration: parseInt((scrollTop + windowHeight).toFixed(0)),
      totalDuration: parseInt(fullHeight.toFixed(0)),
    })
    // Verifica se o scroll chegou ao final da página
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!userContext || !props.oe) {
      return;
    }

    const oeAssitido = (
      userContext.attributes?.OesConsumidosParcialmente?.data || []
    ).find(
      (item) =>
        !!item &&
        !!item.attributes?.ImagemOeId &&
        !!props.oe.attributes?.Infografico &&
        !!props.oe.attributes?.Infografico.length &&
        item.attributes?.ImagemOeId.toString() ===
        props.oe.attributes?.Infografico[0].id,
    );

    if (!!oeAssitido) {
      setOeConcluida(true);
    }
  }, [userContext, props.oe]);

  const handleZoomChange = (count: number) => {
    setCurrent(count);

    // GTM doc linha 91
    gtm.push(
      EDataLayerEventCategory.ADE_OE_PAGINA,
      EDataLayerEventAction.CLIQUE,
      {
        label: `ver_infografico-zoom`,
        oe: formatTitle(props.oe.attributes?.Titulo!),
      },
    );
  };

  const handleFinish = async (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    info: ComponentObjetoEducacionalInfogaficoInfografico,
  ) => {
    ev.stopPropagation();

    if (!info) {
      return;
    }

    if (!userContext) {
      dispatch({
        type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
        payload: true,
      });
      return;
    }

    const result = await consume.saveOeConsume({
      infograficoId: parseInt(info.id),
      partialDuration: 1,
      totalDuration: 1,
      oe: props.oe.id,
      user: userContext.id,
    });

    if (!!result) {
      mostrarModalConclusao();
    }
  };

  return (
    <div data-infografico-oe-content>
      <div data-tools="navigation">
        <OEBackButton />
      </div>
      <div
        data-text-container={
          props.oe.attributes?.TipoObjeto?.data.find(
            (item) => item.attributes?.titulo?.toLocaleLowerCase() === "imagem",
          )
            ? "infografico"
            : ""
        }
      >
        <h1>{formatTitle(props.oe.attributes?.Titulo!)}</h1>
        {props.oe.attributes?.Infografico?.map((info) => (
          <div key={info.id} className={`zoom zoom-${current}x`}>
            <h2>{formatTitle(info.Titulo!)}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: props.oe.attributes?.Descricao as string,
              }}
            />
            {props.oe.attributes?.SecoesTexto!.map((text, index) => (
              <div key={text?.id}>
                <h2>{criarTituloSecaoTexto(index)}</h2>
                <div dangerouslySetInnerHTML={{ __html: text?.Secao }} />
              </div>
            ))}

            <div data-infografico-image>
              {info.Imagem?.data?.attributes?.url && (
                <img
                  src={info.Imagem.data.attributes?.url}
                  alt={formatTitle(info.Titulo!)}
                  title={formatTitle(info.Titulo!)}
                />
              )}
            </div>

            {props.oe.attributes?.PossuiCertificado && !isOeConcluida && (
              <div data-button-finish>
                <Button
                  data-testid="btn-concluir-oe-infografico"
                  type="button"
                  className="button button-primary w-full"
                  onClick={(ev) => handleFinish(ev, info)}
                  variant="primary"
                >
                  Concluir Conteúdo
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      <div data-content-interactions>
        <Favoritar
          idOe={props.oe.id}
          color="green"
          item={{
            id: props.oe.id!,
            titulo: props.oe.attributes?.Titulo!,
          }}
        />
        <ZoomControls
          current={current}
          max={4}
          handleChange={handleZoomChange}
        />
      </div>
    </div>
  );
};

export default OeInfograficoTexto;

export const criarTituloSecaoTexto = (index: number) =>
  `Seção ${index + 1 <= 9 ? "0" + (index + 1) : index + 1}`;
