import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../../generated/graphql";

import { ModalService } from "../../../../shared/components/Modal";

import CertificadoPageV2 from "../../../CertificadoPageV2";

import "./style.scss";
import { HEADER_ACTION_TYPE } from "../../../../shared/constants/action-types";
import { useUtils } from "../../../../shared/utils";
import * as Icon from "../../../../assets/svgs";
import { useModalNaoConcluido } from "../ModalNaoConcluido";
import { useModalNaoConcluidoPlaylist } from "../ModalNaoConcluidoPlaylist";

interface AcessoDeclaracaoProps {
  oe: ObjetoEducacionalEntity;
  user: UsersPermissionsUserEntity | undefined;
  playlist?: {
    certificadoPlaylist?: boolean;
    playlistVisualizada?: boolean;
    tituloPlaylist?: string;
    idPlaylist?: string;
    duracaoPlaylist: string;
  };
}

interface OE {
  id: string;
}

export const AcessoDeclaracao: React.FC<AcessoDeclaracaoProps> = ({
  oe,
  user,
  playlist,
}) => {
  const dispatch = useDispatch();

  const [concluido, setConcluido] = useState(false);
  const [estahLogado, setEstahLogado] = useState(false);
  const { formatTitle } = useUtils();

  const { mostrar } = useModalNaoConcluido();
  const modalPlaylistNaoConcluido = useModalNaoConcluidoPlaylist();

  function hasUser() {
    setEstahLogado(!!user);
    return !!user && oe && oe.id;
  }

  useEffect(() => {
    if (playlist?.certificadoPlaylist && playlist?.playlistVisualizada) {
      setConcluido(true);
    }
  }, [playlist?.playlistVisualizada]);

  useEffect(() => {
    if (hasUser() && !playlist?.certificadoPlaylist) {
      if (!!oe.attributes?.VideoAudio && oe.attributes.VideoAudio.length > 1) {
        let totalWatched = 0;
        let watchedAllVideo = true;
        let finishAllVideo = true;

        let oesConsumidas = user?.attributes?.OesConsumidosParcialmente;

        for (let o of oe.attributes?.VideoAudio) {
          watchedAllVideo =
            watchedAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data.findIndex(
              (e) => e.attributes?.VideoAudioId?.toString() == o.id,
            ) > -1;
          finishAllVideo =
            finishAllVideo &&
            !!oesConsumidas &&
            oesConsumidas.data
              .filter((a) => a.attributes?.VideoAudioId?.toString() == o.id)
              .reduce((ant, prox) => {
                let a = ant || 0;
                a = a + prox.attributes?.segundos!;
                return a;
              }, 0) >=
              oesConsumidas.data.find(
                (a) => a.attributes?.VideoAudioId?.toString() == o.id,
              )?.attributes?.duracao!;
        }

        oesConsumidas &&
          oesConsumidas.data?.map((p) => {
            const hasVideoIdOe =
              oe.attributes?.VideoAudio &&
              oe.attributes?.VideoAudio.filter(
                ({ id: idOE }: OE) =>
                  parseInt(idOE) === p.attributes?.VideoAudioId,
              );

            if (
              hasVideoIdOe &&
              hasVideoIdOe.length > 0 &&
              !!p.attributes?.segundos
            ) {
              totalWatched += p.attributes?.segundos / 60;
            }

            return totalWatched;
          });

        setEstahLogado(true);

        if (finishAllVideo && watchedAllVideo) {
          setConcluido(true);
          return;
        }
      } else {
        const isOeConcluidaPorUsuario =
          user?.attributes?.OesConsumidosParcialmente?.data.filter(
            (item) =>
              item.attributes?.Oes_Assistidos &&
              item.attributes.Oes_Assistidos.data[0]?.id === oe.id &&
              item.attributes.duracao &&
              item.attributes.segundos &&
              item.attributes.duracao === item.attributes.segundos,
          ).length;

        setEstahLogado(true);

        if (!!isOeConcluidaPorUsuario) {
          setConcluido(true);
          return;
        }
      }
    }
  }, [oe, user]);

  const possuiCertificado =
    oe.attributes?.PossuiCertificado || !!playlist?.certificadoPlaylist;

  return (
    <>
      {possuiCertificado && (
        <>
          {concluido && (
            <div>
              <div
                className="flex cursor-pointer flex-col items-center justify-between md:flex-row"
                data-concluido
                onClick={(ev) => {
                  ev.stopPropagation();
                  mostrarModalCertificado(
                    formatTitle(
                      playlist?.tituloPlaylist
                        ? playlist?.tituloPlaylist
                        : oe.attributes?.Titulo!,
                    ),
                    oe?.attributes?.TempoMedioDuracao!,
                    playlist,
                  );
                }}
              >
                <Icon.Certificate />
                <span className="text-[10px] font-normal leading-normal text-[#111316]">
                  Declaração
                </span>
              </div>
            </div>
          )}

          {!concluido && (
            <div>
              <div
                className="flex cursor-pointer flex-col items-center  justify-between md:flex-row"
                onClick={() => {
                  ModalService.hide();
                  if (estahLogado) {
                    playlist?.certificadoPlaylist
                      ? modalPlaylistNaoConcluido.mostrar()
                      : !playlist?.certificadoPlaylist && mostrar();
                  } else {
                    dispatch({
                      type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
                      payload: true,
                    });
                  }
                }}
              >
                <Icon.CertificateInactive />
                <span className="text-[10px] font-normal leading-normal text-gray-400">
                  Declaração
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const mostrarModalCertificado = (
  titulo: string,
  tempoMedioDuracao: string,
  playlist?: {
    certificadoPlaylist?: boolean;
    playlistVisualizada?: boolean;
    tituloPlaylist?: string;
    idPlaylist?: string;
    duracaoPlaylist: string;
  },
) => {
  ModalService.hide();
  ModalService.show({
    content: (
      <CertificadoPageV2
        titulo={
          playlist?.certificadoPlaylist
            ? (playlist?.tituloPlaylist ?? "")
            : titulo
        }
        tempoMedioDuracao={
          playlist?.certificadoPlaylist
            ? playlist?.duracaoPlaylist?.toString()
            : tempoMedioDuracao
        }
        playlist={playlist}
      />
    ),
    showComponentInFullScreen: true,
    showCloseButton: true,
    closeOutsideModal: true,
  });
};
