import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import ProviderUserContext from "./context/UserContext";
import "./styles/global.css";
// import "./index.scss";
import React from "react";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container!);

root.render(
  <>
    <ProviderUserContext>
      <>
        <App />
      </>
    </ProviderUserContext>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
