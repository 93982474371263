import { useHistory } from "react-router";
import Container from "../../../shared/components/Container";
import { Button } from "../../../shared/components/ui/button";
import React from "react";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import { mostrarModalFlowLogin } from "../../../shared/modals/ModalFlowCadastro";

export default function FacaParte() {
  const history = useHistory();
  const gtm = useGtm();

  const redirectTo = (url: string) => {
    history.push(url);
  };

  return (
    <Container className="flex flex-col justify-center gap-6 py-6 text-center md:max-w-[43.56rem] md:gap-6 md:py-20 tablet:max-w-[32.32rem] tablet:gap-9 tablet:py-10">
      <h2 className="text-xl font-normal normal-case leading-7 text-gray-900 md:text-2.5xl md:leading-8 tablet:text-2xl tablet:leading-7">
        Faça parte da maior rede <br />
        <span className="gradiente-einstein font-semibold">gratuita</span> de
        conhecimento
      </h2>
      <div>
        <Button
          data-testid="btn-crie-sua-conta-gratis"
          variant="primary"
          className="md:h-[58px]"
          size="default"
          onClick={() => {
            mostrarModalFlowLogin();

            gtm.push(
              EDataLayerEventCategory.ADE_CADASTRO,
              EDataLayerEventAction.CLIQUE,
              {
                label: "cadastro-fim",
              },
            );
          }}
        >
          Crie sua conta grátis
        </Button>
      </div>
    </Container>
  );
}
