import { useContext, useEffect, useState } from "react";
import Container from "../../../shared/components/Container";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { ObjetoEducacional } from "../../../models/ObjetoEducacional";
import CardObjetoEducacional from "../../../shared/components/oe-card";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { UserContext } from "../../../context/UserContext";
import React from "react";
import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../hooks/useBannerApi";
import { Banner } from "../../../shared/components/banner-home";
import { BannerVertical } from "../../../shared/components/banner-home-vertical";
import {
  Banner as BannerType,
  loadBanner,
} from "../../../graphql/collections/queries/banner.query";
import { isDesktop, isMobile, isTablet } from "react-device-detect";

async function getIndicacoesConteudos() {
  const response = await fetchApi(
    "objeto-educacional/get-objeto-educacional-card-voce-pode-gostar",
    "GET",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function IndicacoesConteudos() {
  const [banners, setBanners] = useState<BannerType[]>([]);
  const { user } = useContext(UserContext);

  const [indicacoesConteudos, setIndicacoesConteudos] = useState<
    ObjetoEducacional[]
  >([] as ObjetoEducacional[]);

  const loadIndicacoes = async () => {
    setIndicacoesConteudos(await getIndicacoesConteudos());
  };

  useEffect(() => {
    loadIndicacoes();
  }, []);

  const carregarBanners = async () => {
    try {
      const bannersResult = await loadBanner({
        homePage: true,
      });
      setBanners(bannersResult);
    } catch (err) {
      setBanners([]);
    }
  };

  useEffect(() => {
    carregarBanners();
  }, []);

  return (
    <Container className="flex flex-col gap-6 py-9 md:py-10">
      <div className="flex max-w-[55rem] flex-col justify-center gap-6 md:self-center">
        <div className="flex gap-6">
          <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
            Você também pode gostar
          </h2>
        </div>
        <div className="flex flex-col justify-center gap-6 md:flex-row md:self-center">
          {indicacoesConteudos ? (
            <div className="hidden gap-6 md:grid md:grid-cols-2 md:grid-rows-2">
              {indicacoesConteudos?.map(
                (_: ObjetoEducacional, index: number) => (
                  <div
                    key={index}
                    className="overflow-hidden rounded-xl md:basis-1/2 lg:basis-1/3"
                  >
                    <CardObjetoEducacional
                      objetoEducacional={_}
                      classNameItems={{ rotulos: "max-w-[8.625rem]" }}
                    />
                  </div>
                ),
              )}
            </div>
          ) : (
            <div className="hidden gap-6 md:grid md:grid-cols-2 md:grid-rows-2">
              <div className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
              </div>{" "}
              <div className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
              </div>
              <div className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
              </div>
              <div className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
              </div>
            </div>
          )}

          <Carousel className="mb-4 block md:hidden">
            <CarouselContent className="">
              {indicacoesConteudos?.map(
                (_: ObjetoEducacional, index: number) => (
                  <CarouselItem
                    key={index}
                    className="md:basis-1/2 lg:basis-1/3"
                  >
                    <CardObjetoEducacional objetoEducacional={_} />
                  </CarouselItem>
                ),
              )}
            </CarouselContent>
            <CarouselBullets className="absolute left-0 right-0 mx-auto " />
          </Carousel>

          {(function () {
            const bannerVertical = banners.filter(
              (a) =>
                a.areaPagina === TIPO_AREA_PAGINA.VERTICAL &&
                a.tipoPagina === TIPO_PAGINA.HOME &&
                ((isMobile && a.field === "ImagemMobile") ||
                  (isDesktop && a.field === "ImagemDesktop") ||
                  (isTablet && a.field === "ImagemTablet")) &&
                ((!!user?.id && a.ocultarLogado === false) || !user?.id),
            );

            if (bannerVertical && bannerVertical.length > 0) {
              return (
                <Container className="md:w-[170px] tablet:w-[170px]">
                  <BannerVertical banners={bannerVertical} />
                </Container>
              );
            } else {
              return <></>;
            }
          })()}
        </div>
      </div>
    </Container>
  );
}
