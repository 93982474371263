import { useHistory } from "react-router";
import { ModalService } from "../shared/components/Modal";
import { ModalPlaylistOnBoard } from "../shared/components/ModalPlaylistOnBoard";

export const useModalPlaylistOnBoard = () => {
  const history = useHistory();
  const mostrar = () => {
    ModalService.show({
      content: <ModalPlaylistOnBoard />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
      onClose: () => {
        localStorage.setItem("onBoardPlaylists", "true");
        ModalService.hide();
      },
    });
  };

  return { mostrar };
};
