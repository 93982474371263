import React, { useEffect, useState } from "react";

import Puff from "../../../assets/svgs/svg-loaders/puff.svg";
import LogoMobile from "../../../assets/svgs/logo-mobile.svg";

import "./styles.scss";

export interface LoaderProps {
  show: boolean;
  message?: string;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  const { show, message } = props;
  const [state, setState] = useState({
    height: "100%",
    width: "100%",
  });

  document
    .querySelector<HTMLElement>("[data-loader]")
    ?.addEventListener("animationend", (el) => {
      (el.target as HTMLElement).style.display = "none";
    });

  return (
    <>
      {show && (
        <div
          data-loader
          data-show={show}
          style={{
            height: state.height,
            width: state.width,
          }}
        >
          <img src={LogoMobile} alt="Logo" />
          <p>{message}</p>
          <img src={Puff} alt="Logo" />
        </div>
      )}
    </>
  );
};
