import React, { useState, useEffect } from "react";

import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import { ObjetoEducacionalVideo } from "../../../pages/OEPage/OeVideoPage";

import "./styles.scss";

interface SmartLinkProps {
  oe: ObjetoEducacionalEntity;
  children?: any;
  onShowCertificate?: () => void;
  isListCertificados?: boolean;
}

export const SmartLink: React.FC<SmartLinkProps> = (props) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (props.oe.attributes && props.oe.attributes?.TipoObjeto?.data) {
      const oeType =
        (props.oe.attributes?.TipoObjeto &&
          props.oe.attributes?.TipoObjeto?.data[0]?.attributes?.titulo) ||
        "";
      let _url = formatarUrl(oeType, props.oe.id!);

      if (!!(props.oe as ObjetoEducacionalVideo).VideoId) {
        _url = formatarUrl(
          oeType,
          props.oe.id!,
          (props.oe as ObjetoEducacionalVideo).VideoId,
        );
      }
      setUrl(_url);
    }
  }, [props.oe]);

  const residenciaMedica = props?.oe?.attributes?.Status === "ResidenciaMedica";

  const formatarUrl = (oeType: string, oeId: string, videoId?: string) => {
    if (props.isListCertificados) {
      return "";
    }

    if (residenciaMedica) {
      return `/prepara-einstein`;
    }

    switch (oeType) {
      case "Áudio":
        return `/oe/${oeId}/podcast/`;
      case "Imagem":
        return `/oe/${oeId}/imagem/preview`;
      case "Questionário":
      case "Quizz":
        return `/oe/${oeId}/quiz`;
      case "Simulação":
        return `/oe/${oeId}/simulacao/`;
      case "Leitura":
      case "Texto":
        return `/oe/${oeId}/leitura`;
      case "Live":
        return `/oe/${oeId}/live${!!videoId ? `/${videoId}` : ""}`;
      case "Vídeo":
        return `/oe/${oeId}/video${!!videoId ? `/${videoId}` : ""}`;
      case "Webcast":
        return `/oe/${oeId}/webcast${!!videoId ? `/${videoId}` : ""}`;
      case "PDF":
        return `/oe/${oeId}/pdf${!!videoId ? `/${videoId}` : ""}`;
      default:
        return "";
    }
  };

  return props.isListCertificados ? (
    <span
      style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
      onClick={() => {
        if (props.isListCertificados) {
          props.onShowCertificate?.();
        }
      }}
    >
      {props.children}
    </span>
  ) : (
    <a href={url} style={{ textDecoration: "none", color: "inherit" }}>
      {props.children}
    </a>
  );
};
