import React from "react";
import { ModalService } from "../../../../shared/components/Modal";
import { mostrarModalLogin } from "../../../../shared/modals/ModalLogin";

import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";
import ExclusivoMedicina from "../../../../assets/images/exclusivo-medicina.png";

export const ModalBloqueiaUsuarioNaoLogado: React.FC = () => {
  const handleVoltar = () => {
    ModalService.hide();
    window.history.back();
  };

  const handleLogar = () => mostrarModalLogin();

  return (
    <div
      data-modal-bloqueia-perfil-nao-medico
      className="items-center justify-center"
    >
      <img
        src={ExclusivoMedicina}
        alt="Imagem de conteúdo exclusivo para médicos
      "
        className="mb-6 mt-10 h-[103.42px] w-[120px] md:h-[137.89px] md:w-[160px]"
      />
      <h3 className="mb-3 text-lg font-bold leading-22 text-[#004F92] md:text-base">
        Conteúdo exclusivo para médicos
      </h3>
      <p className="!mx-8 mb-6 w-[250px] text-base leading-22 text-[#313335] md:w-[350px] md:text-sm md:leading-5">
        Se Você é médico, faça o login para acessar.
      </p>
      <div
        data-modal-bloqueia-perfil-nao-medico-footer
        className="mb-16 md:mb-14"
      >
        <Button
          data-testid="btn-voltar-modal-bloqueio"
          type="button"
          variant="primary"
          onClick={handleVoltar}
        >
          Voltar
        </Button>
        <Button
          type="button"
          data-testid="btn-fazer-login-bloqueia-usuario"
          variant="primary"
          onClick={handleLogar}
        >
          Fazer Login
        </Button>
      </div>
      <a
        href="/novo-usuario-v2/dados-pessoais"
        data-testid="btn-ainda-nao-e-cadastrado"
      >
        {" "}
        Ainda não é cadastrado? Cadastre-se gratuitamente
      </a>
    </div>
  );
};
