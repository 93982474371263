import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import {
  Grid,
  MediaCard,
  HeaderSection,
  Pagination,
  Loader,
  Breadcrumb,
  BreadcrumbItem,
} from "../../shared/components";

import {
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  Rotulo,
  RotuloEntity,
  TipoOe,
  TipoOeEntity,
  useGetOesFavoritadasByUserLazyQuery,
} from "../../generated/graphql";

import { getUserContext } from "../../context/UserContext";

import "./styles.scss";
import { useUtils } from "../../shared/utils";

type OEFavoritosState = {
  lista: ObjetoEducacionalEntity[];
  pagina: number;
  loading: boolean;
  error: string | null | undefined;
};

const tamanhoPagina: number = 16;

const OEFavoritosPage: React.FC = () => {
  const user = getUserContext();

  const [state, setState] = useState<OEFavoritosState>({
    lista: [],
    pagina: 1,
    loading: true,
    error: null,
  });

  const [GetOesFavoritados] = useGetOesFavoritadasByUserLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setState({
        ...state,
        loading: false,
        lista: response.findMe?.attributes?.OesFavoritados
          ?.data as ObjetoEducacionalEntity[],
      });
    },
    onError: (err) => {
      setState({ ...state, error: err.message.toString() });
    },
  });

  const handlePageChange = async (event: any, page: number) => {
    event.preventDefault();
    setState({ ...state, pagina: page });
  };

  useEffect(() => {
    GetOesFavoritados();
  }, [GetOesFavoritados]);

  const { pagina: page, lista: result, loading } = state;
  const { formatTitle } = useUtils();

  const bulletsCount = result
    ? Math.abs(Math.ceil(result.length / tamanhoPagina))
    : 0;

  // User must be logged in, otherwise redirect to Home
  if (!user) {
    return <Redirect to="/" />;
  }

  return (
    <div className="oe-search-page">
      <Loader
        show={loading}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <HeaderSection>
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          <BreadcrumbItem to="/meu-perfil">Meu Perfil</BreadcrumbItem>
          <BreadcrumbItem active>Favoritos</BreadcrumbItem>
        </Breadcrumb>
        <h3 className="text-base md:text-xxl font-semibold leading-none text-white">
          Favoritos
        </h3>
      </HeaderSection>

      {(!result.length && !loading && (
        <div className="oe-search-error">
          <p>Nenhum resultado encontrado para o seu critério de pesquisa.</p>
        </div>
      )) || (
        <section className="oe-search-result">
          <Pagination
            count={bulletsCount}
            page={page}
            onChange={handlePageChange}
            disabled={loading}
          />
          <Grid cols={3} gap={4}>
            {result
              .slice(
                (page - 1) * tamanhoPagina,
                (page - 1) * tamanhoPagina + tamanhoPagina
              )
              .map((oe: ObjetoEducacionalEntity, index: number) => (
                <div key={oe.id}>
                  <MediaCard
                    id={oe.id!}
                    title={formatTitle(oe.attributes?.Titulo as string)}
                    image={oe.attributes?.ImagemPreview?.data?.attributes?.url}
                    badgeRotulos={
                      oe.attributes?.Rotulos?.data as RotuloEntity[]
                    }
                    // TODO: Adicionar para o usuario a OesVisualizadas
                    // badgeSeen={!!this.user && !!oe.OesAssistidos?.some(item => item.id === this.user.oes)}
                    media={{
                      type: oe.attributes?.TipoObjeto?.data as TipoOeEntity[],
                      duration: oe.attributes?.TempoMedioDuracao,
                    }}
                    oe={oe}
                  />
                </div>
              ))}
          </Grid>
          <Pagination
            count={bulletsCount}
            page={page}
            onChange={handlePageChange}
            disabled={loading}
          />
        </section>
      )}
    </div>
  );
};

export default OEFavoritosPage;
