import React from 'react';
import { If } from '../../../../shared/components';
import * as Icon from "../../../../assets/svgs";
import { RespostaModel } from '../../../../models/RespostaModel';

export interface ItemRespostaQuizProps {
  className: string;
  resposta: RespostaModel;
  alphabetIndex: string;
  showingQuestionFeedback: boolean;
  respostaAtiva: any;
  respostaCorreta: any;
  activeQuizIndex: number;
  activeQuestionIndex: number;
  onChange: () => void;
}

export const ItemRespostaQuiz: React.FC<ItemRespostaQuizProps> = ({
  className,
  resposta,
  alphabetIndex,
  showingQuestionFeedback,
  respostaAtiva,
  respostaCorreta,
  activeQuizIndex,
  activeQuestionIndex,
  onChange,
}) => {
  return (
    <label className={className}>
      <div
        className="flex items-center"
        style={{
          width: showingQuestionFeedback
            ? "100%"
            : "100%",
        }}
      >
        <input
          type="radio"
          onChange={() => onChange()}
          name={`_${activeQuizIndex}_${activeQuestionIndex}`}
          value={btoa(
            encodeURIComponent(
              unescape(JSON.stringify(resposta.id.toString()))
            )
          )}
          style={{
            display: showingQuestionFeedback
              ? "none"
              : "flex",
            all: "unset",
          }}
        />
        <If
          test={
            showingQuestionFeedback &&
            (btoa(
              unescape(
                encodeURIComponent(
                  JSON.stringify(resposta.id.toString())
                )
              )
            ) as any) ===
            (respostaCorreta as any)
          }
        >
          <div className="w-[28.667px] mr-2">
            <Icon.OpcaoCorreta />
          </div>
        </If>
        <If
          test={
            respostaAtiva ===
            btoa(
              encodeURIComponent(
                JSON.stringify(resposta.id.toString())
              )
            ) &&
            showingQuestionFeedback &&
            respostaAtiva !==
            (respostaCorreta as any) &&
            (btoa(
              unescape(
                encodeURIComponent(
                  JSON.stringify(resposta.id.toString())
                )
              )
            ) as any) !==
            (respostaCorreta as any)
          }
        >
          <div className="mr-2">
            <Icon.OpcaoIncorreta />
          </div>
        </If>
        <span data-line-height="1-2">
          <span className="font-bold leading-normal">
            {`${alphabetIndex} · `}
          </span>
          {resposta.opcao}
        </span>
      </div>
    </label>
  );
}
