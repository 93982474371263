import React from "react";
import Container from "../../../shared/components/Container";
import ThumbResidencia from "../../../assets/svgs/residencia/thumb-residencia.svg";
import { useState } from "react";
import { cn } from "../../../shared/utils/utils";
import Vimeo from "@u-wave/react-vimeo";
import { Button } from "../../../shared/components/ui/button";
import { useHistory } from "react-router";
import DialogPreview from "../../../shared/components/dialog-preview";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

export default function PreparaEinstein() {
  const [play, setPlay] = useState(false);
  const history = useHistory();
  const gtm = useGtm();

  const [selectedItem, setSelectedItem] = useState<string>();

  return (
    <Container className="flex items-center justify-center py-10 md:py-20">
      {selectedItem && (
        <DialogPreview
          className="!bg-transparent"
          setOpen={(open) => {
            if (!open) {
              setSelectedItem(undefined);
            }
          }}
        >
          <>
            <Vimeo
              video={selectedItem}
              autoplay={true}
              className="player-video-preview h-full w-full md:h-[600px] md:w-[1056px]"
            />
          </>
        </DialogPreview>
      )}
      <div className="flex flex-col md:flex-row tablet:flex-row ">
        <div className="flex justify-center">
          <div className="flex max-w-[363px] flex-col justify-center gap-6">
            <h4 className="gradiente-einstein text-center text-3.5xl font-bold leading-9 md:!text-left md:text-5.25xl md:leading-8 tablet:!text-left tablet:text-xxxl tablet:leading-22">
              Prepara Einstein
              <h4 className="text-25 font-medium leading-7 text-black-900 md:mt-3 md:text-xxxl md:leading-8 tablet:mt-2 tablet:text-xl tablet:leading-22">
                para Residência Médica
              </h4>
            </h4>
            <p className="text-center text-sm font-normal leading-18 text-[#626466] md:!text-left md:text-base md:leading-22 tablet:!text-left tablet:text-xs tablet:leading-4">
              Um ambiente completo, feito para levar <br /> a você um
              conhecimento atualizado e abrangente com especialistas do
              <br /> Hospital Israelita Albert Einstein.
            </p>
            <div className="hidden md:flex tablet:flex">
              <Button
                data-testid="btn-saiba-mais-prepara"
                variant="primary"
                size="default"
                className="md:h-[58px]"
                onClick={() => {
                  history.push("/prepara-einstein");
                  gtm.push(
                    EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_SAIBA_MAIS,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: "home-prepara-einstein-saibamais",
                      id: 61,
                    },
                  );
                }}
              >
                Saiba mais
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="mt-0 flex h-auto w-full flex-col items-start justify-center overflow-hidden rounded-[7.406px] md:mt-8 md:w-[551.47px] tablet:w-[404.68px]">
            <div className="flex h-full w-full md:w-[551.47px] tablet:w-[404.68px]">
              <img
                onClick={() =>
                  setSelectedItem("https://vimeo.com/916034818/baab1471b9")
                }
                data-testid="img-prepara"
                src={ThumbResidencia}
                alt="Prepara Residência Médica"
                className={cn(
                  "mt-6 w-[551.47px] cursor-pointer object-cover object-center md:mt-0 md:w-[551.47px] tablet:mt-0 tablet:w-[404.68px]",
                  "w-[551.47px] md:w-[551.47px] tablet:w-[404.68px]",
                )}
              />
            </div>
            <div className="mt-6 self-center md:hidden tablet:hidden">
              <Button
                data-testid="btn-saiba-mais-prepara-2"
                variant="primary"
                size="default"
                onClick={() => history.push("/prepara-einstein")}
              >
                Saiba mais
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
