import React, { ReactNode } from "react";

import "./index.scss";

interface BreadcrumbProps {
  children?: ReactNode;
  className?: string;
}

/**
 * Breadcrumb Component.
 *
 * @author Adriano Rosa <adriano.souza@mjv.com.br>
 *
 * @usage
 * <code>
 *   <Breadcrumb>
 *     <BreadcrumbItem to="/home">Home</BreadcrumbItem>
 *     <BreadcrumbItem to="/page-2">Page 2</BreadcrumbItem>
 *     <BreadcrumbItem active">Current Page</BreadcrumbItem>
 *   </Breadcrumb>
 * </code>
 * */
const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <nav className={props.className}>
      <ol data-breadcrumb className={props.className}>
        {" "}
        {props.children}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
