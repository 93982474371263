import React from "react";
import { useHistory } from "react-router-dom";
import { AreaConhecimento } from "../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";

interface KnowlegdeAreaProps {
  areas: AreaConhecimento[];
  columns?: number;
  idAutor: string;
}

export const AreasDeInteresseAutor: React.FC<KnowlegdeAreaProps> = (props) => {
  const history = useHistory();
  const gtm = useGtm();

  const handleNavigate = (item: any) => {
    history.push(`/autor/${props.idAutor}/detalhes/oe`, {
      idAreaInteresse: item.id,
      nameAreaInteresse: item.titulo,
    });
  };

  return (
    <div data-interest-areas-inner>
      {props.areas.map(
        (item: any, i: number) =>
          item.icone && (
            <div
              key={i}
              // to={`/area-de-interesse/${item.titulo.toString().toLowerCase().replace(/ /g,"-")}`}
              data-area-content
              onClick={() => {
                handleNavigate(item);
                // GTM doc linha 65
                gtm.push(
                  EDataLayerEventCategory.ADE_AREAS_INTERESSE,
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: item.titulo,
                  },
                );
              }}
            >
              <img
                height="75px"
                src={item.icone.url}
                alt={item.titulo}
                loading="lazy"
                style={{ height: "75px" }}
              />
              <span>{item.titulo}</span>
            </div>
          ),
      )}
    </div>
  );
};
