import React from "react";
import { IconNext, IconPrevious } from "../Icons";

export interface PaginationItemProps {
  type: string;
  page: number;
  onClick?: any;
  selected: boolean;
  disabled: boolean;
}

const PaginationItem = ({ type, page, ...item }: PaginationItemProps) => {
  return (
    <button data-testid={type} {...item}>
      {type === "previous" && <IconPrevious />}
      {type === "next" && <IconNext />}
      {type.indexOf("ellipsis") !== -1 && `...`}
      {type === "page" && page}
    </button>
  );
};

export default PaginationItem;
