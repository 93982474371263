import React, { useRef } from "react";
import { SwiperSlide } from "swiper/react";

import { CloseSharp } from "@material-ui/icons";
import { BannerEntity } from "../../../generated/graphql";
import { BannerComponentHOC } from "../Banner";
import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../hooks/useBannerApi";
import CustomSwiper from "../CustomSwiper";

export type BannerType = BannerEntity & {
  TipoPagina: TIPO_PAGINA;
  AreaPagina: TIPO_AREA_PAGINA;
};

interface BannerSwiperProps {
  items: BannerType[];
  onClose?: any;
  showBanner?: Boolean;
  fixo?: Boolean;
}

export const BannerSwiper: React.FC<BannerSwiperProps> = ({
  items,
  onClose,
  showBanner,
  fixo,
}) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const refPagination = useRef<HTMLDivElement>(null);

  return (
    <>
      {!fixo && (
        <span className="font-normal  text-xs text-center leading-normal inline-block">
          Patrocinado
        </span>
      )}
      {items.length > 0 && (
        <div
          className={` h-auto relative w-full ${
            items.length <= 1 && "swiper-button-hidden"
          }`}
        >
          {fixo && (
            <div
              className="absolute cursor-pointer right-0 top-0 z-50 bg-slate-200 w-4 h-4 flex items-center justify-center"
              onClick={() => onClose()}
            >
              <CloseSharp fontSize="small" className="w-3" />
            </div>
          )}
          <CustomSwiper
            className={
              fixo
                ? "h-auto overflow-hidden !rounded-none !pb-0"
                : "overflow-hidden rounded-2xl h-full w-full flex mt-1"
            }
            breakpoints={{
              640: {
                width: 640,
                slidesPerView: 1,
              },
              1246: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
            }}
            nextRef={nextRef}
            previousRef={prevRef}
            refPagination={refPagination}
            navigation={fixo ? false : true}
            pagination={fixo ? false : true}
            spaceBetween={20}
            slidesPerView={1}
            slidesPerGroup={1}
            autoplay={{
              delay: 15000,
            }}
            quantidade={items.length}
          >
            {items.map((item, idx) => (
              <SwiperSlide key={idx}>
                <BannerComponentHOC item={item} fixo={fixo} />
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </div>
      )}
    </>
  );
};
