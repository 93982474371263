import React from 'react';

export interface PercentualTesteConhecimentoProps {
    percentualUsuario: number;
    percentualOutros: number;
}

export const PercentualTesteConhecimento: React.FC<PercentualTesteConhecimentoProps> = ({
    percentualUsuario,
    percentualOutros,
}) => {
    return (
        <>
            {true && (
                <div className="border-[#9EA5B8] border-1 rounded-xl w-full flex h-auto !mt-5 justify-center py-10 ">
                    <div className="flex flex-col w-full justify-center max-w-[250px]">
                        <span className="text-sm leading-normal font-normal text-[#111316] ">
                            {`Seu percentual de acerto: `}
                            <span className="text-[#006DB4] font-bold">
                                {`${percentualUsuario}%`}
                            </span>
                        </span>
                        <span className="text-sm leading-normal font-normal text-[#111316] ">
                            {`Média geral: `}
                            <span className="text-[#00B193] font-bold">
                                {`${percentualOutros}%`}
                            </span>
                        </span>
                        <>
                            <div className="flex gap-3 items-center mt-2">
                                <div className="w-full bg-[#E1E3E6] rounded-full h-2.5 relative">
                                    <div
                                        className={`bg-[#004F92] left-0 h-2.5 rounded-full w-[${percentualUsuario
                                            }%] absolute ${percentualUsuario >
                                                percentualOutros
                                                ? "z-10"
                                                : "z-20"
                                            }`}
                                        style={{
                                            width: `${percentualUsuario}%`,
                                        }}
                                    />
                                    <div
                                        className={`bg-[#00B193] h-2.5 left-0 rounded-full w-[${percentualOutros
                                            }%] absolute ${percentualUsuario >
                                                percentualOutros
                                                ? "z-20"
                                                : "z-10"
                                            }`}
                                        style={{
                                            width: `${percentualOutros}%`,
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            )}
        </>
    );
}