import React from "react";
import { Close } from "@material-ui/icons";
import { MediaImagemVideoFeedBackQuiz } from "./MediaImagemVideoFeedBack";
import { QuizModel } from "../../../../models/QuizModel";

export interface ModalPreviewFeedbackMobileQuizProps {
  isMobile: boolean;
  nextRef: any;
  previousRef: any;
  quizAtivo: QuizModel;
  onClickClose: () => void;
  onClickShow: () => void;
}

export const ModalPreviewFeedbackMobileQuiz: React.FC<
  ModalPreviewFeedbackMobileQuizProps
> = ({
  isMobile,
  nextRef,
  previousRef,
  quizAtivo,
  onClickClose,
  onClickShow,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-[99998] h-screen w-full bg-black-900">
      <div className="flex h-full flex-col items-center justify-between">
        <div
          className="z-[99998] flex w-full items-end justify-end p-4 text-[#FFFF]"
          onClick={() => onClickClose()}
        >
          <Close />
        </div>
        <div className="max-h-[300px] px-1">
          <MediaImagemVideoFeedBackQuiz
            className="w-96"
            isMobile={isMobile}
            nextRef={nextRef}
            previousRef={previousRef}
            quizAtivo={quizAtivo}
            onClickMobile={() => onClickShow()}
          />
        </div>

        <div className="mb-14 ml-4 h-4 w-full"> </div>
      </div>
    </div>
  );
};
