import { combineReducers } from 'redux';
import headerReducer from '../redux/header/header.reducer';
import userReducer from '../redux/user/user.reducer';
import modalReducer from '../shared/components/Modal/model.reducer';
import curtirReducer from '../redux/curtir/curtir.reduce';

const rootReducer =  combineReducers({
    headerReducer,
    userReducer,
    modalReducer,
    curtirReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;