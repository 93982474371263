import { useHistory } from "react-router";
import { EDataLayerEventAction, useGtm } from "../../../hooks/useGtm";
import { useUtils } from "../../utils";
import { ResidenciaMedicaHomeModel } from "../../../models/ResidenciaMedicaHomeModel";

export type CardData = {
  id: number;
  icon: React.ReactNode;
  title: string;
  percentual: number;
  quantitative: string;
  hours: string;
  rowSpan2?: boolean;
  gradient: string;
};
export interface CardAreaResidenciaMedicaProps {
  index: number;
  item: ResidenciaMedicaHomeModel;
}
export const CardAreaResidenciaMedica: React.FC<
  CardAreaResidenciaMedicaProps
> = ({ index, item }) => {
  const history = useHistory();
  const gtm = useGtm();
  const { removeAcentosCaracteresInvalidosEspacos } = useUtils();

  const percentual = (
    (item?.quantidade === 0
      ? 0
      : item?.progresso! / item?.quantidade!) *
    100
  ).toFixed(0);

  return (
    <div
      key={index}
      style={{
        background: item?.backgroundCss,
      }}
      onClick={() => {
        gtm.push(
          `preparaeinstein_area_${removeAcentosCaracteresInvalidosEspacos(
            item?.titulo!
          )}` as any,
          EDataLayerEventAction.CLIQUE,
          {
            label: item?.titulo!,
          }
        );
        history.push(`/prepara-einstein/${Number(item?.id)}`);
      }}
      className={`h-[191px] justify-between flex !px-5 py-7.5 md:p-[30px] relative flex-col rounded-[9.958px] md:h-[164.31px] md:w-[267px] w-full cursor-pointer`}
    >
      <div
        className={`flex gap-3 h-auto  ${
          index === 0 ? "flex-row md:flex-col item-start " : "items-center"
        }`}
      >
        <img
          src={item?.iconeUrl}
          className="bg-cover  w-16 h-16"
          alt={item?.titulo}
        />

        <span className="text-lg font-semibold leading-6 text-white inline-block self-center">
          {item?.titulo}
        </span>
      </div>
      <div className="flex w-full flex-col">
        <div className="flex w-full justify-between text-xs font-medium leading-normal text-white">
          <span className="text-[#E0F3FD]">
            {item?.cargaHoraria &&
              item?.cargaHoraria + " horas"}
          </span>
          <span className="text-[#E0F3FD]">
            {item?.progresso}/{item?.quantidade}
          </span>
        </div>
        <div className="flex flex-row gap-4 items-center mt-[7px] justify-center">
          {
            <div className="relative bg-[#CED1D4] shadow-inner w-full h-2 rounded-2xl flex box-content">
              <div
                className={`absolute h-2 rounded-2xl flex left-0 bg-[#FFFFFF]  `}
                style={{
                  width: `${Number(percentual) > 100 ? 100 : percentual}%`,
                  boxShadow: "3px 4px 5px rgba(0, 0, 0, 0.10)",
                }}
              ></div>
            </div>
          }
          <span className="text-[9.958px] font-semibold leading-none text-white inline-block text-center">
            {Number(percentual) > 100 ? 100 : percentual}%
          </span>{" "}
        </div>
      </div>
    </div>
  );
};
