import React from "react";

export interface TituloQuizProps {
  quizAtivo?: any;
}

const TituloQuiz: React.FC<TituloQuizProps> = ({ quizAtivo }) => {
  return (
    <h2 className="mt-2 normal-case">
      <span className="text-base font-semibold leading-normal text-[#004F92] md:text-xxl md:leading-8 ">
        {quizAtivo?.nome}
      </span>
    </h2>
  );
};

export default TituloQuiz;
