import * as Icon from "../../../../assets/svgs";
import { Button } from "../../../../shared/components/ui/button";
import Interrogacao from "../../../../assets/images/interrogacao.png";

export const ModalConfirmacao = ({
  setShow,
  show,
  resetQuiz,
}: {
  show?: boolean;
  setShow?: any;
  resetQuiz?: any;
}) => {
  return (
    <>
      {show && (
        <div className="fixed left-0 top-0 z-[99998] flex h-full w-full items-center justify-center">
          <div className="absolute inset-0 z-[99998] bg-[#000000] opacity-60"></div>

          <div className="bg-white relative z-[99998] flex flex-col items-center justify-center rounded-xl p-10">
            <div
              className="absolute right-5 top-5 cursor-pointer"
              onClick={() => setShow(false)}
            >
              <Icon.Close />
            </div>

            <img
              data-testid="img-prepara"
              src={Interrogacao}
              alt="Modal cursos pagos"
              className="h-[104.19px] w-[100px] md:w-[120px] mb-6"
            />
            <span className="text-lg md:text-base font-bold leading-26 md:leading-22 tracking-[-0.16px] text-[#004F92]">
              Deseja refazer esse teste?
            </span>
            <span className="mt-3 text-center md:text-sm text-base font-normal leading-5 text-[#313335]  w-[251px] md:w-[350px] md:px-12">
              Se você refazê-lo, o resultado anterior vai ser substituído.
            </span>
            <div className="!mt-6 flex flex-col md:flex-row gap-3 w-full justify-center">
              <Button
                data-testid="btn-refazer-teste-confirmacao"
                onClick={() => {
                  setShow(false);
                  resetQuiz();
                }}
                className="text-white flex h-[52px] w-full md:w-[164px] flex-shrink-0 items-center justify-center bg-[#004F92] px-[14.046px] py-[11.954px] hover:bg-[#005AAB]"
              >
                Refazer teste
              </Button>
              <Button
                data-testid="btn-cancelar-confirmacao"
                variant="outline"
                onClick={() => setShow(false)}
                className="flex h-[52px] w-full md:w-[164px] flex-shrink-0 items-center justify-center border border-[#004F92] px-[14.046px] py-[11.954px] text-[#004F92]"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
