import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  TermosDeUsoEntity,
  useGetTermoUsoAtualLazyQuery,
} from "../../../../generated/graphql";
import { useNovoUsuario } from "../../../../hooks/useNovoUsuario";
import { ModalService } from "../../../../shared/components/Modal";
import { NovoUsuarioInterface } from "../AreasInteresseV2";

import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";

export const ModalTermsV2: React.FC = () => {
  const [termData, setTermData] = useState<TermosDeUsoEntity | undefined>();
  const localUser = useNovoUsuario<NovoUsuarioInterface>();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [GetTermUse, { data }] = useGetTermoUsoAtualLazyQuery({
    onCompleted: (data) => {
      setLoading(false);
      if (
        data &&
        data.termosDeUsos?.data &&
        data.termosDeUsos.data.length > 0
      ) {
        setTermData(data.termosDeUsos.data[0]);
        setLoading(true);
      }
    },
    onError: () => {
      setLoading(false);
      setTermData(undefined);
    },
  });

  useEffect(() => {
    setLoading(true);
    GetTermUse();

    data && data.termosDeUsos && setTermData(data.termosDeUsos.data[0]);
  }, [loading, GetTermUse, data]);

  const confirmTerms = () => {
    localUser
      .load()
      .then((user) => {
        user.acceptTerm = true;

        return user;
      })
      .then((user) => localUser.save(user))
      .then((res) => ModalService.hide());
  };

  return (
    <div data-modal-term>
      <h3>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</h3>
      <div data-modal-term-content data-scrollable>
        {termData?.attributes?.Conteudo &&
          ReactHtmlParser(termData!.attributes?.Conteudo!)}
      </div>
      <div data-footer className="flex-jc-c buttons flex">
        <Button
          type="submit"
          data-testid="btn-fechar"
          className="button button-secondary-blue"
          onClick={() => ModalService.hide()}
        >
          Fechar
        </Button>

        <Button
          type="submit"
          data-testid="btn-aceitar"
          variant="primary"
          onClick={(e) => confirmTerms()}
        >
          Aceitar
        </Button>
      </div>
    </div>
  );
};
