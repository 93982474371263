import { Card, CardContent, CardFooter, CardHeader } from "./ui/card";
import Rotulo from "./rotulos";
import { cn } from "../utils/utils";
import {
  getDescricaoTipoObjeto,
  getIconeTipoObjeto,
  getTipoObjeto,
} from "../utils/tipo-objeto";
import CalendarBlue from "../../assets/svgs/icons/calendar.svg";
import DeclaracaoIcon from "../../assets/svgs/new/certificado.svg";
import { typeObjetoEducacional } from "../../models/TipoObjetoEducacional";
import { ObjetoEducacionalEntity } from "../../generated/graphql";
import { getUserContext } from "../../context/UserContext";
import { SmartLink } from "./SmartLink";
import { Favoritar } from "./Favoritar";
import React from "react";
import { Badge } from "./ui/badge";
import { ModalService } from "./Modal";
import CertificadoPageV2 from "../../pages/CertificadoPageV2";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown";
import { EllipsisVertical, Bookmark, Send } from "lucide-react";
import { useModalPlaylist } from "../../hooks/useModalPlaylist";
import Compartilhar from "../../pages/OEPage/components/Compartilhar";
import { ObjetoEducacionalVideo } from "../../pages/OEPage/OeVideoPage";

export const getIcon = (type: number) => {
  switch (type) {
    case typeObjetoEducacional.VIDEO:
      return "/assets/svg/tipo-objetos/video.svg";
    case typeObjetoEducacional.QUESTIONARIO:
      return "/assets/svg/tipo-objetos/questionario.svg";
    case typeObjetoEducacional.TEXTO:
      return "/assets/svg/tipo-objetos/texto.svg";
    case typeObjetoEducacional.IMAGEM:
      return "/assets/svg/tipo-objetos/imagem.svg";
    case typeObjetoEducacional.PDF:
      return "/assets/svg/tipo-objetos/pdf.svg";
    case typeObjetoEducacional.AUDIO:
      return "/assets/svg/tipo-objetos/audio.svg";
    default:
      return "";
  }
};

export default function CardObjetoEducacionalGraphQL({
  objetoEducacional,
  isListCertificados,
}: {
  objetoEducacional: ObjetoEducacionalEntity;
  isListCertificados?: boolean;
}) {
  function formatarTempoMinutos(minutes: string) {
    const totalMinutes = Number(minutes);
    const hours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    const formattedMinutes = remainingMinutes.toString().padStart(2, "");
    const formattedSeconds = "00";

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds.toString().padStart(2, "0")}`;
    } else {
      return `${formattedMinutes.toString().padStart(2, "0")}:${formattedSeconds.toString().padStart(2, "0")}`;
    }
  }

  const mostrarModalCertificado = (
    titulo: string,
    tempoMedioDuracao: string,
  ) => {
    ModalService.hide();
    ModalService.show({
      content: (
        <CertificadoPageV2
          titulo={titulo}
          tempoMedioDuracao={tempoMedioDuracao}
        />
      ),
      showComponentInFullScreen: true,
      showCloseButton: true,
      closeOutsideModal: true,
    });
  };

  const getFinalizado = (item: ObjetoEducacionalEntity) => {
    const user = getUserContext();
    if (!!user) {
      if (
        item.id &&
        ((item.attributes?.VideoAudio &&
          item.attributes?.VideoAudio.length &&
          user?.attributes?.OesConsumidosParcialmente?.data.some(
            (itemConsumo) =>
              item.attributes?.VideoAudio &&
              item.attributes.VideoAudio[0].toString() ===
                itemConsumo?.attributes?.VideoAudioId?.toString() &&
              itemConsumo.attributes?.duracao &&
              itemConsumo.attributes?.segundos &&
              itemConsumo.attributes?.duracao ===
                itemConsumo.attributes?.segundos,
          )) ||
          (item.attributes?.SecoesTexto &&
            item.attributes?.SecoesTexto.length &&
            item.attributes?.SecoesTexto.some((leitura) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (itemConsumo) =>
                  leitura?.id.toString() ===
                    itemConsumo.attributes?.LeituraOeId?.toString() &&
                  itemConsumo.attributes?.duracao &&
                  itemConsumo.attributes?.segundos &&
                  itemConsumo.attributes?.duracao ===
                    itemConsumo.attributes?.segundos,
              ),
            )) ||
          (item.attributes?.Infografico &&
            item.attributes?.Infografico.length &&
            item.attributes?.Infografico.some((imagem) =>
              user?.attributes?.OesConsumidosParcialmente?.data.some(
                (itemConsumo) =>
                  imagem?.id.toString() ===
                    itemConsumo.attributes?.ImagemOeId?.toString() &&
                  itemConsumo.attributes?.duracao &&
                  itemConsumo.attributes?.segundos &&
                  itemConsumo.attributes?.duracao ===
                    itemConsumo.attributes?.segundos,
              ),
            )))
      ) {
        return true;
      }

      return false;
    }
  };

  return (
    <Card className="flex h-full flex-col gap-2 shadow-none">
      <SmartLink
        oe={objetoEducacional}
        onShowCertificate={() => {
          if (isListCertificados) {
            mostrarModalCertificado(
              objetoEducacional?.attributes?.Titulo ?? "",
              objetoEducacional?.attributes?.TempoMedioDuracao ?? "",
            );
          }
        }}
        isListCertificados={isListCertificados}
      >
        <CardHeader className="group relative h-[188.42px] md:h-[227.66px]">
          <img
            alt="imagem capa objeto educacional"
            src={
              objetoEducacional?.attributes?.ImagemPreview.data?.attributes
                ?.url ?? ""
            }
            width={300}
            height={150}
            className={cn(
              "absolute left-0 top-0 h-full w-full rounded-xl border-4 object-cover object-top group-hover:border-[#B1E3D8] group-focus:border-[#B1E3D8]",
              objetoEducacional?.attributes?.patrocinador?.data?.id
                ? "border-4 border-[#626466]"
                : "",
            )}
          />
          <img
            src={getTipoObjeto(
              parseInt(
                objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
              ),
            )}
            alt="ícone tipo objeto educacional"
            width={60} //60 52.03
            height={60} //60 52.03
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
          />
          <div className="absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6">
            <Rotulo
              rotuloName={
                objetoEducacional?.attributes?.Rotulos?.data &&
                objetoEducacional?.attributes?.Rotulos?.data?.length > 0
                  ? (objetoEducacional?.attributes?.Rotulos?.data[0].attributes
                      ?.titulo ?? "")
                  : ""
              }
              id={
                objetoEducacional?.attributes?.Rotulos?.data &&
                objetoEducacional?.attributes?.Rotulos?.data?.length > 0
                  ? parseInt(
                      objetoEducacional?.attributes?.Rotulos?.data[0].id ?? "0",
                    )
                  : 0
              }
            />
          </div>
          <div className="absolute right-5 top-5 md:right-6 md:top-6">
            <Favoritar
              idOe={objetoEducacional?.id?.toString() || "0"}
              color="yellow"
              item={{
                id: objetoEducacional?.id?.toString()!,
                titulo: objetoEducacional?.attributes?.Titulo!,
              }}
            />
          </div>
          {objetoEducacional && (
            <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
              <span className="text-xxs  font-bold text-white-200 md:text-xs">
                {formatarTempoMinutos(
                  objetoEducacional?.attributes?.TempoMedioDuracao ?? "",
                )}
              </span>
            </Badge>
          )}

          {getFinalizado(objetoEducacional) && (
            <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
              Visualizado
            </span>
          )}
        </CardHeader>
      </SmartLink>
      <CardContent className="flex flex-col items-start justify-between gap-2 p-0">
        {objetoEducacional?.attributes?.patrocinador?.data?.id && (
          <span className="text-[10px] font-bold text-[#626466]">
            CONTEÚDO PATROCINADO
          </span>
        )}
        <span className="flex w-full items-start justify-between text-sm font-semibold leading-4 text-[#111316] md:text-base tablet:text-xs tablet:leading-3">
          <SmartLink
            oe={objetoEducacional}
            onShowCertificate={() => {}}
            isListCertificados={false}
          >
            {objetoEducacional?.attributes?.Titulo}
          </SmartLink>
          <MoreOptions
            idOe={parseInt(objetoEducacional?.id || "0")}
            titulo={objetoEducacional.attributes?.Titulo || ""}
            descricao={objetoEducacional.attributes?.Descricao || ""}
            imagemPreview={
              objetoEducacional.attributes?.ImagemPreview.data?.attributes
                ?.url || ""
            }
            shareUrl={getUrl(objetoEducacional)}
            tipoOe={parseInt(
              objetoEducacional.attributes?.TipoObjeto?.data[0].id!,
            )}
          />
        </span>
      </CardContent>
      <></>
      <CardFooter className="flex flex-col items-start gap-[3px] p-0">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row items-center gap-1">
            {getIconeTipoObjeto(
              parseInt(
                objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
              ),
              "#004F92",
              20,
            )}
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {getDescricaoTipoObjeto(
                parseInt(
                  objetoEducacional?.attributes?.TipoObjeto?.data[0].id ?? "0",
                ),
              )}
            </span>
          </div>

          <div className="flex flex-row items-center gap-1">
            <img
              src={CalendarBlue}
              alt="icon-video"
              width={20} //24
              height={20} //24
            />
            <span className="text-xxs font-semibold text-[#111316] md:text-[11px]">
              {objetoEducacional?.attributes?.DataAtualizacaoProducao &&
                objetoEducacional?.attributes?.DataAtualizacaoProducao.split(
                  "-",
                )
                  .reverse()
                  .join("/")}
            </span>
            {objetoEducacional?.attributes?.PossuiCertificado && (
              <div className="flex flex-row items-center gap-[3px]">
                <img
                  src={DeclaracaoIcon}
                  alt="ícone certificado"
                  width={20} //24
                  height={20} //24
                />

                <span className="text-xxs font-semibold leading-18 text-[#111316] md:text-xs md:leading-4 tablet:text-xxs">
                  Declaração de participação
                </span>
              </div>
            )}
          </div>
        </div>
      </CardFooter>
    </Card>
  );
}

const formatarUrl = (oeType: string, oeId: string, videoId?: string) => {
  switch (oeType) {
    case "Áudio":
      return `/oe/${oeId}/podcast/`;
    case "Imagem":
      return `/oe/${oeId}/imagem/preview`;
    case "Questionário":
    case "Quizz":
      return `/oe/${oeId}/quiz`;
    case "Simulação":
      return `/oe/${oeId}/simulacao/`;
    case "Leitura":
    case "Texto":
      return `/oe/${oeId}/leitura`;
    case "Live":
      return `/oe/${oeId}/live${!!videoId ? `/${videoId}` : ""}`;
    case "Vídeo":
      return `/oe/${oeId}/video${!!videoId ? `/${videoId}` : ""}`;
    default:
      return "";
  }
};

const getUrl = (oe: any) => {
  const oeType = (oe.TipoObjeto && oe.TipoObjeto[0]?.titulo) || "";
  let _url = formatarUrl(oeType, oe.id);

  if (!!(oe as ObjetoEducacionalVideo).VideoId) {
    _url = formatarUrl(oeType, oe.id, (oe as ObjetoEducacionalVideo).VideoId);
  }

  return _url;
};

export function MoreOptions({
  idOe,
  imagemPreview,
  titulo,
  descricao,
  shareUrl,
  tipoOe,
}: {
  idOe?: number;
  imagemPreview: string;
  titulo: string;
  descricao: string;
  shareUrl: string;
  tipoOe: number;
}) {
  const mostrarModalPlaylist = useModalPlaylist();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button aria-haspopup="dialog">
            <span className="sr-only">Mais opções</span>
            <EllipsisVertical className="size-5" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {tipoOe !== 7 && (
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault();
                mostrarModalPlaylist.mostrar({
                  idOe: idOe!,
                });
              }}
              className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50"
            >
              <Bookmark />
              Salvar na playlist
            </DropdownMenuItem>
          )}

          <DropdownMenuItem className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50">
            <Send className="size-5" />
            Compartilhar
            <Compartilhar
              item={{
                descricao: descricao,
                titulo: titulo,
                url: imagemPreview,
                id: idOe?.toString(),
              }}
              color="white"
              shareUrl={shareUrl}
              newIcon={true}
            />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
