import { ModalService } from "../shared/components/Modal";
import { ModalPlaylistCard } from "../shared/components/ModalPlaylistCard";

export const useModalPlaylist = () => {
  const mostrar = ({ idOe }: { idOe: number }) => {
    ModalService.show({
      content: <ModalPlaylistCard idOe={idOe} />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
    });
  };

  return { mostrar };
};
