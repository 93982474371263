import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Badge } from "lucide-react";
import Container from "../../../shared/components/Container";
import Rotulo from "../../../shared/components/rotulos";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import VerTudoIcon from "../../../assets/svgs/icons/ver-tudo.svg";
import fetchApi from "../../../shared/services/rest-dotnet.service";
import { Cardplaylist } from "../PlaylistListPersonalizada";
import { cn } from "../../../lib/utils";
import * as Icon from "../../../assets/svgs";

interface Playlist {
  id: number;
  titulo: string;
  thumbnail: string;
  qtdVideos: number;
  categoriaDescricao: string;
  categoriaId: number;
  visualizado: boolean;
}

interface PlaylistResponse {
  items: Playlist[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

async function getPlaylistPersonalizada(): Promise<PlaylistResponse> {
  const response = await fetchApi(
    "playlists/usuario/playlists",
    "POST",
    JSON.stringify({
      titulo: "",
      pageIndex: 1,
      pageSize: 3,
      ordernar: 0,
    }),
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return {
        items: [],
        totalItems: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
      };
    }
  });

  return response;
}

export default function PlaylistPersonalizada() {
  const [assistidos, setAssistidos] = useState<PlaylistResponse>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const loadAssistidos = async () => {
    setLoading(true);
    setAssistidos(await getPlaylistPersonalizada());
    setLoading(false);
  };

  useEffect(() => {
    loadAssistidos();
  }, []);

  return (
    <Container className="flex flex-col gap-6 py-9 md:py-10">
      <div className="flex items-center  justify-between">
        <div className="">
          <div className="">
            <div className="flex flex-col gap-3">
              {assistidos && assistidos?.items?.length > 0 ? (
                <>
                  <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
                    Minhas playlists
                  </h2>
                  <span className=" text-sm font-normal text-[#111316] md:block md:text-base">
                    Criadas por você
                  </span>
                </>
              ) : (
                <>
                  <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
                    Minhas playlists
                  </h2>
                  <div className="flex flex-col">
                    <span className=" text-sm font-bold text-[#111316] md:block md:text-base">
                      Você ainda não tem playlists criadas.
                    </span>
                    <span className=" text-sm font-normal text-[#111316] md:block md:text-base">
                      Aprenda a criar e personalize seu aprendizado com os
                      conteúdos que mais te interessam.
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {assistidos && assistidos?.items?.length > 0 && (
          <span
            className="flex cursor-pointer items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
            onClick={() => history.push("/playlists-personalizada")}
            role="link"
          >
            VER TUDO
            <img
              src={VerTudoIcon}
              alt="icon-video"
              aria-hidden="true"
              width={24}
              height={24}
              className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
            />
          </span>
        )}
      </div>

      {assistidos && assistidos?.items?.length > 0 ? (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {assistidos?.items?.map((playlist: Playlist, index: number) => (
              <CarouselItem
                key={playlist.id}
                className="rounded-xl md:basis-1/2 lg:basis-1/3"
              >
                <Cardplaylist playlist={playlist} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto" />
        </Carousel>
      ) : (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            <CarouselItem className="md:basis-1/2 lg:basis-1/4">
              <div className="relative flex h-[304px] w-full flex-col items-center justify-center gap-6 overflow-hidden rounded-lg border p-6 text-center md:h-[304px]">
                <Icon.onBoard1 className="h-[140px] w-[160px]" />
                <div className="flex flex-col gap-3">
                  <span className="text-base font-semibold leading-[19.5px] text-black-900">
                    1. Escolha um conteúdo
                  </span>
                  <p className="text-base font-normal leading-[19.5px] text-black-900">
                    Navegue e selecione o conteúdo que deseja adicionar à sua
                    playlist.
                  </p>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="md:basis-1/2 lg:basis-1/4">
              <div className="relative flex h-[304px] w-full flex-col items-center justify-center gap-6 overflow-hidden rounded-lg border p-6 text-center md:h-[304px]">
                <Icon.onBoard2 className="h-[140px] w-[160px]" />
                <div className="flex flex-col gap-3">
                  <span className="text-base font-semibold leading-[19.5px] text-black-900">
                    2. Clique no botão do card
                  </span>
                  <p className="text-base font-normal leading-[19.5px] text-black-900">
                    Encontre e clique no botão correspondente para adicionar o
                    conteúdo.
                  </p>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="md:basis-1/2 lg:basis-1/4">
              <div className="relative flex h-[304px] w-full flex-col items-center justify-center gap-6 overflow-hidden rounded-lg border p-6 text-center md:h-[304px]">
                <Icon.onBoard3 className="h-[140px] w-[160px]" />
                <div className="flex flex-col gap-3">
                  <span className="text-base font-semibold leading-[19.5px] text-black-900">
                    3. Selecione a Playlist
                  </span>
                  <p className="text-base font-normal leading-[19.5px] text-black-900">
                    Escolha uma playlist existente ou crie uma nova para
                    adicionar o conteúdo
                  </p>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem className="md:basis-1/2 lg:basis-1/4">
              <div className="relative flex h-[304px] w-full flex-col items-center justify-center gap-6 overflow-hidden rounded-lg border p-6 text-center md:h-[304px]">
                <Icon.onBoard4 className="h-[140px] w-[160px]" />
                <div className="flex flex-col gap-3">
                  <span className="text-base font-semibold leading-[19.5px] text-black-900">
                    4. Pronto
                  </span>
                  <p className="text-base font-normal leading-[19.5px] text-black-900">
                    Basta acessar o menu "Playlists" para visualizar todas elas.
                  </p>
                </div>
              </div>
            </CarouselItem>
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="banner hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="banner hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto" />
        </Carousel>
      )}
    </Container>
  );
}
