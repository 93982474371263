import React from "react";
import { Tooltip } from "@material-ui/core";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { SmartLink, RotuloCategoria } from "..";
import FooterOeMediaRest from "../OeMedia/FooterOeMediaRest";
import { mostrarModalCertificado } from "../../../pages/OEPage/components/AcessoDeclaracao";
import { splitOeTitle } from "../../../helpers/oe-card.helper";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import "./styles.scss";
import classNames from "classnames";
import { VariantProps, tv } from "tailwind-variants";
import { useUtils } from "../../utils";
import { ObjetoEducacionalModel } from "../../../models/ObjetoEducacionalModel";
import { SmartLinkRest } from "../SmartLinkRest";
interface CardSwiperItemRestProps {
  item: ObjetoEducacionalModel;
  mostraMarcaDaguaCertificados: boolean;
  loading?: boolean;
  isListCertificados?: boolean;
}
const div = tv({
  base: "flex flex-col w-full rounded-2xl bg-[#6d6e70]",
});
type CardSwiperItemRestVariantProps = VariantProps<typeof div>;
export const CardSwiperItemRest: React.FC<
  CardSwiperItemRestProps & CardSwiperItemRestVariantProps
> = ({ mostraMarcaDaguaCertificados, item, loading, ...props }) => {
  const isPatrocinador = item.patrocinador ? item.patrocinador?.id > 0 : false;
  const { formatTitle } = useUtils();
  const gtm = useGtm();
  const showCertificate = () => {
    gtm.push(
      EDataLayerEventCategory.ADE_MEU_PERFIL,
      EDataLayerEventAction.CLIQUE,
      {
        label: "declaracoes",
      }
    );
    mostrarModalCertificado(formatTitle(item.titulo!), item.tempoMedioDuracao!);
  };
  if (loading) {
    return (
      <div className="overflow-hidden relative space-y-5 rounded-2xl bg-slate-100 h-full ">
        <div className="w-full h-full  rounded-2xl -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-gray-400/20 to-transparent"></div>
      </div>
    );
  } else {
    return (
      <>
        {!!item && !!item?.imagemPreviewUrl && (
          <div
            data-card-oe
            className={classNames(div(props), {
              "border-3 border-[#6d6e70] h-[280px] overflow-hidden ":
                isPatrocinador,
              "h-[280px]": !isPatrocinador,
            })}
          >
            <div
              className="relative w-full h-full bg-cover bg-center rounded-2xl p-[1.875rem]"
              style={{
                backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.15) 0%, rgba(0,0,0,.4) 100%),
                url(${item && item.imagemPreviewUrl})`,
              }}
            >
              {mostraMarcaDaguaCertificados && (
                <div data-download onClick={showCertificate}>
                  <ArrowDownwardRoundedIcon fontSize="large" />
                  Clique aqui para fazer o download
                  <br />
                  da declaração
                </div>
              )}
              <SmartLinkRest
                oe={item}
                onShowCertificate={showCertificate}
                isListCertificados={props.isListCertificados}
              >
                <div className="h-full">
                  <Tooltip title={formatTitle(item?.titulo!)}>
                    <h3
                      className="text-base font-semibold leading-normal text-white leading-5  "
                      data-width="65"
                      onClick={() => {
                        // GTM doc linha 60
                        gtm.push(
                          EDataLayerEventCategory.ADE_VIDEOS_HOME,
                          EDataLayerEventAction.CLIQUE,
                          {
                            label: formatTitle(item?.titulo!),
                          }
                        );
                      }}
                    >
                      {splitOeTitle(formatTitle(item?.titulo!))}
                    </h3>
                  </Tooltip>
                  <div className="flex flex-wrap mt-3" data-width="50">
                    {item?.subRotulos.length > 0
                      ? item?.subRotulos?.map((rotulo, idx) => (
                          <RotuloCategoria
                            key={idx}
                            data={rotulo.titulo ?? ""}
                          />
                        ))
                      : item.rotulos.map((rotulo, idx) => (
                          <RotuloCategoria
                            key={idx}
                            data={rotulo!.titulo ?? ""}
                          />
                        ))}
                  </div>
                </div>
              </SmartLinkRest>
              <FooterOeMediaRest
                item={{
                  Descricao: item?.descricao!,
                  id: item.id.toString()!,
                  imagemPreview: item.imagemPreviewUrl!,
                  PossuiCertificado: item.possuiCertificado!,
                  TempoMedioDuracao: item.tempoMedioDuracao!,
                  TipoObjeto:
                    item?.tiposObjeto.map((a) => ({
                      titulo: a.titulo!,
                    })) || [],
                  status: item.finalizado ? "FINALIZADO" : "",
                  titulo: formatTitle(item?.titulo!),
                }}
                color="white"
                padding={[15]}
                gradient={isPatrocinador}
                favoriteColor="yellow"
                shareUrl=""
              />
            </div>
            {isPatrocinador && (
              <div className="flex relative w-full h-7 bg-[#6d6e70]">
                <span className="flex items-center  text-white text-[10px] font-bold px-2">
                  Conteúdo Patrocinado
                </span>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
};
