import { HEADER_ACTION_TYPE } from "../../shared/constants/action-types";
import { GetHeaderData } from "../../graphql/single-types/queries/header.query.graphql";
import { HeaderPortal } from "../../shared/interfaces/header.interface";
import { HeaderPortalEntity, HeaderPortalEntityResponse } from "../../generated/graphql";

export const toggleMenuMobileAction = (toggleMenuMobile: boolean) => {
    return {
        type: HEADER_ACTION_TYPE.TOGGLE_MENU_MOBILE,
        payload: toggleMenuMobile
    };
}

export const toggleLoginAction = (toggleLogin: boolean) => {
    return {
        type: HEADER_ACTION_TYPE.TOGGLE_LOGIN,
        payload: toggleLogin
    };
}

export const togglePreRegisterUserAction = (togglePreRegisterUser: boolean) => {
    return {
        type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
        payload: togglePreRegisterUser
    };
}

export const loadHeaderData = async () => {
    const result = await GetHeaderData();
    const data = !result ? {} as HeaderPortalEntityResponse : result.data.headerPortal;
    return {
        type: HEADER_ACTION_TYPE.LOAD,
        payload: data
    };
}