// eslint-disable-next-line import/no-anonymous-default-export
export const GET_IDENTITY = "GET_IDENTITY";
export const SET_IDENTITY = "SET_IDENTITY";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SET_TOKEN = "SET_TOKEN";
export const TOKEN_VALIDATED = "TOKEN_VALIDATED";
export const USER_FETCHED = "USER_FETCHED";

export enum HEADER_ACTION_TYPE {
  TOGGLE_MENU_MOBILE = "TOGGLE_MENU_MOBILE",
  TOGGLE_LOGIN = "TOGGLE_LOGIN",
  PRE_REGISTER_USER = "PRE_REGISTER_USER",
  NEW_USER = "NEW_USER",
  LOAD = "LOAD_HEADER",
}

export enum FOOTER_ACTION_TYPE {
  LOAD = "LOAD_FOOTER",
}

export enum AUTH_ACTION_TYPE {
  SIGNIN = "SIGNIN",
}

export enum TOKEN_ACTION_TYPE {
  SET_TOKEN = "SET_TOKEN",
  GET_TOKEN = "GET_TOKEN",
  VALID_TOKEN = "VALID_TOKEN",
}

export enum TOPIC_ACTION_TYPE {
  LOAD = "LOAD_TOPIC",
}

export enum OE_ACTION_TYPE {
  LOAD_QUIZZ = "LOAD_QUIZZ",
  LOAD_WEEK_DESTAK = "LOAD_WEEK_DESTAK",
  LOAD_CONTENT_HIGHER = "LOAD_CONTENT_HIGHER",
  LOAD_VIDEO_HIGHER = "LOAD_VIDEO_HIGHER",
  LOAD_HOME_PAGE = "LOAD_HOME_PAGE",
  LOAD_FULL_CONTENT = "LOAD_FULL_CONTENT",
}

export enum AREA_ACTION_TYPE {
  LOAD = "LOAD_AREA",
}

export enum STEP_REGISTER_USER {
  STEP_PERSON_INFORMATION = "STEP_PERSON_INFORMATION",
  STEP_PROFESSIONAL_INFORMATION = "STEP_PROFESSIONAL_INFORMATION",
  STEP_USER_PREFERENCE = "STEP_USER_PREFERENCE",
  REGISTER_USER_SUCCEED = "REGISTER_USER_SUCCEED",
  TOGGLE_MODAL_TERM = "TOGGLE_MODAL_TERM",
}

export enum CURTIR_TYPE {
  TOGGLE_CURTIR = "TOGGLE_CURTIR",
}
