import React from "react";

interface EmptyMessageProps {
  message: string;
  children?: React.ReactNode;
}

export const EmptyMessage: React.FC<EmptyMessageProps> = (props) => {
  return (
    <div data-empty-message>
      <p>{props.message}</p>
      {props.children}
    </div>
  );
};
