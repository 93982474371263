import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
} from "../../shared/components";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { ProfissoesCards } from "./components/ProfissoesCards";
import { useUtils } from "../../shared/utils";
import Container from "../../shared/components/Container";
import { PublicoAlvoModel } from "../../models/PublicoAlvoModel";
import { fetchApi } from "../../shared/services/rest-dotnet.service";

async function getPublicosAlvos(): Promise<PublicoAlvoModel[]> {
  const response = await fetchApi("publico-alvo/get-publicos-alvos", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });
  return response;
}

const ProfissoesPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<PublicoAlvoModel[]>([]);
  const gtm = useGtm();
  const scrollTop = useScrollTop();
  const { changePosition } = useUtils();

  useEffect(() => {
    scrollTop();
    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "profissoes",
        }
      );
    };
  }, []);

  const loadPublicosAlvos = async () => {
    setLoading(true);
    setData(await getPublicosAlvos());
    setLoading(false);
  };

  useEffect(() => {
    loadPublicosAlvos();
  }, []);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div data-professions-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Profissões</BreadcrumbItem>
          </Breadcrumb>
          <h3 className="text-base text-white font-semibold leading-none md:text-xxl text-base">
            Estude pela área de atuação
          </h3>
        </HeaderSection>
        <section className="pb-8">
          <Container>
            <div className="mt-12">
              <h3 className="text-lg font-extrabold leading-none text-[#107B95] mb-8  hidden md:flex">
                Perfil profissional
              </h3>
            </div>
            <ProfissoesCards publicosAlvos={data} />
          </Container>
        </section>
      </div>
    </>
  );
};

export default ProfissoesPage;
