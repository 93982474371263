import React from 'react';
import { ComponentPerguntaOpcaoRespostas } from '../../../../generated/graphql';
import { PerguntaModel } from '../../../../models/PerguntaModel';
import { RespostaModel } from '../../../../models/RespostaModel';

export interface PercentualQuestaoQuizProps {
  showingQuestionFeedback: boolean;
  perguntaAtiva?: PerguntaModel;
  respostaAtiva?: any;
}

export const PercentualQuestaoQuiz: React.FC<PercentualQuestaoQuizProps> = ({
  showingQuestionFeedback,
  respostaAtiva,
  perguntaAtiva
}) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const calcAnswerPercentage = (answer: RespostaModel) => {
    let _p = perguntaAtiva?.respostas
      ?.map((opcao) => opcao?.contador as number)
      .reduce(
        (accumulator, currentValue) =>
          (accumulator || 0) + currentValue
      ) as number;
    if (_p === 0) {
      return 0;
    }

    _p = (answer.contador / _p) * 100;
    return _p;
  }

  return (
    <>
      {showingQuestionFeedback && (
        <div className="border-[#9EA5B8] border-1 rounded-xl w-full flex h-auto !mt-5 justify-center py-10 ">
          <div className="flex flex-col w-full justify-center max-w-[220px]">
            <span className="text-sm leading-normal font-normal text-[#111316] ">
              Porcentagem das respostas de outros usuários:
            </span>
            <>
              {perguntaAtiva?.respostas?.map(
                (resposta, _idx) => {
                  const alphabetIndex = alphabet[_idx];

                  const percentage = Math.round(
                    calcAnswerPercentage(resposta)
                  );

                  return (
                    <div
                      className="flex gap-3 items-center mt-2"
                      key={_idx}
                    >
                      <span className="text-[#004F92] text-xs font-semibold">
                        {alphabetIndex}
                      </span>
                      <div className="w-full bg-[#E1E3E6] rounded-full h-2.5 ">
                        <div
                          className={`bg-[#004F92] h-2.5 rounded-full w-[${percentage}%]`}
                          style={{
                            width: `${percentage}%`,
                          }}
                        ></div>
                      </div>
                      <span className="text-[#111316] text-xs font-semibold">
                        {(() => {
                          return (
                            <span>
                              {percentage > 10
                                ? percentage
                                : `  ${percentage}`}
                              %
                            </span>
                          );
                        })()}
                      </span>
                    </div>
                  );
                }
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}
