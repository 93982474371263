import { HEADER_ACTION_TYPE } from "../../shared/constants/action-types";
import { HeaderState, HeaderAction } from "../../shared/interfaces/header.interface";

const INIT_STATE: HeaderState = {
  url: "",
  toggleMenuMobile: false,
};

const headerReducer = (
  state: HeaderState = INIT_STATE,
  action: HeaderAction
) => {
  /* ao abrir qualquer modal o body não deve possuir barra  de rolagem
  !!action.payload 
  ? (() =>{
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';
  })() 
  : (() =>{
    document.body.style.height = 'auto';
    document.body.style.overflow = 'auto';
  })();
  */
  switch (action.type) {
    case HEADER_ACTION_TYPE.TOGGLE_MENU_MOBILE:
      return {
        toggleMenuMobile: action.payload
      }
    case HEADER_ACTION_TYPE.PRE_REGISTER_USER:
      return {
        togglePreRegisterUser: action.payload,
      };
    case HEADER_ACTION_TYPE.TOGGLE_LOGIN:      
      return {
        toggleLogin: action.payload,
      };
    case HEADER_ACTION_TYPE.LOAD:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default headerReducer;
