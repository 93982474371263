import Container from "../../../shared/components/Container";
import DialogPreview from "../../../shared/components/dialog-preview";
import Vimeo from "@u-wave/react-vimeo";
import { ComoDesejaEstudarModel } from "../../../models/ComoDesejaEstudar";
import { getTipoObjeto } from "../../../shared/utils/tipo-objeto";
import { typeObjetoEducacional } from "../../../models/TipoObjetoEducacional";
import { useEffect, useState } from "react";
import React from "react";

interface NossosFormatosItem {
  imageSrc: string;
  imageWidth: number;
  imageHeight: number;
  srcConteudo: string;
  altText: string;
  label: string;
  type: typeObjetoEducacional;
}

enum TiposConteudos {
  VIDEOAULAS = "Videoaulas",
  VIDEOCAST = "Videocasts",
  INFOGRAFICOS = "Infográficos",
}

async function getComoDesejaEstudar(): Promise<ComoDesejaEstudarModel[]> {
  const response = await fetch(
    "https://academiadigitalapicore.einstein.br/api/home/como-deseja-estudar",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

const formatos = [
  "Videoaulas",
  "Videocasts",
  "Podcasts",
  "Textos",
  "Quiz",
  "Infográficos",
];

export default function NossosFormatos() {
  const [comoDesejaEstudar, setComoDesejaEstudar] =
    useState<ComoDesejaEstudarModel[]>();

  const loadComoDesejaEstudar = async () => {
    setComoDesejaEstudar(await getComoDesejaEstudar());
  };

  useEffect(() => {
    loadComoDesejaEstudar();
  }, []);

  const [selectedItem, setSelectedItem] = useState<ComoDesejaEstudarModel>();

  return (
    <div className="bg-[#F5F8FF]">
      <Container className="flex max-w-[946px] items-center justify-center py-6 md:py-20 tablet:py-10">
        {selectedItem && (
          <DialogPreview
            className="!bg-transparent"
            setOpen={(open) => {
              if (!open) {
                setSelectedItem(undefined);
              }
            }}
          >
            <>
              {selectedItem?.tipo === TiposConteudos.VIDEOCAST ||
              selectedItem?.tipo === TiposConteudos.VIDEOAULAS ? (
                <Vimeo
                  video={selectedItem?.url}
                  className="player-video-preview h-full w-full md:h-[600px] md:w-[1056px]"
                />
              ) : (
                <img
                  src={selectedItem?.url}
                  alt="Imagem preview do conteúdo"
                  width={1056}
                  height={600}
                  className="h-full w-full"
                />
              )}
            </>
          </DialogPreview>
        )}
        <div className="flex flex-col gap-3 md:!gap-9 tablet:!gap-6 ">
          <div className="flex flex-col items-center justify-center !gap-3 self-center text-center  md:items-start md:!text-start">
            <h2 className="gradiente-einstein items-center justify-center text-center text-xxl font-semibold normal-case leading-30 md:w-full md:text-3.5xl md:leading-38  tablet:text-xxl tablet:leading-26">
              Como você deseja estudar?
            </h2>
            <span className="flex flex-col text-center text-sm font-normal leading-18 md:text-lg md:leading-8 tablet:text-lg tablet:leading-8">
              Na Academia Digital Einstein, você tem uma variedade de conteúdos:{" "}
              <span className="flex justify-center text-center font-bold text-[#4F5153]">
                videoaulas, videocast, podcast, texto, infográficos e muito
                mais.
              </span>
            </span>
          </div>

          <div className="flex flex-col gap-3 md:!grid  md:!max-h-[364px] md:max-w-[946px]  md:!grid-cols-10 md:!grid-rows-4  md:gap-6 tablet:gap-[1.125rem]">
            <div
              onClick={() =>
                comoDesejaEstudar && setSelectedItem(comoDesejaEstudar[0])
              }
              className="group relative col-span-6 row-span-4 h-[140.11px] cursor-pointer md:h-auto"
              key={0}
            >
              <img
                src={
                  comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[0].thumbnail
                    : ""
                }
                width={558}
                height={364}
                alt={"como-deseja-estudar"}
                className="h-full w-full rounded-xl object-cover opacity-95 brightness-100 filter transition-all duration-300 ease-in-out group-hover:brightness-75 "
              />
              <img
                src={getTipoObjeto(typeObjetoEducacional.VIDEO) || ""}
                alt="icone-video"
                width={60}
                height={59}
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  transform transition-all duration-300 ease-in-out group-hover:block md:hidden "
              />
              <div className="absolute bottom-6 left-6 flex h-[30px] w-auto  items-center justify-center rounded-full border  px-[19.78px] group-hover:opacity-0 md:h-[36px] md:px-6">
                <span className="text-xxs font-medium leading-9 text-white-200 md:text-sm">
                  {comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[0].tipo
                    : ""}
                </span>
              </div>
            </div>

            <div
              className="group relative h-[140.11px] cursor-pointer  md:col-span-4 md:row-span-2 md:h-auto"
              onClick={() =>
                comoDesejaEstudar && setSelectedItem(comoDesejaEstudar[1])
              }
              key={1}
            >
              <img
                src={
                  comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[1].thumbnail
                    : ""
                }
                width={364}
                height={171}
                alt={"como-deseja-estudar"}
                className="h-full w-full rounded-xl object-cover opacity-95 brightness-100 filter transition-all duration-300 ease-in-out group-hover:brightness-75 "
              />
              <img
                src={getTipoObjeto(typeObjetoEducacional.IMAGEM) || ""}
                alt="icone-video"
                width={60}
                height={59}
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform transition-all duration-500 ease-in-out group-hover:block md:hidden "
              />
              <div className="absolute bottom-6 left-6 flex h-[36px] w-auto items-center justify-center rounded-full  border px-[19.78px] group-hover:opacity-0 md:px-6">
                <span className="text-xxs font-medium leading-9 text-white-200 md:text-sm">
                  {comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[1].tipo
                    : ""}
                </span>
              </div>
            </div>

            <div
              className="group  relative h-[140.11px] cursor-pointer md:col-span-4 md:row-span-2 md:h-auto"
              onClick={() =>
                comoDesejaEstudar && setSelectedItem(comoDesejaEstudar[2])
              }
              key={2}
            >
              <img
                src={
                  comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[2].thumbnail
                    : ""
                }
                width={364}
                height={171}
                alt="Imagem mostrando opções de estudo"
                className="h-full w-full rounded-xl object-cover opacity-95 brightness-100 filter transition-all duration-500 ease-in-out group-hover:brightness-75 "
              />
              <img
                src={getTipoObjeto(typeObjetoEducacional.VIDEO) || ""}
                alt="Ícone de vídeo"
                width={60}
                height={59}
                className="absolute left-1/2 top-1/2 -translate-x-1/2  -translate-y-1/2 transform transition-all duration-300 ease-in-out group-hover:block md:hidden "
              />
              <div className="absolute bottom-6 left-6 flex h-[36px] w-auto items-center justify-center rounded-full  border px-[19.78px] group-hover:opacity-0 md:px-6">
                <span className="text-xxs font-medium leading-9 text-white-200 md:text-sm">
                  {comoDesejaEstudar && comoDesejaEstudar?.length > 0
                    ? comoDesejaEstudar[2].tipo
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
