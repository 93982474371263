import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { CardContainer } from "../../shared/components";
import { ModalSolicitaMudancaSenha } from "./components";
import { ModalService } from "../../shared/components/Modal";

import "./styles.scss";
import { Button } from "../../shared/components/ui/button";
import {
  CircleCheck,
  CircleCheckBig,
  EyeIcon,
  EyeOff,
  MessageCircleQuestion,
} from "lucide-react";
import { ToastContainer } from "react-toastify";

import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../shared/components/ui/dialog";
import { twMerge } from "tailwind-merge";
import { Input } from "../../shared/components/ui/input";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import { useHistory } from "react-router";
import { ScrollArea } from "../../shared/components/ui/scroll-area";

interface RedefinicaoSenha {
  senha: string;
  confirmacaoSenha: string;
  hash: string;
}

export interface PasswordRules {
  rule: RegExp;
  error: string | undefined | null | any;
}

const EsqueciMinhaSenhaPage: React.FC<any> = (props) => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [expired, setExpired] = useState(false);
  const [response, setResponse] = useState<any>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  const formik = useFormik<RedefinicaoSenha>({
    initialValues: {
      senha: "",
      confirmacaoSenha: "",
      hash: props.match.params.hash,
    },
    validationSchema: yup.object().shape({
      senha: yup
        .string()
        .required("Campo obrigatório")
        .test({
          test: (value) => {
            const erros = validarSenha(value || "");

            if (!erros.length) {
              return true;
            }

            return new yup.ValidationError(erros.join(", "), null, "senha");
          },
        }),
      confirmacaoSenha: yup
        .mixed()
        .test("senha", "Senha não confere", function () {
          return this.parent.senha === this.parent.confirmacaoSenha;
        }),
    }),
    onSubmit: (dados) => {
      fetch(`${process.env.REACT_APP_CHANGE_PASSWORD_URL}`, {
        method: "POST",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(dados),
      }).then(() => {
        switch (response.status) {
          case 200:
            setSuccess(true);
            break;

          case 410:
            setExpired(true);
            break;

          case 404:
          case 500:
            setFailed(true);
            break;
        }
      });
    },
  });

  useEffect(() => {
    async function _fetch() {
      const response = await fetch(
        `${process.env.REACT_APP_CHANGE_PASSWORD_HASH_VERIFY_URL}`,
        {
          method: "POST",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            hash: props.match.params.hash,
          }),
        },
      );

      setResponse(response);

      switch (response.status) {
        case 200:
          break;

        case 404:
          setFailed(true);
          break;

        case 410:
          setExpired(true);
          break;
      }
    }

    _fetch();
  }, []);

  const mostrarModalSolicitacaoMudanca = (e: any) => {
    e.preventDefault();

    ModalService.show({
      content: <ModalSolicitaMudancaSenha />,
    });
  };

  const mostrarMensagemLinkExpirado = () => {
    return (
      <div data-password-reset>
        <ToastContainer />
        <CardContainer>
          <h3>Link expirado</h3>
          <p>
            <a href="" onClick={mostrarModalSolicitacaoMudanca}>
              Clique aqui
            </a>{" "}
            para solicitar novo envio.
          </p>
        </CardContainer>
      </div>
    );
  };

  const mostrarMensagemUrlInvalida = () => {
    return (
      <div data-password-reset>
        <CardContainer shadow>
          <h3>Essa url não é válida</h3>
        </CardContainer>
      </div>
    );
  };

  const mostrarMensagemSucesso = () => {
    return (
      <Dialog open={true}>
        <DialogContent className="h-auto w-[90vw] rounded-xl sm:w-[450px]">
          <DialogHeader className="flex items-center text-lg font-bold leading-none text-[#004F92]">
            Alteração de senha
          </DialogHeader>
          <div className="flex flex-col items-center justify-center gap-6">
            <div className="flex flex-col items-center justify-center gap-6">
              <CircleCheckBig
                width={400}
                color="#21AB27"
                className="text-[#21AB27]"
              />
              <span className="text-base font-semibold text-[#111316]">
                Senha alterada com sucesso!
              </span>
            </div>
            <div className="flex justify-center">
              <Button
                data-testid="btn-ok"
                onClick={() => {
                  mostrarModalLogin();
                  history.push("/");
                }}
                variant="primary"
                className="flex h-[58px] w-[259px]"
              >
                Voltar para o login
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const [openModal, setOpenModal] = useState(
    !expired && !failed && !success ? true : false,
  );

  const renderizarFormulario = () => {
    return (
      <Dialog
        open={openModal}
        onOpenChange={() => {
          setOpenModal(false);
          history.push("/");
        }}
      >
        <DialogContent>
          <DialogHeader className="flex items-center justify-between text-lg font-bold leading-none text-[#004F92]">
            Cadastrar nova senha
          </DialogHeader>
          <form
            onSubmit={formik.handleSubmit}
            className="flex max-h-[90vh] max-w-[90vw] flex-col justify-center gap-2 overflow-y-scroll px-6 md:h-auto md:w-full"
          >
            <Input
              label="Criar uma senha*"
              id="password"
              name="senha"
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.senha}
              errorMessage={formik.errors.senha}
              placeholder="Insira sua senha"
              icon={
                showPassword ? (
                  <EyeIcon
                    className="cursor-pointer text-[#004F92]"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <EyeOff
                    className="cursor-pointer text-[#004F92]"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )
              }
            />

            <Input
              label="Confirmar sua senha*"
              id="confirmPassword"
              name="confirmacaoSenha"
              type={showConfirmPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.confirmacaoSenha}
              errorMessage={formik.errors.confirmacaoSenha}
              placeholder="Confirme sua senha"
              icon={
                showConfirmPassword ? (
                  <EyeIcon
                    className="cursor-pointer text-[#004F92]"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                ) : (
                  <EyeOff
                    className="cursor-pointer text-[#004F92]"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                )
              }
            />

            <div className=" flex flex-col items-start justify-start text-start">
              <p className="mb-3 text-sm font-bold">
                Sua senha deve conter no mínimo:
              </p>
              <p
                className={twMerge(
                  "flex items-center justify-center text-sm font-medium",
                  (formik.values.senha && formik.values.senha.length >= 8) ||
                    formik.values.senha.length === 0
                    ? "text-black-900"
                    : "text-[#D64214]",
                )}
              >
                <CircleCheck
                  width={18}
                  color="#21AB27"
                  className={twMerge(
                    formik.values.senha && formik.values.senha.length >= 8
                      ? ""
                      : "hidden",
                  )}
                />
                Necessário ao menos 8 caracteres
              </p>
              <p
                className={twMerge(
                  "flex items-center justify-center gap-2 text-sm font-medium",
                  (formik.values.senha && /[a-z]/.test(formik.values.senha)) ||
                    formik.values.senha.length === 0
                    ? "text-black-900"
                    : "text-[#D64214]",
                )}
              >
                <CircleCheck
                  width={18}
                  color="#21AB27"
                  className={twMerge(
                    formik.values.senha && /[a-z]/.test(formik.values.senha)
                      ? ""
                      : "hidden",
                  )}
                />
                1 caractere minúsculo
              </p>
              <p
                className={twMerge(
                  "flex items-center justify-center gap-2 text-sm font-medium",
                  (formik.values.senha && /[A-Z]/.test(formik.values.senha)) ||
                    formik.values.senha.length === 0
                    ? "text-black-900"
                    : "text-[#D64214]",
                )}
              >
                <CircleCheck
                  width={18}
                  color="#21AB27"
                  className={twMerge(
                    formik.values.senha && /[A-Z]/.test(formik.values.senha)
                      ? ""
                      : "hidden",
                  )}
                />
                1 caractere maiúsculo
              </p>
              <p
                className={twMerge(
                  "flex items-center justify-center gap-2 text-sm font-medium",
                  (formik.values.senha && /[0-9]/.test(formik.values.senha)) ||
                    formik.values.senha.length === 0
                    ? "text-black-900"
                    : "text-[#D64214]",
                )}
              >
                <CircleCheck
                  width={18}
                  color="#21AB27"
                  className={twMerge(
                    formik.values.senha && /[0-9]/.test(formik.values.senha)
                      ? ""
                      : "hidden",
                  )}
                />
                1 caractere numérico
              </p>
              <p
                className={twMerge(
                  "flex items-center justify-center gap-2 text-sm font-medium",
                  (formik.values.senha &&
                    /[!@#$%^&*(),.?":{}|<>]/.test(formik.values.senha)) ||
                    formik.values.senha.length === 0
                    ? "text-black-900"
                    : "text-[#D64214]",
                )}
              >
                <CircleCheck
                  width={18}
                  color="#21AB27"
                  className={twMerge(
                    formik.values.senha &&
                      /[^a-zA-Z0-9]/.test(formik.values.senha)
                      ? ""
                      : "hidden",
                  )}
                />
                1 caractere especial (pontuação ou outro símbolo)
              </p>
            </div>

            <div className="flex flex-col items-start justify-start gap-2 rounded-xl bg-[#F5F8FF] p-4 text-start">
              <div className="flex flex-row gap-2">
                <MessageCircleQuestion color="#004F92" className="size-10 " />
                <span className="text-sm font-semibold text-[#313335]">
                  Caso precise de ajuda ou tenha alguma dúvida, entre em contato
                  com nosso suporte.
                </span>
              </div>

              <a
                className="ml-7 text-sm font-normal text-[#313335] underline"
                href="mailto:academia.digital@einstein.br"
              >
                academia.digital@einstein.br
              </a>
              <button className="ml-7 text-sm font-normal text-[#313335]">
                (11) 2151-1001
              </button>
            </div>
            <div className="mt-3 flex justify-center">
              <Button
                data-testid="btn-ok"
                type="submit"
                variant="primary"
                className="flex h-[58px] w-[259px]"
              >
                Cadastrar nova senha
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  return (() => {
    return (
      <>
        {expired && mostrarMensagemLinkExpirado()}
        {failed && mostrarMensagemUrlInvalida()}
        {!expired && !failed && !success && renderizarFormulario()}
        {success && mostrarMensagemSucesso()}
      </>
    );
  })();
};

export default EsqueciMinhaSenhaPage;

export function validarSenha(senha: string): PasswordRules[] {
  if (!senha) return [];

  let requiredLowerCase = {
    rule: /([a-z])/,
    error: "um caractere minúsculo",
  };
  let requiredUppercase = {
    rule: /([A-Z])/,
    error: "um caractere maiúsculo",
  };
  let requiredDigit = {
    rule: /[0-9]/,
    error: "pelo menos um digito",
  };
  let requiredLength = {
    rule: /.{8}/,
    error: "8 caracteres",
  };
  let requiredSpecialChars = {
    rule: /(!|@|#|\$|%|ˆ|&|\*|\+|=|\)|\(|\\}|\\{|\[|\\]|\?|<|>)/,
    error: "um caractere especial ex: @#$%^&+?}{()",
  };

  const rules: PasswordRules[] = [
    requiredLowerCase,
    requiredUppercase,
    requiredDigit,
    requiredLength,
    requiredSpecialChars,
  ];

  let errorMessages: PasswordRules[] = [];

  rules.forEach((item) => {
    if (!item.rule.test(senha)) {
      errorMessages.push(item.error);
    }
  });
  return errorMessages;
}
