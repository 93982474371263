import { isMobile } from "react-device-detect";
import ThumbResidencia from "../../../../assets/svgs/residencia/thumb-residencia.svg";
import PreparaResidenciaMedica from "../../../../assets/svgs/residencia/banner-residencia-medica.svg";
import Vimeo from "@u-wave/react-vimeo";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

function ResidenciaMedicaBanner({
  page,
  handleAcessarResidencia,
}: {
  page: string;
  handleAcessarResidencia?: (page: string) => void;
}) {
  const [play, setPlay] = useState(false);
  const gtm = useGtm();

  return (
    <>
      <div className="my-8 flex flex-col justify-center md:my-0 md:flex-row">
        <div className="flex justify-end">
          <div className="flex flex-col justify-center md:px-8">
            <span className="bg-gradient-to-r from-[#107B95] to-[#1BB28E] bg-clip-text text-[2.180rem] font-bold leading-[40px] tracking-[-0.69px] text-transparent md:text-[34.513px]">
              Prepara Einstein
            </span>
            <span className="text-[1.60rem] font-medium leading-8 tracking-[-0.56px] text-black-900 md:text-xl md:text-xxxl">
              para Residência Médica
            </span>
            <span className="mt-3 w-full text-base font-normal leading-5 text-[#626466] md:w-[314px]">
              Adquira conhecimento atualizado e abrangente com especialistas do
              Hospital Albert Einstein.
            </span>
            {isMobile &&
              (page !== "home" ? (
                <div className="flex md:hidden">
                  <img
                    src={PreparaResidenciaMedica}
                    alt="Prepara Residência Médica"
                    className="h-[auto] w-full object-cover object-center md:w-[50%]"
                  />
                </div>
              ) : (
                <div className="flex w-full py-10 ">
                  <img
                    onClick={() => setPlay(true)}
                    src={ThumbResidencia}
                    alt="Prepara Residência Médica"
                    className={twMerge(
                      "h-auto cursor-pointer object-cover object-center",
                      play ? "hidden" : "w-full",
                    )}
                  />
                  <Vimeo
                    video={"https://vimeo.com/916034818/baab1471b9"}
                    responsive
                    paused={!play}
                    className={twMerge(
                      "vp-center h-full w-full",
                      play ? "block" : "hidden",
                    )}
                  />
                </div>
              ))}
            <div
              className={`mt-8 cursor-pointer self-center rounded-[30px]
                bg-[#0A589B] px-[40px] py-[20px] hover:bg-[#0a579bd8] md:!mt-8 md:!self-start ${
                  page === "home" ? "w-[169px]" : "w-[208px]"
                }`}
              onClick={() => {
                handleAcessarResidencia?.(page);
              }}
            >
              <span className="text-white text-base font-semibold">
                {page === "home" ? "Saiba mais" : "Quero começar"}
              </span>
            </div>
          </div>
        </div>
        {!isMobile &&
          (page !== "home" ? (
            <div className="hidden w-[40%] md:flex">
              <img
                src={PreparaResidenciaMedica}
                alt="Prepara Residência Médica"
                className=" h-[auto] object-cover object-center"
              />
            </div>
          ) : (
            <div className="hidden w-[40%] pb-8 pt-[4.5rem] md:flex">
              <img
                onClick={() => setPlay(true)}
                src={ThumbResidencia}
                alt="Prepara Residência Médica"
                className={twMerge(
                  "h-[auto] cursor-pointer object-cover object-center",
                  play ? "hidden" : "w-full",
                )}
              />
              <Vimeo
                pip={true}
                video={"https://vimeo.com/916034818/baab1471b9"}
                responsive
                paused={!play}
                className="h-auto w-full overflow-hidden rounded-[8.84px]"
              />
            </div>
          ))}
      </div>
    </>
  );
}

export default ResidenciaMedicaBanner;
