import { ModalService } from "../shared/components/Modal";
import { ModalPlaylistConfirmDelete } from "../shared/components/ModalPlaylistConfirmDelete";

export const useModalPlaylistConfirmDelete = () => {
  const mostrar = ({ idPlaylist }: { idPlaylist: number }) => {
    ModalService.show({
      content: <ModalPlaylistConfirmDelete idPlaylist={idPlaylist} />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
    });
  };

  return { mostrar };
};
