import React from 'react';
import { ObjetoEducacionalEntity } from '../../../../generated/graphql';

export interface RecomendadoQuizProps {
    oe: ObjetoEducacionalEntity
}

const RecomendadoQuiz: React.FC<RecomendadoQuizProps> = ({ oe }) => {
    return (
        <>
            <div
                data-flex
                data-recommended
                className="text-xs font-medium text-[#111316] leading-4 mt-2"
            >
                {!!oe?.attributes?.PublicoAlvo &&
                    !!oe?.attributes?.PublicoAlvo.data.length && (
                        <>
                            Recomendado para:{" "}
                            {oe.attributes?.PublicoAlvo?.data
                                ?.filter((tag) => tag)
                                .map((tag: any) => tag.attributes.titulo)
                                .join(", ")}
                        </>
                    )}
            </div>
        </>
    );
}

export default RecomendadoQuiz;