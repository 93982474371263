import EnsinoProfile2 from "../../../assets/svgs/ensino-profile2.svg";
import EnsinoProfile1 from "../../../assets/svgs/ensino-profile1.svg";
import Container from "../../../shared/components/Container";

const Opinioes = () => {
  const array = [
    <div
      key={1}
      className="box-border flex  w-full flex-col items-center rounded-lg bg-[#F4F4F4] p-3 shadow-xl md:w-[407px] lg:w-[407px] xl:w-[407px]"
    >
      <div className="flex h-24">
        <img
          src={EnsinoProfile2}
          alt="Foto perfil usuária Gaby Barboza"
          className="mr-4 h-24 w-24"
        />
        <div className="">
          <h3 className="mb-2 text-lg font-bold text-[#626466] md:text-2xl">
            Gaby Barboza
          </h3>
          <span className="text-[0.90rem] italic leading-4 text-[#626466]">
            Médica do Einstein e ex-aluna de cursos de Atualização em Gestão.
          </span>
        </div>
      </div>

      <div className="flex flex-row">
        <span className="mr-1 mt-10 h-0 text-4xl font-bold">“</span>
        <span className="mt-11 text-[0.90rem]  leading-5 text-[#626466] ">
          Sem deixar a parte técnica como médica, escolhi a liderança como
          carreira. Para isso, é necessário estudar e buscar mais conhecimento
          para atuar com excelência como ensina o Einstein
          <span className="absolute ml-2 align-bottom text-4xl font-bold">
            ”
          </span>
        </span>
      </div>
    </div>,
    <div
      key={2}
      className="mt-8 box-border flex w-full flex-col items-center rounded-lg bg-[#F4F4F4] p-3 shadow-xl md:mt-0 md:w-[407px] lg:w-[407px] xl:w-[407px]"
    >
      <div className="flex h-24">
        <img
          src={EnsinoProfile1}
          alt="Foto perfil usuária Juliana Ribeiro"
          className="mr-4 h-24 w-24"
        />
        <div className="">
          <h3 className="mb-2	text-2xl font-bold text-[#626466]">
            Juliana Ribeiro
          </h3>
          <span className="text-[0.90rem] italic leading-4 text-[#626466]">
            Fisioterapeuta e ex-aluna na Pós-graduação EAD em Gestão.
          </span>
        </div>
      </div>
      <div className="flex flex-row">
        <span className="mr-1 mt-10 h-0 text-4xl font-bold">“</span>
        <span className="mt-11 text-[0.90rem]  leading-5 text-[#626466] ">
          Fazer a Pós EAD do Einstein facilitou demais a minha vida e o
          conhecimento agregou muito à minha carreira e já faz a diferença no
          meu currículo
          <span className="absolute ml-2 align-bottom text-4xl font-bold">
            ”
          </span>
        </span>
      </div>
    </div>,
  ];

  return (
    <div className="bg-white">
      <Container>
        <div className="my-10 flex flex-col items-center justify-center">
          <h2 className="text-3xl font-bold normal-case text-[#004F92]">
            {" "}
            Surpreenda-se com a opinião de quem fez
          </h2>
          <div className="mt-10 flex w-full flex-col justify-center md:flex-row md:gap-16">
            {/* <div className="banner-swiper">
              <CustomSwiper
                // className="overflow-hidden rounded-2xl min-h-[300px] h-[300px]"
                slidesPerView={1}
                spaceBetween={30}
                previousRef={prevRef}
                nextRef={nextRef}
                navigation={true}
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                }}
              >
                {array.map((item, index) => (
                  <SwiperSlide key={index}>{item}</SwiperSlide>
                ))}
              </CustomSwiper>
            </div> */}
            {array}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Opinioes;
