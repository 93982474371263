import Container from "../../../shared/components/Container";
import Heart from "../../../assets/svgs/icons/heart.svg";
import Play from "../../../assets/svgs/icons/play.svg";
import Award from "../../../assets/svgs/icons/award.svg";
import NoSave from "../../../assets/svgs/icons/no-save.svg";
import React from "react";
import { useUtils } from "../../../shared/utils";

export interface ExploreProps {
  qtdMembros?: number;
}

export default function Explore({ qtdMembros }: ExploreProps) {
  const { formatNumberWithCommas } = useUtils();

  return (
    <Container className="flex justify-center py-10 md:py-20 tablet:py-10 ">
      <div className="flex flex-col items-center justify-center text-center md:w-full">
        <h2 className="gradiente-einstein text-xxl font-semibold normal-case leading-30 md:text-2.5xl md:leading-9 tablet:text-xxl tablet:leading-9">
          + {formatNumberWithCommas(qtdMembros || 0)} membros
        </h2>
        <p className="mt-3 !text-sm font-normal !leading-18 md:mt-3 md:!text-lg md:!leading-22 tablet:mt-1 tablet:!text-sm tablet:!leading-22">
          Junte-se à milhares de profissionais e tenha acesso a uma variedade de{" "}
          <span className="font-bold"> materiais exclusivos</span> disponíveis.
        </p>
        <div className="!mt-3 grid grid-cols-2 flex-wrap gap-3 md:!mt-6 md:flex md:flex-nowrap md:gap-6 tablet:!mt-6 tablet:grid tablet:grid-cols-4 tablet:gap-3.5 ">
          <div className="flex h-[153px] w-full flex-col justify-between rounded-[20px] bg-[#F5F8FF] p-6 md:h-[194px] md:w-[266px] md:flex-grow md:rounded-3xl md:p-10 tablet:h-[161.61px] tablet:w-full tablet:px-6 tablet:py-6  ">
            <div className="flex w-full justify-end">
              <img
                src={Heart}
                alt="icon heart"
                width={40}
                height={40}
                className="w-[22.99px] md:w-10 tablet:h-[24.3px] tablet:w-[24.3px] "
              />
            </div>
            <p className="w-full text-start text-xs font-medium !leading-3 text-[#626466] md:w-[158px] md:max-w-[158px] md:text-base md:!leading-4 tablet:max-w-[85.66px] tablet:text-xs tablet:!leading-3">
              Assista, ouça ou leia conteúdos do seu interesse
            </p>
          </div>
          <div className="flex h-[153px] w-full flex-col justify-between rounded-[20px] bg-[#F5F8FF] p-6 md:h-[194px] md:w-[266px] md:flex-grow md:rounded-3xl md:p-10 tablet:h-[161.61px] tablet:w-full tablet:px-6 tablet:py-6  ">
            <div className="flex w-full justify-end">
              <img
                src={Play}
                alt="icon play"
                width={40}
                height={40}
                className="w-[22.99px] md:w-10 tablet:h-[24.3px] tablet:w-[24.3px]  "
              />
            </div>
            <p className="w-full text-start text-xs font-medium !leading-3 text-[#626466] md:w-[158px] md:text-base md:!leading-4 tablet:text-xs tablet:!leading-3">
              Continue de onde parou
            </p>
          </div>
          <div className="flex h-[153px] w-full flex-col justify-between rounded-[20px] bg-[#F5F8FF] p-6 md:h-[194px] md:w-[266px] md:flex-grow md:rounded-3xl md:p-10 tablet:h-[161.61px] tablet:w-full tablet:px-6 tablet:py-6  ">
            <div className="flex w-full justify-end">
              <img
                src={Award}
                alt="icon awart"
                width={40}
                height={40}
                className="w-[22.99px] md:w-10 tablet:h-[24.3px] tablet:w-[24.3px] "
              />
            </div>
            <p className="w-full text-start text-xs font-medium !leading-3 text-[#626466] md:w-[158px] md:max-w-[158px] md:text-base  md:!leading-4 tablet:max-w-[85.66px] tablet:text-xs tablet:!leading-3">
              Receba declaração de participação
            </p>
          </div>
          <div className="flex h-[153px] w-full flex-col justify-between rounded-[20px] bg-[#F5F8FF] p-6 md:h-[194px] md:w-[266px] md:flex-grow md:rounded-3xl md:p-10 tablet:h-[161.61px] tablet:w-full tablet:px-6 tablet:py-6  ">
            <div className="flex w-full justify-end">
              <img
                src={NoSave}
                alt="icon no save"
                width={40}
                height={40}
                className="w-[22.99px] md:w-10 tablet:h-[24.3px] tablet:w-[24.3px]"
              />{" "}
            </div>
            <p className="w-full text-start text-xs font-medium !leading-3 text-[#626466] md:w-[158px] md:max-w-[158px] md:text-base md:!leading-4 tablet:max-w-[85.66px] tablet:text-xs tablet:!leading-3">
              Salve seus conteúdos favoritos
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}
