import * as Icon from "../../../../assets/svgs";

export default function ProgressoTesteConhecimento({
  progressoValue,
  qtdAcertada,
  qtdPerguntas
}: {
  progressoValue: number;
  qtdAcertada: number;
  qtdPerguntas: number;
}) {
  const progressoQuestionario = [
    {
      key: 0,
      icon: <Icon.ProgressoQuestionario9 />,
      mensage: `Você acertou ${qtdAcertada} das ${qtdPerguntas} questões!`,
      feedback:
        "Legal, você fez o quiz! Continue estudando na nossa plataforma. O aprendizado é progressivo e você está no caminho certo!",
    },
    {
      key: 30,
      icon: <Icon.ProgressoQuestionario18 />,
      mensage: `Você acertou ${qtdAcertada} das ${qtdPerguntas} questões!`,
      feedback:
        "Você está se destacando e indo muito bem! Continue sua jornada de aprendizado conosco.",
    },
    {
      key: 50,
      icon: <Icon.ProgressoQuestionario24 />,
      mensage: `Você acertou ${qtdAcertada} das ${qtdPerguntas} questões!`,
      feedback:
        "Você foi Incrível! Continue estudando e prepare-se para conquistas ainda maiores.",
    },
    {
      key: 90,
      icon: <Icon.ProgressoQuestionario29 />,
      mensage: `Você acertou ${qtdAcertada} das ${qtdPerguntas} questões!`,
      feedback:
        "Fantástico, você atingiu o topo! Continue se desafiando e testando seus conhecimentos.",
    },
  ];

  const getItem = () => {
    let itemSelecionado;

    for (let i = progressoQuestionario.length - 1; i >= 0; i--) {
      if (progressoValue >= progressoQuestionario[i].key) {
        itemSelecionado = progressoQuestionario[i];
        break;
      }
    }
    return itemSelecionado
  }

  return (
    <div className="flex justify-center mt-3 flex-col items-center">
      {getItem()?.icon}
      <span className="mt-3 text-[#004F92] text-xxl font-semibold leading-8">
        {
          getItem()
            ?.mensage
        }
      </span>
      <span className="mt-3 text-center font-normal text-sm leading-normal max-w-[507px] text-[#111316]">
        {
          getItem()
            ?.feedback
        }
      </span>
    </div>
  );
}
