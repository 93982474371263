import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import queryString from "query-string";

export interface Banner {
  id: number;
  linkFuga: string;
  bannerOrder: number;
  field: string;
  url: string;
  areaPagina: string;
  ocultarLogado: boolean;
  tipoPagina: string;
  nomePatrocinador: string;
}

export interface BannerResponse {
  data: {
    banners: Banner[];
  };
}

export interface BannerRequestModelInput {
  homePage: boolean;
  areaDeConhecimentoId?: number[];
  objetoEducacionalId?: number;
  patrocinadorId?: number;
  publicoAlvoId?: number[];
  subAreaDeConhecimentoId?: number[];
}

export const loadBannerPreparaEinstein = (
  filter: BannerRequestModelInput
): Promise<Banner[]> => {
  const query = queryString.stringify(filter);
  return new Promise((resolver, reject) => {
    fetchApi(`banners/prepara-einstein?${query}`, "GET")
      .then((resultado: any) => {
        resultado.json().then((data: any) => {
          resolver(data);
        });
      })
      .catch(() => reject());
  });
};

export const loadBanner = (
  filter: BannerRequestModelInput
): Promise<Banner[]> => {
  const query = queryString.stringify(filter);
  return new Promise((resolver, reject) => {
    fetchApi(`banners/get-all?${query}`, "GET")
      .then((resultado: any) => {
        resultado.json().then((data: any) => {
          resolver(data);
        });
      })
      .catch(() => reject());
  });
};
