import { CURTIR_TYPE } from "../../shared/constants/action-types";

export interface CurtirPayload {
  closeModalFeedback?: boolean;
  toggleFeedback?: boolean;
}

export interface CurtirState {
  closeModalFeedback?: boolean;
  toggleFeedback?: boolean;
}

export interface CurtirAction {
  type: CURTIR_TYPE;
  payload: CurtirPayload;
}

const INIT_STATE: CurtirState = {
  closeModalFeedback: true,
  toggleFeedback: false,
};

const curtirReducer = (
  state: CurtirState = INIT_STATE,
  action: CurtirAction
) => {
  switch (action.type) {
    case CURTIR_TYPE.TOGGLE_CURTIR:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default curtirReducer;
