import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

export interface IItemSelectField {
  label: string;
  value: any;
}

interface SelectFieldProps {
  name: string;
  value: string | number | readonly string[] | undefined;
  items: IItemSelectField[];
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  size?: "sm" | "md" | "lg"; // Default md
  color?: "white" | "dark" | "gray" | undefined;
}

export const SelectField: React.FC<SelectFieldProps> = (props) => {
  const { name, value, items, handleChange, label = "1. O que é?", color, size = "md" } = props;
  return (
    <>
      <label htmlFor={name} className={styles.selectLabel}>
        {label}
      </label>
      <select
        multiple={false}
        name={name}
        className={cx(styles.selectField, {
          [styles.small]: size === "sm",
          [styles.large]: size === "md",
          [styles.large]: size === "lg",
          [styles.colorWhite]: color === "white",
          [styles.colorGray]: color === "gray",
          [styles.colorDark]: color === "dark",
          [styles.dropdownIndicator]: color === "dark",
        })}
        onChange={handleChange}
        value={value}
      >
        {items &&
          items.length &&
          items.map((item, index: number) => (
            <option key={index} value={item?.value || ''}>
              {item?.label || ''}
            </option>
          ))}
      </select>
    </>
  );
};
