import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.scss";

import videoIcon from "../../../../assets/svgs/user-history-video-gray.svg";
import audioIcon from "../../../../assets/svgs/user-history-audio-gray.svg";
import quizIcon from "../../../../assets/svgs/user-history-quiz-gray.svg";
import textIcon from "../../../../assets/svgs/user-history-text-gray.svg";

import { CardContainer } from "../../../../shared/components/CardContainer";
import { UserContext } from "../../../../context/UserContext";
import useResumeOe from "../../../../hooks/useResumeOe";

import { AreasDeInteresse } from "../../../_HomePage/components";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import Container from "../../../../shared/components/Container";

type TProps = { children?: ReactNode } & {
  path: any;
};

const ViewHistoryNav: React.FC<TProps> = (props) => {
  const [avatar, setAvatar] = useState("");
  const { user } = useContext(UserContext);
  const history = useHistory();

  const { data: resume, knowlegdesArea, gerarHistorico } = useResumeOe();

  const gtm = useGtm();

  useEffect(() => {
    if (!user) {
      return;
    }

    setAvatar(user.attributes?.Avatar?.data?.attributes?.url || "");
    gerarHistorico(user.id!);
  }, [user]);

  const nav = [
    {
      name: "Vídeos",
      count: resume?.videos || 0,
      href: "/meu-perfil/historico/videos",
      icon: videoIcon,
    },
    {
      name: "Áudios",
      count: resume?.audio || 0,
      href: "/meu-perfil/historico/audios",
      icon: audioIcon,
    },
    {
      name: "Quizzes e Desafios",
      count: resume?.quizes || 0,
      href: "/meu-perfil/historico/quizzes-e-desafios",
      icon: quizIcon,
    },
    {
      name: "Textos e Artigos",
      count: resume?.textos || 0,
      href: "/meu-perfil/historico/textos-e-artigos",
      icon: textIcon,
    },
  ];

  function handleMeusAutores() {
    if (user && user.attributes?.Seguindo?.data?.length! > 0) {
      history.push("/meu-perfil/autores");
    } else {
      return;
    }

    gtm.push(
      EDataLayerEventCategory.ADE_MEU_PERFIL,
      EDataLayerEventAction.CLIQUE,
      {
        label: "seguindo",
      },
    );
  }

  return (
    <>
      {user && user && (
        <section data-view-history>
          <Container className="py-7">
            <CardContainer shadow>
              <section data-user>
                <Link data-avatar to="/meu-perfil">
                  <div style={{ backgroundImage: `url(${avatar})` }}></div>
                </Link>
                <div data-user-name>
                  Olá, {user.attributes?.nome_completo?.split(" ")[0]}!
                </div>
                <Link data-edit-profile to="/meu-perfil/informacoes-pessoais">
                  Editar Perfil
                </Link>
                <div data-user-totals>
                  <Link to="/favoritos">
                    <span>Meus Favoritos</span>
                    <strong>
                      {user?.attributes?.OesFavoritados?.data?.length}
                    </strong>
                  </Link>

                  <a
                    onClick={() => handleMeusAutores()}
                    className="cursor-pointer"
                  >
                    <span>Seguindo</span>
                    <strong>{user.attributes?.Seguindo?.data?.length}</strong>
                  </a>
                </div>
              </section>

              <section data-history>
                <h4>Histórico de visualização</h4>
                <div>
                  {nav.map((item, idx) => (
                    <Link
                      to={item.href}
                      key={idx}
                      className={item.href === props.path ? "active" : ""}
                    >
                      <div>
                        <img alt={item.name} src={item.icon} />
                        <span>{item.name}</span>
                        <strong>{item.count}</strong>
                      </div>
                    </Link>
                  ))}
                </div>
              </section>

              <section className="w-full md:w-[403px]">
                <h4>
                  Áreas de interesse
                  <br /> mais acessadas
                </h4>
                <div className="py-4 md:px-3">
                  <AreasDeInteresse
                    perfilUser={true}
                    areas={knowlegdesArea.map((a) => ({
                      icone: {
                        url: a.attributes?.icone?.data?.attributes?.url!,
                      },
                      titulo: a.attributes?.titulo!,
                      id: a.id!,
                    }))}
                  />
                </div>
              </section>
            </CardContainer>
          </Container>
        </section>
      )}
    </>
  );
};

export default ViewHistoryNav;
