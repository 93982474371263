import { ComponentProps } from "react";
import { tv, VariantProps } from "tailwind-variants";

const div = tv({
  base: "overflow-hidden relative space-y-5 rounded-xl bg-[#ffffff7c] drop-shadow-xl shadow-md",
  variants: {
    size: {
      pathways: "h-[83px]",
      podcast: "h-[83px] ",
      webcast: "h-[253px]",
      card: "h-[253px]",
      destaque: "md:h-[364px] h-[280px]",
    },
  },
  defaultVariants: {
    size: "card",
  },
});

export type LoadingCardProps = ComponentProps<"div"> & VariantProps<typeof div>;

export default function LoadingCard({ size, ...props }: LoadingCardProps) {
  return (
    <div className={div({ size })} {...props}>
      <div className="w-full h-full rounded-xl  -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent via-gray-700/20 to-transparent"></div>
    </div>
  );
}
