import React, { ReactNode } from "react";

import cx from "classnames";

import "./styles.scss";

interface GridProps {
  cols: number;
  gap?: 1 | 2 | 3 | 4 | 5 | undefined;
  padding?: number;
  children?: ReactNode;
}

export const Grid: React.FC<GridProps> = (props) => {
  const { cols, gap, children } = props;
  const clsCol = `grid-template-col-${cols}`;
  const clsGap = `gap-${gap}`;
  return (
    <div
      className={cx(`grid-container`, {
        [clsCol]: cols > 0,
        [clsGap]: gap && gap <= 5,
      })}
    >
      {children}
    </div>
  );
};
