import { useDispatch } from "react-redux";
import { getDayOfYear } from "date-fns";

import { HEADER_ACTION_TYPE } from "../shared/constants/action-types";
import { INVITATION_REGISTER, TOKEN_SECRECT } from "../shared/constants/auth-types";

import { useLocation } from 'react-router-dom';

import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../shared/services/localStorage.service";

export const useConviteCadastro = () => {
  const dispatch = useDispatch();

  const mostrar = () => {
    const localValue = getLocalStorageValue(TOKEN_SECRECT);
    const userContext = localValue ? JSON.parse(localValue) : null;

    if (!!userContext) {
      return;
    }

    if (
      !!window.location.href.includes("novo-usuario") ||
      !!window.location.href.includes("trocar-senha")
    ) {
      return;
    }

    if (!sessaoExpirado()) {
      return;
    }

    mostrarModal();
  };

  const location = useLocation();
  const mostrarModal = () => {
    
    if(location.pathname.includes('/oe/')) {
      return
    }

    dispatch({
      type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
      payload: true,
    });
  };

  const persistirCache = () => {
    setLocalStorageValue(
      INVITATION_REGISTER,
      JSON.stringify({
        createdAt: new Date(),
      })
    );
  };

  const sessaoExpirado = () => {
    const dadosConviteCadastro = getLocalStorageValue(INVITATION_REGISTER);
    const dadosConviteCadastroObj = dadosConviteCadastro
      ? JSON.parse(dadosConviteCadastro)
      : {};

    if (!dadosConviteCadastroObj.createdAt) {
      return true;
    }

    const dataAtual: Date = new Date();
    const dataConviteCadastro: Date = new Date(
      dadosConviteCadastroObj.createdAt
    );

    const dataAtualMaiorQueDataConviteCadastro =
      getDayOfYear(dataConviteCadastro) < getDayOfYear(dataAtual);

    return dataAtualMaiorQueDataConviteCadastro;
  };

  return { mostrar, persistirCache };
};
