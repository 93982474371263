import { useState, useEffect } from "react";

import {
  AreaConhecimento,
  AreaDeConhecimentoEntity,
  UsersPermissionsUserEntity,
} from "../generated/graphql";
export interface AreaConhecimentoWithSelected extends AreaConhecimento {
  selected: boolean;
  display: "block" | "none";
}

const useSelectPreference = () => {

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ROOT}/api/area-de-conhecimentos/v2`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    }).then(async (res) => {
      const _ = (await res.json()).results as AreaConhecimento[];
      setList(
        _.map((item) => {
          return { ...item, display: "block", selected: false } as AreaConhecimentoWithSelected;
        })
      );
      setLoading(false);
    });
  }, []);

  const [loading, setLoading] = useState(true);

  const [list, setList] = useState<AreaConhecimentoWithSelected[]>([]);

  // Seleciona a area de conhecimento baseado no id e retorna
  // a lista das areas de conhecimento que estejam
  // selecionadas.
  const selectPreference = (id: string, formData: any): number[] => {
    // Recebe a lista de areas de conhecimento do formulário
    const area_de_conhecimentos =
      formData!.attributes?.area_de_conhecimentos?.data || [];
    const objAreasSelected: any = {};

    // Cria um objeto com a partir dos ids de areas de conhecimento
    for (const item of area_de_conhecimentos) {
      const itemCloned = typeof item === "object" ? item.id!.toString() : item.toString();

      objAreasSelected[itemCloned] = true;
    }

    
    const listCloned = list.map((a) => ({
      ...a,
      selected: a.id?.toString() === id.toString() ? (a.selected ? false : (list.filter(a => a.selected).length < 5 ? true : false)) : a.selected
    }));

    setList(listCloned);

    const _listSelected = listCloned.filter(a => a.selected).map(a =>  parseInt(a.id!));

    return _listSelected;
  };

  const updateAreasWithUser = (user: UsersPermissionsUserEntity) => {
    if (!!user && !!user.attributes?.area_de_conhecimentos) {
      const newList = list.reduce<AreaConhecimentoWithSelected[]>(
        (acc, item) => {
          const itemCloned: AreaConhecimentoWithSelected = { ...item };

          itemCloned.selected = false;
          if (
            user.attributes?.area_de_conhecimentos?.data.some(
              (areaUser: any) =>
                item!.id!.toString() === (typeof areaUser === "object" ? areaUser.id.toString() : areaUser.toString())
            )
          ) {
            itemCloned.selected = true;
          }

          acc.push(itemCloned);

          return acc;
        },
        []
      );

      setList([...newList]);
    }
  };

  return { list, setList, loadingAreas: loading, selectPreference, updateAreasWithUser };
};

export default useSelectPreference;
