import { ModalService } from "../shared/components/Modal";
import ModalDegustacaoPos from "../shared/components/ModalDegustacaoPos";

export const useModalDegustacaoPos = () => {
  const mostrar = () => {
    ModalService.show({
      content: <ModalDegustacaoPos />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
    });
  };

  return { mostrar };
};
