import React, { useEffect, useState } from "react";

interface ErrorValidator {
  message: string;
  valid: boolean;
}

const useAreasSelectedValidator = () => {
  const [areas, setAreas] = useState<number[]>([]);
  const [errors, setErrors] = useState<ErrorValidator>({
    message: "",
    valid: false,
  });

  useEffect(() => {
    if (!areas || areas.length === 0) {
      setErrors({
        message: "Por favor, selecione pelo menos uma área de interesse.",
        valid: true,
      });
    } else {
      setErrors({
        message: "",
        valid: false,
      });
    }
  }, [areas]);

  const render = () => {
    return (
      errors.valid && (
        <div className="text-sm font-bold text-red-600 pb-2">
          {errors.message}
        </div>
      )
    );
  };
  return { render, setAreas, errors };
};

export default useAreasSelectedValidator;
