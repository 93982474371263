import { useState, useEffect } from "react";
import { format } from "date-fns";
import { BannerType } from "../shared/components/BannerSwiper";
import { getUserContext } from "../context/UserContext";

import { BannerEntity, BannerEntityResponse } from "../generated/graphql";

import qs from "qs";
interface BannerProps extends BannerEntity {
  sort?: any;
}

export const useBannerApi = () => {
  const [lista, setLista] = useState<BannerProps[]>([]);
  const user = getUserContext();

  const orderBanners = (banners: BannerProps) => {
    const sortBanners = banners.sort(
      (a: BannerProps, b: BannerProps) =>
        a.attributes?.Ordenacao! - b.attributes?.Ordenacao!
    );

    setLista(sortBanners);
  };

  useEffect(() => {
    const data = format(new Date(), "yyyy-MM-dd");
    let objFilter: any = {
      filters: {
        $and: [
          {
            PeriodoPermanenciaFinal: {
              $gte: data,
            },
          },
          {
            PeriodoPermanenciaInicial: {
              $lte: data,
            },
          },
          {
            publishedAt: {
              $notNull: true,
            },
          },
        ],
      },
    };
    if (!!user) {
      objFilter.filters.$and.push({
        $or: [
          {
            OcultarLogado: {
              $eq: false,
            },
          },
          {
            OcultarLogado: {
              $null: true,
            },
          },
        ],
      });
    }
    const query = qs.stringify(objFilter, {
      encodeValuesOnly: true, // prettify URL
    });

    fetch(`${process.env.REACT_APP_ROOT}/api/banners?populate=*&${query}`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then((resposta) =>
        resposta
          .json()
          .then((dados: BannerEntityResponse) => orderBanners(dados.data!))
      )
      .catch(() => {
        setLista([]);
      });
  }, []);

  const getPorAreaPagina = (
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ): BannerType[] | [] => {
    const resultado =
      !!lista && !!Array.isArray(lista)
        ? lista.filter(
            (item) =>
              !item.attributes?.ObjetosEducacionais?.data?.length &&
              !item.attributes?.publico_alvos?.data?.length &&
              !item.attributes?.AreasConhecimento?.data.length &&
              !item.attributes?.sub_area_conhecimentos?.data.length &&
              item.attributes?.TiposAreaPagina?.data &&
              item.attributes?.TiposAreaPagina.data?.some(
                (tipo) => tipo && tipo.attributes?.Nome === areaPagina
              ) &&
              item.attributes?.TiposPaginas?.data &&
              item.attributes?.TiposPaginas.data?.some(
                (tipo) => tipo && tipo.attributes?.Nome === pagina
              )
          )
        : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  const getBannerPorAreaConhecimento = (
    areaConhecimento: string,
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ) => {
    const resultado = lista
      ? lista.filter((item) => {
          return (
            item.attributes?.TiposAreaPagina &&
            item.attributes?.TiposAreaPagina.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === areaPagina
            ) &&
            item.attributes?.TiposPaginas?.data &&
            item.attributes?.TiposPaginas.data.some(
              (tipo) => tipo && tipo.attributes?.Nome === pagina
            ) &&
            item.attributes?.AreasConhecimento?.data &&
            item.attributes?.AreasConhecimento.data?.some(
              (item) =>
                item.attributes?.titulo?.toString().toLocaleLowerCase() ===
                areaConhecimento.toString().toLocaleLowerCase()
            )
          );
        })
      : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  const getBannerPorSubAreaConhecimento = (
    subAreaConhecimento: string[],
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ) => {
    const resultado = lista
      ? lista.filter(
          (item) =>
            item.attributes?.TiposAreaPagina &&
            item.attributes?.TiposAreaPagina.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === areaPagina
            ) &&
            item.attributes?.TiposPaginas?.data &&
            item.attributes?.TiposPaginas.data.some(
              (tipo) => tipo && tipo.attributes?.Nome === pagina
            ) &&
            item.attributes?.sub_area_conhecimentos?.data &&
            item.attributes?.sub_area_conhecimentos.data?.some((item) =>
              subAreaConhecimento
                .map((a) => a.toLocaleLowerCase())
                .includes(
                  item.attributes?.Nome?.toLocaleLowerCase().toString() || ""
                )
            )
        )
      : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  const getBannerPorAreasConhecimentos = (
    areaConhecimento: string[],
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ) => {
    const resultado = lista
      ? lista.filter(
          (item) =>
            item.attributes?.TiposAreaPagina &&
            item.attributes?.TiposAreaPagina.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === areaPagina
            ) &&
            item.attributes?.TiposPaginas?.data &&
            item.attributes?.TiposPaginas.data.some(
              (tipo) => tipo && tipo.attributes?.Nome === pagina
            ) &&
            item.attributes?.AreasConhecimento?.data &&
            item.attributes?.AreasConhecimento.data?.some((item) =>
              areaConhecimento.includes(
                item.attributes?.titulo.toString() || ""
              )
            )
        )
      : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  const getBannerPorObjetoEducacional = (
    objetoEducacional: string,
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ) => {
    let resultado = lista
      ? lista.filter(
          (item) =>
            item.attributes?.TiposAreaPagina?.data &&
            item.attributes?.TiposAreaPagina.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === areaPagina
            ) &&
            item.attributes?.TiposPaginas &&
            item.attributes?.TiposPaginas.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === pagina
            ) &&
            item.attributes?.ObjetosEducacionais?.data &&
            item.attributes?.ObjetosEducacionais.data.some(
              (oe) => oe.id!.toString() === objetoEducacional.toString()
            )
        )
      : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  const getBannerPorPublicoAlvo = (
    publicoAlvo: string[],
    pagina: TIPO_PAGINA,
    areaPagina: TIPO_AREA_PAGINA
  ) => {
    let resultado = lista
      ? lista.filter(
          (item) =>
            item.attributes?.TiposAreaPagina?.data &&
            item.attributes?.TiposAreaPagina.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === areaPagina
            ) &&
            item.attributes?.TiposPaginas &&
            item.attributes?.TiposPaginas.data?.some(
              (tipo) => tipo && tipo.attributes?.Nome === pagina
            ) &&
            item.attributes?.publico_alvos?.data &&
            item.attributes?.publico_alvos.data.some((publico) =>
              publicoAlvo.includes(publico.attributes?.titulo!)
            )
        )
      : undefined;

    return resultado
      ? resultado.map((item) => {
          const bannerType: BannerType = {
            ...item,
            TipoPagina: pagina,
            AreaPagina: areaPagina,
          };

          return bannerType;
        })
      : [];
  };

  return {
    getPorAreaPagina,
    getBannerPorAreaConhecimento,
    getBannerPorObjetoEducacional,
    getBannerPorAreasConhecimentos,
    getBannerPorSubAreaConhecimento,
    getBannerPorPublicoAlvo,
  };
};

export enum TIPO_PAGINA {
  HOME = "Home",
  AREA_INTERESSE = "Área de Interesse",
  SUBAREA_INTERESSE = "Sub área de Interesse",
  OE = "OE",
  RESIDENCIA_MEDICA_HOME = "Residência Médica Home",
  RESIDENCIA_MEDICA_MODULOS = "Residência Médica Módulos",
}

export enum TIPO_AREA_PAGINA {
  ABAIXO_CABECALHO = "Abaixo do Cabeçalho",
  ACIMA_RODAPE = "Acima do Rodapé",
  CENTRO = "Centro",
  VERTICAL = "Vertical",
  FIXO = "Fixo",
}
