import { gql } from '@apollo/client';
import { QueryClient } from '../..';
import { GraphqlHeaderResponse } from '../../../shared/interfaces/header.interface';

export const HEADER_QUERY = gql`
query {
  headerPortal {
    data {
      id
      attributes {
        logo {
          data {
            attributes {
              url
            }
          }
				}
    		item_menus {
          data {
            id
            attributes {
              nome
              ordem
              rota {
                data {
                  attributes {
                    path
                  }
                }
              }
            }
          }
				}
      }
    }
	}
}
`;

export const GetHeaderData = () => {
  return QueryClient<GraphqlHeaderResponse>(HEADER_QUERY);
}