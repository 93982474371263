import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import cx from "classnames";

import "./styles.scss";
import {
  Profissao,
  ProfissaoEntity,
  useGetEspecialidadesLazyQuery,
  useGetProfissoesLazyQuery,
  useUpdateUserMutation,
} from "../../../../../../generated/graphql";
import {
  getUserWithJwtContext,
  IUserContext,
} from "../../../../../../context/UserContext";
import { from } from "rxjs";
import { take } from "rxjs/operators";
import ResetUserContext from "../../../../../../hooks/ResetUserContext";
import { Button } from "../../../../../../shared/components/ui/button";
import { CircleCheckBig } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";

const _UF = [
  { AC: "Acre" },
  { AL: "Alagoas" },
  { AP: "Amapá" },
  { AM: "Amazonas" },
  { BA: "Bahia" },
  { CE: "Ceará" },
  { DF: "Distrito Federal" },
  { ES: "Espírito Santo" },
  { GO: "Goías" },
  { MA: "Maranhão" },
  { MT: "Mato Grosso" },
  { MS: "Mato Grosso do Sul" },
  { MG: "Minas Gerais" },
  { PA: "Pará" },
  { PB: "Paraíba" },
  { PR: "Paraná" },
  { PE: "Pernambuco" },
  { PI: "Piauí" },
  { RJ: "Rio de Janeiro" },
  { RN: "Rio Grande do Norte" },
  { RS: "Rio Grande do Sul" },
  { RO: "Rondônia" },
  { RR: "Roraíma" },
  { SC: "Santa Catarina" },
  { SP: "São Paulo" },
  { SE: "Sergipe" },
  { TO: "Tocantins" },
];

type ProfessionalItem = {
  id?: string | undefined;
  titulo?: string | undefined;
};

const styles = {
  root: {
    "& .MuiInputLabel-root": {
      fontSize: "12px",
      fontWeight: "600",
      color: "#0A589B",
    },
    "& .MuiInput-underline:hover:before": {
      borderColor: "#0A589B",
    },
    "& .MuiInput-underline:before": {
      borderColor: "rgba(112, 112, 112, .3)",
    },
    "& .MuiInput-underline:after": {
      borderColor: "#0A589B",
    },
    "& .MuiInputBase-input": {
      color: "#69737C",
      fontFamily: "Open Sans",
      fontSize: "14px",
    },
  },
};

const MyTextField = withStyles((theme) => ({
  ...styles,
}))(TextField);

export const MyFormControl = withStyles((theme) => ({
  ...styles,
}))(FormControl);

const ProfessionalData: React.FC<{ user: any }> = ({ user }) => {
  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);

  const [perfilProfissional, setPerfilProfissional] = useState("");
  const [perfilEspecialidade, setPerfilEspecialidade] = useState("");
  const [perfilEstudante, setPerfilEstudante] = useState("");
  const [perfilOutraProfissao, setPerfilOutraProfissao] = useState("");
  const [perfilTecnico, setPerfilTecnico] = useState("");
  const [perfilCRM, setPerfilCRM] = useState("");
  const [perfilUF, setPerfilUF] = useState("");

  const [isEstudante, setIsEstudante] = useState(false);
  const [isTecnicoAux, setIsTecnicoAux] = useState(false);
  const [isMedico, setIsMedico] = useState(false);
  const [isOutraProfissao, setIsOutraProfissao] = useState(false);

  const [estudanteList, setEstudanteList] = useState<ProfissaoEntity[]>([]);
  const [tecnicoAuxList, setTecnicoAuxList] = useState<ProfissaoEntity[]>([]);

  const [profissoes, setProfissoes] = useState<ProfissaoEntity[]>([]);

  const [updateUser] = useUpdateUserMutation();

  const { resetUserContext } = ResetUserContext();

  const [GetProfissoes] = useGetProfissoesLazyQuery({
    onCompleted: (data) => {
      if (!!data) {
        const _data = data.profissaos?.data as ProfissaoEntity[];
        setProfissoes(_data);

        // Encontra o ID de cada tipo Estudante / Tecnico Auxiliar
        const studentID = _data.find(
          (item) =>
            item.attributes?.ehEstudante === true &&
            !item.attributes?.profissaoPai?.data,
        );
        const tecnicoAuxID = _data.find(
          (item) =>
            item.attributes?.ehTecnicoAuxiliar === true &&
            !item.attributes?.profissaoPai?.data,
        );

        // Cria a combo list para estudantes
        if (studentID) {
          setEstudanteList(
            _data.filter(
              (item) =>
                item.attributes?.profissaoPai?.data?.id!.toString() ===
                studentID.id,
            ) as [],
          );
        }

        // Cria a combo list para tecnico auxiliar
        if (tecnicoAuxID) {
          setTecnicoAuxList(
            _data.filter(
              (item) =>
                item.attributes?.profissaoPai?.data?.id!.toString() ===
                tecnicoAuxID.id,
            ) as [],
          );
        }
      }
    },
  });

  const handleInputChange = (event: any) => {
    let { name, value } = event.target;

    if (name === "PerfilProfissao") {
      const selected = profissoes?.filter((item) => item.id === value).shift();

      setIsEstudante(!!selected?.attributes?.ehEstudante);
      setIsTecnicoAux(!!selected?.attributes?.ehTecnicoAuxiliar);
      setIsMedico(selected?.attributes?.PossuiCRM as boolean);

      setPerfilProfissional(value);

      setIsOutraProfissao(
        selected?.attributes?.titulo?.toLowerCase().indexOf("outro") !== -1,
      );
      return;
    }

    if (name === "perfilEstudante") {
      const profissao = profissoes?.filter((item) => item.id === value).shift();
      setPerfilEstudante(value);
      setIsEstudante(true);
      setIsTecnicoAux(false);
      setIsMedico(false);
      setIsOutraProfissao(
        profissao?.attributes?.titulo
          ?.toLowerCase()
          .includes("outro") as boolean,
      );
      return;
    }

    if (name === "perfilTecnico") {
      setPerfilTecnico(value);
      setIsEstudante(false);
      setIsTecnicoAux(true);
      setIsMedico(false);
      return;
    }

    if (name === "CRM") {
      setPerfilCRM(value);
      return;
    }

    if (name === "UF") {
      setPerfilUF(value);
      return;
    }

    if (name === "perfilOutraProfissao") {
      setPerfilOutraProfissao(value);
      return;
    }
  };

  const showUpdateSucceedModal = () => {
    toast.success(
      <div className="flex flex-row gap-3 mx-2 items-center">
        <CircleCheckBig className="h-5 w-5" />
        <div className="flex flex-col">
          <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Atualização feita com sucesso!</div>
        </div>
      </div>, {
      icon: false,
      closeButton: false,
      style: {
        backgroundColor: '#21AB27',
        color: 'white',
        borderRadius: '35px',
      }
    });
  };

  const idByFieldPerfil = {
    tecnicoOuAuxilar: "11",
    outraProfissao: "13",
    estudanteGraduacaoOutroCurso: "16",
  };

  /**
   * Atualiza as preferências de conteúdo do usuário
   */
  const handleUpdateUser = (e: any) => {
    e.preventDefault();
    let data: any = {};

    // Default perfil profissão para todos os tipos
    data.PerfilProfissao = perfilProfissional;
    data.OutraProfissaoFilha = perfilOutraProfissao;
    data.especialidade = perfilEspecialidade;

    if (
      perfilProfissional === idByFieldPerfil.tecnicoOuAuxilar &&
      !perfilTecnico
    ) {
      return;
    }

    if (
      perfilProfissional === idByFieldPerfil.outraProfissao &&
      !perfilOutraProfissao
    ) {
      return;
    }

    if (isMedico) {
      data.CRM = perfilCRM;
      data.UF = perfilUF;

      if (!perfilCRM || !perfilUF) {
        return;
      }
    } else {
      data.CRM = "";
      data.UF = "";
    }

    if (isEstudante) {
      data.PerfilProfissao = perfilEstudante;
      // Determina se OutraProfissaoFilha será preenchida,
      // somente se o valor selecionado no select Indique seu Curso
      // contém o titulo Outro
      data.OutraProfissaoFilha =
        perfilEstudante &&
        estudanteList
          .find((i) => i.id === perfilEstudante)
          ?.attributes?.titulo?.toLowerCase()
          .indexOf("outro") !== -1
          ? perfilOutraProfissao
          : "";

      if (isEstudante && !perfilEstudante) {
        return;
      }

      if (
        isEstudante &&
        perfilEstudante === idByFieldPerfil.estudanteGraduacaoOutroCurso
      ) {
        if (!perfilOutraProfissao) {
          return;
        }
      }
    }

    if (isTecnicoAux) {
      data.PerfilProfissao = perfilTecnico;
    }

    if (!user) return;



    from(
      updateUser({
        variables: {
          id: user.id!,
          input: {
            ...data,
            OutraProfissaoFilha: data.OutraProfissaoFilha ? data.OutraProfissaoFilha : undefined,
            especialidade: data.especialidade ? data.especialidade : undefined
          },
        },
      }),
    )
      .pipe(take(1))
      .subscribe(() => {
        resetUserContext();
        showUpdateSucceedModal();
      });
    return false;
  };

  useEffect(() => {
    GetProfissoes();
  }, [GetProfissoes]);

  const [GetEspecialidade, { data: especialidades }] =
    useGetEspecialidadesLazyQuery({
      variables: {
        filtros: {
          profissaos: {
            id: {
              eq: perfilProfissional || "0",
            },
          },
        },
      },
    });

  useEffect(() => {
    GetEspecialidade();
  }, [GetEspecialidade]);

  useEffect(() => {
    GetEspecialidade();
  }, [perfilProfissional]);

  useEffect(() => {
    if (!!user && user.attributes?.PerfilProfissao?.data?.length) {
      const _userProfissao = user.attributes?.PerfilProfissao.data[0];
      const { profissaoPai } = _userProfissao.attributes!;

      setPerfilEspecialidade(user.attributes.especialidade?.data?.id || "");
      // Definir o Perfil Profissional de acordo com profissaoPai
      if (profissaoPai?.data?.id) {
        setPerfilProfissional(profissaoPai.data?.id!);
        // Definir a sub area select
        setIsEstudante(profissaoPai.data?.attributes?.ehEstudante as boolean);
        setIsTecnicoAux(
          profissaoPai.data?.attributes?.ehTecnicoAuxiliar as boolean,
        );
        setIsMedico(_userProfissao.attributes?.PossuiCRM as boolean);

        if (profissaoPai.data?.attributes?.ehEstudante) {
          setPerfilEstudante(_userProfissao.id!);
          setPerfilOutraProfissao(
            user.attributes?.OutraProfissaoFilha
              ? user.attributes?.OutraProfissaoFilha
              : "",
          );
          setIsEstudante(true);
          setIsOutraProfissao(
            _userProfissao?.attributes?.titulo
              ?.toLowerCase()
              .includes("outro") as boolean,
          );
        }

        if (profissaoPai.data?.attributes?.ehTecnicoAuxiliar) {
          setPerfilTecnico(_userProfissao.id! || "");
          setIsTecnicoAux(true);
        }
      } else {
        setPerfilProfissional(_userProfissao.id!);
        setIsMedico(_userProfissao.attributes?.PossuiCRM as boolean);
        setPerfilCRM(user.attributes?.CRM as string);
        setPerfilUF(user.attributes?.UF as string);
        setPerfilOutraProfissao(user.attributes?.OutraProfissaoFilha as string);
        setIsOutraProfissao(
          _userProfissao?.attributes?.titulo?.toLowerCase().indexOf("outro") !==
            -1,
        );
      }
    }
  }, [user]);

  return (
    <section data-professional-data>
      <ToastContainer />
      <form
        autoComplete="off"
        name="personal-data-form"
        onSubmit={handleUpdateUser}
      >
        <fieldset>
          <MyFormControl>
            <InputLabel>FORMAÇÃO ACADÊMICA</InputLabel>
            <Select
              id="PerfilProfissao"
              name="PerfilProfissao"
              value={perfilProfissional}
              onChange={handleInputChange}
            >
              {profissoes
                .filter((item) => !item.attributes?.profissaoPai?.data?.id)
                .map((item) => (
                  <MenuItem key={item?.id} value={item.id as string}>
                    {item?.attributes?.titulo}
                  </MenuItem>
                ))}
            </Select>
          </MyFormControl>

          {especialidades?.especialidades?.data &&
            especialidades?.especialidades?.data?.length > 0 && (
              <MyFormControl>
                <InputLabel>ESPECIALIDADE</InputLabel>
                <Select
                  id="Especialidade"
                  name="Especialidade"
                  value={perfilEspecialidade}
                  onChange={(ev: any) => {
                    ev.stopPropagation();

                    setPerfilEspecialidade(ev.target.value);
                  }}
                >
                  {especialidades.especialidades?.data &&
                    especialidades.especialidades?.data
                      ?.filter((a) => !a.attributes?.ultima)
                      .sort(
                        (a, b) =>
                          a.attributes?.nome
                            ?.toLocaleLowerCase()
                            .localeCompare(
                              b?.attributes?.nome?.toLocaleLowerCase()!,
                              "pt-BR",
                              { sensitivity: "base" },
                            )!,
                      )
                      .map((item, idx) => (
                        <MenuItem key={idx} value={item.id}>
                          {item.attributes?.nome}
                        </MenuItem>
                      ))}
                  {especialidades.especialidades?.data &&
                    especialidades.especialidades?.data
                      ?.filter((a) => !!a.attributes?.ultima)
                      .map((item, idx) => (
                        <MenuItem key={idx} value={item.id}>
                          {item.attributes?.nome}
                        </MenuItem>
                      ))}
                </Select>
              </MyFormControl>
            )}

          <MyFormControl>
            <MyTextField
              name="CRM"
              label="CRM"
              style={{ display: isMedico ? "flex" : "none" }}
              value={perfilCRM}
              onChange={handleInputChange}
              error={isMedico && !perfilCRM && showAllErrorsMessages}
            />

            {isMedico && !perfilCRM && showAllErrorsMessages && (
              <span className="MuiFormHelperText-root Mui-error">
                Campo obrigatório
              </span>
            )}
          </MyFormControl>

          <MyFormControl
            style={{ display: isMedico ? "flex" : "none" }}
            error={isMedico && !perfilUF && showAllErrorsMessages}
          >
            <InputLabel>UF</InputLabel>
            <Select name="UF" value={perfilUF} onChange={handleInputChange}>
              {_UF.map((item, idx) => (
                <MenuItem key={idx} value={Object.keys(item)[0]}>
                  {item[Object.keys(item)[0] as "AC"]}
                </MenuItem>
              ))}
            </Select>

            {isMedico && !perfilUF && showAllErrorsMessages && (
              <span className="MuiFormHelperText-root Mui-error">
                Campo obrigatório
              </span>
            )}
          </MyFormControl>
        </fieldset>

        <fieldset className={cx({ "d-none": !isEstudante })}>
          <MyFormControl
            error={isEstudante && !perfilEstudante && showAllErrorsMessages}
          >
            <InputLabel>SE VOCÊ É ESTUDANTE, INDIQUE SEU CURSO:</InputLabel>
            <Select
              id="perfilEstudante"
              name="perfilEstudante"
              value={perfilEstudante || ""}
              onChange={handleInputChange}
            >
              {estudanteList.map((item) => (
                <MenuItem key={item?.id} value={item.id as string}>
                  {item?.attributes?.titulo!}
                </MenuItem>
              ))}
            </Select>

            {isEstudante && !perfilEstudante && showAllErrorsMessages && (
              <span className="MuiFormHelperText-root Mui-error">
                Campo obrigatório
              </span>
            )}
          </MyFormControl>
        </fieldset>

        <fieldset className={cx({ "d-none": !isOutraProfissao })}>
          <MyFormControl>
            <MyTextField
              name="perfilOutraProfissao"
              label="QUAL?"
              value={perfilOutraProfissao || ""}
              onChange={handleInputChange}
              error={
                (perfilProfissional === idByFieldPerfil.outraProfissao ||
                  (isEstudante &&
                    perfilEstudante ===
                      idByFieldPerfil.estudanteGraduacaoOutroCurso)) &&
                !perfilOutraProfissao &&
                showAllErrorsMessages
              }
            />

            {(perfilProfissional === idByFieldPerfil.outraProfissao ||
              (isEstudante &&
                perfilEstudante ===
                  idByFieldPerfil.estudanteGraduacaoOutroCurso)) &&
              !perfilOutraProfissao &&
              showAllErrorsMessages && (
                <span className="MuiFormHelperText-root Mui-error">
                  Campo obrigatório
                </span>
              )}
          </MyFormControl>
        </fieldset>

        <fieldset className={cx({ "d-none": !isTecnicoAux })}>
          <MyFormControl
            error={
              perfilProfissional === idByFieldPerfil.tecnicoOuAuxilar &&
              !perfilTecnico &&
              showAllErrorsMessages
            }
          >
            <InputLabel>SE TÉCNICO OU AUXILIAR, ESPECIFIQUE ABAIXO:</InputLabel>
            <Select
              id="perfilTecnico"
              name="perfilTecnico"
              value={perfilTecnico || ""}
              onChange={handleInputChange}
            >
              {tecnicoAuxList.map((item) => (
                <MenuItem key={item?.id} value={item.id as string}>
                  {item?.attributes?.titulo}
                </MenuItem>
              ))}
            </Select>

            {perfilProfissional === idByFieldPerfil.tecnicoOuAuxilar &&
              !perfilTecnico &&
              showAllErrorsMessages && (
                <span className="MuiFormHelperText-root Mui-error">
                  Campo obrigatório
                </span>
              )}
          </MyFormControl>
        </fieldset>
        <div data-row-center>
          <Button
            data-testid="btn-salvar"
            type="submit"
            className="Button button-primary"
            onClick={() => setShowAllErrorsMessages(true)}
          >
            Salvar
          </Button>

          <Link
            to="/meu-perfil"
            data-testid="btn-voltar-para-inicio"
            className="button button-secondary-blue hide-for-mobile rounded-5xl !border-[#0a589b] !text-[#0a589b] !border-2 justify-center items-center p-2 px-4"
          >
            Voltar para o início
          </Link>
        </div>
      </form>
    </section>
  );
};

export default ProfessionalData;
