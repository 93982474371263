import Container from "../../../shared/components/Container";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselBullets,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { ObjetoEducacional } from "../../../models/ObjetoEducacional";
import CardObjetoEducacional from "../../../shared/components/oe-card";
import { useEffect, useState } from "react";
import VerTudoIcon from "../../../assets/svgs/icons/ver-tudo.svg";
import { useHistory } from "react-router";

async function getContinueAssistindo() {
  const response = await fetchApi(
    "objeto-educacional/get-objeto-educacional-card-assistidos",
    "GET",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function ContinueAssistindo() {
  const [assistidos, setAssistidos] = useState<ObjetoEducacional[]>();
  const history = useHistory();

  const loadAssistidos = async () => {
    setAssistidos(await getContinueAssistindo());
  };

  useEffect(() => {
    loadAssistidos();
  }, []);

  return assistidos && assistidos?.length > 0 ? (
    <Container className="flex flex-col gap-6 py-9 md:py-10">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
          Continue assistindo
        </h2>
        <span
          className="flex cursor-pointer items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
          onClick={() => history.replace("/continue-assistindo")}
        >
          VER TUDO
          <img
            src={VerTudoIcon}
            alt="icon-video"
            aria-hidden="true"
            width={24}
            height={24}
            className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
          />
        </span>
      </div>
      {assistidos && assistidos?.length > 0 ? (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {assistidos?.map((_: ObjetoEducacional, index: number) => (
              <CarouselItem
                key={index}
                className="overflow-hidden rounded-xl md:basis-1/2 lg:basis-1/3"
              >
                <CardObjetoEducacional objetoEducacional={_} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto" />
        </Carousel>
      ) : (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {Array.from({ length: 3 }).map((_, index) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <div className="min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                  <div className="flex h-[187.5px] w-full animate-[shimmer_2s_infinite] bg-gradient-to-r  from-transparent via-gray-400/20 to-transparent md:h-[227.66px]" />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      )}
    </Container>
  ) : <></>;
}
