import React from "react";
import { createContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CircleAlert, CircleCheckBig, TriangleAlert } from "lucide-react";

export type MessageType = 'INFO' | 'ERRO' | 'WARN' | 'SUCESS';

export interface IAlertContext {
  showMessage(message: string, tipo: MessageType): void;
}

export const AlertContext = createContext<IAlertContext>({} as IAlertContext);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: { children: any }) => {


  const showMessage = (message: string, tipo: MessageType) => {
    switch (tipo) {
      case 'SUCESS':
        toast.success(
          <div className="flex flex-row gap-3 mx-2 items-center">
            <CircleCheckBig className="h-5 w-5" />
            <div className="flex flex-col">
              <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>{message}</div>
            </div>
          </div>, {
          icon: false,
          closeButton: false,
          style: {
            backgroundColor: '#21AB27',
            color: 'white',
            borderRadius: '35px',
          }
        });
        break;
      case 'ERRO':
        toast.error(
          <div className="flex flex-row gap-3 mx-2 items-center">
            <CircleAlert className="h-5 w-5" />
            <div className="flex flex-col">
              <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>{message}</div>
            </div>
          </div>, {
          icon: false,
          closeButton: false,
          style: {
            backgroundColor: '#D64214',
            color: 'white',
            borderRadius: '35px',
          }
        });
        break;
      case "WARN":
        toast.warn(
          <div className="flex flex-row gap-3 mx-2 items-center">
            <TriangleAlert className="h-5 w-5" />
            <div className="flex flex-col">
              <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>Você ainda não concluiu esse conteúdo.</div>
            </div>
          </div>, {
          icon: false,
          closeButton: false,
          style: {
            backgroundColor: '#FFAD0D',
            color: 'white',
            borderRadius: '35px',
          }
        });
        break;
      default:
        toast.success(
          <div className="flex flex-row gap-3 mx-2 items-center">
            <CircleCheckBig className="h-5 w-5" />
            <div className="flex flex-col">
              <div style={{ fontWeight: 600, fontSize: '12px', color: '#FFFFFF' }}>{message}</div>
            </div>
          </div>, {
          icon: false,
          closeButton: false,
          style: {
            backgroundColor: '#21AB27',
            color: 'white',
            borderRadius: '35px',
          }
        });
        break;
    }

  }

  return (
    <AlertContext.Provider
      value={{
        showMessage
      }}
    >
      <ToastContainer />
      {children}
    </AlertContext.Provider>
  );
};

