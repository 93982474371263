import { STEP_REGISTER_USER } from "../../shared/constants/action-types";
import { User } from "../../shared/interfaces/user.interface";

const INIT_STATE: UserStateReducer = {
  data: {
    username: "",
    fullName: "",
    birthDate: "",
    cpf: "",
    celular: "",
    email: "",
    password: "",
    confirmPassword: "",
    professionalProfile: "",
    professionalOcuppation: "",
    area_de_conhecimentos: [],
    CRM: "",
    UF: "",
    OutraProfissaoFilha: "",
    acceptTerm: false,
    acceptSharePersonData: false,
    deficiencia_auditiva: false,
    deficiencia_visual: false,
    deficiencia_fisica: false,
    deficiencia_intelectual_mental: false,
    deficiencia_psicossocial_autismo: false
  },
  togglePersonInformation: true,
  toggleProfessionalInformation: false,
  toggleUserPreference: false,
  toggleTerm: false,
  step: 1,
};

export interface UserStateReducer {
  data: User;
  togglePersonInformation: boolean;
  toggleProfessionalInformation: boolean;
  toggleUserPreference: boolean;
  toggleTerm: boolean;
  step: number;
}

export interface UserActionReducer {
  type: STEP_REGISTER_USER;
  payload: UserStateReducer;
}

const userReducer = (state = INIT_STATE, action: UserActionReducer) => {
  switch (action.type) {
    case STEP_REGISTER_USER.STEP_PERSON_INFORMATION:
      return {
        data: action.payload.data,
        togglePersonInformation: true,
        toggleProfessionalInformation: false,
        toggleUserPreference: false,
        toggleTerm: false,
        step: 1,
      };
    case STEP_REGISTER_USER.STEP_PROFESSIONAL_INFORMATION:
      return {
        data: action.payload.data,
        togglePersonInformation: false,
        toggleProfessionalInformation: true,
        toggleUserPreference: false,
        toggleTerm: false,
        step: 2,
      };
    case STEP_REGISTER_USER.STEP_USER_PREFERENCE:
      return {
        data: action.payload.data,
        togglePersonInformation: false,
        toggleProfessionalInformation: false,
        toggleUserPreference: true,
        toggleTerm: false,
        step: 3,
      };
    case STEP_REGISTER_USER.REGISTER_USER_SUCCEED:
      return {
        data: action.payload.data,
        toggleRegisterUserSucceed: true,
        togglePersonInformation: false,
        toggleProfessionalInformation: false,
        toggleUserPreference: false,
        toggleTerm: false,
        step: 4,
      };
    case STEP_REGISTER_USER.TOGGLE_MODAL_TERM:
      return {
        data: action.payload.data,
        toggleRegisterUserSucceed: false,
        togglePersonInformation: false,
        toggleProfessionalInformation: false,
        toggleUserPreference: true,
        toggleTerm: action.payload.toggleTerm,
      };
    default:
      return state;
  }
};

export default userReducer;
