import { Component } from "react";
import { Link } from "react-router-dom";

import {
  ObjetoEducacionalEntity,
  RotuloEntity,
  TipoOeEntity,
} from "../../../generated/graphql";
import { Pagination, Grid, MediaCard } from "../";

import "./styles.scss";
import Container from "../Container";
import { useUtils } from "../../utils";

interface PaginationGridProps {
  oes: ObjetoEducacionalEntity[];
  title?: string;
  match?: any;
}

interface PaginationGridState {
  pageSize: number;
  bulletsCount: number;
  page: number;
  loading: boolean;
  title: string;
}

export class PaginationGrid extends Component<
  PaginationGridProps,
  PaginationGridState
> {
  state = {
    pageSize: 16,
    bulletsCount: 0,
    page: 1,
    loading: false,
    title: "",
  };

  componentDidMount() {
    const bulletsCount = Math.abs(
      Math.ceil(this.props.oes.length / this.state.pageSize),
    );
    this.setState({ bulletsCount });
  }

  handlePageChange = async (event: any, page: number) => {
    event.preventDefault();
    this.setState({ page });
  };

  getTitle = () => {
    const path = window.location.pathname;

    switch (true) {
      case path.indexOf("/historico/videos") !== -1:
        return "Vídeos";

      case path.indexOf("/historico/audios") !== -1:
        return "Áudios";

      case path.indexOf("/historico/quizzes-e-desafios") !== -1:
        return "Quizzes e Desafios";

      case path.indexOf("/historico/textos-e-artigos") !== -1:
        return "Textos e Artigos";
    }
  };

  showBackButton() {
    const path = window.location.pathname;
    return path.includes("/historico");
  }

  render() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { formatTitle } = useUtils();
    const { page, pageSize, loading, bulletsCount } = this.state;
    return (
      <Container>
        <div
          data-ajuste-xpto
          className={`${this.showBackButton() ? "hide-mobile" : ""}`}
        >
          {this.props.title && <h3>{this.props.title}</h3>}
          <Pagination
            count={bulletsCount}
            page={page}
            onChange={this.handlePageChange}
            disabled={this.state.loading}
          />
          <Link
            className="text-sm font-bold"
            data-link-primary
            to="/meu-perfil"
          >
            voltar
          </Link>
        </div>
        <Grid cols={3} gap={4}>
          {this.props.oes
            .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
            .map((oe: ObjetoEducacionalEntity, index: number) => (
              <div key={index}>
                <MediaCard
                  id={oe.id!}
                  title={formatTitle(oe.attributes?.Titulo as string)}
                  image={oe.attributes?.ImagemPreview?.data?.attributes?.url}
                  badgeRotulos={oe.attributes?.Rotulos?.data as RotuloEntity[]}
                  badgeSeen={true}
                  media={{
                    type: oe.attributes?.TipoObjeto?.data! as TipoOeEntity[],
                    favorite: false,
                  }}
                  oe={oe}
                />
              </div>
            ))}
        </Grid>
        <Pagination
          count={bulletsCount}
          page={page}
          onChange={this.handlePageChange}
          disabled={loading}
        />
      </Container>
    );
  }
}
