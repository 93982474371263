import React from "react";

import BoasVindas from "../../../../assets/svgs/boasvindas.svg";

import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";

interface props {
  origin?: string;
}

export const ModalRegisterUserSucceedV2: React.FC<props> = ({ origin }) => {
  const redirectToHome = () => {
    window.location.href = origin ? `/${origin}?logged=true` : "/";
  };

  return (
    <div className="flex flex-col items-center justify-center gap-3 rounded-xl border p-2 text-center">
      <div className="mx-10 my-9 flex w-[250px] flex-col items-center justify-center md:mx-9 md:my-8 md:w-[350px]">
        <img
          src={BoasVindas}
          alt="Imagem de boas vindas"
          className="h-[100px] w-[118.52px]"
        />
        <div className="mx-2 mt-6 text-lg font-bold leading-22 text-[#004F92] md:text-base">
          Cadastro concluído, boas-vindas!
        </div>
        <div className="mt-3 text-base font-normal leading-5 text-[#313335] md:text-sm">
          Agora você tem acesso aos conteúdos da Academia Digital Einstein.
        </div>
        <Button
          data-testid="btn-continuar"
          variant={"primary"}
          className="mt-6"
          onClick={() => {
            redirectToHome();
          }}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
};
