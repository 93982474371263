import { useState } from "react";
import {
  ObjetoEducacionalEntity,
  useGetOesLazyQuery as ObjetosEducacionaisLazyQuery,
} from "../generated/graphql";

export const useOE = () => {
  const [carregandoOEs, setCarregandoOes] = useState(true);

  const [totalBullets, setTotalBullets] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(15);
  const [resultadoOE, setResultadoOE] = useState<ObjetoEducacionalEntity[]>(
    [] as ObjetoEducacionalEntity[]
  );

  const [PesquisarOes] = ObjetosEducacionaisLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (response) => {
      if (response && response.objetoEducacionals) {
        setResultadoOE(
          response.objetoEducacionals.data as ObjetoEducacionalEntity[]
        );
      }

      if (response && response.oesResults?.count?.pagination.total) {
        const total = response.oesResults?.count?.pagination.total;

        setTotalItems(total);

        const bulletCount = total ? Math.abs(Math.ceil(total / offset)) : 0;

        setTotalBullets(bulletCount);
      }

      setCarregandoOes(false);
    },
  });

  const buscarOes = async (
    page: number,
    sort: string,
    where?: any,
    whereUser?: any
  ) => {
    setCarregandoOes(true);
    const variables = {
      start: page,
      limit: offset,
      sort: sort,
      where: { Status: { eq: "Ativo" }, ...(where || {}) },
      whereUser: {},
    };
    if (!!whereUser) {
      variables.whereUser = whereUser;
    }

    PesquisarOes({
      variables: {
        ...variables,
      },
    });
  };

  return {
    offset,
    setOffset,
    totalBullets,
    totalItems,
    setTotalBullets,
    carregandoOEs,
    setCarregandoOes,
    buscarOes,
    resultadoOE,
  };
};
