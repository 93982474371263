import { useRef } from "react";
import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import {
  AvaliarSection,
  FavoritarSection,
} from "../../OEPage/components/BarraInteracaoOe";

export default function BarraFavoritar({
  objetoEducacionalSelecionado,
  user,
}: {
  objetoEducacionalSelecionado: ObjetoEducacionalEntity;
  user: any;
}) {
  const ref = useRef();

  return (
    <>
      <div className="w-full h-[88px] shadow-sm mt-2 !py-5 md:!py-5 md:!px-6 rounded-xl bg-white  items-center justify-center flex gap-4 text-xs">
        <FavoritarSection
          props={{
            user: user,
            oe: objetoEducacionalSelecionado,
            live: false,
          }}
          refFavoritar={ref}
        />
        <AvaliarSection
          props={{
            user: user,
            oe: objetoEducacionalSelecionado,
            live: false,
          }}
        />
      </div>
    </>
  );
}
