import { useContext } from "react";
import { upsertUserContext, UserContext } from "../context/UserContext";
import {
  useGetUserByIdCacheLazyQuery as GetUserByIdCacheLazyQuery,
  UsersPermissionsUserEntity,
} from "../generated/graphql";

const ResetUserContext: any = () => {
  const { user, setUser } = useContext(UserContext);

  const [reset] = GetUserByIdCacheLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) =>
      _onCompleted(
        data.findMe as UsersPermissionsUserEntity,
      ),
    onError: (error) => {
    },
  });

  const _onCompleted = (user: UsersPermissionsUserEntity) => {
    if (!user) {
      return;
    }

    setUser(user);

    upsertUserContext({ user });
  };

  const resetUserContext = (id?: string) => {
    if (!user && !id) return;

    reset().then((response) =>
      _onCompleted(
        response.data?.findMe as UsersPermissionsUserEntity,
      ),
    );
  };

  return { resetUserContext } as any;
};

export default ResetUserContext;
