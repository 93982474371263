import React from "react";
interface Props {
  children: React.ReactNode;
  className?: string;
  hidden?: boolean;
}

const Container = (props: Props) => {
  const { children, className = "", hidden = false } = props;
  return (
    <div
      className={
        hidden
          ? ""
          : `h-12.5 mx-auto my-0 box-border w-[90%] max-w-[1260px] px-[0.9375rem] ${className}`
      }
    >
      {children}
    </div>
  );
};

export default Container;
