import React, { ReactNode } from "react";
import cx from "classnames";
import "./styles.scss";
import Container from "../Container";

interface HeaderSectionProps {
  children?: ReactNode;
  classe?: string;
}

export const HeaderSection: React.FC<HeaderSectionProps> = (props) => {
  return (
    <div className={cx({
      "flex flex-row via-[#1BB28E]-98%  bg-gradient-to-br from-[#107B95] to-[#1BB28E]": true,
      [props.classe || '']: !!props.classe
    })}>
      <Container className="mb-4">{props.children}</Container>
    </div>
  );
};
