import React from "react";
import { QuizModel } from "../../../../models/QuizModel";

export interface ProgressoTesteConhecimentoQuizProps {
  perguntasTotais: number;
  activeQuestionIndex: number;
  quizAtivo: QuizModel;
}

export const ProgressoTesteConhecimentoQuiz: React.FC<
  ProgressoTesteConhecimentoQuizProps
> = ({ perguntasTotais, activeQuestionIndex, quizAtivo }) => {
  const progresso = Math.round((activeQuestionIndex / perguntasTotais) * 100);

  return (
    <>
      <div className="h-[94px] w-full rounded-xl bg-[#E0F3FD] mt-3 flex items-center justify-between px-10">
        <span className="text-[#004F92] text-sm font-semibold leading-normal max-w-[324px] inline-block">
          {quizAtivo?.nome}
        </span>
        <div className="flex gap-1 items-center w-full max-w-[220px] flex-col">
          <div className="flex justify-between flex-row w-full">
            {/* progresso de porcentagem (12%) */}
            <span className="text-[#111316] text-right text-xs font-normal inline-block">
              {(() => {
                return (
                  <span>{progresso > 10 ? progresso : `  ${progresso}`}%</span>
                );
              })()}
            </span>

            {/* progresso de quantidade (1 / 30) */}
            <span className="text-[#111316] text-right text-xs font-normal inline-block">
              {(() => {
                return (
                  <span>
                    {activeQuestionIndex + 1} / {perguntasTotais}
                  </span>
                );
              })()}
            </span>
          </div>

          {/* progressBar  */}
          <div className="w-full bg-[#E1E3E6] rounded-full h-2.5 ">
            <div
              className={`bg-[#004F92] h-2.5 rounded-full w-[${progresso}%]`}
              style={{
                width: `${progresso}%`,
              }}
            />
          </div>
        </div>
      </div>
      <h2 className="normal-case !mt-5">
        <span className="font-semibold  text-base md:text-xxl leading-normal md:leading-8 text-[#004F92] ">
          {/* Questão 1/30 */}
          Questão {activeQuestionIndex + 1}/{perguntasTotais}
        </span>
      </h2>
    </>
  );
};
