import { useState } from "react";

export interface ZoomControlParams {
  current: number;
  max?: number;
  handleChange?: (count: number) => void;
}
const useZoomControls = ({
  current,
  max = 10,
  handleChange,
}: ZoomControlParams) => {
  const [zoom, setZoom] = useState(current);

  const zoomIn = () => {
    if (zoom < max) {
      let count = zoom + 1;
      setZoom(count);
      if (handleChange) {
        handleChange(count);
      }
    }
  };

  const zoomOut = () => {
    if (zoom > 1) {
      let count = zoom - 1;
      setZoom(count);
      if (handleChange) {
        handleChange(count);
      }
    }
  };

  return {
    zoom,
    zoomIn,
    zoomOut,
  };
};

export default useZoomControls;
