import { Link } from "react-router-dom";

interface LinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  style?: any;
}

const LinkCustom = ({ to, children, onClick, className, style }: LinkProps) => {
  const isValidUrl = (url: string): boolean => {
    const pattern = /^[a-zA-Z0-9/+_\-áàâãéèêíïóôõöúçÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇ]+$/;
    return pattern.test(url);
  };

  return (
    <Link
      to={isValidUrl(to) ? to : "/"}
      onClick={onClick}
      className={className}
      style={style}
    >
      {children}
    </Link>
  );
};

export default LinkCustom;
