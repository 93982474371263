import React from "react";
import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";
import { isMobile } from "react-device-detect";

import "./styles.scss";
import PodcastPreviewDesktop from "./components/PodcastPreviewDesktop";
import PodcastPreviewMobile from "./components/PodcastPreviewMobile";

interface PodcastPageProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}

const PodcastPage: React.FC<PodcastPageProps> = (props) => {
  return (
    <>
      <nav className="hidden md:block">
        <PodcastPreviewDesktop oe={props.oe} user={props.user} />
      </nav>
      <nav className="block md:hidden">
        <PodcastPreviewMobile oe={props.oe} user={props.user} />
      </nav>
    </>
  );
};

export default PodcastPage;
