import { useEffect, useRef, useState } from "react";
import {
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
  useGetPercentualQuizQuery,
} from "../../../../generated/graphql";
import { ButtonActionsQuiz } from "./ButtonActions";
import Container from "../../../../shared/components/Container";
import { Loader } from "../../../../shared/components";
import { ModalPreviewFeedbackMobileQuiz } from "./ModalPreviewFeedbackMobile";
import { FeedbackDesktopQuiz } from "./FeedbackDesktop";
import FeedbackPDFQuiz from "./FeedbackPDFQuiz";
import { PercentualQuestaoQuiz } from "./PercentualQuestao";
import { LegendaImagemQuiz } from "./LegendaImagem";
import { MediaImagemVideoQuiz } from "./MediaImagemVideo";
import { ModalPreviewMobileQuiz } from "./ModalPreviewMobile";
import { EnunciadoQuiz } from "./Enunciado";
import { isMobile } from "react-device-detect";
import FeedbackAcertoQuiz from "./FeedbackAcerto";
import { HeaderQuiz } from "./Header";
import { ItemRespostaQuiz } from "./ItemResposta";
import { ModalConfirmacao } from "./ModalConfirmacao";
import { ModalAcessoPermitidoLogados } from "../../components/ModalAcessoPermitidoLogados";
import { fetchApi } from "../../../../shared/services/rest-dotnet.service";
import { QuizModel } from "../../../../models/QuizModel";
import { PerguntaModel } from "../../../../models/PerguntaModel";
import { RespostaModel } from "../../../../models/RespostaModel";
import { RespostaQuestionarioRequestModel } from "../../../../models/RespostaQuestionarioRequestModel";
import { RespostaQuestionarioModel } from "../../../../models/RespostaQuestionarioModel";
import useOeConsume from "../../../../hooks/useOeConsume";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export interface QuizComponentProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  nextRef?: any;
  previousRef?: any;
  onFinish?: () => void;
  updateQuestionCounter?: (
    pergunta: PerguntaModel,
    resposta: RespostaModel,
  ) => void;
  IsResidenciaMedica?: boolean;
}

export const QuizComponent: React.FC<QuizComponentProps> = ({
  oe,
  user,
  nextRef,
  previousRef,
  onFinish,
  IsResidenciaMedica = false,
}) => {
  // respostaCorreta : any | undefined;
  let activeQuizIndex = 0;
  let answer: any = "";
  let answers: any = [];

  const refForm = useRef<HTMLFormElement>(null);
  const [quizAtivo, setQuizAtivo] = useState<QuizModel>();
  const [perguntaAtiva, setPerguntaAtiva] = useState<PerguntaModel>();
  const [respostaAtiva, setRespostaAtiva] = useState<any>();
  const [perguntasTotais, setPerguntasTotais] = useState<number>(0);
  const [showingQuestionFeedback, setShowingQuestionFeedback] =
    useState<boolean>(false);
  const [percentQuestion, setPercentQuestion] = useState<any[]>([
    { percent: 0, idAnswer: "" },
  ]);
  const [respostaCorreta, setRespostaCorreta] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [resposta, setResposta] = useState<any>({ id: "" });
  const [showPreviewMobile, setShowPreviewMobile] = useState<boolean>(false);
  const [showPreviewFeedbackMobile, setShowPreviewFeedbackMobile] =
    useState<boolean>(false);
  const [showModalConfirmacao, setShowModalConfirmacao] =
    useState<boolean>(false);
  const [idQuiz, setIdQuiz] = useState<number>(0);
  const [finalizou, setFinalizou] = useState<boolean>(false);
  const [qtdAcertada, setQtdAcertada] = useState<number>(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0);
  const [perguntasPuladas, setPerguntasPuladas] = useState<number[]>([]);
  const [posicaoPergunta, setPosicaoPergunta] = useState<number>(0);
  const [isTesteConhecimento, setIsTesteConhecimento] = useState(false);
  const [respostas, setRespostas] = useState<RespostaQuestionarioModel[]>([]);

  const consume = useOeConsume();
  const handleTimeUpdateQuizRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateQuizRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === oe.id) !== undefined
        );

      if ((oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration) {
        return;
      }

      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration + 1,
        totalDuration: data.event.totalDuration,
        oe: oe.id,
        user: user?.id,
      });
    });

    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };

  }, [user])

  const updateTimeInProgress = (page: number, pageTotal: number) => {
    handleTimeUpdateQuizRef.current({
      partialDuration: page,
      totalDuration: pageTotal,
    })
  }

  const deleteResposta = async () => {
    new Promise<void>((resolve, reject) => {
      fetchApi(
        `quiz/delete-resposta-questionario?QuestionarioId=${idQuiz.toString()}&UsuarioId=${user?.id!.toString()}`,
        "POST",
      )
        .then((result) => resolve())
        .catch((err) => reject());
    });
  };

  const loadResposta = async (id?: number) => {
    setLoading(true);
    fetchApi(
      `quiz/get-resposta-questionario?QuestionarioId=${id || idQuiz.toString()}&UsuarioId=${user?.id!.toString()}`,
      "GET",
    )
      .then((resultado: any) => {
        resultado.json().then((data: RespostaQuestionarioModel[]) => {
          setRespostas(data);
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const ultimaPerguntaQuiz =
    (activeQuestionIndex >= (quizAtivo?.perguntas?.length || 0) - 1 &&
      perguntasPuladas?.length === 0) ||
    false;

  const loadQuiz = async () => {
    setFinalizou(false);
    setShowingQuestionFeedback(false);
    setLoading(true);
    setIdQuiz(parseInt(oe?.attributes?.Quizzes?.data[0]?.id || "0"));
    fetchApi(`quiz/get-by-id?id=${oe?.attributes?.Quizzes?.data[0]?.id}`, "GET")
      .then((resultado: any) => {
        resultado.json().then((data: QuizModel) => {
          setQuizAtivo(data);
          setPerguntaAtiva(data.perguntas[0]);
          setPerguntasTotais(data.perguntas.length);
          setLoading(false);
          setPosicaoPergunta(0);
          setShowingQuestionFeedback(false);
          setPercentQuestion([{ percent: 0, idAnswer: "" }]);
          setResposta({ id: "" });
          setShowPreviewMobile(false);
          setShowModalConfirmacao(false);
          setQtdAcertada(0);
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchDataOE = async () => {
      setFinalizou(false);
      setShowingQuestionFeedback(false);
      if (!!!user?.id) {
        setLoading(false);
        return;
      }
      const regex = /\/oe\/(.*)\/(quiz|podcast|video|leitura)/;
      let path = window.location.pathname;
      path = path.replace(regex, "$1");
      await loadQuiz();

      if (
        oe.attributes?.Rotulos?.data?.find((item) => {
          return (
            item.attributes?.titulo?.toUpperCase() ===
            "TESTE SEUS CONHECIMENTOS"
          );
        })
      ) {
        setIsTesteConhecimento(true);
      } else {
        setIsTesteConhecimento(false);
      }

      await fetchData(parseInt(oe?.attributes?.Quizzes?.data[0]?.id || "0"));
      verificaPerguntaJafoiRespondida(false, quizAtivo);
      setTimeout(() => setLoading(false), 500);
    };

  useEffect(() => {
    if (!IsResidenciaMedica) {
      fetchDataOE();
    }
  }, [IsResidenciaMedica]);

  useEffect(() => {
    if (IsResidenciaMedica) {
      fetchDataOE();
    }
  }, [oe.id, IsResidenciaMedica]);

  const { data: percentual, refetch: refetchPercentual } =
    useGetPercentualQuizQuery({
      skip: !!!user?.id,
      fetchPolicy: "cache-and-network",
      variables: {
        questionarioId: idQuiz.toString(),
        usuarioId: user?.id!.toString() || "",
      },
    });

  useEffect(() => {
    refetchPercentual();
  }, [finalizou, idQuiz]);

  useEffect(() => {
    if (finalizou) {
      onFinish?.();
    }
  }, [finalizou]);

  useEffect(() => {
    verificaPerguntaJafoiRespondida();
    if (respostas) {
      const retorno = respostas;
      setQtdAcertada(
        retorno?.reduce((prev, curr) => {
          let count = prev || 0;

          count += curr.respostaQuestao === curr.respostaCorretaQuestao ? 1 : 0;

          return count;
        }, 0),
      );
    }
  }, [respostas, quizAtivo]);

  const fetchData = async (id?: number) => {
    loadResposta(id);
  };


  const showQuestionFeedback = () => {
    setShowingQuestionFeedback(true);
  };

  const hideQuestionFeedback = () => {
    setShowingQuestionFeedback(false);
  };

  const verificaPerguntaJafoiRespondida = async (
    reset: boolean = false,
    quizz: QuizModel | undefined = undefined,
  ) => {
    const quiz = quizz || quizAtivo;

    if (!quiz) {
      return;
    }

    for (let i = 0; i < (quiz! as QuizModel).perguntas?.length; i++) {
      const perguntaIndex = (quiz! as QuizModel).perguntas[i];

      const respostaGrav = reset
        ? undefined
        : respostas?.find(
            (respostas) => respostas.questaoId === perguntaIndex?.id,
          );

      if (isTesteConhecimento || reset) {
        if (respostaGrav === undefined) {
          setPerguntaAtiva(
            quiz &&
              (quiz! as QuizModel).perguntas &&
              (quiz! as QuizModel).perguntas[i],
          );
          setActiveQuestionIndex(i);
          setPosicaoPergunta(i);
          setResposta({ id: "" });
          setQtdAcertada(
            respostas?.reduce((prev, curr) => {
              let count = prev || 0;

              count +=
                curr.respostaQuestao === curr.respostaCorretaQuestao ? 1 : 0;

              return count;
            }, 0) || 0,
          );
          setFinalizou(false);
          return;
        }
      }
    }

    if (respostas.length < (quiz! as QuizModel).perguntas?.length) {
      setFinalizou(false);
      return;
    }

    if (perguntasTotais === 0) {
      return;
    }

    setFinalizou(true);
    setQtdAcertada(
      respostas?.reduce((prev, curr) => {
        let count = prev || 0;

        count += curr.respostaQuestao === curr.respostaCorretaQuestao ? 1 : 0;

        return count;
      }, 0) || 0,
    );
    if (isTesteConhecimento) {
      setActiveQuestionIndex((quiz! as QuizModel).perguntas.length);
    }
    if (!isTesteConhecimento) {
      const respostaGrav = respostas?.find(
        (p) => p.questaoId! === (quiz! as QuizModel).perguntas[0].id,
      );
      if (respostaGrav) {
        setPerguntaAtiva((quiz! as QuizModel).perguntas[0]);
        setRespostaAtiva(
          btoa(
            unescape(
              encodeURIComponent(
                JSON.stringify(respostaGrav.respostaQuestao?.toString()!),
              ),
            ),
          ),
        );
        setRespostaCorreta(
          btoa(
            unescape(
              JSON.stringify(
                encodeURIComponent(
                  respostaGrav.respostaCorretaQuestao?.toString()!,
                ),
              ),
            ),
          ),
        );
        showQuestionFeedback();
        setActiveQuestionIndex((quiz! as QuizModel).perguntas.length - 1);
        setFinalizou(true);
      } else {
        setFinalizou(false);
      }
    }
  };

  const gravarRespostaQuestionario = async (
    data: RespostaQuestionarioRequestModel,
  ) => {
    return new Promise<void>((resolve, reject) => {
      fetchApi("quiz/resposta-questionario", "POST", JSON.stringify(data))
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  };

  const percistirResposta = async () => {
    answer = "";
    const form = document.querySelector<HTMLInputElement>(
      "form[data-opcoes] input",
    );
    const inputName = form?.getAttribute("name");
    answer = (
      document.querySelector<HTMLFormElement>("form[data-opcoes]") as any
    )[inputName || ""]["value"];

    if (!answer) {
      return;
    }

    answers.push(answer);
    setRespostaAtiva(answer);

    // FRED VERIFICAR
    const respostaCorreta = perguntaAtiva?.respostas?.find(
      (resposta) => !!resposta.opcaoCorreta,
    );

    const respostaOfuscada = respostaCorreta
      ? btoa(unescape(JSON.stringify(encodeURIComponent(respostaCorreta.id))))
      : undefined;

    // this.answer => resposta em btoa
    setRespostaCorreta(respostaOfuscada);

    // atualizamos o contador
    const a = parseInt(decodeURIComponent(atob(answer)).replaceAll('"', ""));
    const resposta = perguntaAtiva?.respostas?.find((resposta) => {
      return a === resposta.id;
    });

    //if (!IsResidenciaMedica) {
      updateTimeInProgress(posicaoPergunta, perguntasTotais);
   //}

    await gravarRespostaQuestionario({
      questionarioId: idQuiz,
      questaoId: perguntaAtiva!.id,
      respostaCorretaQuestao: respostaCorreta!.id,
      respostaQuestao: resposta!.id,
    });

    setPerguntaAtiva({
      ...perguntaAtiva!,
      respostas:
        perguntaAtiva?.respostas.map((a) => ({
          ...a,
          contador: a.id === resposta?.id ? a.contador + 1 : a.contador,
        })) || [],
    });

    showQuestionFeedback();
  };

  const resetFormulario = () => {
    const _form = document.querySelector<HTMLFormElement>(
      "form[data-opcoes]",
    ) as HTMLFormElement;
    if (_form?.style) {
      _form.style.display = "none";
    }
    setTimeout(() => (_form.style.display = "flex"), 100);
    if (showingQuestionFeedback) {
      hideQuestionFeedback();
      document.querySelector<HTMLFormElement>("form[data-opcoes]")?.reset();
    }
  };

  const irParaProximaPergunta = () => {
    let activeQuestionIndexLoc =
      activeQuestionIndex >= perguntasTotais - 1
        ? activeQuestionIndex
        : activeQuestionIndex + 1;

    setPerguntaAtiva(
      quizAtivo &&
        quizAtivo.perguntas &&
        quizAtivo.perguntas[activeQuestionIndexLoc],
    );
    setPosicaoPergunta(activeQuestionIndexLoc);
    if (
      activeQuestionIndex + 1 > perguntasTotais - 1 &&
      perguntasPuladas.length > 0
    ) {
      const perguntasPuladasLoc = perguntasPuladas;
      const indexPerguntaPulada = perguntasPuladasLoc.shift();
      setPerguntaAtiva(
        quizAtivo &&
          quizAtivo.perguntas &&
          quizAtivo.perguntas[indexPerguntaPulada!],
      );
      setPosicaoPergunta(indexPerguntaPulada!);
    } else {
      setActiveQuestionIndex(activeQuestionIndexLoc);
    }
    for (var i = 0; i < (refForm.current?.elements?.length || 0); i++) {
      if ((refForm.current?.elements[i] as any).type === "radio") {
        (refForm.current?.elements[i] as any).checked = false;
      }
    }

    setResposta({ id: "" });
    window.scrollTo(0, 0);
  };

  /**
   * TODO: esta função está fazendo muita coisa, irei refatorá-la em breve
   */
  const nextQuestion = async () => {
    // antes de avançar para a próxima pergunta, mostramos o feedback
    // da mesma
    if (!showingQuestionFeedback) {
      await percistirResposta();

      if (!isTesteConhecimento) {
        setFinalizou(true);
        return;
      }

      await refetchPercentual();

      /*if (!isTesteConhecimento) {
        loadResposta();
        setFinalizou(
          perguntasTotais - 1 === activeQuestionIndex ? true : false
        );
      }*/

      return;
    }

    // se for a última pergunta do quiz, paramos o avanço
    if (ultimaPerguntaQuiz) {
      return;
    }

    resetFormulario();

    irParaProximaPergunta();
  };

  const pularQuestao = () => {
    hideQuestionFeedback();
    const perguntasPuladasLoc = perguntasPuladas;
    perguntasPuladasLoc.push(activeQuestionIndex);
    setPerguntasPuladas(perguntasPuladasLoc);
    let activeQuestionIndexLoc =
      activeQuestionIndex < perguntasTotais - 1
        ? activeQuestionIndex + 1
        : activeQuestionIndex;
    setActiveQuestionIndex(activeQuestionIndexLoc);
    setPerguntaAtiva(
      quizAtivo &&
        quizAtivo.perguntas &&
        quizAtivo.perguntas[activeQuestionIndexLoc],
    );
    setPosicaoPergunta(activeQuestionIndexLoc);

    setResposta({ id: "" });
    setRespostaCorreta("");
    setRespostaAtiva("");
    if (
      activeQuestionIndexLoc >= perguntasTotais &&
      perguntasPuladasLoc.length > 0
    ) {
      const indexPerguntaPulada = perguntasPuladasLoc.pop();
      setPerguntaAtiva(
        quizAtivo &&
          quizAtivo.perguntas &&
          quizAtivo.perguntas[indexPerguntaPulada!],
      );
      setPosicaoPergunta(indexPerguntaPulada!);
    }
    for (var i = 0; i < (refForm.current?.elements?.length || 0); i++) {
      if ((refForm.current?.elements[i] as any).type === "radio") {
        (refForm.current?.elements[i] as any).checked = false;
      }
    }
    window.scrollTo(0, 0);
  };

  const resetQuiz = async () => {
    hideQuestionFeedback();
    setPerguntasPuladas([]);
    const regex = /\/oe\/(.*)\/(quiz|podcast|video|leitura)/;

    let path = window.location.pathname;
    path = path.replace(regex, "$1");

    await deleteResposta();
    for (var i = 0; i < (refForm.current?.elements?.length || 0); i++) {
      if ((refForm.current?.elements[i] as any).type === "radio") {
        (refForm.current?.elements[i] as any).checked = false;
      }
    }
    setTimeout(async () => {
      await fetchData(parseInt(oe?.attributes?.Quizzes?.data[0]?.id || "0"));
      if (!!!user) {
        setLoading(false);
        return;
      }
      setLoading(true);
      loadResposta();
      let activeQuestionIndexLoc = 0;
      setPerguntaAtiva(
        quizAtivo &&
          quizAtivo.perguntas &&
          quizAtivo.perguntas[activeQuestionIndexLoc],
      );
      setPosicaoPergunta(activeQuestionIndexLoc);
      setRespostaAtiva("");
      setRespostaCorreta(undefined);
      setResposta({ id: "" });
      setPercentQuestion([{ percent: 0, idAnswer: "" }]);
      setShowingQuestionFeedback(false);
      verificaPerguntaJafoiRespondida(true);
      setActiveQuestionIndex(activeQuestionIndexLoc);
      setTimeout(() => setLoading(false), 500);
      setFinalizou(false);
      resetFormulario();
    }, 600);
    window.scrollTo(0, 0);
  };
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const isRespostaIncorreta = (respostaId: string) => {
    if (!respostaAtiva) {
      return false;
    }

    const respostaAtivaId = parseInt(
      decodeURIComponent(atob(respostaAtiva).replaceAll('"', "")).replaceAll(
        '"',
        "",
      ),
    );
    const respostaIdBase64 = btoa(
      unescape(encodeURIComponent(JSON.stringify(respostaId))),
    );
    return (
      respostaAtivaId === parseInt(respostaId) &&
      respostaIdBase64 !== respostaCorreta
    );
  };

  const getClassNames = (respostaId: string) => {
    const isRespostaAtual = parseInt(resposta?.id!) === parseInt(respostaId);

    const demaisOpcoes =
      showingQuestionFeedback &&
      (btoa(
        unescape(encodeURIComponent(JSON.stringify(respostaId as any))),
      ) as any) !== (respostaCorreta as any) &&
      respostaAtiva !==
        btoa(unescape(encodeURIComponent(JSON.stringify(respostaId))));

    if (
      respostaCorreta ===
      btoa(unescape(encodeURIComponent(JSON.stringify(respostaId))))
    ) {
      return " border-2 border-[#21AB27] rounded-md px-3 py-2 cursor-pointer text-[#21AB27] bg-[#21AB2627] text-sm font-bold";
    } else if (isRespostaIncorreta(respostaId)) {
      return "border-2 border-[#D64214] rounded-md px-3 py-2 cursor-pointer text-[#D64214] bg-[#D6421426] text-sm font-bold";
    } else if (isRespostaAtual) {
      return "border-2 border-[#004F92] rounded-md px-3 py-2 cursor-pointer text-[#004F92] text-sm font-bold";
    } else if (demaisOpcoes) {
      return "border-1 border-[#9EA5B8] rounded-md px-3 py-2 text-sm font-medium text-[#9EA5B8] ";
    } else {
      return "border-1 border-[#9EA5B8] rounded-md px-3 py-2 cursor-pointer  hover:border-[#004F92] text-sm font-medium ";
    }
  };

  const ButtonActions = () => {
    return (
      <ButtonActionsQuiz
        finalizou={finalizou}
        perguntaAtual={activeQuestionIndex}
        perguntaTotais={perguntasTotais}
        showingQuestionFeedback={showingQuestionFeedback}
        ultimaPerguntaQuiz={ultimaPerguntaQuiz}
        resposta={resposta}
        onClickRefazer={() => {
          setShowModalConfirmacao(true);
        }}
        onClickVerResultado={async () => {
          const regex = /\/oe\/(.*)\/(quiz|podcast|video|leitura)/;

          let path = window.location.pathname;
          path = path.replace(regex, "$1");
          loadResposta();
          setFinalizou(true);
          window.scrollTo(0, 0);
        }}
        onClickProximaPergunta={() => nextQuestion()}
        onClickPular={() => pularQuestao()}
        isTesteConhecimento={isTesteConhecimento}
      />
    );
  };

  if (finalizou && isTesteConhecimento) {
    return (
      <FeedbackAcertoQuiz
        loading={loading}
        oe={oe}
        qtdAcertada={qtdAcertada}
        perguntasTotais={perguntasTotais}
        isResidenciaMedica={IsResidenciaMedica}
        percentualUsuario={
          percentual?.getPercentualResposta.percentual_usuario || 0
        }
        percentualOutros={
          percentual?.getPercentualResposta.percentual_outros || 0
        }
        idQuiz={idQuiz.toString()}
        user={user}
        showModalConfirmacao={showModalConfirmacao}
        onCloseModal={() => setShowModalConfirmacao(false)}
        onResetQuiz={() => resetQuiz()}
        onClickRefazer={() => {
          setShowModalConfirmacao(true);
        }}
      />
    );
  }

  return (
    <>
      {!!!user && <ModalAcessoPermitidoLogados />}
      {showPreviewMobile && (
        <ModalPreviewMobileQuiz
          nextRef={nextRef}
          previousRef={previousRef}
          perguntaAtiva={perguntaAtiva}
          onClickClose={() => setShowPreviewMobile(false)}
          onClickShow={() => setShowPreviewMobile(true)}
          isMobile={isMobile}
        />
      )}
      {showPreviewFeedbackMobile && (
        <ModalPreviewFeedbackMobileQuiz
          nextRef={nextRef}
          previousRef={previousRef}
          quizAtivo={quizAtivo!}
          onClickClose={() => setShowPreviewFeedbackMobile(false)}
          onClickShow={() => setShowPreviewFeedbackMobile(true)}
          isMobile={isMobile}
        />
      )}
      <Container hidden={IsResidenciaMedica}>
        <Loader
          show={loading === true && !IsResidenciaMedica}
          message="Aguarde enquanto o conteúdo é carregado..."
        />

        <section
          className={`quizz flex w-full flex-col md:w-[752px] ${
            IsResidenciaMedica ? "" : "mx-auto"
          }`}
        >
          <HeaderQuiz
            oe={oe}
            isTesteConhecimento={isTesteConhecimento}
            perguntasTotais={perguntasTotais}
            quizAtivo={quizAtivo!}
            activeQuestionIndex={posicaoPergunta}
            isResidencia={IsResidenciaMedica}
          />
          {!isTesteConhecimento && (
            <ModalConfirmacao
              show={showModalConfirmacao}
              setShow={() => setShowModalConfirmacao(false)}
              resetQuiz={() => resetQuiz()}
            />
          )}

          <section className={"mt-3 md:mt-0"}>
            <MediaImagemVideoQuiz
              className="rounded-xl"
              isMobile={isMobile}
              nextRef={nextRef}
              onClickMobile={() => setShowPreviewMobile(true)}
              perguntaAtiva={perguntaAtiva}
              previousRef={previousRef}
            />
            {perguntaAtiva?.imagemUrls && (
              <LegendaImagemQuiz
                color="#000000"
                perguntaAtiva={perguntaAtiva}
              />
            )}
            <div>
              <div>
                <EnunciadoQuiz perguntaAtiva={perguntaAtiva} />
                <form
                  data-opcoes
                  ref={refForm}
                  style={{
                    pointerEvents: showingQuestionFeedback ? "none" : "auto",
                  }}
                  className="!mt-5 flex flex-col gap-3"
                >
                  {perguntaAtiva?.respostas?.map(
                    (resposta: RespostaModel, _idx: number) => {
                      const alphabetIndex = alphabet[_idx];
                      const className = getClassNames(resposta?.id.toString()!);
                      return (
                        <ItemRespostaQuiz
                          key={_idx}
                          className={className}
                          showingQuestionFeedback={showingQuestionFeedback}
                          onChange={() => setResposta(resposta)}
                          activeQuizIndex={activeQuizIndex}
                          activeQuestionIndex={activeQuestionIndex}
                          resposta={resposta}
                          alphabetIndex={alphabetIndex}
                          respostaAtiva={respostaAtiva}
                          respostaCorreta={respostaCorreta}
                        />
                      );
                    },
                  )}
                </form>
                <PercentualQuestaoQuiz
                  perguntaAtiva={perguntaAtiva}
                  respostaAtiva={respostaAtiva}
                  showingQuestionFeedback={showingQuestionFeedback}
                />
                <FeedbackDesktopQuiz
                  isMobile={isMobile}
                  nextRef={nextRef}
                  perguntaAtiva={perguntaAtiva}
                  previousRef={previousRef}
                  quizAtivo={quizAtivo}
                  respostaAtiva={respostaAtiva}
                  showingQuestionFeedback={showingQuestionFeedback}
                  ultimaPerguntaQuiz={ultimaPerguntaQuiz}
                  onClickMobile={() => setShowPreviewFeedbackMobile(true)}
                />

                {true &&
                  respostaAtiva &&
                  perguntaAtiva?.pDFUrl !== undefined && (
                    <FeedbackPDFQuiz pdfUrl={perguntaAtiva?.pDFUrl} />
                  )}
              </div>
              {ButtonActions()}
            </div>
          </section>
        </section>
      </Container>
    </>
  );
};
