import * as Yup from "yup";

export const loginValidator = Yup.object().shape({
  username: Yup.string().required("Campo obrigatório"),
  password: Yup.string()
    .required("Campo obrigatório")
    .test({
      message: "Necessário um caractere minúsculo",
      test: (value) => {
        return !!value && /[a-z]/.test(value);
      },
    })
    .test({
      message: "Necessário um caractere maiúsculo",
      test: (value) => {
        return !!value && /[A-Z]/.test(value);
      },
    })
    .test({
      message: "Necessário um caractere numérico",
      test: (value) => {
        return !!value && /[0-9]/.test(value);
      },
    })    
    .min(8, "Necessário ao menos 8 caracteres"),
});
