import Container from "../../shared/components/Container";
import * as Icon from "../../assets/svgs";
import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../shared/components/ui/carousel";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { ObjetoEducacional } from "../../models/ObjetoEducacional";

async function getIndicacoesConteudos() {
  const response = await fetchApi(
    "objeto-educacional/get-objeto-educacional-card-voce-pode-gostar",
    "GET",
  ).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

const NotFound = () => {
  const [indicacoesConteudos, setIndicacoesConteudos] = useState<
    ObjetoEducacional[]
  >([] as ObjetoEducacional[]);

  const loadIndicacoes = async () => {
    setIndicacoesConteudos(await getIndicacoesConteudos());
  };

  useEffect(() => {
    loadIndicacoes();
  }, []);

  return (
    <Container className="mb-4 flex flex-col gap-6 py-9 md:py-10">
      <div className="mb-5 flex flex-col items-center justify-center gap-9">
        <Icon.PageNotFound />{" "}
        <div className="flex flex-col justify-center gap-3 text-center">
          {" "}
          <h1 className="text-xxl font-semibold leading-[30px] text-[#004F92]">
            Página não encontrada{" "}
          </h1>{" "}
          <span className="font-normal font-sans text-[#111316] text-sm leading-4 w-[300px] md:w-[468px] text-center px-3">Confira se buscou pelos termos corretos ou consulte os conteúdos a seguir de acordo com sua área profissional.</span>
        </div>
      </div>
      {indicacoesConteudos && indicacoesConteudos?.length > 0 ? (
        <>
          <div className="flex items-center justify-between">
            <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
              Você pode se interessar
            </h2>
          </div>
          <Carousel
            opts={{
              breakpoints: {
                "(min-width: 768px)": { slidesToScroll: 3 },
              },
            }}
            className="w-full"
          >
            <CarouselContent className="">
              {indicacoesConteudos?.map((_: ObjetoEducacional, index: number) => (
                <CarouselItem
                  key={index}
                  className="overflow-hidden rounded-xl md:basis-1/2 lg:basis-1/3"
                >
                  <CardObjetoEducacional objetoEducacional={_} />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious
              data-testid="btn-previous-continue-assistindo"
              height="h-[0px] md:h-[227.66px]"
              className="hidden md:block"
            />
            <CarouselNext
              data-testid="btn-next-continue-assistindo"
              height="h-[0px] md:h-[227.66px]"
              className="hidden md:block"
            />
            <CarouselBullets className="absolute left-0 right-0 mx-auto" />
          </Carousel>
        </>
      ) : (
        <></>
      )}
    </Container>
  );

  // return (
  //   <Container>
  //     <div className="flex min-h-[500px] w-full flex-col items-center justify-center gap-9 py-10">
  //       <Icon.PageNotFound />
  //       <div className="flex flex-col justify-center gap-3 text-center">
  //         <h1 className="text-xxl font-semibold leading-[30px] text-[#004F92]">
  //           Página não encontrada
  //         </h1>
  //       </div>
  //       <div className="mt-9 flex flex-col items-start justify-start gap-6">
  //         <div className="w-full py-10">
  //           <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
  //             Você pode se interessar
  //           </h2>
  //           <Carousel className="mb-4">
  //             <CarouselContent className="">
  //               {indicacoesConteudos?.map(
  //                 (_: ObjetoEducacional, index: number) => (
  //                   <CarouselItem
  //                     key={index}
  //                     className="md:basis-1/2 lg:basis-1/3"
  //                   >
  //                     <CardObjetoEducacional objetoEducacional={_} />
  //                   </CarouselItem>
  //                 ),
  //               )}
  //             </CarouselContent>
  //             <CarouselBullets className="absolute left-0 right-0 mx-auto " />
  //           </Carousel>
  //         </div>
  //       </div>
  //     </div>
  //   </Container>
  // );
};

export default NotFound;
