import React, { ReactNode, useEffect, useRef, useState } from "react";
import SwiperCore from "swiper";
import { twMerge } from "tailwind-merge";
import { Swiper, SwiperClass, SwiperProps } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.scss";

import Preview from "../../../assets/svgs/preview.svg";
import Next from "../../../assets/svgs/next.svg";
import { PaginationOptions } from "swiper/types";

interface CustomSwiperProps extends SwiperProps {
  children: ReactNode;
  nextRef?: React.RefObject<HTMLImageElement>;
  previousRef?: React.RefObject<HTMLImageElement>;
  refPagination?: React.RefObject<HTMLDivElement>;
  navigation?: boolean;
  pagination?: boolean;
  className?: string;
  quantidade: number;
}

const useSwiperRef = <T extends HTMLElement>(): [
  T | undefined,
  React.Ref<T>
] => {
  const [wrapper, setWrapper] = useState<T>();
  const ref = useRef<T>(null);

  useEffect(() => {
    if (ref.current) {
      setWrapper(ref.current);
    }
  }, []);

  return [wrapper, ref];
};

export default function CustomSwiper({
  children,
  navigation = true,
  pagination = true,
  quantidade,
  className,
  nextRef: nextRefProp,
  previousRef: previousRefProp,
  refPagination: refPaginationProp,
  ...props
}: CustomSwiperProps) {
  const prevRef = useRef<HTMLImageElement>(null);
  const nextRef = useRef<HTMLImageElement>(null);
  const swipperRef = useRef<SwiperClass>();
  const [beginning, setBeginning] = useState<boolean>(true);
  const [end, setEnd] = useState<boolean>(false);
  const [qtdPorPagina, setQtdPorPagina] = useState(1);

  useEffect(() => {
    if (swipperRef.current) {
      const visibleSlides = swipperRef.current.params.slidesPerView;
      setQtdPorPagina(visibleSlides as any);
    }
  }, [swipperRef]);

  const onBeforeInit = (Swiper: SwiperCore): void => {
    swipperRef.current = Swiper;
    if (swipperRef.current) {
      swipperRef.current.on("slideChange", (swiper) => {
        setBeginning(swiper.isBeginning || false);
        setEnd(swiper.isEnd || false);
      });
    }

    setBeginning(swipperRef.current?.isBeginning || true);
    setEnd(swipperRef.current?.isEnd || false);
    if (typeof Swiper.params.navigation !== "boolean") {
      const navigation = Swiper.params.navigation;
      if (navigation !== undefined) {
        navigation.prevEl = prevRef.current;
        navigation.nextEl = nextRef.current;
      }
    }
  };

  return (
    <div className="relative h-full w-full box-border">
      <Swiper
        className={twMerge(className)}
        onBeforeInit={onBeforeInit}
        modules={[Pagination, Navigation, Autoplay]}
        allowSlidePrev={true}
        allowSlideNext={true}
        pagination={{
          clickable: true,
          enabled: pagination,
        }}

        {...props}
      >
        {children}
      </Swiper>
      {navigation &&
        (React.Children.count(children) > qtdPorPagina ||
          quantidade! > qtdPorPagina) && (
          <>
            <img
              ref={prevRef}
              src={Preview}
              onClick={() => swipperRef.current?.slidePrev()}
              alt="arrow-left"
              className={twMerge(
                "z-10 left-[-25px] cursor-pointer absolute top-[47%] transform -translate-y-1/2 hidden md:flex",
                pagination ? "top-[48%]" : "top-1/2",
                beginning ? "opacity-50" : ""
              )}
            />
            <img
              ref={nextRef}
              src={Next}
              onClick={() => swipperRef.current?.slideNext()}
              alt="arrow-left"
              className={twMerge(
                "z-10 right-[-25px] cursor-pointer absolute top-[48%] transform -translate-y-1/2 hidden md:flex",
                pagination ? "top-[47%]" : "top-1/2",
                end ? "opacity-50" : ""
              )}
            />
          </>
        )}
    </div>
  );
}
