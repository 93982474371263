import React, { useEffect, useRef, useState } from "react";
import { from } from "rxjs";
import { take } from "rxjs/operators";

import {
  ComponentPerguntaOpcaoRespostas,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
  useUpdatePerguntaQuizMutation,
} from "../../../generated/graphql";

import { BannerSection } from "../../../shared/components";

import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../../hooks/useBannerApi";

import "./styles.scss";
import { QuizComponent } from "./components/Quiz";
import {
  Banner,
  loadBanner,
} from "../../../graphql/collections/queries/banner.query";
import { RespostaModel } from "../../../models/RespostaModel";
import { PerguntaModel } from "../../../models/PerguntaModel";

/**
 * HOC component
 */
const QuizHOC: React.FC<{
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
}> = ({ oe, user }) => {
  const nextRef = useRef(null);
  const previousRef = useRef(null);

  const [updatePerguntaQuiz] = useUpdatePerguntaQuizMutation();
  const [showBanner, setShowBanner] = useState(true);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: oe.id! ? Number(oe.id!) : undefined,
      areaDeConhecimentoId:
        oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        oe.attributes?.SubAreaConhecimento?.data?.map((a) => Number(a.id!)) ||
        [],
      publicoAlvoId:
        oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) || [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [oe]);

  const updateQuestionCounter = (
    pergunta: PerguntaModel,
    resposta: RespostaModel,
  ) => {
    const respostasInalteradas: {
      id: string;
    }[] = pergunta.respostas
      .filter((opcao) => opcao.id !== resposta.id)
      .map((opcao) => {
        return { id: opcao.id.toString() };
      });

    from(
      updatePerguntaQuiz({
        variables: {
          id: pergunta.id.toString(),
          input: {
            respostas: [
              ...respostasInalteradas,
              {
                id: resposta.id.toString(),
                contador: (resposta?.contador as number) + 1,
              },
            ],
          },
        },
      }),
    )
      .pipe(take(1))
      .subscribe();
  };

  const _props = {
    oe,
    user,
    updateQuestionCounter,
    nextRef,
    previousRef,
  };

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  return (
    <>
      {(() => {
        let bannersFixo = banners?.filter(
          (a) =>
            a.tipoPagina === TIPO_PAGINA.OE &&
            a.areaPagina === TIPO_AREA_PAGINA.FIXO &&
            ((!!user?.id && a.ocultarLogado === false) || !user?.id),
        );

        return (
          <>
            {showBanner && !!bannersFixo && !!bannersFixo.length && (
              <div
                data-banner
                style={{
                  zIndex: 99999,
                }}
                className={
                  !user
                    ? "deslogado"
                    : "fixed bottom-0 left-0 right-0 z-50 mx-auto flex w-full flex-col items-center justify-center md:w-[728px]"
                }
              >
                <BannerSection
                  fixo={true}
                  items={bannersFixo}
                  onClose={closeBanner}
                  showBanner={showBanner}
                />
              </div>
            )}
          </>
        );
      })()}

      <QuizComponent
        oe={oe}
        user={user}
        updateQuestionCounter={updateQuestionCounter}
        nextRef={nextRef}
        previousRef={previousRef}
      />
    </>
  );
};

export default QuizHOC;
