import { useEffect, useState } from "react";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import Container from "../../../shared/components/Container";
import { Dot } from "lucide-react";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../shared/components/ui/tool-tip";
import { Button } from "../../../shared/components/ui/button";

interface SimulacaoRealistica {
  id: number;
  titulo: string;
  descricao: string;
  urlImagem: string;
  linkUrl: string;
}

interface SimulacaoRealisticaResponse {
  simulacaoRealistica: SimulacaoRealistica[];
}
export default function CursosPagosResidencia() {
  const [simulacaoRealistica, setSimulacaoRealistica] =
    useState<SimulacaoRealisticaResponse>();

  useEffect(() => {
    async function getSimulacaoRealistica() {
      const response = await fetchApi(
        "residencia/get-simulacao-realistica?busca=busca",
        "GET",
      );
      if (response.ok) {
        const data = await response.json();
        setSimulacaoRealistica(data);
      } else {
        setSimulacaoRealistica({} as SimulacaoRealisticaResponse);
      }
    }
    getSimulacaoRealistica();
  }, []);

  return (
    <div className="bg-[#f7f7f7]">
      <Container className="flex flex-col gap-6 py-10">
        <div className="">
          <p className="flex-nowrap text-lg font-bold leading-8 text-[#004F92] md:text-[22px] tablet:text-start">
            Simulação Realística{" "}
          </p>
          <p className="text-base font-semibold leading-[19px] text-[#004F92] md:text-[22px] md:leading-8">
            Formação prática no Centro de Simulação Realística do Einstein{" "}
          </p>
        </div>
        <div>
          <p className="text-sm font-normal leading-6 text-[#313335] md:text-base">
            Os cursos realizados no Centro de Simulação Realística do Ensino
            Einstein irão preparar os alunos para prestar assistência ao
            paciente, de forma prática, simulando diferentes ambientes e
            situações que podem ocorrer no dia a dia do ambiente hospitalar.
            Assim, os alunos podem aprender de forma segura e eficiente.
          </p>
        </div>
        <div className="">
          <Carousel
            opts={{
              breakpoints: {
                "(min-width: 768px)": { slidesToScroll: 3 },
              },
            }}
          >
            <CarouselContent className="overflow-hidden bg-[#f7f7f7] md:p-2">
              {simulacaoRealistica?.simulacaoRealistica.map(
                (simulacao, index) => (
                  <CarouselItem
                    key={index}
                    className="rounded-xl md:basis-1/2 lg:basis-1/3"
                  >
                    <div
                      key={index}
                      className="w-full overflow-hidden rounded-xl bg-white-200 shadow-md md:w-[352px] tablet:h-full tablet:w-full "
                    >
                      <img src={simulacao.urlImagem} alt={simulacao.titulo} />
                      <div className="flex flex-col gap-2 p-6">
                        <p className="text-base font-semibold text-[#21272A]">
                          {simulacao.titulo}
                        </p>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger className="leading-6" asChild>
                              <p className="line-clamp-6 text-sm font-normal ">
                                {simulacao.descricao}
                              </p>
                            </TooltipTrigger>
                            <TooltipContent className="max-w-lg bg-black-900 text-gray-500">
                              <p className=" text-sm font-normal">
                                {simulacao.descricao}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <div className="flex w-full justify-center pb-4">
                        <Button
                          data-testid="btn-saiba-mais-simulacao"
                          id={`btn-saiba-mais-simulacao_${index}`}
                          variant="primary"
                          size="default"
                          onClick={() =>
                            window.open(simulacao.linkUrl, "_blank")
                          }
                        >
                          Saiba mais
                        </Button>
                      </div>
                    </div>
                  </CarouselItem>
                ),
              )}
            </CarouselContent>
            <CarouselPrevious
              data-testid="btn-previous-continue-assistindo"
              height="h-[0px] md:h-[227.66px]"
              className="hidden md:block"
            />
            <CarouselNext
              data-testid="btn-next-continue-assistindo"
              height="h-[0px] md:h-[227.66px]"
              className="hidden md:block"
            />

            <CarouselBullets />
          </Carousel>
        </div>
      </Container>
    </div>
  );
}
