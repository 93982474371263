import React from "react";
import { PerguntaModel } from "../../../../models/PerguntaModel";

export interface EnunciadoQuizProps {
  perguntaAtiva?: PerguntaModel;
}

export const EnunciadoQuiz: React.FC<EnunciadoQuizProps> = ({
  perguntaAtiva,
}) => {
  return (
    <div className="!mt-5 md:!mt-5">
      <h4
        data-line-height="1-2"
        className="text-[#111316] !text-sm md:!text-base !font-normal !leading-[19.5px] !font-[Montserrat]"
        dangerouslySetInnerHTML={{
          __html: perguntaAtiva?.enunciado
            ?.replaceAll("font-size:", "fonte")
            ?.replaceAll("<p>", "<span>")
            ?.replaceAll("</p>", "</span><br />") || '',
        }}
      />
    </div>
  );
};
