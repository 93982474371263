import * as Icon from "../../../assets/svgs";
import { Button } from "../ui/button";

export default function ModalDegustacaoPos() {
  return (
    <div className="bg-white z-50 flex h-auto  items-center justify-center rounded-3xl p-6 md:py-8 ">
      <div className="flex w-[320px]  flex-col items-center justify-center md:w-[417px]">
        <Icon.Confirmacao className="w-[209.11px] md:w-full " />
        <span className="mt-3 text-center text-base font-bold leading-[22px] text-[#004F92]">
          Falta pouco para você
          <br /> experimentar o EAD do Einstein!
        </span>
        <p className="mt-4 text-center text-sm font-normal leading-5 text-[#111316]">
          Em até 24 horas receberá suas instruções
          <br /> de acesso em seu e-mail.
        </p>
        <p className="mt-2 text-center text-sm font-normal leading-5 text-[#111316]">
          Enquanto isso, fique à vontade para navegar
          <br /> nos conteúdos da Academia Digital.
        </p>
        <Button
          data-testid="btn-explorar-a-academia-digital-einstein"
          onClick={() => {
            window.location.href = "/";
          }}
          className="relative mt-4 h-[38px] w-auto rounded-[26px] bg-[#004F92] px-4 !text-[11.98px] font-semibold text-[#FFFFFF] hover:bg-[#3777af]	md:mt-12 "
        >
          Explorar a Academia Digital Einstein
        </Button>
      </div>
    </div>
  );
}
