import React, { useEffect, useState } from "react";

import {
  ObjetoEducacionalEntity,
  useGetOesByPalavrasChaveIdLazyQuery,
} from "../../../../generated/graphql";
import { CardSection } from "../../../../shared/components";
import Container from "../../../../shared/components/Container";

interface OesRealacionadosProps {
  oe: ObjetoEducacionalEntity;
}

export const OesRelacionados: React.FC<OesRealacionadosProps> = (props) => {
  const [lista, setLista] = useState<ObjetoEducacionalEntity[]>([]);

  useEffect(() => {
    if (!props.oe || !props.oe.attributes?.palavras_chave?.data) return;

    const ids = props.oe.attributes?.palavras_chave.data.reduce<string[]>(
      (acc, item) => {
        acc.push(item.id?.toString()!);
        return acc;
      },
      [] as string[]
    );

    GetOesByPalavrasChaveId({
      variables: {
        where: {
          id: {
            in: ids,
          },
        },
      },
    });
  }, [props.oe.id]);

  const [GetOesByPalavrasChaveId] = useGetOesByPalavrasChaveIdLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (!response || !response.palavraChaves) {
        return;
      }

      const objectOes: any = {};
      for (const palavraChave of response.palavraChaves.data) {
        if (!!palavraChave.attributes?.objeto_educacionais?.data) {
          for (const oe of palavraChave.attributes?.objeto_educacionais.data) {
            const isNotInObject = !objectOes[oe.id!];
            const isNotOePage = oe.id?.toString() !== props.oe.id?.toString();
            const containImagemPreview = !!oe.attributes?.ImagemPreview.data;

            if (isNotInObject && isNotOePage && containImagemPreview) {
              objectOes[oe.id!] = oe;
            }
          }
        }
      }

      const relacionados = props.oe.attributes?.ConteudosRelacionados?.data;

      const palavras = Object.keys(objectOes).map((id) => objectOes[id]);

      relacionados?.push(...palavras);

      // Convert object to list
      setLista(relacionados!);
    },
  });

  return (
    <>
      {!!lista && !!lista.length && (
        <Container>
          <div className={(lista.length < 3 && "swipper-button-hidden") || ""}>
            <CardSection
              title="Conteúdos Relacionados"
              items={lista}
              showAllSee={true}
            />
          </div>
        </Container>
      )}
    </>
  );
};
