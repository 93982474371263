import React, { useEffect, useState } from "react";
import CalendarGreen from "../../../assets/svgs/calendar-green.svg";
import CalendarWhite from "../../../assets/svgs/calendar-white.svg";
import CalendarBlue from "../../../assets/svgs/calendar-blue.svg";

import "./styles.scss";

interface IDateViewProps {
  date: string;
  animate?: boolean;
  color?: "green" | "white" | "blue";
}

const formatDate = (date: string) => {
  const month = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];

  if (!date) {
    return "";
  }

  date = `${date.slice(0, 10)}T03:00:00`;

  const _date = new Date(date);

  const _newDateFormatted = `${_date.getDate()} ${
    month[_date.getMonth()]
  } ${_date.getFullYear().toString().slice(2, 4)}`;

  return _newDateFormatted;
};

export const DateView: React.FC<IDateViewProps> = ({
  date,
  animate,
  color,
}) => {
  const [_date, setDate] = useState([] as any);

  useEffect(() => {
    if (date) {
      setDate(
        formatDate(date)
          .split("")
          .map((char, idx) => (
            <span key={idx} className={char !== " " ? "" : "space"}>
              {char}
            </span>
          )),
      );
    }
  }, [date]);

  const getIcon = (_color: "blue" | "white" | "green") => {
    switch (_color) {
      case "blue":
        return CalendarBlue;
      case "green":
        return CalendarGreen;
      default:
        return CalendarWhite;
    }
  };

  return (
    <div
      data-date-view
      data-animate={animate}
      className="flex items-end p-[1px]"
    >
      <img
        className="date-icon"
        src={getIcon(color!)}
        alt="Ícone de calendário"
      />
      <div style={{ color: color === "white" ? "white" : "" }}>
        {animate ? (
          _date
        ) : (
          <span
            className={`text-center text-[11px] font-normal !leading-4
      ${color === "blue" ? " text-[#107B95]" : "text-white"}`}
          >
            {formatDate(date)}
          </span>
        )}
      </div>
    </div>
  );
};
