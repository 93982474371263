import { useLocation } from "react-router";

/**
 * Custom Hook para manipular query string.
 *
 * @usage:
 *  <code>
 *    URL: http://example.com/search?query=foo
 *    const qs = useQuerystring();
 *    // GETTER
 *    query.get('search'); // foo
 *
 *    // SETTER
 *    query.set('search', 'bar');
 *    location.history.push(`/search?${query}`) // search?query=bar
 *  </code>
 * @return {String|null}
 * */
export function useQuerystring() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Helper que devolve um objeto à partir de query params,
 * de maneira a facilitar a construção da consulta.
 */
export const useQueryParams = <T,>(): T => {
  const _params = new URLSearchParams(window.location.search);
  const iterator = _params.entries();
  let param = iterator.next();
  let o: any = {};

  while (!param.done) {
    o[param.value[0]] = param.value[1].split(",");
    param = iterator.next();
  }

  return o;
};
