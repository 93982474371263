import React from "react";
import { BreadCrumbQuiz } from "./BreadCrumb";
import { InfoQuiz } from "./Info";
import RecomendadoQuiz from "./Recomendado";
import { RotuloDesktopQuiz } from "./RotuloDesktop";
import { ProgressoTesteConhecimentoQuiz } from "./ProgressoTesteConhecimento";
import Titulo from "./Titulo";
import { QuizModel } from "../../../../models/QuizModel";

export interface HeaderQuizProps {
  oe: any;
  isTesteConhecimento: boolean;
  perguntasTotais: number;
  activeQuestionIndex: number;
  quizAtivo: QuizModel;
  isResidencia: boolean;
}

export const HeaderQuiz: React.FC<HeaderQuizProps> = ({
  oe,
  isTesteConhecimento,
  perguntasTotais,
  quizAtivo,
  activeQuestionIndex,
  isResidencia,
}) => {
  return (
    <header>
      {!isResidencia && (
        <>
          <BreadCrumbQuiz isTesteConhecimento={isTesteConhecimento} />
          <RotuloDesktopQuiz oe={oe} />
          <InfoQuiz
            isTesteConhecimento={isTesteConhecimento}
            perguntasTotais={perguntasTotais}
            oe={oe}
          />
        </>
      )}
      <RecomendadoQuiz oe={oe} />
      {isTesteConhecimento ? (
        <ProgressoTesteConhecimentoQuiz
          activeQuestionIndex={activeQuestionIndex}
          perguntasTotais={perguntasTotais}
          quizAtivo={quizAtivo}
        />
      ) : (
        <Titulo quizAtivo={quizAtivo} />
      )}
    </header>
  );
};
