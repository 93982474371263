import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  EDataLayerEventCategory,
  EDataLayerEventAction,
  useGtm,
} from "../../../hooks/useGtm";
import { upsertUserContext, UserContext } from "../../../context/UserContext";
import InputMask from "react-input-mask";

import { ModalService } from "../../components/Modal";

import {
  useGetUserByIdCacheLazyQuery,
  useLoginMutation,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import { useHistory } from "react-router";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import Container from "../../components/Container";
import ReCAPTCHA from "react-google-recaptcha";
import fetchApi, { fetchApiNoRetry } from "../../services/rest-dotnet.service";
import { mostrarModalLogin } from "../ModalLogin";
import ModalEmailEnviado from "../ModalEmailEnviado";
import { set } from "date-fns";

interface FlowLogin {
  cpf: string;
  email: string;
  toggleOtherDocument?: boolean;
  documentType?: string;
  documentNumber?: string;
}

declare global {
  interface Window {
    grecaptcha: any;
  }
}

async function loginFlowApi(email: string, cpf: string, token: string) {
  const response = await fetchApiNoRetry(
    `user/flow-create-user`,
    "POST",
    JSON.stringify({
      cpf: cpf,
      email: email,
      token,
    }),
  );

  const data = await response;

  // Comparar a pontuação com o limiar definido para a ação específica
  return data;
}

interface responseFlow {
  email: string;
  flow: string;
}

export const ModalFlowLogin: React.FC<{ isMessageGratuito: boolean }> = ({
  isMessageGratuito,
}) => {
  const [error, setError] = useState("");
  const [loginMutation, { loading: loadingLogin }] = useLoginMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [responseMensage, setMensageResponse] = useState<responseFlow>();
  const [showEmailEnviado, setShowEmailEnviado] = useState<boolean>(false);
  const { setUser, stayConnected } = useContext(UserContext);
  const recaptchaRef = useRef(null);
  const history = useHistory();

  const gtm = useGtm();

  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

  useEffect(() => {
    // Carregar o script do reCAPTCHA v3 dinamicamente
    const loadRecaptchaScript = () => {
      if (!document.getElementById("recaptcha-script")) {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.id = "recaptcha-script";
        document.body.appendChild(script);
      }
    };

    loadRecaptchaScript();
  }, []);

  const [GetUserByIdQuery, { loading: loadingUserByID }] =
    useGetUserByIdCacheLazyQuery({
      onError: (error) => {
        setLoading(false);
        console.error(error);
      },
      onCompleted: (data) => {
        try {
          const { findMe: user } = data;

          setUser(user as UsersPermissionsUserEntity);
          const expirationTime = stayConnected
            ? null
            : new Date(new Date().getTime() + 1000 * 60 * 60 * 8);
          upsertUserContext({
            user: user as UsersPermissionsUserEntity,
            expirationTime,
          });
          window.location.reload();
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      },
    });

  const handleSubmit = async (values: FlowLogin) => {
    let token = "";
    if (
      process.env.REACT_APP_RECAPTCHA_SITE_KEY &&
      !process.env.REACT_APP_RECAPTCHA_SITE_KEY.startsWith("#")
    ) {
      const recaptchaValue = (recaptchaRef as any).current.getValue();
      if (!recaptchaValue) {
        setError("Por favor, verifique o reCAPTCHA.");
        return;
      }

      token = recaptchaValue;
    }

    try {
      setLoading(true);

      const response = await loginFlowApi(
        values.email,
        values.toggleOtherDocument ? "" : values.cpf,
        token,
      );

      if (response?.error) {
        setError(response.error);
        setLoading(false);
        setShowEmailEnviado(false);
        return;
      }

      if (response) {
        if (response?.flow === 20) {
          setMensageResponse(response);
          setShowEmailEnviado(true);
        }
        if (response?.flow === 50) {
          ModalService.hide();
          history.push("/novo-usuario-v2/dados-pessoais", {
            emailCadastro: values.email,
            cpfCadastro: values.cpf,
          });
        }
        setLoading(false);
        return;
      }

      setLoading(false);
    } catch (reponse: any) {
      if (reponse.error?.errors?.Messages) {
        const messages = reponse.error?.errors.Messages;
        setError(messages.join(" ")); // Une as mensagens, caso haja mais de uma
      }
      (recaptchaRef as any).current?.reset();
      setLoading(false);
      setShowEmailEnviado(false);
      setMensageResponse({
        email: "",
        flow: "",
      });
    }
  };

  const formik = useFormik<FlowLogin>({
    initialValues: {
      email: "",
      cpf: "",
      toggleOtherDocument: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("E-mail inválido")
        .required("Campo obrigatório"),
      cpf: Yup.string(),
      toggleOtherDocument: Yup.boolean(),
    }),
    onSubmit: handleSubmit,
  });

  const handleKeyDown = (e: any) => {
    if (e.nativeEvent.key == "Enter") {
      const form = e.target.form;
      const formFields = [...form.elements];
      const formFieldsWithoutButton = formFields.filter(
        (element) => element.type !== "button",
      );
      const index = [...formFieldsWithoutButton].indexOf(e.target);

      formFieldsWithoutButton[index + 1].focus();

      e.preventDefault();
    }
  };

  const handleClose = () => {
    ModalService.hide();
    setShowEmailEnviado(false);
    setMensageResponse({
      email: "",
      flow: "",
    });
  };

  if (showEmailEnviado) {
    return (
      <ModalEmailEnviado
        email={responseMensage?.email || ""}
        handleClose={() => {
          handleClose();
          ModalService.hide();
        }}
      />
    );
  }

  return (
    <Container className="w-[90vw] sm:w-[450px]">
      <div className="flex !w-full flex-col items-center justify-center py-10">
        <div className="flex w-full max-w-[250px] md:max-w-[302px]">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="flex flex-col gap-3"
          >
            <h4 className="self-center text-center text-xl  font-bold leading-26 text-[#004F92]">
              Crie sua conta
            </h4>
            <div className="flex flex-col gap-3">
              <div className="flex w-full flex-col">
                <label className="text-sm font-normal leading-normal text-[#313335]">
                  E-mail
                  <Input
                    className="mt-2 h-[50px] w-full rounded-[30px] !bg-[#FAFAFC]"
                    type="email"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errorMessage={
                      formik.touched.email ? formik.errors.email : ""
                    }
                  />
                </label>
              </div>
            </div>

            <div className="flex w-full flex-col">
              {!formik.values.toggleOtherDocument && (
                <InputMask
                  mask="999.999.999-99"
                  onChange={formik.handleChange}
                  value={formik.values.cpf}
                  placeholder="999.999.999-99"
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      label="CPF*"
                      id="cpf"
                      name="cpf"
                      onChange={formik.handleChange}
                      onKeyPress={handleKeyDown}
                      value={formik.values.cpf}
                      errorMessage={formik.errors.cpf}
                      placeholder="Digite seu CPF"
                    />
                  )}
                </InputMask>
              )}
              {formik.values.toggleOtherDocument && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <Input
                      label="Numero do documento"
                      id="documentNumber"
                      name="documentNumber"
                      onChange={formik.handleChange}
                      onKeyPress={handleKeyDown}
                      value={formik.values.documentNumber}
                      errorMessage={formik.errors.documentNumber}
                      placeholder="Digite o número do documento"
                    />
                  </div>
                </div>
              )}
              {!formik.values.toggleOtherDocument && (
                <div
                  onClick={(ev) => {
                    formik.setValues({
                      ...formik.values,
                      toggleOtherDocument: !formik.values.toggleOtherDocument,
                      cpf: "",
                    });
                  }}
                  className="mt-1 flex w-full justify-end"
                >
                  <span className="cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                    Não tenho CPF
                  </span>
                </div>
              )}
              {formik.values.toggleOtherDocument && (
                <a
                  onClick={(ev) => {
                    formik.setValues({
                      ...formik.values,
                      toggleOtherDocument: !formik.values.toggleOtherDocument,
                      documentType: "",
                      documentNumber: "",
                    });
                  }}
                  className="mt-1 flex justify-end text-end"
                >
                  <span className="cursor-pointer text-end text-xs font-semibold text-[#004F92] underline">
                    Tenho CPF
                  </span>
                </a>
              )}
            </div>
            {process.env.REACT_APP_RECAPTCHA_SITE_KEY &&
              !process.env.REACT_APP_RECAPTCHA_SITE_KEY.startsWith("#") && (
                <div className="flex items-center justify-center text-sm font-normal leading-none">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                    ref={recaptchaRef}
                    onChange={(token) => {
                      setError("");
                    }}
                  />
                </div>
              )}
            <div className="flex w-full items-center justify-center md:flex-row">
              <Button
                data-testid="btn-entrar-modal-login"
                type="submit"
                className="w-[249px] md:w-[119px]"
                size="sm"
                disabled={loadingLogin || loadingUserByID || loading}
              >
                {loadingLogin || loadingUserByID || loading
                  ? `Aguarde ...`
                  : `Continuar`}
              </Button>
            </div>

            <div className="flex w-full justify-center text-center">
              {error && (
                <span className="animate-pulse self-center text-sm font-semibold text-red-600">
                  {error}
                </span>
              )}
            </div>
            <hr />
            <div className="flex flex-row flex-nowrap items-center justify-center gap-2">
              <span className="!text-sm font-medium text-[#626466]">
                Já é usuário?
              </span>
              <button
                data-testid="btn-criar-conta"
                type="button"
                className="!text-sm font-semibold text-[#004F92]"
                onClick={() => {
                  ModalService.hide();
                  mostrarModalLogin(true);

                  // GTM doc linha 34
                  gtm.push(
                    EDataLayerEventCategory.ADE_LOGIN,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: "login-topo",
                    },
                  );
                }}
              >
                Entrar
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export const mostrarModalFlowLogin = (
  isMessageGratuito?: boolean,
  onCloseModal?: () => void,
) => {
  if (ModalService.show) {
    ModalService.show({
      showCloseButton: true,
      onClose: onCloseModal,
      closeOutsideModal: true,
      notFullScreenMobile: true,
      content: (
        <ModalFlowLogin isMessageGratuito={isMessageGratuito || false} />
      ),
    });
  }
};
