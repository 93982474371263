import { Label } from "@radix-ui/react-label";
import { Button } from "../ui/button";
import fetchApi from "../../services/rest-dotnet.service";
import { useContext, useEffect, useState } from "react";
import { ScrollArea } from "../ui/scroll-area";
import { Input } from "../ui/input";
import { Loader2, PlusIcon, Trash2, X } from "lucide-react";
import { AlertContext } from "../../../context/UseAlert";
import { useModalPlaylistConfirmDelete } from "../../../hooks/useModalConfirmDelete";

export interface Playlist {
  id: number;
  titulo: string;
  existeOe: boolean;
}

export interface PlaylistResponse {
  items: Playlist[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

interface SearchProps {
  oeId?: string;
  titulo?: string;
  sort?: string;
  page?: string;
}

export default async function getCategoriasPlaylists({
  oeId,
  sort,
  titulo,
  page,
}: SearchProps) {
  const response = await fetchApi(
    "playlists/usuario/playlists/modal",
    "POST",
    JSON.stringify({
      titulo: titulo || "",
      pageIndex: page,
      OeId: oeId,
      pageSize: "20",
      ordernar: 1,
    }),
  );

  return response.json();
}

export function ModalPlaylistCard({ idOe }: { idOe: number }) {
  const [playlist, setPlaylist] = useState<PlaylistResponse>();
  const [criarPlaylist, setCriarPlaylist] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [loadingCriar, setLoadingCriar] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean | number>(false);
  const [playlistChecked, setPlaylistChecked] = useState<number[]>([]);
  const [newPlaylistName, setNewPlaylistName] = useState<string>("");
  const { showMessage } = useContext(AlertContext);
  const modalConfirmDelete = useModalPlaylistConfirmDelete();

  useEffect(() => {
    const listPlaylistsUser = async () => {
      setLoading(true);
      const playlists: PlaylistResponse = await getCategoriasPlaylists({
        oeId: idOe.toString(),
        page: "1",
        sort: "1",
      });

      setPlaylist(playlists);
      setPlaylistChecked(
        playlists.items.filter((item) => item.existeOe).map((item) => item.id),
      );
      setLoading(false);
    };

    listPlaylistsUser();
  }, [idOe]);

  const updateCheckPlaylist = async (itemId: number) => {
    setLoadingUpdate(itemId);

    const updatedPlaylistChecked = [...playlistChecked];
    const isCurrentlyChecked = playlistChecked.includes(itemId);

    try {
      const payload: any = {
        oeId: idOe,
        playlistId: itemId,
      };

      if (itemId === 0 && newPlaylistName) {
        payload.descricao = newPlaylistName;
      }

      if (isCurrentlyChecked) {
        const index = updatedPlaylistChecked.indexOf(itemId);
        await removeItemPlaylist({ oeId: idOe, playlistId: itemId });
        updatedPlaylistChecked.splice(index, 1);
      } else {
        const response = await fetchApi(
          `playlists/usuario/save`,
          "POST",
          JSON.stringify(payload),
        );
        if (response.ok) {
          updatedPlaylistChecked.push(itemId);
          showMessage("Conteúdo adicionado com sucesso!", "SUCESS");
        } else {
          showMessage("Erro ao adicionar objeto", "ERRO");
        }
      }
      setPlaylistChecked(updatedPlaylistChecked);
    } catch (error) {
      showMessage("Erro ao adicionar objeto", "ERRO");
    } finally {
      setLoadingUpdate(false);
    }
  };

  const removeItemPlaylist = async ({
    oeId,
    playlistId,
  }: {
    oeId: number;
    playlistId: number;
  }) => {
    setLoadingCriar(true);

    try {
      const response: any = await fetchApi(
        `playlists/remove-item-playlist?playlistId=${playlistId}&oeId=${oeId}`,
        "POST",
      );

      if (response.ok) {
        const playlists: PlaylistResponse = await getCategoriasPlaylists({
          oeId: idOe.toString(),
          page: "1",
          sort: "1",
        });
        setPlaylist(playlists);
        setPlaylistChecked(
          playlists.items
            .filter((item) => item.existeOe)
            .map((item) => item.id),
        );
        setNewPlaylistName("");
        setCriarPlaylist(false);
        showMessage("Objeto removido da playlist com sucesso!", "SUCESS");
      } else {
        showMessage("Erro ao remover da playlist.", "ERRO");
      }
    } catch (error) {
      showMessage("Erro ao remover da playlist.", "ERRO");
    } finally {
      setLoadingCriar(false);
    }
  };

  const createPlaylist = async ({ oeId }: { oeId: number }) => {
    if (!newPlaylistName) return;

    setLoadingCriar(true);

    try {
      const payload = {
        descricao: newPlaylistName,
        oeId: oeId,
      };

      const response: any = await fetchApi(
        "playlists/usuario/save",
        "POST",
        JSON.stringify(payload),
      );

      const newPlaylist = await response.json();

      if (newPlaylist?.value === "Sucesso") {
        const playlists: PlaylistResponse = await getCategoriasPlaylists({
          oeId: idOe.toString(),
          page: "1",
          sort: "1",
        });
        setPlaylist(playlists);
        setPlaylistChecked(
          playlists.items
            .filter((item) => item.existeOe)
            .map((item) => item.id),
        );
        setNewPlaylistName("");
        setCriarPlaylist(false);
        showMessage("Playlist criada com sucesso!", "SUCESS");
      } else {
        showMessage(newPlaylist.value, "ERRO");
      }
    } catch (error) {
      showMessage("Erro ao cadastrar a playlist.", "ERRO");
    } finally {
      setLoadingCriar(false);
    }
  };

  return (
    <div className="w-[300px] p-6" key={idOe}>
      <div>
        <h2 className="normal-case text-black-900">Salvar na playlist...</h2>
      </div>
      <ScrollArea className=" overflow-y-auto py-3">
        <div className="grid gap-3 p-1 py-2">
          {loading && (
            <div className="flex justify-center">
              <Loader2 className="size-8 animate-spin" />
            </div>
          )}
          {playlist?.items?.length === 0 ? (
            <Label className="text-sm font-normal italic leading-18 text-black-900">
              Nenhuma playlist encontrada...
            </Label>
          ) : (
            playlist?.items?.map((item, index) => (
              <div key={index}>
                <div className="flex flex-row justify-between">
                  <Label
                    key={item.id}
                    className="flex cursor-pointer items-center justify-between gap-2 text-sm font-normal leading-18 text-black-900 hover:text-gray-500"
                  >
                    {loadingUpdate === item.id ? (
                      <Loader2 className="size-6 animate-spin" />
                    ) : (
                      <input
                        type="checkbox"
                        id={item?.id?.toString()}
                        className="size-5 cursor-pointer accent-[#004F92]"
                        checked={playlistChecked.includes(item.id)}
                        onChange={() => updateCheckPlaylist(item.id)}
                      />
                    )}
                    {item.titulo}
                  </Label>
                  <button
                    data-testid="btn-delete"
                    onClick={() => {
                      modalConfirmDelete.mostrar({
                        idPlaylist: item.id,
                      });
                    }}
                  >
                    <Trash2 />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </ScrollArea>
      <div>
        {criarPlaylist ? (
          <div className="flex w-full flex-col items-center justify-center gap-4">
            <div className="flex w-full flex-col items-start justify-start gap-2">
              <Label htmlFor="nome" className="text-right text-sm">
                Nome
              </Label>
              <Input
                id="nome"
                placeholder="Inserir o título da playlist"
                className="col-span-3"
                autoComplete="off"
                autoFocus
                value={newPlaylistName}
                onChange={(e) => {
                  setNewPlaylistName(e.target.value);
                }}
              />
            </div>

            <Button
              onClick={() => createPlaylist({ oeId: idOe })}
              data-testid="btn-criar"
              id="btn-criar"
              variant="primary"
              size="default"
              className="w-[96px]"
              disabled={loadingCriar || newPlaylistName.trim() === ""}
            >
              {loadingCriar ? (
                <Loader2 className="size-4 animate-spin" />
              ) : (
                "Criar"
              )}
            </Button>
          </div>
        ) : (
          <div className="flex w-full items-center justify-center">
            <button
              onClick={() => setCriarPlaylist(true)}
              data-testid="btn-criar-playlist"
              className="flex w-full items-center gap-3 text-sm font-normal leading-18 text-black-900 hover:text-gray-600"
            >
              <PlusIcon className="size-8" />
              Criar Playlist
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
