import * as Yup from "yup";
import { parse, isValid } from "date-fns";

import { existsUser } from "../../../../../../../graphql/auth/queries/user.graphql";

export const dadosUsuarioValidator = Yup.object().shape({
  nome_completo: Yup.string()
    .required("Campo obrigatório")
    .min(3, "Quantidade mínima de caracteres não atingida")
    .max(255, "Quantidade máxima de caracteres ultrapassada")
    .test({
      message: "Campo possui caracteres não permitidos.",
      test: (value) => {
        return !!value && !/[0-9<>/"'&]/.test(value);
      },
    })
    .test({
      name: "nome_completo",
      message: "Nome e sobrenome são obrigatórios",
      test: (value) => {
        return !!value && value.trim().split(" ").length >= 2;
      }
    }),
  data_nascimento: Yup.date()
    .transform((value, originalValue) => {
      try {
        const regexDataNascimento =
          /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        // /^(0?[1-9]|[12][0-9]|3[01])(0?[1-9]|1[012])\d{4}$/;

        if (!regexDataNascimento.test(originalValue)) {
          return new Date();
        }

        const parsedDate = parse(originalValue, "dd/MM/yyyy", new Date());

        if (parsedDate.toString() === "Invalid Date") {
          return new Date();
        }

        const validated = isValid(parsedDate) ? parsedDate : new Date();

        return validated;
      } catch (error) {
        console.error("error data de nascimento", error);
        return new Date();
      }
    })
    .test({
      message: "Campo inválido",
      test: (value) => {
        if (!value) {
          return false;
        }

        const anoDataNascimento = value.getFullYear();
        const anoAtual = new Date().getFullYear();

        const totalAnos = anoAtual - anoDataNascimento;

        return totalAnos >= 13 && totalAnos <= 100;
      },
    }),
  celular: Yup.string()
    .required("Campo obrigatório")
    .when("isInternationPhoneNumber", {
      is: false,
      then: Yup.string().test({
        message: "Número de celular inválido",
        test: (value) => {
          return (
            // /^\([1-9]{2}\) 9 ([0-9]{4}\-[0-9]{4})$/
            !!value && /^\([1-9]{2}\) 9 ([0-9]{4}\-[0-9]{4})$/.test(value)
          );
        },
      }),
    })

    .when("isInternationPhoneNumber", {
      is: true,
      then: Yup.string().test({
        message: "Invalid cell number",
        test: (value) => {
          return !!value && value.length >= 6;
        },
      }),
    }),
});

const validarCPf = (Cpf: string | undefined) => {
  Cpf = !!Cpf ? Cpf.replace(/(\.|-)/g, "") : "0";

  if (Cpf === "") {
    return false;
  }

  if (
    Cpf.length !== 11 ||
    Cpf === "00000000000" ||
    Cpf === "11111111111" ||
    Cpf === "22222222222" ||
    Cpf === "33333333333" ||
    Cpf === "44444444444" ||
    Cpf === "55555555555" ||
    Cpf === "66666666666" ||
    Cpf === "77777777777" ||
    Cpf === "88888888888" ||
    Cpf === "99999999999"
  ) {
    return false;
  }

  let add = 0;
  for (let i = 0; i < 9; i++) {
    add += parseInt(Cpf.charAt(i)) * (10 - i);
  }

  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(9))) {
    return false;
  }

  add = 0;
  for (let i = 0; i < 10; i++) {
    add += parseInt(Cpf.charAt(i)) * (11 - i);
  }

  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(Cpf.charAt(10))) {
    return false;
  }

  return true;
};
