import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CategoriaTemaEntity, TemaEntity, useGetCategoriasTemasLazyQuery } from "../../../generated/graphql";
import { useGtm } from "../../../hooks/useGtm";
import { Origem } from "../../OeAreaConhecimentoPage";

import "./styles.scss";

interface TemaProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
} 

export const OeTema: React.FC<TemaProps> = (props) => {
  const gtm = useGtm();
  const [data, setData] = useState<CategoriaTemaEntity[]>([]);
  const history = useHistory();

  const [GetCategorias] = useGetCategoriasTemasLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (response.categoriaTemas?.data) {
        setData(response.categoriaTemas.data as CategoriaTemaEntity[]);
      } else {
        setData([]);
      }
      props.setLoading(false);
    },
    onError: (error) => {
      props.setLoading(false);
    },
  })

  useEffect(() => {
    props.setLoading(true);
    GetCategorias({
      variables: {
        limit: 12,
        start: 0,
        sort: 'titulo',
        sortTema: 'titulo'
      }
    });
  }, [])

  const handleOe = (item: TemaEntity) => {
    history.push(`/temas-saude/${item.attributes?.titulo.toLowerCase().split(' ').join('-')}/${item.id}`, {
      origem: Origem.TEMA,
      titulo: item.attributes?.titulo
    })
  }

  return (
    <>
      {
        data.map(item => 
          {
            
            return item.attributes?.Temas && item.attributes?.Temas.data.length > 0 && (<div data-tema key={item.id}>
            <h5>{item.attributes?.titulo}</h5>
            <ul>
              {item.attributes?.Temas?.data.map(a => (
                <li key={a.id} onClick={() => handleOe(a)}>{a.attributes?.titulo}</li>
               ))}
            </ul>
          </div>)
          }
        )
      }
    </>
  );
};
