import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Notificacao from "../../../../assets/svgs/icone-notificacao.svg";

import { TOKEN_SECRECT } from "../../../../shared/constants/auth-types";
import { removeLocalStorageValue, removeTokenFromCookie } from "../../../../shared/services/localStorage.service";

import { UserContext, getUserContext } from "../../../../context/UserContext";
import { If } from "../../../../shared/components/If";

import { HEADER_ACTION_TYPE } from "../../../../shared/constants/action-types";

import { useValidacaoDadosUsuario } from "../../../../hooks/useValidacaoDadosUsuario";
import { mostrarModalLogin } from "../../../../shared/modals/ModalLogin";

import "./styles.scss";
import { ItemMenuEntity } from "../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";
import { MenuModel } from "../../../../models/HeaderPageModel";
import LinkCustom from "../../../../shared/components/Link";

export const ModalMenuMobile: React.FC<{
  data: MenuModel[];
  setToggleMenuMobile: any;
}> = (props: { data: MenuModel[]; setToggleMenuMobile: any }) => {
  const state = useSelector((state: any) => state.headerReducer);
  const history = useHistory();

  const userContext = getUserContext();
  const gtm = useGtm();

  const { validadoDadosUsuario } = useValidacaoDadosUsuario();
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    removeLocalStorageValue(TOKEN_SECRECT);
    removeTokenFromCookie();
    window.location.href = "/";
    window.location.reload();
  };

  const handleLink = () => {
    props.setToggleMenuMobile(false);

    dispatch({
      type: HEADER_ACTION_TYPE.TOGGLE_MENU_MOBILE,
      payload: false,
    });
  };

  const isInternalPath = (path: string) => path?.startsWith("/");

  return (
    <div>
      <ul className="text-white-20 mt-4 flex flex-col items-center justify-center gap-4 overflow-y-scroll">
        {props.data.map((item) => (
          <li>
            <LinkCustom
              className="flex text-base font-semibold text-white-200"
              to={
                !item
                  ? user
                    ? "/meus-conteudos"
                    : "/"
                  : isInternalPath(item?.path) ? item?.path : "/"
              }
              onClick={() => {
                handleLink();
                if (item?.path?.includes("/prepara-einstein")) {
                  gtm.push(
                    EDataLayerEventCategory.ADE_MENU_PREPARA_EINSTEIN,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: item?.nome!.split(" ").join("_") || "",
                    },
                  );
                }
              }}
            >
              {" "}
              {item?.nome}{" "}
            </LinkCustom>
          </li>
        ))}
        <li>
          <a
            target="blank"
            href="https://www.einstein.br"
            className="flex text-base font-semibold text-white-200"
            onClick={handleLink}
          >
            {" "}
            Einstein.br{" "}
          </a>
        </li>

        <If test={!!userContext}>
          <li>
            <Link
              className="flex text-base font-semibold text-white-200"
              to="/meu-perfil"
              onClick={handleLink}
            >
              {" "}
              Meu Perfil{" "}
              {!validadoDadosUsuario && (
                <img
                  data-menu-notificacao-mobile
                  src={Notificacao}
                  alt="Notificação"
                  height="14px"
                  width="14px"
                  style={{ height: "14px", width: "14px" }}
                />
              )}
            </Link>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              className="flex text-base font-semibold text-white-200"
              to="#"
              onClick={handleLogoutClick}
            >
              {" "}
              Deslogar{" "}
            </Link>
          </li>
        </If>
        <If test={!userContext}>
          <li>
            <Link
              to=""
              className="flex text-base font-semibold text-white-200"
              onClick={(ev) => {
                ev.stopPropagation();
                dispatch({
                  type: HEADER_ACTION_TYPE.TOGGLE_MENU_MOBILE,
                  payload: false,
                });

                mostrarModalLogin();
              }}
            >
              {" "}
              Entrar{" "}
            </Link>
          </li>
        </If>
      </ul>
    </div>
  );
};
