import Container from "../../../shared/components/Container";
import Clinic from "../../../assets/svgs/new/beneficios/clinic.svg";
import Calendar from "../../../assets/svgs/new/beneficios/calendar.svg";
import Method from "../../../assets/svgs/new/beneficios/method.svg";
import Favorite from "../../../assets/svgs/new/beneficios/favorite.svg";
import Certificate from "../../../assets/svgs/new/beneficios/certificate.svg";
import Locker from "../../../assets/svgs/new/beneficios/locker.svg";
import React from "react";

export default function Beneficios() {
  return (
    <div
      style={{
        background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
      }}
    >
      <Container className="flex flex-col items-center justify-start py-6 text-center md:py-20 tablet:py-10">
        <h3 className="!text-lg font-semibold !leading-6 text-white-200 md:!text-2xl md:!leading-30 tablet:!text-lg tablet:!leading-6">
          O que você pode esperar da
          <br />
          Academia Digital Einstein?
        </h3>
        <div className="mt-11 flex max-w-[942px] flex-col items-start justify-start gap-6 md:flex-row tablet:flex-col">
          <div className="flex w-full flex-col gap-6 text-start">
            <div className="flex items-start gap-6">
              <img
                src={Calendar}
                alt="icone calendário"
                width={36}
                height={36}
              />

              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Aprenda no seu ritmo
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Acesse conteúdos atualizados quando e onde
                  <br /> quiser, que se adaptam ao seu ritmo.
                </p>
              </div>
            </div>
            <div className="flex items-start gap-6">
              <img src={Clinic} alt="icone clinica" width={36} height={36} />
              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Conteúdo produzido por profissionais Einstein
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Aprenda através do conhecimento de especialistas do Hospital
                  Israelita Albert Einstein.
                </p>
              </div>
            </div>
            <div className="flex items-start gap-6">
              <img
                src={Method}
                alt="icone methodologia"
                width={36}
                height={36}
              />
              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Metodologia
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Oferecemos uma variedade de métodos de
                  <br /> ensino para atender às suas preferências de <br />
                  aprendizado, seja por meio de vídeos, textos,
                  <br /> podcasts, questionários e muito mais.
                </p>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-6 text-start">
            <div className="flex items-start gap-6">
              <img src={Favorite} alt="icone save" width={36} height={36} />
              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Conteúdo atualizado
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Mantenha-se em constante aprendizado com nossos conteúdos
                  novos em diversas áreas de conhecimento.
                </p>
                <br />
              </div>
            </div>

            <div className="flex items-start gap-6">
              <img
                src={Certificate}
                alt="icone certificado"
                width={36}
                height={36}
              />
              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Declaração de participação
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Receba reconhecimento pelo seu aprendizado
                  <br /> com declarações de participação em nossos
                  <br /> conteúdos.
                </p>
              </div>
            </div>
            <div className="flex items-start gap-6">
              <img src={Locker} alt="icone cadeado" width={36} height={36} />
              <div className="text-base/22 font-bold text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                Acesso gratuito
                <p className="text-sm font-normal leading-5 text-white-200 md:text-base md:leading-22 tablet:text-sm tablet:leading-18">
                  Desfrute de acesso gratuito e ilimitado à nossa
                  <br /> plataforma.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
