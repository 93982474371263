import React from "react";

import { BannerSwiper } from "../BannerSwiper";

import "./styles.scss";
import { isMobile } from "react-device-detect";
interface BannerProps {
  items: any[];
  onClose?: any;
  showBanner?: Boolean;
  fixo?: Boolean;
}

export const BannerSection: React.FC<BannerProps> = ({
  items,
  onClose,
  fixo,
  showBanner,
}) => {
  return (
    <>
      <div
        className={
          fixo
            ? "w-full"
            : "flex justify-center items-center flex-col w-full relative"
        }
      >
        <BannerSwiper
          items={items.filter((a: any) => a.field === (isMobile ? 'ImagemMobile' : 'ImagemDesktop')).filter((item, index, self) => self.findIndex(t => t.id === item.id) === index)}
          onClose={onClose}
          showBanner={showBanner}
          fixo={fixo}
        />
      </div>
    </>
  );
};
