import { Plus, Share2 } from "lucide-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Favoritar,
} from "../../../shared/components";
import Container from "../../../shared/components/Container";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../shared/components/ui/avatar";
import {
  BreadcrumbList,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "../../../shared/components/ui/breadcrumb";
import { Button } from "../../../shared/components/ui/button";
import {
  ObjetoEducacionalEntity,
  PerfilDeAutorEntity,
  UsersPermissionsUserEntity,
  useSeguirMutation,
} from "../../../generated/graphql";
import { cn } from "../../../lib/utils";
import { useContext, useEffect, useRef, useState } from "react";
import Tags from "../../../shared/components/Tags";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../../shared/components/ui/dialog";
import { useHistory } from "react-router";
import { updateUserContext, UserContext } from "../../../context/UserContext";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import useOeConsume from "../../../hooks/useOeConsume";
import { useUtils } from "../../../shared/utils";
import Compartilhar from "../components/Compartilhar";
import { Link } from "react-router-dom";
import { Badge } from "../../../shared/components/ui/badge";
import ResetUserContext from "../../../hooks/ResetUserContext";
import React from "react";

interface OeTextoPageProps {
  oe: ObjetoEducacionalEntity;
}

const Autores = React.memo(
  ({
    isFooter = false,
    props,
  }: {
    isFooter?: boolean;
    props: OeTextoPageProps;
  }) => {
    const history = useHistory();
    const [seguirMutation] = useSeguirMutation();
    const [following, setFollowing] = useState<boolean>(false);
    const [seguindo, setSeguindo] = useState<string[]>([]);
    const [openModal, setOpenModal] = useState(false);

    const { user, setUser } = useContext(UserContext);

    const handleFollow = (idAuthor: string) => {
      const hasFollower = user?.attributes?.Seguindo?.data.find(
        (author) => idAuthor === author?.id?.toString(),
      );

      const otherFollowers = (
        user?.attributes?.Seguindo?.data as PerfilDeAutorEntity[]
      )
        .filter((author) => author?.id !== idAuthor)
        .map((author) => author?.id!);

      const newFollowers = !hasFollower
        ? [...otherFollowers, idAuthor]
        : [...otherFollowers];

      setSeguindo(newFollowers);

      seguirMutation({
        variables: {
          id: user?.id!,
          input: {
            Seguindo: newFollowers,
          },
        },
      }).then(async () => {
        setFollowing(!hasFollower);

        const newUser = {
          id: user?.id,
          attributes: {
            ...user?.attributes,
            Seguindo: {
              __typename: "PerfilDeAutorRelationResponseCollection",
              data: newFollowers.reduce((acc: any[], id) => {
                const perfil: PerfilDeAutorEntity = {
                  id: id,
                };
                acc.push(perfil);
                return acc;
              }, []) as PerfilDeAutorEntity[],
            },
          },
        };
        updateUserContext(newUser as UsersPermissionsUserEntity);
        setUser(newUser as UsersPermissionsUserEntity);
      });
    };

    useEffect(() => {
      if (!!user && user) {
        const autores = props?.oe?.attributes?.Autores?.data.map(
          (author) => author?.id,
        );

        const following =
          user?.attributes?.Seguindo?.data.filter((author) =>
            autores?.includes(author?.id?.toString()),
          ) || [];

        setSeguindo(following.map((author) => author?.id?.toString()!));
      }
    }, [user]);

    return (
      <>
        <div className=" flex h-auto flex-row gap-2">
          <div className="flex">
            {props?.oe?.attributes?.Autores?.data?.map(
              (autor: any, index: number) => (
                <Link
                  to={`/autor/${autor.id}/detalhes`}
                  className="cursor-pointer"
                >
                  <Avatar
                    className={cn(
                      " size-16 border-2 bg-[#FFFFFF] md:size-24",
                      index > 0 ? "-ml-5" : "",
                    )}
                  >
                    <AvatarImage
                      src={autor.attributes?.foto?.data?.attributes?.url}
                      alt={autor?.Nome}
                    />

                    <AvatarFallback>{autor?.Nome}</AvatarFallback>
                  </Avatar>
                </Link>
              ),
            )}
          </div>
          <div className="flex items-center gap-2">
            {(props?.oe?.attributes?.Autores?.data?.length ?? 0) > 1 ? (
              <>
                <button onClick={() => setOpenModal(true)}>
                  <span className="cursor-pointer text-xs font-normal leading-18 text-[#111316] hover:underline">
                    {
                      props?.oe?.attributes?.Autores?.data[0]?.attributes
                        ?.nome_exibicao
                    }{" "}
                    e {(props?.oe?.attributes?.Autores?.data?.length ?? 0) - 1}{" "}
                    outros
                  </span>
                </button>

                <Dialog
                  open={openModal}
                  onOpenChange={(isOpen) => setOpenModal(isOpen)}
                >
                  <DialogContent className="max-w-[95%] rounded-lg md:max-w-sm">
                    <DialogHeader></DialogHeader>
                    <div className="flex flex-col gap-3">
                      {props?.oe?.attributes?.Autores?.data?.map(
                        (autor: any, index: number) => (
                          <div className="flex flex-row items-center gap-2">
                            <Link to={`/autor/${autor.id}/detalhes`}>
                              <Avatar
                                className={cn(
                                  "size-16 border-2 bg-[#FFFFFF] md:size-24",
                                )}
                              >
                                <AvatarImage
                                  src={
                                    autor.attributes?.foto?.data?.attributes
                                      ?.url
                                  }
                                  alt={autor?.Nome}
                                />
                                <AvatarFallback>{autor?.Nome}</AvatarFallback>
                              </Avatar>
                            </Link>
                            <div className="flex flex-col">
                              <span
                                onClick={() => {
                                  history.push(`/autor/${autor.id}/detalhes`);
                                }}
                                className="cursor-pointer text-xs font-semibold leading-18 text-[#111316] hover:underline"
                              >
                                {
                                  autor
                                    ?.attributes?.nome_exibicao
                                }
                              </span>
                              <span className="text-xs font-normal leading-18 text-[#111316]">
                                {
                                  autor
                                    ?.attributes?.cargo
                                }
                              </span>
                            </div>
                            {seguindo.includes(autor.id) ? (
                              <button onClick={() => handleFollow(autor.id)}>
                                <Badge className="cursor-pointer border-none bg-slate-200 text-gray-500">
                                  Seguindo
                                </Badge>
                              </button>
                            ) : (
                              <button
                                className="h-[50px]"
                                onClick={() => handleFollow(autor.id)}
                              >
                                <Badge
                                  className="cursor-pointer
                              border-none bg-[#00498D] py-1 text-white-200
                              "
                                >
                                  Seguir
                                </Badge>
                              </button>
                            )}
                          </div>
                        ),
                      )}
                    </div>
                  </DialogContent>
                </Dialog>
              </>
            ) : (
              <>
                <div className="flex flex-col">
                  <Link
                    to={`/autor/${props?.oe?.attributes?.Autores?.data[0]?.id}/detalhes`}
                  >
                    <span className="cursor-pointer text-xs font-semibold leading-18 text-[#111316] hover:underline">
                      {
                        props?.oe?.attributes?.Autores?.data[0]?.attributes
                          ?.nome_exibicao
                      }
                    </span>
                  </Link>
                  <span className="text-xs font-normal leading-18 text-[#111316]">
                    {props?.oe?.attributes?.Autores?.data[0]?.attributes?.cargo}
                  </span>
                  {!isFooter && (
                    <span className="text-xs font-normal leading-18 text-[#111316]">
                      {props?.oe?.attributes?.TempoMedioDuracao} min de leitura
                      -{" "}
                      {props.oe.attributes?.DataProducao
                        ? format(
                            new Date(
                              `${props.oe.attributes?.DataProducao}T00:00:00`,
                            ),
                            "dd/MM/yyyy",
                            { locale: ptBR },
                          )
                        : ""}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {(props?.oe?.attributes?.Autores?.data?.length ?? 0) === 1 &&
          isFooter && (
            <div className="flex gap-2">
              <Button
                data-testid="button-compartilhar"
                variant="primary"
                size={"sm"}
                className="flex h-[47px] items-center gap-2"
                onClick={() => {
                  handleFollow(props?.oe?.attributes?.Autores?.data[0]?.id!);
                }}
              >
                {following ? "Seguindo" : "Seguir"}
              </Button>
              <Button
                data-testid="button-compartilhar"
                variant="outline"
                size={"sm"}
                className="flex h-[47px] items-center gap-2"
                onClick={() => {
                  history.push(
                    `/autor/${props?.oe?.attributes?.Autores?.data[0]?.id}/detalhes/oe`,
                  );
                }}
              >
                <Plus size={16} className="" />
                Conteúdos
              </Button>
            </div>
          )}
      </>
    );
  },
);

export default function OeTextoPage(props: OeTextoPageProps) {
  const [limitTag, setLimitTag] = useState<boolean>(true);

  const consume = useOeConsume();
  const { user } = useContext(UserContext);
  const handleTimeUpdateRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();
    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));
    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(
              (i) => i.id === props.oe.id,
            ) !== undefined,
        );
      if (
        (oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration
      ) {
        return;
      }
      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: props.oe.id,
        user: user?.id,
      });
    });
    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };
  }, [user]);

  const handleScroll = () => {
    const scrollTop = window.scrollY; // Posição atual do scroll
    const windowHeight = window.innerHeight; // Altura visível da janela
    const fullHeight = document.documentElement.scrollHeight - 2500;
    const oeAssistido = user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item) =>
        !!item?.attributes?.Oes_Assistidos &&
        item.attributes.Oes_Assistidos.data.find(
          (i) => i.id === props.oe.id,
        ) !== undefined,
    );
    if ((oeAssistido?.attributes?.segundos || 0) > scrollTop + windowHeight) {
      return;
    }
    handleTimeUpdateRef.current({
      partialDuration: parseInt((scrollTop + windowHeight).toFixed(0)),
      totalDuration: parseInt(fullHeight.toFixed(0)),
    });
    // Verifica se o scroll chegou ao final da página
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const criarTituloSecaoTexto = (index: number) =>
    `Seção ${index + 1 <= 9 ? "0" + (index + 1) : index + 1}`;

  const tags = limitTag
    ? props.oe?.attributes?.palavras_chave?.data?.slice(0, 1)
    : props.oe?.attributes?.palavras_chave?.data;

  const { formatTitle } = useUtils();

  const refCompartilhar = useRef<any>();
  const refFavoritar = useRef<any>();

  return (
    <>
      <Container className="max-w-[34.75rem]">
        <Breadcrumb className="text-start font-normal">
          <BreadcrumbList className="flex justify-start text-start">
            <BreadcrumbItem className="!text-black-900">
              <BreadcrumbLink href="/" className="!text-black-900">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink className="!text-black-900">
                Segurança em Pediatria recém-nascido{" "}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex flex-col gap-3 py-4">
          <h1 className="text-xxl font-semibold leading-7 text-[#107B95]">
            {props?.oe?.attributes?.Titulo}
          </h1>
          <p className="text-base font-normal leading-6 text-[#626466]">
            {props?.oe?.attributes?.Descricao?.replace(/<[^>]*>?/gm, "")}
          </p>
        </div>

        <Autores isFooter={false} props={props} />

        <div className="relative flex gap-2 py-6">
          <Compartilhar
            item={{
              url: props?.oe?.attributes?.ImagemPreview?.data?.attributes?.url!,
              descricao: props?.oe.attributes?.Descricao!,
              titulo: formatTitle(props.oe.attributes?.Titulo!),
              id: props.oe.id,
            }}
            ref={refCompartilhar}
            newIcon={true}
          />
          <Button
            data-testid="button-compartilhar"
            variant="outline"
            size={"sm"}
            className="h-[40.7px] border-[#CED1D4] !text-[13.57px]"
            onClick={() => {
              refCompartilhar.current &&
                (refCompartilhar.current as any).click();
            }}
          >
            <Share2 size={16} className="mr-2" />
            Compartilhar
          </Button>

          <Button
            data-testid="button-favoritar"
            variant="outline"
            className="h-[40.7px] !border-[#CED1D4] !text-[13.57px] "
            onClick={() => {
              refFavoritar.current && (refFavoritar.current as any).click();
            }}
          >
            <Favoritar
              className="[&>svg]:mr-1 [&>svg]:size-5"
              color="green"
              idOe={props.oe.id}
              item={{
                id: props.oe.id!,
                titulo: props.oe.attributes?.Titulo!,
              }}
              ref={refFavoritar}
            />
            Favoritar
          </Button>
        </div>
        <div className="flex flex-col gap-2 py-6">
          <span className="text-sm font-bold text-black-900">Recomendado:</span>
          <p className="text-sm font-normal">
            {props?.oe?.attributes?.PublicoAlvo?.data?.map(
              (publico: any, index: number) => (
                <>
                  {publico.attributes?.titulo}
                  {index <
                    (props?.oe?.attributes?.PublicoAlvo?.data?.length ?? 0) -
                      1 && ", "}
                </>
              ),
            )}
          </p>
        </div>

        {props?.oe?.attributes?.SecoesTexto?.map((text, index) => (
          <div key={text?.id}>
            <h2>{criarTituloSecaoTexto(index)}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: text?.Secao }}
              className="text-base font-normal leading-6 text-[#626466]"
            />
          </div>
        ))}

        <div className="py-2">
          {props?.oe?.attributes?.SecoesTexto?.map((text, index) => (
            <span className="text-base font-medium italic leading-6 text-black-900">
              {text.FontesCitacoes}
            </span>
          ))}
        </div>
        <div className="flex flex-col gap-1 py-6">
          <span className="text-sm font-bold text-black-900">
            Palavras-chave:
          </span>
          <div className="flex flex-wrap gap-[6px]">
            {tags?.map((m, index) => (
              <Tags
                key={index}
                isSearchable={true}
                title={m.attributes?.titulo!}
                className="bg-[#F5F8FF] [&>span]:text-[#004F92]"
              />
            ))}
          </div>
        </div>

        <div className=" flex h-auto flex-col items-center justify-center gap-3 border-b border-t py-14">
          <Autores isFooter={true} props={props} />
        </div>
      </Container>
    </>
  );
}
