import React, { Dispatch, useEffect, useState } from "react";
import {
  ComponentSecaoVideoAudioVideoAudio,
  ObjetoEducacional,
  ObjetoEducacionalEntity,
  UserFragment,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";
import { isMobile } from "react-device-detect";
import { OeLiveDesktop } from "./components/OELivePreview/OeLiveDesktop";
import { OeLiveMobile } from "./components/OELivePreview/OeLiveMobile";
import { useParams } from "react-router";
import { OeVideoWatch } from "../OeVideoPage/components/OeVideoWath";
import axios from "axios";
import {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from "../../../shared/services/localStorage.service";
import { TOKEN_SECRECT } from "../../../shared/constants/auth-types";

interface OeLiveProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  oesRelacionadas?: ObjetoEducacionalEntity[];
  setEhLive: Dispatch<boolean>;
}

export const OeLivePage: React.FC<OeLiveProps> = (props) => {
  const [isPreview, setPreview] = useState<boolean>(true);
  const [videoAtual, setVideoAtual] = useState<
    ComponentSecaoVideoAudioVideoAudio | undefined
  >();
  const params = useParams<{ videoId: string }>();

  useEffect(() => {
    if (!props.oe) return;

    if (!!params && !!params.videoId) {
      const _videoAtual = props.oe.attributes?.VideoAudio?.find(
        (item) => item.id === params.videoId
      );

      if (!_videoAtual) {
        window.location.href = "/";
        return;
      }

      setPreview(false);
      setVideoAtual(_videoAtual);
    }
  }, [props.oe, params]);

  const _oeAssistido =
    props.user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item) =>
        !!item?.attributes?.VideoAudioId &&
        item?.attributes?.VideoAudioId.toString() === videoAtual?.id
    );

  function registerLive() {
    const liveRegister = getLocalStorageValue("LIVE_REGISTER");

    const payloadLiveRegister = {
      LiveId: props.oe.id,
      UserId: props.user?.id,
    };

    if (liveRegister === null) {
      if (liveRegister === JSON.stringify(payloadLiveRegister)) return;

      setLocalStorageValue(
        "LIVE_REGISTER",
        JSON.stringify(payloadLiveRegister)
      );

      try {
        const response = axios.post(
          `${process.env.REACT_APP_ROOT}/api/viewer`,
          {
            data: payloadLiveRegister,
          },
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageValue(TOKEN_SECRECT)}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {}
    }
  }

  registerLive();

  return (
    <>
      {isPreview ? (
        !isMobile ? (
          <OeLiveDesktop oe={props.oe} user={props.user} />
        ) : (
          <OeLiveMobile oe={props.oe} user={props.user} />
        )
      ) : (
        <OeVideoWatch
          oe={props.oe}
          videoAtivo={videoAtual}
          user={props.user}
          _oeAssistido={_oeAssistido}
        />
      )}
    </>
  );
};
