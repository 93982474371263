import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface CardContainerProps {
  shadow?: boolean;
  children: React.ReactNode;
}

export const CardContainer: React.FC<CardContainerProps> = (props) => {
  return (
    <section
      data-card={classNames({
        shadow: props.shadow,
      })}
    >
      {props.children}
    </section>
  );
};
