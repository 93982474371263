import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { normalizeStr } from "../../helpers/common";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import HeaderPageBreadcrumb from "../../shared/components/HeaderPageBreadcrumb";
import { useUtils } from "../../shared/utils";
import Menu from "../../shared/components/menu";
import Container from "../../shared/components/Container";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { Root, ObjetoEducacional } from "../../models/ObjetoEducacional";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { Pagination } from "@mui/material";
import NenhumItem from "../../assets/svgs/nenhum_item.svg";
import {
  SubRotuloEntity,
  useGetSubRotuloLazyQuery,
} from "../../generated/graphql";

interface SearchProps {
  a?: string[];
  s?: string;
  subRotuloId: string;
  page?: number;
}

async function searchObjetosEducacionais({
  a,
  s,
  subRotuloId,
  page,
}: SearchProps): Promise<Root> {
  const params = {
    areaDeInteresses: Array.isArray(a) ? a : a ? [a] : [],
    ordernar: s,
    subRotuloId,
    pageIndex: page || 1,
    pageSize: 15,
  };

  const response = await fetchApi(
    "objeto-educacional/get-oe-subrotulo",
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return {
        items: [],
        totalItems: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
      } as Root;
    }
  });

  return response;
}

interface OeSubMetodosEnsinoProps {
  location: any;
}

export const OESubMetodosEnsinoPage: React.FC<OeSubMetodosEnsinoProps> = (
  props,
) => {
  const params = useParams<{
    name: string;
    id: string;
    subname: string;
    subid: string;
  }>();
  const [oes, setOes] = useState<ObjetoEducacional[]>([]);
  const [oesTotalPage, setOesTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [oesTotal, setOesTotal] = useState<number>(0);
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const [page, setPage] = useState<number>(1);
  const [subRotulo, setSubRotulo] = useState<SubRotuloEntity>();

  const location = useLocation();

  const loadObjetos = async (search: SearchProps) => {
    setLoading(true);
    const resultado = await searchObjetosEducacionais(search || searchParams);
    setOes(resultado.items);
    setOesTotalPages(resultado.totalPages);
    setOesTotal(resultado.totalItems);
    setLoading(false);
    if (resultado.items.length > 0) {
      //setPatrocinador(resultado.items[0].);
    }
  };

  const [getSubRotulo] = useGetSubRotuloLazyQuery({
    variables: {
      id: params.subid,
    },
    onCompleted: (response) => {
      setSubRotulo(response.subRotulo?.data);
    },
  });

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      a: queryLocation.getAll("a") || undefined,
      s: queryLocation.get("s") || undefined,
      subRotuloId: params.subid,
      page: page,
    };
    setSearchParams(search);
    loadObjetos(search);
  }, [location.search, page]);

  useEffect(() => {
    getSubRotulo({
      variables: {
        id: params.subid,
      },
    });
  }, [params.id]);

  const scrollTop = useScrollTop();
  const { formatName, formatTitle } = useUtils();
  scrollTop();

  const gtm = useGtm();

  useEffect(() => {
    if (!params) return;
    const _ = params.name ? params["name"].replace(/-/g, " ") : "";

    const __ = params["subname"].replace(/-/g, " ");

    // GTM doc linha 69
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: `subcategoria:${__.toUpperCase()}`,
      },
    );
  }, [params]);

  return (
    <>
      <HeaderPageBreadcrumb
        itens={
          params.name
            ? [
                { to: "/", title: "Home" },
                { to: "/metodos-ensino", title: "Métodos de ensino" },
                {
                  to: `/metodos-ensino/${params.name}/${params.id}`,
                  title: formatName(subRotulo?.attributes?.Titulo || ""),
                },
              ]
            : [
                { to: "/", title: "Home" },
                { to: "/metodos-ensino", title: "Métodos de ensino" },
              ]
        }
        title={formatName(subRotulo?.attributes?.Titulo || "")}
        color={subRotulo?.attributes?.Titulo.toLowerCase() || ""}
      />
      <Container className="h-full min-h-screen  py-9 pb-9">
        <div className="flex flex-col items-center gap-10">
          <Menu
            qtdConteudos={oesTotal}
            typeFiltro="SUB_METODO_ENSINO"
            loading={loading}
          />

          <div className="mt-5 grid w-full grid-cols-1 gap-6 md:mt-0 md:grid-cols-3">
            {loading
              ? Array.from({ length: 9 }).map((_, index) => (
                  <div className="w-full min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                    <div className="flex h-[187.5px] animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent  via-gray-400/20 to-transparent md:h-[227.66px] md:w-[400px]" />
                  </div>
                ))
              : oes?.map((_: ObjetoEducacional, index: number) => (
                  <div key={index} className="p-1">
                    <CardObjetoEducacional objetoEducacional={_} />
                  </div>
                ))}
          </div>
          {!loading && oes && oes.length === 0 && (
            <div className="flex w-full max-w-[43.87rem] flex-col items-center justify-center text-center">
              <img src={NenhumItem} className="mb-6 h-52" />
              <span className="text-base font-semibold leading-18 text-[#004F92] md:text-xxl md:leading-26 tablet:text-xxl tablet:leading-26">
                Não encontramos conteúdos com as suas preferências
              </span>
              <span className="mt-3 text-sm font-medium leading-4 text-[#626466]  md:text-lg md:leading-22 tablet:text-lg tablet:leading-22 ">
                Parece que não encontramos conteúdos com os filtros que você
                escolheu. Tente ajustar ou selecionar novos filtros para ver
                mais opções!
              </span>
            </div>
          )}
          {!loading && oes && oesTotalPage > 1 && (
            <div className="flex w-full items-end justify-end">
              <Pagination
                count={oesTotalPage}
                onChange={(e, p) => setPage(p)}
                page={page}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
