import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import {
  PerfilDeAutor,
  PerfilDeAutorEntity,
  useGetAuthorsWithFiltersLazyQuery,
} from "../../../../generated/graphql";

import { AuthorBox, Loader } from "../../../../shared/components";

import { UserContext } from "../../../../context/UserContext";

import "./styles.scss";

export const MeusAutoresPage: React.FC = () => {
  const [autores, setAutores] = useState<PerfilDeAutor[]>([]);
  const { user } = useContext(UserContext);

  const [GetAuthors, { loading }] = useGetAuthorsWithFiltersLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.perfilDeAutors) {
        setAutores(data.perfilDeAutors.data as PerfilDeAutor[]);
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (!user || loading) {
      return;
    }

    let consulta = !!user.attributes?.Seguindo
      ? user.attributes?.Seguindo?.data.map((autor, idx) => autor.id!)
      : [];

    GetAuthors({
      variables: {
        sort: "nome_exibicao asc",
        start: 0,
        limit: 99999,
        filters: {
          id: { in: consulta },
        },
      },
    });
  }, [user]);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      {!!autores?.length && (
        <section data-meus-autores>
          <div data-row data-titulo-meus-autores>
            <h3>Seguindo</h3>

            <Link data-link-primary to="/meu-perfil">
              voltar
            </Link>
          </div>

          {autores.map((autor, index) => (
            <div data-row key={index}>
              <div data-card>
                <AuthorBox author={autor as PerfilDeAutorEntity} />
              </div>
            </div>
          ))}
        </section>
      )}
    </>
  );
};
