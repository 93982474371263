import React, { useContext, useEffect, useState } from "react";
import Container from "../../shared/components/Container";
import { CardAreaResidenciaMedica } from "../../shared/components/CardAreaResidenciaMedica";
import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../hooks/useBannerApi";
import { BannerSection, Loader } from "../../shared/components";
import { UserContext } from "../../context/UserContext";
import * as Icon from "../../assets/svgs";
import "./styles.scss";

import {
  ResidenciaMedicaEntity,
  useGetResidenciaMedicasHomeQuery,
} from "../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import {
  Banner,
  loadBannerPreparaEinstein,
} from "../../graphql/collections/queries/banner.query";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { UserDetalheContext } from "../../context/UserPreparaEinsteinContext";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { ResidenciaMedicaHomeModel } from "../../models/ResidenciaMedicaHomeModel";
import { useModalCursosPagos } from "../../hooks/useModalCursosPagos";
import { differenceInDays, formatISO, parse, parseISO } from "date-fns";

async function getResidenciaMedicaHome() {
  const response = await fetchApi("residencia/get-all-home", "GET").then(
    (res) => {
      if (res.ok) {
        return res.json();
      } else {
        return [];
      }
    },
  );

  return response;
}

export default function ResidenciaMedicaHome() {
  const { user } = useContext(UserContext);
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  const [residenciaMedicas, setResidenciaMedicas] = useState<
    ResidenciaMedicaHomeModel[] | null
  >(null);
  const [loadingResindeciaHome, setLoadingResindeciaHome] = useState(false);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBannerPreparaEinstein({
      homePage: false,
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, []);

  scrollTop();

  const loadResidenciaHome = async () => {
    setLoadingResindeciaHome(true);
    const response = await getResidenciaMedicaHome();
    setResidenciaMedicas(response);
    setLoadingResindeciaHome(false);
  };

  useEffect(() => {
    gtm.push(
      EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_CONTEUDO_VIEWS,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "home_prepara_einstein_views",
      },
    );
    loadResidenciaHome();
  }, []);

  const mostrarModalCursosPagos = useModalCursosPagos();

  useEffect(() => {
    const getDataModal = () => {
      const data = localStorage.getItem("PREPARA_EINSTEIN");
      const dataJson = JSON.parse(data || "{}");

      const now = new Date();
      const lastAccess = parse(
        dataJson?.data_acesso_cursos,
        "dd/MM/yyyy",
        new Date(),
      );

      const differenceInDaysValue = differenceInDays(lastAccess, now);

      if (differenceInDaysValue >= 7 || !dataJson?.data_acesso_cursos) {
        mostrarModalCursosPagos.mostrar();
      }
    };

    getDataModal();
  }, []);

  return (
    <>
      <Container className="relative flex flex-col items-center justify-center  ">
        <div className="flex md:!hidden">
          <Icon.BannerMobile />
        </div>
        <div className="hidden max-w-[946px] md:flex">
          <Icon.BannerHomeResidencia />
        </div>
        <Loader
          show={loadingResindeciaHome}
          message="Aguarde enquanto o conteúdo é carregado..."
        />
        <div className="mt-10 flex justify-center text-center">
          <span className="text-lg font-semibold leading-normal text-[#004F92]">
            Olá{" "}
            {user?.attributes?.nome_exibicao ||
              user?.attributes?.nome_completo?.split(" ")[0]}
            ,
            <br />
            <span className="font-normal text-[#111316]">
              o que você deseja aprender hoje?
            </span>
          </span>
        </div>
        <div className="mt-8 flex w-full max-w-[849px] flex-col gap-[10px] md:!grid md:!grid-cols-3   md:!grid-rows-2 md:!gap-6">
          {residenciaMedicas &&
            residenciaMedicas.map((card, index) => (
              <CardAreaResidenciaMedica key={index} index={index} item={card} />
            ))}
        </div>
      </Container>
      <Container>
        {(() => {
          let bannersCabecalho = banners?.filter(
            (a) =>
              a.tipoPagina === TIPO_PAGINA.RESIDENCIA_MEDICA_HOME &&
              ((!!user?.id && a.ocultarLogado === false) || !user?.id),
          );

          return (
            <>
              {bannersCabecalho && bannersCabecalho.length > 0 && (
                <div
                  data-banner
                  data-banner-cabecalho
                  className="mt-10 max-w-[952px]"
                >
                  <BannerSection items={bannersCabecalho} />
                </div>
              )}
            </>
          );
        })()}
      </Container>
    </>
  );
}
