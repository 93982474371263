import { ModalService } from "../shared/components/Modal";
import ModalCursosPagos from "../shared/components/ModalCursosPagos";

export const useModalCursosPagos = () => {
  const mostrar = () => {
    ModalService.show({
      content: <ModalCursosPagos />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
      onClose: async () => {
        const data = await localStorage.getItem("PREPARA_EINSTEIN");
        localStorage.setItem(
          "PREPARA_EINSTEIN",
          JSON.stringify({
            ...JSON.parse(data || "{}"),
            data_acesso_cursos: new Date().toLocaleDateString(),
          }),
        );
      },
    });
  };

  return { mostrar };
};
