import { useState } from "react";
import {
  useCreateVisualizacaoPreparaEinsteinMutation,
  useGetVizualizacaoPreparaEinsteinLazyQuery,
  useUpdateVisualizacaoPreparaEinsteinMutation,
} from "../generated/graphql";

export interface ContentVisualizacaoPreparaEinsteinState {
  user_id?: string;
  residencia_id?: string;
  id?: string;
}

const useVisualizacaoPreparaEinstein = () => {
  const [state, setState] = useState<ContentVisualizacaoPreparaEinsteinState | null>(null);

  const [createVisualizacaoPreparaEinstein] =
    useCreateVisualizacaoPreparaEinsteinMutation();

  const [updateVisualizacaoPreparaEinstein] =
    useUpdateVisualizacaoPreparaEinsteinMutation();

  const [getVisualizacaoPreparaEinstein] = useGetVizualizacaoPreparaEinsteinLazyQuery();


  const _isValid = (data: ContentVisualizacaoPreparaEinsteinState) =>
    !!data &&
    !!data.user_id &&
    !!data.residencia_id;

  const saveVizualizacaoResidencia = async (dataState?: ContentVisualizacaoPreparaEinsteinState) => {
    const _state: ContentVisualizacaoPreparaEinsteinState = {
      ...(state ? state : ({} as ContentVisualizacaoPreparaEinsteinState)),
      ...(dataState ? dataState : ({} as ContentVisualizacaoPreparaEinsteinState)),
    };

    setState(_state);

    if (!_isValid(_state)) {
      return false;
    }

    let data: any = {
      user_id: Number(_state?.user_id),
      residencia_id: Number(_state?.residencia_id),
    };

    getVisualizacaoPreparaEinstein({
      fetchPolicy: 'cache-and-network',
      variables: {
        user_id: Number(_state?.user_id),
        residencia_id: Number(_state?.residencia_id),
      },
      onCompleted: async (value) => {
        if (!value.visualizacaoPreparaEinsteins?.data || value.visualizacaoPreparaEinsteins?.data?.length === 0) {
          const response = await createVisualizacaoPreparaEinstein({
            variables: {
              data: {
                ...data,
              },
            },
          });

          setState({
            ...(_state ? _state : {}),
            id: response.data?.createVisualizacaoPreparaEinstein?.data?.id!,
          });
        } else {
          await updateVisualizacaoPreparaEinstein({
            variables: {
              id: value.visualizacaoPreparaEinsteins?.data[0].id!.toString(),
              data: {
                ...data,
              },
            },
          });
        }
      }
    })

    return true;
  };

  const updateVizualizacaoResidencia = (data: ContentVisualizacaoPreparaEinsteinState) => {
    setState({
      ...state,
      ...data,
    });
  };

  return {
    saveVizualizacaoResidencia,
    updateVizualizacaoResidencia,
  };
};

export default useVisualizacaoPreparaEinstein;
