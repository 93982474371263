import CompleteTudoDeclaracao from "../../../../assets/images/complete-tudo-declaracao.png";

import { ModalService } from "../../../../shared/components/Modal";
import { Button } from "../../../../shared/components/ui/button";

export const useModalNaoConcluidoPlaylist = () => {
  const handleFechar = () => {
    ModalService.hide();
  };

  const _renderizar = () => {
    return (
      <div className="bg-white z-50 flex items-center justify-center rounded-3xl py-4">
        <div className="flex flex-col items-center justify-center px-9 py-8 md:px-12  md:py-1">
          <img
            data-testid="img-prepara"
            src={CompleteTudoDeclaracao}
            alt="Complete tudo para receber sua declaração de participação"
            className="mb-6 h-[109.01px] w-[120px] md:h-[145.35px] md:w-[160px]"
          />
          <span className="mt-6 w-[250px] text-center text-lg font-bold leading-26 text-[#004F92] md:w-[350px] md:text-base md:leading-22">
            Complete a trilha para receber sua declaração de participação!
          </span>

          <span className="mt-3 w-[250px] text-center text-base font-normal leading-22 text-[#313335] md:w-[350px] md:text-sm md:leading-5">
            Você está quase lá! Conclua todos os conteúdos e receba sua
            declaração de participação.
          </span>

          <div data-row data-flex-row-arround className="mt-6">
            <Button
              type="button"
              data-testid="btn-ver-agora-nao-concluido"
              className="!text-base"
              variant="primary"
              onClick={() => {
                ModalService.hide();
              }}
            >
              Continuar
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const mostrar = () => {
    ModalService.show({
      content: _renderizar(),
      closeOutsideModal: true,
      notFullScreenMobile: true,
    });
  };

  return { mostrar };
};
