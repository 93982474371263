import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ComponentSecaoVideoAudioVideoAudio,
  Enum_Objetoeducacional_Status,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import { OeVideoWatch } from "./components/OeVideoWath";
import { CardSection } from "../../../shared/components";
import { isMobile } from "react-device-detect";
import { OePreviewDesktop, TypeOE } from "../Preview/OePreviewDesktop";
import { OePreviewMobile } from "../Preview/OePreviewMobile";
import { useUtils } from "../../../shared/utils";

interface OeVideoProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  oesRelacionadas?: ObjetoEducacionalEntity[];
}

interface OeVideoState {
  isPreview: boolean;
  videoAudioActual: ComponentSecaoVideoAudioVideoAudio | undefined;
  oesRelacionadas?: ObjetoEducacionalEntity[];
}

export type ObjetoEducacionalVideo = ObjetoEducacionalEntity & {
  VideoId: string;
};

export const OeVideoPage: React.FC<OeVideoProps> = (props) => {
  const params = useParams<{ videoId: string }>();

  const [listCardOesVideos, setListCardOesVideos] = useState<
    ObjetoEducacionalEntity[]
  >([]);

  const [state, setState] = useState<OeVideoState>({
    isPreview: true,
    videoAudioActual: undefined,
    oesRelacionadas: props.oesRelacionadas,
  });

  const { formatTitle } = useUtils();

  useEffect(() => {
    if (!props.oe) return;

    if (!!params && !!params.videoId) {
      const _videoAtual = props.oe.attributes?.VideoAudio?.find(
        (item) => item.id === params.videoId,
      );

      if (!_videoAtual) {
        window.location.href = "/";
        return;
      }

      setState({
        ...state,
        videoAudioActual: _videoAtual,
        isPreview: false,
      });
    }
  }, [props.oe, params]);

  useEffect(() => {
    if (
      !state.videoAudioActual ||
      !props.oe ||
      !props.oe.attributes?.VideoAudio
    ) {
      return;
    }

    const newListCardOesVideos = props.oe.attributes?.VideoAudio.map(
      (card) =>
        ({
          id: props.oe.id,
          VideoId: card.id,
          attributes: {
            ImagemCapa: {
              data: card.Thumbs?.data,
            },
            ImagemPreview: {
              data: card.Thumbs?.data,
            },
            Patrocinador: {},
            Rotulos: {
              data: [],
            },
            VideoId: card.id,
            VideoAudio: [card],
            TipoObjeto: {
              data: [...(props.oe.attributes?.TipoObjeto?.data || [])],
            },
            Descricao: card.Descricao,
            created_at: undefined,
            updated_at: undefined,
            Resumo: "",
            Status: Enum_Objetoeducacional_Status.Ativo,
            TempoMedioDuracao: card.TempoMedioDuracao || "0",
            patrocinador: undefined,
            id: props.oe.id,
            Titulo: formatTitle(card.Titulo!),
            OrdemSequencial: card.OrdemSequencial,
            OesAssistidos: {
              data: (props.oe.attributes?.OesAssistidos?.data || []).filter(
                (itemOeAssistidos) =>
                  itemOeAssistidos.attributes?.VideoAudioId?.toString() ===
                  card.id.toString(),
              ),
            },
          },
        }) as ObjetoEducacionalEntity,
    ).sort((a: any, b: any) =>
      a.OrdemSequencial < b.OrdemSequencial ? -1 : 1,
    );

    setListCardOesVideos([...newListCardOesVideos]);
  }, [props.oe, state.videoAudioActual]);

  const _oeAssistido =
    props.user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item) =>
        !!item?.attributes?.VideoAudioId &&
        item?.attributes?.VideoAudioId.toString() ===
          state.videoAudioActual?.id,
    );

  return (
    <>
      {state.isPreview ? (
        <>
          <nav className="hidden md:block">
            <OePreviewDesktop
              oe={props.oe}
              user={props.user}
              type={TypeOE.Video}
            />
          </nav>
          <nav className="block md:hidden">
            <OePreviewMobile
              oe={props.oe}
              user={props.user}
              type={TypeOE.Video}
            />
          </nav>
        </>
      ) : (
        <OeVideoWatch
          oe={props.oe}
          videoAtivo={state.videoAudioActual}
          user={props.user}
          listCardOesVideos={listCardOesVideos}
          _oeAssistido={_oeAssistido}
        />
      )}
    </>
  );
};
