import { useState } from "react";
import {
  SubAreaConhecimento,
  SubAreaConhecimentoEntity,
  useGetSubAreasInteresseLazyQuery,
} from "../generated/graphql";



export const useSubAreaInteresseSeach = () => {
  const [carregandoOEs, setCarregandoOes] = useState(false);

  const [totalBullets, setTotalBullets] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(16);
  const [resultadoOE, setResultadoOE] = useState<SubAreaConhecimentoEntity[]>(
    [] as SubAreaConhecimentoEntity[]
  );


  const [pesquisasSubAreaInteresse] = useGetSubAreasInteresseLazyQuery({
    fetchPolicy: "network-only",
    onError: (erro) => {
      console.log(erro);
    },
    onCompleted: (response) => {
      if (response && response) {
        setResultadoOE(response?.subAreaConhecimentos?.data as SubAreaConhecimentoEntity[]);
      }

      if (response && response.subAreaConhecimentos?.meta?.pagination?.total) {

        const { pagination } = response.subAreaConhecimentos?.meta;

        const { pageCount, total } = pagination!;
        setTotalItems(pageCount ?? 0);

        const _count = total! > pageCount! ? pageCount! : total!;

        const bulletCount = _count ? Math.abs(Math.ceil(_count / offset)) : 0;

        setTotalBullets(bulletCount);

      }

      setCarregandoOes(false);
    },
  });

  const buscarOes = async (
    page: number,
    sort: string,
    where?: any,
    whereUser?: any,
    whereNew?: any,
    limit?: number,
    variable?: any,
  ) => {
    setCarregandoOes(true);
   
    let variables = {
      start: 0,
      limit: limit || 12,
      sort: sort,
      where: { ...({}) },
      whereUser: {
      },
      whereObjetoEducacional: { ...({}) },
      ...variable
    };

    if (!!whereUser) {
      variables.whereUser = whereUser;
    }

    if (!!whereNew) {
      (variables as any) = { ...variables, ...whereNew};
    }

    pesquisasSubAreaInteresse({
      variables: {
        ...variables,
      }
    }); 
  };

  return {
    offset,
    setOffset,
    totalBullets,
    totalItems,
    setTotalBullets,
    carregandoOEs,
    setCarregandoOes,
    buscarOes,
    resultadoOE,
  };
};
