export const useUtils = () => {
  const formatName = (name: string, remoteToUpperCase: string[] = ['da', 'de', 'do', 'e', 'em']): string => {
    if (name.length == 0) {
      return '';
    }

    return name.toLowerCase().split(' ').map(a => a && a[0] ? (remoteToUpperCase.includes(a) ? a : a[0].toUpperCase() + a.substring(1)) : a).join(' ')
  }

  const formatNumberWithCommas = (number: number): string => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const changePosition = (arr: any[], from: number, to: number) => {
    try {
      arr.splice(to, 0, arr.splice(from, 1)[0]);
    } catch (e) {
    }
    return arr;
  }

  function formatTitle(title: string): string {
    // const wordsToLowerCase = [
    //   'a', 'é', 'não', 'ante', 'após', 'até', 'com', 'contra', 'de', 'do', 'da', 'desde', 'em', 'entre', 'para', 'perante', 'por', 'sem', 'sob', 'sobre', 'trás', 'afora', 'como', 'conforme', 'consoante', 'durante', 'exceto', 'mediante', 'menos', 'salvo', 'segundo', 'visto', 'na', 'nas', 'no', 'nos', 'da', 'das', 'do', 'dos', 'daquilo', 'naquele', 'numa', 'o', 'a', 'os', 'as', 'à', 'às', 'àquilo', 'aos', 'ao', 'e', 'mas ainda', 'mas também', 'também', 'ainda', 'contudo', 'entretanto', 'mas', 'não obstante', 'no entanto', 'porém', 'todavia', 'já...', 'ou', 'ou...', 'ora', 'ora...', 'quer', 'quer...' , 'assim', 'então', 'logo', 'pois', 'por', 'conseguinte', 'por isso', 'portanto', 'pois', 'porquanto', 'porque', 'que', 'abaixo de', 'acima de', 'a fim de', 'além de', 'antes de', 'até a', 'depois de', 'ao invés de', 'ao lado de', 'em que pese a', 'à custa de', 'em via de', 'à volta com', 'defronte de', 'a par de', 'perto de', 'por causa de', 'através de', 'se'
    // ];

    // if (title) {

    //   const words = title.split(' ');

    //   for (let i = 0; i < words.length; i++) {
    //     const word = words[i];
    //     if (i === 0 || wordsToLowerCase.indexOf(word.toLowerCase()) === -1) {
    //       // Mantém a primeira letra em maiúscula ou converte a palavra para minúsculas
    //       words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    //     } else {
    //       // Mantém a palavra em minúsculas
    //       words[i] = word.toLowerCase();
    //     }
    //   }

    //   return words.join(' ');
    // } else {
      return title;
    // }
  }

  function removeAcentosCaracteresInvalidosEspacos(str: string) {
    return str
      .normalize("NFD") // Normaliza os caracteres Unicode e separa os acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove os acentos combinados
      .replace(/[^\w\s]/gi, '') // Remove caracteres não alfanuméricos exceto espaços
      .replace(/\s+/g, '') // Remove espaços extras
      .toLowerCase();
  }

  return { formatName, changePosition, formatTitle, removeAcentosCaracteresInvalidosEspacos, formatNumberWithCommas }
}
