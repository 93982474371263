import React from "react";
import cx from "classnames";

import StepUnactivate from "../../../../assets/svgs/step-unactivate.svg";
import StepChecked from "../../../../assets/svgs/step-checked.svg";
import StepActivate from "../../../../assets/svgs/step-activate.svg";

import "./styles.scss";
import { twMerge } from "tailwind-merge";

export type Steps = "person" | "professional" | "preference" | "register";

interface StepInterface {
  formStep: number;
}

export const EtapasCadastroV2: React.FC<StepInterface> = ({ formStep }) => {
  return (
    <>
      <div className="mt-4 flex flex-col items-center justify-center gap-9  md:py-6 tablet:py-8">
        <h3 className="text-center text-lg font-normal leading-[21.94px] md:text-3.5xl md:leading-[39.01px]">
          Faça parte da <br />
          <span className="font-semibold text-[#004F92]">
            Academia Digital Einstein
          </span>
        </h3>
        <div className="mb-9  flex flex-col items-center justify-center gap-10">
          <div className="flex w-full gap-9">
            <div className="flex flex-row items-center justify-center gap-3">
              <div
                className={twMerge(
                  "flex size-9 items-center justify-center rounded-full border bg-[#004F92] text-sm font-semibold text-white-200",
                  formStep === 1 ? "bg-[#004F92]" : "bg-[#AAACAF]",
                )}
              >
                1
              </div>
              <span className="hidden !text-base font-semibold text-[#111316] md:flex">
                Informações Pessoais
              </span>
            </div>

            <div className="translate-x-0 rotate-90 border border-b"></div>

            <div className="flex flex-row items-center justify-center gap-3">
              <div
                className={twMerge(
                  "flex h-9 w-9  items-center justify-center rounded-full border bg-[#004F92] text-sm font-semibold text-white-200",
                  formStep === 2 ? "bg-[#004F92]" : "bg-[#AAACAF]",
                )}
              >
                2
              </div>
              <span className="hidden !text-base font-semibold text-[#111316] md:flex">
                Informações Profissionais
              </span>
            </div>

            <div className="translate-x-0 rotate-90 border border-b"></div>

            <div className="flex flex-row items-center justify-center gap-3">
              <div
                className={twMerge(
                  "flex h-9 w-9 items-center justify-center rounded-full border bg-[#004F92] text-sm font-semibold text-white-200",
                  formStep === 3 ? "bg-[#004F92]" : "bg-[#AAACAF]",
                )}
              >
                3
              </div>
              <span className="hidden !text-base font-semibold text-[#111316] md:flex">
                Áreas de Interesse
              </span>
            </div>
          </div>
          <span className="flex !text-base font-semibold text-[#111316] md:!hidden">
            {formStep === 1
              ? "Informações Pessoais"
              : formStep === 2
                ? "Informações Profissionais"
                : "Áreas de Interesse"}
          </span>
        </div>
      </div>
    </>
  );
};
