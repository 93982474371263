import { typeObjetoEducacional } from "../../models/TipoObjetoEducacional";

import FlashCardIcon from "../../assets/svgs/tipo-objetos/questionario.svg";
import Video from "../../assets/svgs/tipo-objetos/video.svg";
import Audio from "../../assets/svgs/tipo-objetos/audio.svg";
import Questionario from "../../assets/svgs/tipo-objetos/questionario.svg";
import Texto from "../../assets/svgs/tipo-objetos/texto.svg";
import Imagem from "../../assets/svgs/tipo-objetos/imagem.svg";
import Pdf from "../../assets/svgs/tipo-objetos/pdf.svg";
import Live from "../../assets/svgs/tipo-objetos/video.svg";
import { ReactElement } from "react";
import Flashcard from "../components/Flashcard";

function getDescricaoTipoObjeto(tipoObjetoId: number) {
  return descricaoTipoObjetoMap[tipoObjetoId] || "";
}

function getIconeTipoObjeto(
  idTipoObjeto: number,
  color: string = "blue",
  size: number = 20,
  className?: string,
): ReactElement {
  return (iconMap[idTipoObjeto] || "")({ color, size, className });
}

function getIconeTipoObjetoColor(idTipoObjeto: number, color: string = "blue") {
  return iconMap[idTipoObjeto] || "";
}

function getTipoObjeto(idTipoObjeto: number) {
  return tipoObjetoMap[idTipoObjeto] || "";
}

const iconMap: {
  [key: number]: (props: {
    color: string;
    size: number;
    className?: string;
  }) => ReactElement;
} = {
  [typeObjetoEducacional.VIDEO]: ({ color, size, className }): ReactElement => (
    <svg
      width={size}
      height={size}
      className={className}
      aria-hidden="true"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2151 7.64709C22.1025 7.58676 21.9757 7.5581 21.8481 7.56416C21.7206 7.57022 21.597 7.61077 21.4907 7.68151L18.353 9.76915V7.55886C18.353 7.18444 18.2043 6.82535 17.9395 6.56059C17.6748 6.29583 17.3157 6.14709 16.9413 6.14709H2.82363C2.44921 6.14709 2.09012 6.29583 1.82536 6.56059C1.5606 6.82535 1.41187 7.18444 1.41187 7.55886V17.4412C1.41187 17.8156 1.5606 18.1747 1.82536 18.4395C2.09012 18.7042 2.44921 18.853 2.82363 18.853H16.9413C17.3157 18.853 17.6748 18.7042 17.9395 18.4395C18.2043 18.1747 18.353 17.8156 18.353 17.4412V15.2353L21.4907 17.3274C21.6072 17.4031 21.7435 17.4427 21.8825 17.4412C22.0697 17.4412 22.2492 17.3668 22.3816 17.2345C22.514 17.1021 22.5883 16.9225 22.5883 16.7353V8.26474C22.5874 8.13773 22.5523 8.01332 22.4866 7.90462C22.4209 7.79591 22.3271 7.70695 22.2151 7.64709ZM16.9413 17.4412H2.82363V7.55886H16.9413V17.4412ZM21.1766 15.4162L18.353 13.5342V11.4659L21.1766 9.58827V15.4162Z"
        fill={color}
      />
    </svg>
  ),
  [typeObjetoEducacional.QUESTIONARIO]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 27C22.5228 27 27 22.5228 27 17C27 11.4772 22.5228 7 17 7C11.4772 7 7 11.4772 7 17C7 22.5228 11.4772 27 17 27Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0901 14.0001C14.3252 13.3317 14.7892 12.7682 15.4 12.4092C16.0108 12.0502 16.729 11.919 17.4273 12.0388C18.1255 12.1586 18.7589 12.5216 19.2152 13.0636C19.6714 13.6056 19.9211 14.2916 19.9201 15.0001C19.9201 17.0001 16.9201 18.0001 16.9201 18.0001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22H17.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [typeObjetoEducacional.TEXTO]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7H11C10.4696 7 9.96086 7.21071 9.58579 7.58579C9.21071 7.96086 9 8.46957 9 9V25C9 25.5304 9.21071 26.0391 9.58579 26.4142C9.96086 26.7893 10.4696 27 11 27H23C23.5304 27 24.0391 26.7893 24.4142 26.4142C24.7893 26.0391 25 25.5304 25 25V13L19 7Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 7V13H25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 18H13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 22H13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 14H14H13"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [typeObjetoEducacional.IMAGEM]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 8H10C8.89543 8 8 8.89543 8 10V24C8 25.1046 8.89543 26 10 26H24C25.1046 26 26 25.1046 26 24V10C26 8.89543 25.1046 8 24 8Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15C14.3284 15 15 14.3284 15 13.5C15 12.6716 14.3284 12 13.5 12C12.6716 12 12 12.6716 12 13.5C12 14.3284 12.6716 15 13.5 15Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 20L21 15L10 26"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [typeObjetoEducacional.PDF]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.4625 24H6.53846C4.03558 24 2 21.9879 2 19.5139V13.486C2 11.0121 4.03558 9 6.53846 9H27.4615C29.9644 9 32 11.0121 32 13.486V19.5139C32.0008 21.9879 29.9656 24 27.4627 24H27.4625ZM6.53846 11.2431C5.28687 11.2431 4.26949 12.2491 4.26949 13.4858V19.5137C4.26949 20.7509 5.28727 21.7565 6.53846 21.7565H27.4615C28.7131 21.7565 29.7305 20.7505 29.7305 19.5137V13.4858C29.7305 12.2487 28.7127 11.2431 27.4615 11.2431H6.53846Z"
        fill={color}
      />
      <path
        d="M10.5869 12.7512C11.0538 12.7512 11.4537 12.8143 11.7891 12.9399C12.1235 13.0648 12.3994 13.2372 12.6154 13.457C12.8325 13.6768 12.9921 13.9349 13.0964 14.2337C13.1993 14.5321 13.25 14.8548 13.25 15.2015C13.25 15.5757 13.1964 15.9188 13.0914 16.2307C12.9854 16.5431 12.8226 16.812 12.6055 17.0338C12.3885 17.2556 12.1105 17.4307 11.7761 17.5552C11.4417 17.6809 11.0446 17.7439 10.5868 17.7439H9.78663V20.25H8.25V12.75H10.5868L10.5869 12.7512ZM10.5869 16.4318C10.9868 16.4318 11.275 16.3233 11.4505 16.1083C11.6263 15.892 11.7141 15.5888 11.7141 15.2023C11.7141 15.0307 11.6919 14.874 11.6464 14.7331C11.601 14.5923 11.5323 14.471 11.4392 14.3705C11.3462 14.27 11.2299 14.191 11.0886 14.1359C10.9473 14.0809 10.7807 14.0537 10.5865 14.0537H9.78632V16.4318L10.5869 16.4318Z"
        fill={color}
      />
      <path
        d="M20.75 16.4973C20.75 17.0395 20.6639 17.5393 20.4917 17.9981C20.3206 18.4556 20.0777 18.8526 19.7671 19.1876C19.455 19.5215 19.0802 19.7824 18.6398 19.9699C18.1998 20.1574 17.7122 20.25 17.1765 20.25H14.5V12.75H17.1765C17.7121 12.75 18.1997 12.8449 18.6398 13.0336C19.0798 13.2223 19.4549 13.4832 19.7671 13.8159C20.0792 14.1487 20.3206 14.5444 20.4917 15.0019C20.6639 15.4607 20.75 15.9582 20.75 16.4972V16.4973ZM19.1135 16.4973C19.1135 16.1267 19.0696 15.7916 18.9813 15.4932C18.893 15.1948 18.7659 14.9419 18.601 14.7336C18.4362 14.5254 18.2325 14.3666 17.994 14.2557C17.7555 14.1448 17.4825 14.0885 17.1766 14.0885H16.1073V18.912H17.1766C17.4822 18.912 17.7555 18.8562 17.994 18.7449C18.2336 18.634 18.4361 18.4736 18.601 18.2657C18.7659 18.0587 18.893 17.8058 18.9813 17.5074C19.0692 17.2086 19.1135 16.8699 19.1135 16.4973V16.4973Z"
        fill={color}
      />
      <path
        d="M23.8113 14.0882V15.9882H26.4659V17.331H23.8113V20.25H22V12.75H27V14.0884L23.8113 14.0882Z"
        fill={color}
      />
    </svg>
  ),
  [typeObjetoEducacional.AUDIO]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 10.0698L11 14.0698H7V20.0698H11L16 24.0698V10.0698Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.07 10C25.9447 11.8753 26.9979 14.4184 26.9979 17.07C26.9979 19.7216 25.9447 22.2647 24.07 24.14M20.54 13.53C21.4774 14.4676 22.004 15.7392 22.004 17.065C22.004 18.3908 21.4774 19.6624 20.54 20.6"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [typeObjetoEducacional.LIVE]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2379 12.83C21.7957 13.3872 22.2383 14.049 22.5402 14.7774C22.8422 15.5057 22.9976 16.2865 22.9976 17.075C22.9976 17.8635 22.8422 18.6443 22.5402 19.3726C22.2383 20.101 21.7957 20.7628 21.2379 21.32M12.7579 21.31C12.2 20.7528 11.7575 20.091 11.4555 19.3626C11.1536 18.6343 10.9981 17.8535 10.9981 17.065C10.9981 16.2765 11.1536 15.4957 11.4555 14.7674C11.7575 14.039 12.2 13.3772 12.7579 12.82M24.0679 10C25.9426 11.8753 26.9957 14.4184 26.9957 17.07C26.9957 19.7216 25.9426 22.2647 24.0679 24.14M9.92786 24.14C8.05315 22.2647 7 19.7216 7 17.07C7 14.4184 8.05315 11.8753 9.92786 10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8 18.9998C14.5464 18.7898 14.3452 18.5404 14.2079 18.2658C14.0707 17.9913 14 17.697 14 17.3998C14 17.1026 14.0707 16.8083 14.2079 16.5338C14.3452 16.2593 14.5464 16.0098 14.8 15.7998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9998 15.8C19.2534 16.01 19.4546 16.2594 19.5919 16.534C19.7291 16.8085 19.7998 17.1028 19.7998 17.4C19.7998 17.6972 19.7291 17.9915 19.5919 18.266C19.4546 18.5406 19.2534 18.79 18.9998 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  [typeObjetoEducacional.FLASHCARD]: ({ color, size, className }) => (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      className={className}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 27C22.5228 27 27 22.5228 27 17C27 11.4772 22.5228 7 17 7C11.4772 7 7 11.4772 7 17C7 22.5228 11.4772 27 17 27Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0901 14.0001C14.3252 13.3317 14.7892 12.7682 15.4 12.4092C16.0108 12.0502 16.729 11.919 17.4273 12.0388C18.1255 12.1586 18.7589 12.5216 19.2152 13.0636C19.6714 13.6056 19.9211 14.2916 19.9201 15.0001C19.9201 17.0001 16.9201 18.0001 16.9201 18.0001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 22H17.01"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};

const descricaoTipoObjetoMap: { [key: number]: string } = {
  [typeObjetoEducacional.FLASHCARD]: "Flashcard",
  [typeObjetoEducacional.VIDEO]: "Vídeo",
  [typeObjetoEducacional.QUESTIONARIO]: "Questionário",
  [typeObjetoEducacional.TEXTO]: "Texto",
  [typeObjetoEducacional.IMAGEM]: "Imagem",
  [typeObjetoEducacional.PDF]: "PDF",
  [typeObjetoEducacional.AUDIO]: "Áudio",
  [typeObjetoEducacional.LIVE]: "Live",
};

const tipoObjetoMap: { [key: number]: string } = {
  [typeObjetoEducacional.FLASHCARD]: FlashCardIcon,
  [typeObjetoEducacional.VIDEO]: Video,
  [typeObjetoEducacional.QUESTIONARIO]: Questionario,
  [typeObjetoEducacional.TEXTO]: Texto,
  [typeObjetoEducacional.IMAGEM]: Imagem,
  [typeObjetoEducacional.PDF]: Pdf,
  [typeObjetoEducacional.AUDIO]: Audio,
  [typeObjetoEducacional.LIVE]: Live,
};

export {
  getTipoObjeto,
  getIconeTipoObjeto,
  getIconeTipoObjetoColor,
  getDescricaoTipoObjeto,
};
