import React, { useState, useEffect, useContext } from "react";
import { useHistory, withRouter } from "react-router";
import HtmlParse from "html-react-parser";
import Vimeo, { PauseEvent, TimeUpdateEvent } from "@u-wave/react-vimeo";

import {
  ComponentSecaoVideoAudioVideoAudio,
  PublicoAlvo,
  ObjetoEducacionalEntity,
  UsersPermissionsUserEntity,
  OEsAssistidosPorUsuarioEntity,
} from "../../../../../generated/graphql";

import AddBlue from "../../../../../assets/svgs/add.svg";

import PlayerControls from "../../../OeVideoPage/components/PlayerControls/PlayerControls";
import useOeConsume from "../../../../../hooks/useOeConsume";
import { UserContext } from "../../../../../context/UserContext";

import "./styles.scss";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Tags } from "../../../../../shared/components/Tags";
import { useUtils } from "../../../../../shared/utils";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../../hooks/useGtm";
import {
  Banner,
  loadBanner,
} from "../../../../../graphql/collections/queries/banner.query";
import Container from "../../../../../shared/components/Container";
import Rotulo from "../../../../../shared/components/rotulos";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../shared/components/ui/dialog";
import { cn } from "tailwind-variants";
import { AudioLines, Calendar, Clock4 } from "lucide-react";

interface PodcastPreviewProps {
  oe: ObjetoEducacionalEntity;
  user?: UsersPermissionsUserEntity;
  history: any;
  location: any;
  match: any;
}

const PodcastPreviewDesktop: React.FC<PodcastPreviewProps> = (props) => {
  const { oe } = props;
  const history = useHistory();
  const [limitTag, setLimitTag] = useState<boolean>(true);
  const { saveOeConsume, updateOeConsume } = useOeConsume();

  const { user } = useContext(UserContext);

  const [videoAudio, setVideoAudio] = useState<
    ComponentSecaoVideoAudioVideoAudio | undefined
  >(undefined);
  const [currentTime, setCurrentTime] = useState(0);
  const [autoPlay, setAutoPlay] = useState(false);
  const [totalDuration, setTotalDuration] = useState(0);
  const [showBanner, setShowBanner] = useState(false);
  const [lastShowBanner, setLastShowBanner] = useState<Number>(0);
  const [partialDuration, setPartialDuration] = useState(0);
  const [mute, setMute] = useState(false);
  const [forwarding, setForwarding] = useState(false);
  const [backwarding, setBackwarding] = useState(false);
  const [player, setPlayer] = useState<any>();
  const [oeAssistido, setOeAssistido] = useState<
    OEsAssistidosPorUsuarioEntity | undefined
  >(undefined);

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      objetoEducacionalId: props.oe.id! ? Number(props.oe.id!) : undefined,
      areaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data
          ?.filter((sub) => {
            return !!sub.attributes?.area_de_conhecimento?.data?.id;
          })
          ?.map((a) => Number(a.attributes?.area_de_conhecimento?.data?.id!)) ||
        [],
      subAreaDeConhecimentoId:
        props.oe.attributes?.SubAreaConhecimento?.data?.map((a) =>
          Number(a.id!),
        ) || [],
      publicoAlvoId:
        props.oe.attributes?.PublicoAlvo?.data?.map((a) => Number(a.id!)) || [],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [props.oe]);

  const joinPublicoAlvoName = (list: PublicoAlvo[] = []) => {
    const strArr = list.reduce((acc: Array<String>, item) => {
      if (item && item.titulo) {
        acc.push(item.titulo);
      }
      return acc;
    }, []);

    return strArr.join(", ");
  };

  const updateTimeInProgress = () => {
    /* Dados para associar usuario com conteudo */
    if (!props.user || !videoAudio || !oe) {
      return false;
    }

    /* Progresso do consumo */
    if (!partialDuration || !totalDuration) {
      return false;
    }

    /* Consumo concluido */
    if (
      !!oeAssistido &&
      oeAssistido.attributes?.duracao === oeAssistido.attributes?.segundos
    ) {
      return false;
    }

    updateOeConsume({
      partialDuration,
      totalDuration,
    });

    return true;
  };

  useEffect(() => {
    setShowBanner(true);
    if (!props.oe) {
      return;
    }

    if (
      !props.oe.attributes?.VideoAudio ||
      !props.oe.attributes?.VideoAudio.length
    ) {
      return;
    }

    if (!props.user) {
      return;
    }
    const videoId = props.oe.attributes?.VideoAudio[0]!.id!;
    setVideoAudio(props.oe.attributes?.VideoAudio[0]);

    const _oeAssistido =
      props.user?.attributes?.OesConsumidosParcialmente?.data.find(
        (item) =>
          item.attributes?.VideoAudioId!?.toString() === videoId.toString(),
      ) as OEsAssistidosPorUsuarioEntity | undefined;

    updateOeConsume({
      oe: oe.id,
      videoAudioId: parseInt(videoId),
      user: props.user.id,
    });

    if (!!_oeAssistido) {
      updateOeConsume({
        id: _oeAssistido.id,
      });
      setOeAssistido(_oeAssistido);

      if (
        _oeAssistido.attributes?.segundos! < _oeAssistido.attributes?.duracao!
      ) {
        setPartialDuration(_oeAssistido?.attributes?.segundos!);
        setCurrentTime(_oeAssistido?.attributes?.segundos!);
        setTotalDuration(_oeAssistido?.attributes?.duracao!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.oe, props.user]);

  useEffect(() => {
    async function process() {
      const timeUpdated = updateTimeInProgress();

      /**
       * Salva consumo caso dados de consumo estejam atualizados
       * e
       * que o consumo esteja concluido
       * */
      if (
        timeUpdated &&
        totalDuration > 0 &&
        totalDuration === partialDuration
      ) {
        await saveOeConsume({
          partialDuration,
          totalDuration,
        });
      }
    }

    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialDuration, totalDuration]);

  const handleShowBanner = (currentPercent: Number) => {
    if (showBanner === false && currentPercent !== lastShowBanner) {
      setLastShowBanner(currentPercent);
      setShowBanner(true);
    }
  };

  const onTimeUpdate = (e: TimeUpdateEvent | PauseEvent) => {
    if (!e) {
      return;
    }

    let { seconds, duration } = e;

    const totalPercentual = (seconds / duration) * 100;

    const ArrayPerc = [25, 50, 90];
    var currentPercent = Math.round(totalPercentual);

    if (ArrayPerc.indexOf(currentPercent) > -1) {
      handleShowBanner(currentPercent);
    }

    if (!seconds || !duration) {
      return;
    }

    seconds = parseInt(seconds?.toString().split(".")[0]);

    // Seek foward 15 seconds
    if (!backwarding && forwarding) {
      seconds = seconds + 15 > duration ? duration : seconds + 15;

      player.setCurrentTime(seconds);
      setForwarding(false);
    }

    // Turn back 15 seconds
    if (!forwarding && backwarding) {
      seconds = seconds - 15 < 0 ? 0 : seconds - 15;

      player.setCurrentTime(seconds);
      setBackwarding(false);
    }

    duration = parseInt(duration.toString().split(".")[0]);
    duration = totalDuration > duration ? totalDuration : duration;

    setCurrentTime(seconds);
    setPartialDuration(seconds);
    setTotalDuration(duration);
  };

  const videoId = oe.attributes?.VideoAudio
    ? oe.attributes?.VideoAudio[0]
    : null;

  const onStop = async (e: PauseEvent) => {
    if (!e) {
      return;
    }
    onTimeUpdate(e);
    await saveOeConsume({
      id: oeAssistido?.id,
      videoAudioId: parseInt(videoId?.id!),
      user: props.user?.id,
      partialDuration: parseInt(partialDuration.toString().split(".")[0]),
      totalDuration: parseInt(totalDuration.toString().split(".")[0]),
      oe: props.oe.id,
    });
  };

  const onStart = async (e: PauseEvent) => {
    setAutoPlay(!autoPlay);

    const timeUpdated = updateTimeInProgress();

    if (
      !!timeUpdated &&
      (!oeAssistido ||
        oeAssistido.attributes?.duracao! > oeAssistido.attributes?.segundos!)
    ) {
      await saveOeConsume({
        id: oeAssistido?.id,
        videoAudioId: parseInt(videoId?.id!),
        user: props.user?.id,
        partialDuration: parseInt(partialDuration.toString().split(".")[0]),
        totalDuration: parseInt(totalDuration.toString().split(".")[0]),
        oe: props.oe.id,
      });
    }
  };

  const handleReady = (player: any) => {
    setPlayer(player);
  };

  const handleSliderDuration = async (value: any) => {
    if (!value) return;
    setMute(true);
    await player.setCurrentTime(value);
    setPartialDuration(value);
    setMute(false);
  };

  const closeBanner = () => {
    setShowBanner(!showBanner);
  };

  const tempoMeioDuracao: string | number = parseInt(
    props.oe?.attributes?.TempoMedioDuracao.replace(/[^\d]/g, "") || "0",
  );

  // const getTextoTipo = () => {
  //   switch (tipoOE) {
  //     case TypeOE.PDF:
  //     case TypeOE.Texto:
  //     case TypeOE.Imagem:
  //       return "Leitura";
  //     case TypeOE.Video:
  //       return props.oe?.attributes?.TipoObjeto?.data.find(
  //         (e) => e.attributes?.titulo == "Vídeo",
  //       )
  //         ? "Vídeo"
  //         : "Vídeocast";
  //     default:
  //       return "";
  //   }
  // };

  const tempoFormatado =
    tempoMeioDuracao > 60
      ? (tempoMeioDuracao / 60).toFixed(2).replaceAll(".", ",") + " horas"
      : tempoMeioDuracao + " minutos";

  const tags = limitTag
    ? props.oe?.attributes?.palavras_chave?.data.slice(0, 6)
    : props.oe?.attributes?.palavras_chave?.data;

  const { formatTitle } = useUtils();
  const gtm = useGtm();

  return (
    <>
      <section
        className="flex h-[592px] w-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${props.oe.attributes?.ImagemCapa?.data?.attributes?.url})`,
        }}
      >
        <Container className="relative">
          <div className="flex h-full flex-col items-start justify-center gap-6 ">
            <div className="flex h-auto w-[364px] flex-col items-start justify-center gap-6">
              <div className="flex flex-col gap-3">
                <div className="flex w-auto">
                  {props.oe.attributes?.Rotulos?.data.map((rotulo, index) => (
                    <Rotulo
                      key={index}
                      id={Number(rotulo?.id!)}
                      rotuloName={rotulo.attributes?.titulo ?? ""}
                    />
                  ))}
                </div>
                <h3
                  className={`text-xxl font-medium  leading-[26.82px] ${
                    true ? "text-[#FDFEFF] " : "text-[#004F92]"
                  }`}
                >
                  {formatTitle(props.oe.attributes?.Titulo!)}
                </h3>
                <div>
                  <Dialog>
                    <DialogTrigger asChild>
                      <div className="text-sm font-medium leading-[17.07px]" role="button">
                        <p
                          className={`!line-clamp-2 inline-block  ${
                            true ? "text-[#FDFEFF] " : "text-[#111316]"
                          }`}
                        >
                          {props.oe.attributes?.Descricao &&
                            HtmlParse(
                              props.oe.attributes?.Descricao.replace(
                                "<p>",
                                "<p className={`truncate line-clamp-2 text-sm font-medium leading-[17.07px]`}>",
                              ),
                            )}
                        </p>
                        {props.oe.attributes?.Descricao?.length! > 200 && (
                          <span
                            className={`font-bold underline ${
                              true ? "text-[#FDFEFF] " : "text-[#111316]"
                            }`}
                          >
                            Mostrar mais
                          </span>
                        )}
                      </div>
                    </DialogTrigger>
                    <DialogContent className="py-8">
                      <DialogHeader className="flex w-full items-start justify-start">
                        <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                          Descrição
                        </DialogTitle>
                      </DialogHeader>
                      <div className="flex items-center space-x-2">
                        <div className="grid flex-1 gap-2">
                          <p
                            className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                          >
                            {props.oe.attributes?.Descricao &&
                              HtmlParse(
                                props.oe.attributes?.Descricao.replace(
                                  "<p>",
                                  "<p className={` text-sm font-normal leading-[20px] text-[#313335]`}>",
                                ),
                              )}
                          </p>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div
                  className={`flex items-center gap-1
              ${true ? "text-[#FDFEFF] " : "text-[#111316]"}`}
                >
                  <AudioLines size={16.94} />
                  <span className="text-xs font-bold uppercase leading-[30px]">
                    PODCAST
                  </span>
                </div>
                <div
                  className={`flex items-center gap-1 text-xs font-bold uppercase leading-[30px] ${
                    true ? "text-[#FDFEFF] " : "text-[#111316]"
                  }`}
                >
                  <Calendar size={16.94} />
                  <span>
                    {props.oe.attributes?.DataProducao
                      ? format(
                          new Date(
                            `${props.oe.attributes?.DataProducao}T00:00:00`,
                          ),
                          "dd MMM yy",
                          { locale: ptBR },
                        )
                      : ""}
                  </span>
                </div>
                <div
                  className={`flex items-center gap-1  text-xs font-bold uppercase leading-[30px] ${
                    true ? "text-[#FDFEFF] " : "text-[#111316]"
                  }`}
                >
                  <Clock4 size={16.94} />
                  <span>{tempoFormatado}</span>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col flex-nowrap">
                  <div className="flex flex-col gap-3">
                    <p
                      className={`text-sm font-bold leading-[17.07px] ${
                        true ? "text-[#FDFEFF] " : "text-[#111316]"
                      }`}
                    >
                      Recomendado:
                    </p>

                    <Dialog>
                      <DialogTrigger asChild>
                        <div className="text-sm font-medium leading-[17.07px]" role="button">
                          <span
                            className={`!line-clamp-2 inline-block text-sm font-medium leading-[17.07px] ${
                              true ? "text-[#FDFEFF] " : "text-[#111316]"
                            }`}
                          >
                            {props.oe.attributes?.PublicoAlvo?.data.map(
                              (p, index, arr) =>
                                index === arr.length - 1
                                  ? `${p.attributes?.titulo}`
                                  : `${p.attributes?.titulo}, `,
                            )}
                          </span>
                          {props?.oe?.attributes?.PublicoAlvo?.data?.length! >
                            6 && (
                            <span
                              className={`font-bold  underline ${
                                true ? "text-[#FDFEFF] " : "text-[#111316]"
                              }`}
                            >
                              Mostrar mais
                            </span>
                          )}
                        </div>
                      </DialogTrigger>
                      <DialogContent className="py-8">
                        <DialogHeader className="flex w-full items-start justify-start">
                          <DialogTitle className="text-base  font-bold normal-case leading-22 text-[#004F92]">
                            Recomendado
                          </DialogTitle>
                        </DialogHeader>
                        <div className="flex items-center space-x-2">
                          <div className="grid flex-1 gap-2">
                            <p
                              className={`inline-block text-sm font-normal leading-[20px] text-[#313335]`}
                            >
                              {props.oe.attributes?.PublicoAlvo?.data.map(
                                (p, index, arr) =>
                                  index === arr.length - 1
                                    ? `${p.attributes?.titulo}`
                                    : `${p.attributes?.titulo}, `,
                              )}
                            </p>
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <p
                  className={`text-sm font-bold leading-[17.07px] ${
                    true ? "text-[#FDFEFF] " : "text-[#111316]"
                  }`}
                >
                  Palavras-chave:
                </p>

                <div className="flex flex-wrap gap-[6px]">
                  {tags?.map((m, index) => (
                    <Tags
                      key={index}
                      isSearchable={true}
                      title={m.attributes?.titulo!}
                    />
                  ))}
                </div>
              </div>
              {limitTag &&
                props.oe.attributes?.palavras_chave?.data.length !=
                  tags?.length && (
                  <div
                    className="m-0 flex items-center gap-2"
                    onClick={() => setLimitTag(false)}
                  >
                    <img
                      className="m-0 h-6 w-6 p-0"
                      src={AddBlue}
                      alt="Ícone adicionar"
                    />
                    <span
                      className={`cursor-pointer"  w-[100px] flex-nowrap text-xs font-bold leading-none text-[#FDFEFF] ${
                        false ? "text-[#FDFEFF] " : "text-[#707070]"
                      }`}
                    >
                      Ver todas
                    </span>
                  </div>
                )}
            </div>
            <div className="flex w-full">
              <div className="flex w-full justify-between">
                <PlayerControls
                  currentTime={partialDuration}
                  duration={totalDuration}
                  play={async (ev: any) => {
                    ev.stopPropagation();
                    await onStart(ev);

                    gtm.push(
                      EDataLayerEventCategory.ADE_OE_INTERACAO_AUDIO,
                      EDataLayerEventAction.CLIQUE,
                      {
                        label: `iniciar-audio:${formatTitle(
                          oe?.attributes?.Titulo!,
                        )}`,
                      },
                    );
                  }}
                  seekBackward={() => setBackwarding(true)}
                  seekForward={() => setForwarding(true)}
                  handleSliderChange={(ev, value) => {
                    handleSliderDuration(value);
                  }}
                  handleVolumeChange={(ev) => {
                    ev.stopPropagation();
                    setMute((mute) => !mute);
                  }}
                  isPlaying={autoPlay}
                  isMuted={mute}
                />

                {!!videoAudio && !!videoAudio?.URL && (
                  <div data-video-content>
                    <Vimeo
                      pip={true}
                      style={{ display: "none" }}
                      volume={mute === true ? 0 : 1}
                      showTitle={false}
                      start={partialDuration}
                      onReady={handleReady}
                      onTimeUpdate={onTimeUpdate}
                      onEnd={(e) => {
                        setAutoPlay(false);
                        onStop(e);
                      }}
                      onPause={(e: any) => {
                        setAutoPlay(false);
                        onStop(e);
                      }}
                      onPlay={() => setAutoPlay(true)}
                      paused={!autoPlay}
                      controls={true}
                      video={videoAudio?.URL!}
                      className="player-video"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default withRouter(PodcastPreviewDesktop);
