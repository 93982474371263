import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
} from "../../shared/components";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { OeTema } from "./components";

export const CategoriaTemaPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  useEffect(() => {
    scrollTop();

    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "temas_saude",
        }
      );
    };
  }, []);


  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <div data-tema-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem active>Temas de saúde</BreadcrumbItem>
          </Breadcrumb>
            <h3>Estude pelo tema da saúde</h3>
        </HeaderSection>
        <section data-container>
          <OeTema setLoading={setLoading} />
        </section>
      </div>
    </>
  );
};
