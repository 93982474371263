import React, { useEffect, useState } from "react";
import {
  RotuloEntity,
  useGetCategoriasLazyQuery,
} from "../../generated/graphql";

import { Loader } from "../../shared/components";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import "./styles.scss";
import HeaderPageBreadcrumb from "../../shared/components/HeaderPageBreadcrumb";
import Container from "../../shared/components/Container";
import Rotulo from "../../shared/components/rotulos";
import { Link } from "react-router-dom";

const MetodosEnsinoPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean | null>(true);
  const [lista, setLista] = useState<RotuloEntity[]>([]);
  const [GetCategorias, { data }] = useGetCategoriasLazyQuery({
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setLoading(false);
      const rotulos = data?.rotulos?.data as RotuloEntity[];
      setLista(rotulos);
    },
    onError: (error) => {
      setLoading(false);
    },
  });
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  useEffect(() => {
    scrollTop();

    // GTM doc linha 68
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: "pagina_metodos_ensino",
      },
    );
  }, []);

  useEffect(() => {
    GetCategorias();

    if (!!data) {
      setLoading(false);
      setLista(data?.rotulos?.data as RotuloEntity[]);
    }
  }, [GetCategorias, data]);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />

      <HeaderPageBreadcrumb
        itens={[{ to: "/", title: "Home" }, { title: "Métodos de ensino" }]}
        title="Aprenda por Métodos"
      />

      <section>
        <Container className="py-9">
          <h3 className="text-sm font-normal leading-22 text-[#626466] md:text-base">
            Aprenda com uma variedade de métodos de ensino para atender às suas
            preferências de aprendizado.
          </h3>
          <div className="mt-9  grid grid-cols-1  grid-rows-3 gap-4 md:grid-cols-3">
            {lista
              .filter((item) => !!item.attributes?.ImagemFundo)
              .map((item, i: number) => (
                <Link
                  key={i}
                  to={`/metodos-ensino/${item?.attributes?.titulo
                    ?.toString()
                    .toLowerCase()
                    .trim()
                    .replace(/ /g, "-")}/${item?.id}`}
                  className="hover:border-[#004F92] focus:border-[#004F92] border-solid border-1 rounded-lg"
                >
                  <div className="flex h-[220px] w-full flex-col  justify-between gap-3 rounded-xl bg-[#F5F8FF] !px-5 py-9 tablet:h-[134.12px] tablet:px-[14.99px] tablet:py-[14.99px]">
                    <div className="flex justify-end" aria-hidden={true}>
                      <Rotulo
                        rotuloName={item.attributes?.titulo.toString()!}
                        id={Number(item.id!)}
                        isMetodoEnsino={true}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="text-base font-semibold leading-18 text-[#111316] tablet:text-[9.75px] tablet:leading-[13.41px]">
                        {item.attributes?.titulo}
                      </span>
                      <span className="text-sm font-normal leading-18 text-[#626466] tablet:text-[8.54px] tablet:leading-[10.97px]">
                        {item.attributes?.descricao}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default MetodosEnsinoPage;
