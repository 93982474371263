import React from "react";
import CustomSwiper from "../../../../shared/components/CustomSwiper";
import { SwiperSlide } from "swiper/react";
import { twMerge } from "tailwind-merge";
import Vimeo from "@u-wave/react-vimeo";
import { PerguntaModel } from "../../../../models/PerguntaModel";

export interface MediaImagemVideoQuizProps {
  className: string;
  isMobile: boolean;
  onClickMobile: () => void;
  perguntaAtiva?: PerguntaModel;
  nextRef: any;
  previousRef: any;
}

export const MediaImagemVideoQuiz: React.FC<MediaImagemVideoQuizProps> = ({
  className,
  isMobile,
  onClickMobile,
  perguntaAtiva,
  nextRef,
  previousRef,
}) => {
  return (
    <>
      <div
        className={className}
        onClick={() => {
          isMobile && onClickMobile();
        }}
      >
        {perguntaAtiva?.imagemUrls && perguntaAtiva?.imagemUrls?.length > 0 && (
          <CustomSwiper
            spaceBetween={1}
            slidesPerView={1}
            navigation={true}
            pagination={true}
            nextRef={nextRef}
            previousRef={previousRef}
            className="h-auto overflow-hidden"
            autoHeight={true}
            quantidade={perguntaAtiva?.imagemUrls?.length}
          >
            {perguntaAtiva?.imagemUrls &&
              perguntaAtiva?.imagemUrls?.map((img, index: number) => (
                <SwiperSlide key={index}>
                  <img
                    src={img}
                    width="auto"
                    className={twMerge(
                      "max-h-[300px] bg-no-repeat object-fill object-center md:max-h-[500px] md:min-h-[500px]",
                      className,
                    )}
                    alt={`Imagem ${index}`}
                  />
                </SwiperSlide>
              ))}
          </CustomSwiper>
        )}

        {!!perguntaAtiva && !!perguntaAtiva?.urlVimeo && (
          <Vimeo
            showTitle={false}
            controls={true}
            autoplay={false}
            video={perguntaAtiva?.urlVimeo}
            className="player-video"
          />
        )}
      </div>
    </>
  );
};
