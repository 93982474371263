import * as React from "react";
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from "embla-carousel-react";

import { cn } from "../../../lib/utils";
import * as Icon from "../../../assets/svgs";
import { Button } from "./button";
import { twMerge } from "tailwind-merge";
import { ChevronLeft, ChevronRight } from "lucide-react";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
  initialSlide?: number;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  currentGroupSlide: number;
  totalGroupSlides: number;
  isHovered: boolean;
  isLightShadown?: boolean;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      initialSlide = 0,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [currentGroupSlide, setCurrentGroupSlide] = React.useState(3);
    const [totalGroupSlides, setTotalGroupSlides] = React.useState(0);
    const [isHovered, setIsHovered] = React.useState(true);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
      setCurrentGroupSlide(api.selectedScrollSnap());
      setTotalGroupSlides(api.scrollSnapList().length);
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (api) {
        api.scrollTo(initialSlide); // Vai para o slide definido em initialSlide
      }
    }, [api, initialSlide]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation:
            orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          currentGroupSlide,
          totalGroupSlides,
          isHovered,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);

Carousel.displayName = "Carousel";

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className={cn(className, "overflow-hidden")}>
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
          className,
        )}
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { orientation } = useCarousel();

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        "min-w-0 shrink-0 grow-0 basis-full",
        orientation === "horizontal" ? "pl-4" : "pt-4",
        className,
      )}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    height?: string;
    isShowButton?: boolean;
    isLightShadown?: boolean;
  }
>(
  (
    { className, variant = "outline", height, color, size = "icon", ...props },
    ref,
  ) => {
    const {
      orientation,
      scrollPrev,
      canScrollPrev,
      isHovered,
      isLightShadown,
      totalGroupSlides,
    } = useCarousel();

    return (
      <>
        {(props.isShowButton || isHovered) && totalGroupSlides > 1 && (
          <div
            className={twMerge(
              "absolute left-0 top-0 flex w-8 ",
              className?.includes("banner")
                ? ""
                : color === "white"
                  ? "rounded-l-xl bg-gradient-to-r from-white-200/35 "
                  : "rounded-l-xl bg-gradient-to-r from-black-900/35 ",
              `${height}`,
            )}
          >
            <button
              ref={ref}
              className={cn(
                "!hover:bg-none absolute hidden  h-8 w-8 md:block md:h-12 md:w-12",
                orientation === "horizontal"
                  ? "left-0 top-1/2 -translate-y-1/2"
                  : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
                canScrollPrev ? "cursor-pointer" : "cursor-default opacity-30",
                className,
              )}
              style={!canScrollPrev ? { background: "none" } : {}}
              disabled={!canScrollPrev}
              aria-label="Anterior"
              onClick={scrollPrev}
              {...props}
            >
              {color === "white" ? (
                <ChevronLeft size={48} color="#00b192" strokeWidth={1.8} />
              ) : (
                <ChevronLeft size={48} color="white" strokeWidth={1.8} />
              )}
            </button>
          </div>
        )}
      </>
    );
  },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    height?: string;
    isShowButton?: boolean;
    isLightShadown?: boolean;
  }
>(
  (
    { className, variant = "ghost", height, color, size = "icon", ...props },
    ref,
  ) => {
    const {
      orientation,
      scrollNext,
      canScrollNext,
      isHovered,
      isLightShadown,
      totalGroupSlides,
    } = useCarousel();

    return (
      <>
        {(props.isShowButton || isHovered) && totalGroupSlides > 1 && (
          <div
            className={twMerge(
              "absolute  right-0 top-0 flex  w-8 ",
              className?.includes("banner")
                ? ""
                : color === "white"
                  ? "rounded-r-xl bg-gradient-to-l from-white-200/35 "
                  : "rounded-r-xl bg-gradient-to-l from-black-900/35 ",
              `${height}`,
            )}
          >
            <button
              className={cn(
                "absolute hidden h-8 w-8 md:block md:h-12 md:w-12 ",
                orientation === "horizontal"
                  ? "right-0 top-1/2 -translate-y-1/2"
                  : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
                canScrollNext ? "cursor-pointer" : "cursor-default opacity-30",
                className,
              )}
              style={!canScrollNext ? { background: "none" } : {}}
              disabled={!canScrollNext}
              onClick={scrollNext}
              {...props}
            >
              {color === "white" ? (
                <ChevronRight size={48} color="#00b192" strokeWidth={1.8} />
              ) : (
                <ChevronRight size={48} color="white" strokeWidth={1.8} />
              )}
              <span className="sr-only">Next slide</span>
            </button>
          </div>
        )}
      </>
    );
  },
);

CarouselNext.displayName = "CarouselNext";

type BulletType = "bullet" | "tag";

const CarouselBullets = ({
  className,
  bulletType,
}: {
  className?: string;
  bulletType?: BulletType;
}) => {
  const { currentGroupSlide, totalGroupSlides, api } = useCarousel();

  const handleBulletClick = (index: number) => {
    if (api) {
      api.scrollTo(index);
    }
  };
  return totalGroupSlides > 1 ? (
    <div className={cn("mt-3 flex justify-center gap-2 md:mt-4", className)}>
      {Array.from({ length: totalGroupSlides }, (_, index) => (
        <div
          onClick={() => handleBulletClick(index)}
          key={index}
          className={`h-2 w-2 cursor-pointer rounded-full ${
            index === currentGroupSlide
              ? `${
                  !bulletType
                    ? "h-2 w-2 bg-brand-blue"
                    : "h-[3.91px] w-[23.46px] bg-gray-50 md:h-[6px] md:w-9"
                }`
              : `${
                  !bulletType
                    ? "h-2 w-2 bg-green-50"
                    : "h-[3.91px] w-[15.64px] bg-gray-400 md:h-[6px] md:w-6"
                }`
          }`}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

CarouselBullets.displayName = "CarouselBullets";

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselBullets,
};
