import React, { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { UserContext } from "./UserContext";
import { fetchApi } from "../shared/services/rest-dotnet.service";
import { UserResidenciaModel } from "../models/UserResidenciaModel";

export interface IUserDetalheContext {
  userDetalhe?: UserResidenciaModel | null;
  setUserDetalhe(
    userDetalhe?: UserResidenciaModel | undefined | null,
  ): void;
  loading: boolean;
  loadUser: () => void;
}

async function getUserResidenciaMedica(): Promise<UserResidenciaModel> {
  const response = await fetchApi("user/get-user-residencia", "POST")
  .then((res) => {
    if (res.ok) {
      const contentLength = res.headers.get('Content-Length');
      const contentType = res.headers.get('Content-Type');

      if (contentLength === '0' || !contentType?.includes('application/json')) {
        return null;
      }

      return res.json().catch(() => null);
    } else {
      return null;
    }
  });
  return response;
}

export const UserDetalheContext = createContext<IUserDetalheContext>(
  {} as IUserDetalheContext,
);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }: { children: any }) => {
  const { user, loadingUser } = useContext(UserContext);
  const [userDetalhe, setUserDetalhe] = useState<
    UserResidenciaModel | undefined | null
    >(undefined);
  const [loadingDetalhe, setLoadingDetalhe] = useState<boolean>(true);

  const loadUser = async () => {
    try {
      setLoadingDetalhe(true);
      const userDetalhe = await getUserResidenciaMedica();
      setUserDetalhe(userDetalhe);
    } catch (e) {

    } finally {
      setLoadingDetalhe(false);
    }
  };

  useEffect(() => {
    if (!user) {
      if (!loadingUser) {
        setUserDetalhe(null);
        setLoadingDetalhe(false);
      }
      return;
    }

    loadUser();
  }, [user, loadingUser]);

  return (
    <UserDetalheContext.Provider
      value={{
        userDetalhe,
        setUserDetalhe,
        loadUser,
        loading: loadingDetalhe
      }}
    >
      {children}
    </UserDetalheContext.Provider>
  );
};
