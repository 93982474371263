import { ResidenciaMedicaEntity } from "../../../generated/graphql";

export default function CardPrincipalResidencia({
  residenciaMedicas,
}: {
  residenciaMedicas?: ResidenciaMedicaEntity;
}) {
  const percentual = Math.min(
    Number(
      (residenciaMedicas?.attributes?.quantidadeModulos === 0
        ? 0
        : (residenciaMedicas?.attributes?.progresso! /
            residenciaMedicas?.attributes?.quantidadeModulos!) *
          100
      ).toFixed(0)
    ),
    100
  );

  return (
    <>
      <div
        style={{
          border: "0.8px solid #E7E9ED",
        }}
        className="h-[92px] w-full flex rounded-xl flex-col p-6 bg-[#00B193] shadow-[0_2px_4px_0_rgba(11,31,77,0.08)]"
      >
        <div className="flex justify-between w-full ">
          <span className="inline-block font-bold text-xs leading-4 text-[#FFF]">
            {residenciaMedicas?.attributes?.titulo}
          </span>
          <span className="inline-block font-medium text-xs leading-4 text-[#FFF]">
            {residenciaMedicas?.attributes?.progresso! || 0}/
            {residenciaMedicas?.attributes?.quantidadeModulos}
          </span>
        </div>

        <div className="flex justify-between w-full items-center gap-3 mt-[14px]">
          <span className="inline-block font-bold text-xs leading-none text-[#FFF]">
            {percentual}%
          </span>
          <div className="relative bg-[#41c5ae] w-full h-[6px] rounded-2xl flex ">
            <div
              className={`bg-[#FFFFFF] h-[6px] rounded-[20px] flex `}
              style={{
                width: `${percentual}%`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
