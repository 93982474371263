import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  ObjetoEducacional,
  Rotulo,
  RotuloEntity,
  TipoOe,
  TipoOeEntity,
} from "../../generated/graphql";

import { normalizeStr } from "../../helpers/common";

import {
  BannerSection,
  Breadcrumb,
  BreadcrumbItem,
  Grid,
  Pagination,
  MediaCard,
  Loader,
  HeaderSection,
} from "../../shared/components";

import {
  TIPO_AREA_PAGINA,
  TIPO_PAGINA,
  useBannerApi,
} from "../../hooks/useBannerApi";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import { UserContext } from "../../context/UserContext";

import "./styles.scss";
import { useOE } from "../../hooks/useOe";
import { useUtils } from "../../shared/utils";
import Container from "../../shared/components/Container";
import {
  Banner,
  loadBanner,
} from "../../graphql/collections/queries/banner.query";
import CardObjetoEducacionalGraphQL from "../../shared/components/oe-card-graphql";

interface OeAreaConhecimentoState {
  raw: ObjetoEducacional[];
  resultado: ObjetoEducacional[];
  total: number;
  pagina: number;
  error: any;
  ordem: string;
}

export enum Origem {
  AREA_CONHECIMENTO,
  PROFISSAO,
  TEMA,
}

export interface OEAreaConhecimentoPageParams {
  origem?: Origem;
  titulo?: string;
  profissao?: boolean;
  publicoAlvo?: string;
}

export const OEAreaConhecimentoPage: React.FC = () => {
  const { user } = useContext(UserContext);
  const params = useParams<{
    name: string;
    id: string;
    area: string;
    idarea: string;
  }>();
  const { state: areaInteresse } = useLocation<OEAreaConhecimentoPageParams>();
  const scrollTop = useScrollTop();
  const { formatName } = useUtils();

  const [banners, setBanners] = useState<Banner[]>();

  const {
    getBannerPorAreaConhecimento,
    getPorAreaPagina,
    getBannerPorSubAreaConhecimento,
  } = useBannerApi();
  const { totalBullets, totalItems, carregandoOEs, buscarOes, resultadoOE } =
    useOE();
  const [areaConhecimento, setAreaConhecimento] = useState<{
    id: string;
    name: string;
  }>({
    id: "",
    name: "",
  });

  const [state, setState] = useState<OeAreaConhecimentoState>({
    raw: [],
    resultado: [],
    total: 0,
    pagina: 1,
    error: null,
    ordem: "DataAtualizacaoProducao:desc",
  });

  const carregarBanners = async () => {
    const bannersResult = await loadBanner({
      homePage: false,
      areaDeConhecimentoId: areaConhecimento.id
        ? [Number(areaConhecimento.id)]
        : undefined,
      subAreaDeConhecimentoId:
        areaInteresse?.origem !== Origem.TEMA &&
        areaInteresse?.origem !== Origem.PROFISSAO
          ? [Number(params["id"])]
          : undefined,
    });

    setBanners(bannersResult);
  };

  useEffect(() => {
    carregarBanners();
  }, [areaConhecimento, areaInteresse]);

  const gtm = useGtm();

  useEffect(() => {
    scrollTop();
  }, [resultadoOE]);

  useEffect(() => {
    carregar();
  }, []);

  useEffect(() => {
    if (!params) return;
    const _ = params["area"].replace(/-/g, " ");
    const _id = params["idarea"];
    setAreaConhecimento({
      id: _id,
      name: _,
    });
  }, [params]);

  useEffect(() => {
    if (!params) return;
    const _ = params["area"].replace(/-/g, " ");

    // GTM doc linha 69
    gtm.push(
      EDataLayerEventCategory.PAGE_VIEW,
      EDataLayerEventAction.NAVEGACAO,
      {
        label: `areaInteresse:${_.toUpperCase()}`,
      },
    );
  }, [params]);

  const handlePageChange = (event: any, page: number) => {
    event.preventDefault();
    setState({
      ...state,
      pagina: page,
    });

    carregar(page - 1 === 0 ? 0 : page * 15 - 15);
  };

  const retornaBreadcrumbItem = () => {
    switch (areaInteresse?.origem) {
      case Origem.TEMA:
        return (
          <BreadcrumbItem to="/temas-saude">Temas de saúde</BreadcrumbItem>
        );
      case Origem.PROFISSAO:
        return (
          <>
            <BreadcrumbItem to="/profissoes">Profissões</BreadcrumbItem>
            <BreadcrumbItem to="/profissoes">
              {areaInteresse.publicoAlvo}
            </BreadcrumbItem>
            <BreadcrumbItem to="/profissoes">
              {formatName(
                params.area
                  .split("-")
                  .filter((a) => a !== "undefined")
                  .join(" "),
              )}
            </BreadcrumbItem>
          </>
        );
      case Origem.AREA_CONHECIMENTO:
        return (
          <BreadcrumbItem to="/areas-de-interesse">
            Áreas de interesse
          </BreadcrumbItem>
        );
      default:
        return areaInteresse?.profissao ? (
          <BreadcrumbItem to="/profissoes">Profissões</BreadcrumbItem>
        ) : (
          <BreadcrumbItem to="/areas-de-interesse">
            Áreas de interesse
          </BreadcrumbItem>
        );
    }
  };

  const retornaTitulo = () => {
    if (areaInteresse?.titulo) {
      return areaInteresse.titulo;
    } else {
      return params.name
        .split("-")
        .filter((a) => a !== "")
        .map((a) => a[0]?.toUpperCase() + a?.substring(1))
        .join(" ");
    }
  };

  const carregar = (page: number = 0) => {
    if (!params["id"]) {
      return;
    }

    let where;

    switch (areaInteresse?.origem) {
      case Origem.TEMA:
        where = {
          Tema: {
            id: params["id"],
          },
        };
        break;
      case Origem.PROFISSAO:
        where = {
          SubAreaConhecimento: {
            id: {
              eq: params["id"],
            },
          },
          PublicoAlvo: {
            titulo: {
              eq: areaInteresse.publicoAlvo,
            },
          },
        };
        break;
      case Origem.AREA_CONHECIMENTO:
        where = {
          SubAreaConhecimento: {
            id: {
              eq: params["id"],
            },
          },
        };
        break;
      default:
        where = {
          SubAreaConhecimento: {
            id: {
              eq: params["id"],
            },
          },
        };
        break;
    }

    buscarOes(page, state.ordem, where);
  };

  return (
    <>
      <Loader
        show={!!carregandoOEs}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <HeaderSection
        classe={normalizeStr(areaConhecimento.name)
          .toLowerCase()
          .split(" ")
          .join("-")}
      >
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
          {retornaBreadcrumbItem()}
          <BreadcrumbItem
            to={`/area-de-interesse/${areaConhecimento.name}/${areaConhecimento.id}/${params.name}/${params.id}`}
            active
          >
            {retornaTitulo()}
          </BreadcrumbItem>
        </Breadcrumb>
        <div data-row-media>
          <h3 className="text-white text-base font-semibold leading-none md:text-xxl">
            {retornaTitulo()}
          </h3>
        </div>
      </HeaderSection>
      <div className="oe-search-page">
        {!!resultadoOE && !!resultadoOE.length && (
          <section className="oe-search-result">
            <strong data-total-conteudo>{`${totalItems} ${
              totalItems > 1 ? "conteúdos" : "conteúdo"
            } em ${retornaTitulo()}`}</strong>
            <Pagination
              count={totalBullets}
              page={state.pagina}
              onChange={handlePageChange}
              disabled={carregandoOEs}
            />
            <Grid cols={3} gap={4}>
              {resultadoOE.map((oe, index: number) => (
                <div key={index}>
                  <CardObjetoEducacionalGraphQL objetoEducacional={oe} />
                </div>
              ))}
            </Grid>
            <Pagination
              count={totalBullets}
              page={state.pagina}
              onChange={handlePageChange}
              disabled={carregandoOEs}
            />
          </section>
        )}

        {!resultadoOE ||
          (!resultadoOE.length && (
            <section data-empty>
              <h4>Não existe nenhum resultado!</h4>
            </section>
          ))}

        {(() => {
          let bannersAcimaRodape = banners?.filter(
            (a) =>
              a.tipoPagina === TIPO_PAGINA.SUBAREA_INTERESSE &&
              a.areaPagina === TIPO_AREA_PAGINA.ACIMA_RODAPE &&
              ((!!user?.id && a.ocultarLogado === false) || !user?.id),
          );

          return (
            <>
              {bannersAcimaRodape && bannersAcimaRodape.length ? (
                <div
                  data-banner
                  className="flex w-[full] justify-center bg-gray-100 py-2"
                  data-banner-rodape
                >
                  <Container>
                    <BannerSection items={bannersAcimaRodape} />
                  </Container>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })()}
      </div>
    </>
  );
};
