import React, { ReactEventHandler } from "react";
import * as Icon from "../../../../../assets/svgs";
import { formatTime } from "../../../../../helpers/common";
import PlayerSlider from "./PlayerSlider";

import "./PlayerControls.scss";
import { CirclePause, CirclePlay } from "lucide-react";
import { Slider } from "../../../../../shared/components/ui/slider";
import Container from "../../../../../shared/components/Container";

interface PlayerControlsProps {
  currentTime: number;
  duration: number;
  play: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  seekBackward: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  seekForward: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleVolumeChange: (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  handleSliderChange: (e: ReactEventHandler, value: any) => void;
  isPlaying: boolean;
  isMuted: boolean;
}

const PlayerControls = ({
  currentTime,
  duration,
  play,
  seekBackward,
  seekForward,
  handleSliderChange,
  handleVolumeChange,
  isPlaying,
  isMuted,
  ...props
}: PlayerControlsProps) => {
  return (
    <div className="flex w-full flex-col justify-start gap-4 md:flex-row md:items-center md:gap-14 ">
      <Container className="flex flex-col gap-2 rounded-md bg-[#0A589B]/50 p-3 md:hidden">
        <div className="flex justify-between">
          <div className="flex items-center gap-3">
            <button data-testid="btn-backward" onClick={seekBackward}>
              <Icon.ButtonSeekBackward />
            </button>
            <button data-testid="btn-play" onClick={(e) => play(e)}>
              {(isPlaying && (
                <CirclePause color="white" className="size-7" />
              )) || <CirclePlay color="white" className="size-7" />}
            </button>
            <button data-testid="btn-forward" onClick={seekForward}>
              <Icon.ButtonSeekForward />
            </button>
          </div>

          <button data-testid="btn-muted" onClick={handleVolumeChange}>
            {(isMuted && (
              <Icon.ButtonVolumeMuted color="white" className="size-7" />
            )) || <Icon.ButtonVolume color="white" className="size-7" />}
          </button>
        </div>
        <div className="flex w-full items-center justify-center gap-3 md:min-w-[600px]">
          <span className="text-xs font-bold leading-none text-[#F3F6F9]">
            <time>{formatTime(currentTime)}</time>
          </span>
          <div className=" mb-1 w-full">
            <Slider
              defaultValue={[currentTime || 0]}
              onValueChange={(value) => {
                let event: any;
                handleSliderChange(event, value[0]);
              }}
              max={duration}
              min={0}
              step={1}
              className="mb-1 mt-2"
            />
          </div>
          <span className="text-xs font-bold leading-none text-[#F3F6F9]">
            <time>{formatTime(duration)}</time>
          </span>
        </div>
      </Container>
      <div className="hidden w-full flex-row gap-14 rounded-md md:flex">
        <div className="flex items-center gap-3">
          <button data-testid="btn-backward" onClick={seekBackward}>
            <Icon.ButtonSeekBackward />
          </button>
          <button data-testid="btn-play" onClick={(e) => play(e)}>
            {(isPlaying && (
              <CirclePause color="white" className="size-7" />
            )) || <CirclePlay color="white" className="size-7" />}
          </button>
          <button data-testid="btn-forward" onClick={seekForward}>
            <Icon.ButtonSeekForward />
          </button>
        </div>

        <div className="flex w-full items-center justify-center gap-3 md:min-w-[600px]">
          <span className="text-xs font-bold leading-none text-[#F3F6F9]">
            <time>{formatTime(currentTime)}</time>
          </span>
          <div className=" mb-1 w-full">
            <Slider
              defaultValue={[currentTime || 0]}
              onValueChange={(value) => {
                let event: any;
                handleSliderChange(event, value[0]);
              }}
              max={duration}
              min={0}
              step={1}
              className="mb-1 mt-2"
            />
          </div>
          <span className="text-xs font-bold leading-none text-[#F3F6F9]">
            <time>{formatTime(duration)}</time>
          </span>
        </div>
        <button data-testid="btn-muted" onClick={handleVolumeChange}>
          {(isMuted && (
            <Icon.ButtonVolumeMuted color="white" className="size-7" />
          )) || <Icon.ButtonVolume color="white" className="size-7" />}
        </button>
      </div>
    </div>
  );
};

export default PlayerControls;
