export enum typeObjetoEducacional {
  VIDEO = 6,
  QUESTIONARIO = 5,
  TEXTO = 4,
  IMAGEM = 2,
  PDF = 8,
  AUDIO = 3,
  LIVE = 7,
  FLASHCARD = 10,
}
