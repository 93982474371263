import React from "react";
import { mostrarModalLogin } from "../../../../shared/modals/ModalLogin";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/app";

import "./styles.scss";
import { Button } from "../../../../shared/components/ui/button";

export const ModalAcessoPermitidoLogados: React.FC = () => {
  const history = useHistory();
  const { show } = useSelector((state: RootState) => state.modalReducer);

  if (!show && mostrarModalLogin) {
    mostrarModalLogin(true, () => {
      history.goBack();
    })
    return <></>;
  }

  return (
    <>
    </>
  );
};
