import { useEffect, useState } from "react";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import AcademiaDigital from "./academia-digital";
import Autores from "./autores";
import BannerTopo from "./banner-topo";
import Beneficios from "./beneficios";
import Explore from "./explore";
import FacaParte from "./faca-parte";
import NossosFormatos from "./nossos-formatos";
import PreparaEinstein from "./prepara-einstein";
import React from "react";

async function getContadores() {
  const response = await fetchApi("quantidade/get", 'GET').then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return {
        quantidadeOes: "0",
        quantidadeAutores: "0",
        quantidadeUser: "0",
      };
    }
  });
  return response;
}

export default function NaoAutenticado() {

  const [contadores, setContadores] = useState<any>();

  const loadContadores = async () => {
    setContadores(await getContadores());
  }
  
  useEffect(() => {
    loadContadores();
  }, [])

  return (
    <div className="min-h-screen ">
      <AcademiaDigital qtdConteudos={contadores?.quantidadeOes} />
      <Explore qtdMembros={contadores?.quantidadeAutores + contadores?.quantidadeUser} />
      <NossosFormatos />
      <Autores qtdProfissionais={contadores?.quantidadeAutores} />
      <BannerTopo />
      <PreparaEinstein />
      <Beneficios />
      <FacaParte />
    </div>
  );
}
