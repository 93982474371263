import React from 'react';
import { ObjetoEducacionalEntity } from '../../../../generated/graphql';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

export interface InfoQuizProps {
    isTesteConhecimento: boolean;
    perguntasTotais: number;
    oe: ObjetoEducacionalEntity;
}

export const InfoQuiz: React.FC<InfoQuizProps> = ({ isTesteConhecimento, perguntasTotais, oe }) => {
    return (
        <>
            <span className="text-xs font-medium leading-4 text-[#111316] inline-block !mt-3">
                {isTesteConhecimento
                    ? `${perguntasTotais} perguntas`
                    : oe.attributes?.TipoObjeto?.data.map((tipo, idx) => (
                        <span key={idx}>{tipo.attributes?.titulo}</span>
                    ))}

                {` · ${oe.attributes?.TempoMedioDuracao
                    } minutos · ${format(
                        new Date(oe?.attributes?.DataProducao + "T00:00:00"),
                        "dd MMM yyyy",
                        {
                            locale: ptBR,
                        }
                    )?.toLowerCase()}`}
            </span>
        </>
    );
}