import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as Icon from "../../../assets/svgs";

import {
  ObjetoEducacionalEntity,
  useCurtirMutation,
  UsersPermissionsUserEntity,
} from "../../../generated/graphql";

import ThumbsUpIcon from "../../../assets/svgs/thumbs-up-icon.svg";
import LikedIcon from "../../../assets/svgs/like-preenchido.svg";

import ResetUserContext from "../../../hooks/ResetUserContext";

import { CURTIR_TYPE, HEADER_ACTION_TYPE } from "../../constants/action-types";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

import "./styles.scss";
import { useUtils } from "../../utils";

interface ICurtirProps {
  user?: UsersPermissionsUserEntity | null;
  oe: ObjetoEducacionalEntity;
  curtido?: boolean;
}

export const Curtir: React.FC<ICurtirProps> = (props) => {
  const dispatch = useDispatch();
  const { resetUserContext } = ResetUserContext();
  const gtm = useGtm();

  const { formatTitle } = useUtils();

  const [curtirMutation] = useCurtirMutation();

  const [curtido, setCurtido] = useState(false);

  let currentIconStyle: any;

  useEffect(() => {
    if (!props.user) {
      return;
    }

    if (!props.oe || !props.oe.id) {
      return;
    }

    if (
      !!props.user &&
      props.user?.attributes?.OesCurtidas?.data.some(
        (oe) => oe.id === props.oe.id,
      )
    ) {
      setCurtido(true);
    }
  }, [props.user, props.oe]);

  const iconStyles: any = {
    like: {
      iconTrue: LikedIcon,
      iconFalse: ThumbsUpIcon,
    },
  };

  const setIconStyle = (style: string) => {
    const iconStyle = style || "like";
    currentIconStyle = iconStyles[iconStyle];
  };

  setIconStyle("like");

  const toggleCurtir = (e: React.MouseEvent) => {
    if (!props.user) {
      dispatch({
        type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
        payload: true,
      });
      return;
    }

    setCurtido(!curtido);

    if (!curtido === true) {
      // GTM doc linha 93
      gtm.push(
        EDataLayerEventCategory.ADE_OE_FAVORITAR,
        EDataLayerEventAction.CLIQUE,
        {
          label: formatTitle(props.oe?.attributes?.Titulo!),
          idOe: props.oe.id,
        },
      );
    }

    let _oesCurtidas = (
      props.user?.attributes?.OesCurtidas?.data as ObjetoEducacionalEntity[]
    ).map((oe) => oe?.id!);
    _oesCurtidas = curtido
      ? _oesCurtidas.filter((id) => id !== props.oe.id!)
      : [..._oesCurtidas, props.oe.id!];

    curtirMutation({
      variables: {
        id: props.user.id!,
        input: {
          OesCurtidas: _oesCurtidas,
        },
      },
    }).then(() => {
      if (!curtido === true) {
        dispatch({
          type: CURTIR_TYPE.TOGGLE_CURTIR,
          payload: {
            closeModalFeedback: false,
            toggleFeedback: !curtido,
          },
        });

        resetUserContext();
      }
    });
  };

  return (
    <div data-curtir className="like-container">
      {curtido ? (
        <Icon.unLike onClick={toggleCurtir} />
      ) : (
        <Icon.Like onClick={toggleCurtir} />
      )}
    </div>
  );
};
