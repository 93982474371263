import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ObjetoEducacionalEntity } from "../../../../generated/graphql";
import CompleteTudoDeclaracao from "../../../../assets/images/complete-tudo-declaracao.png";

import "./styles.scss";

import { ModalService } from "../../../../shared/components/Modal";
import { Button } from "../../../../shared/components/ui/button";


export const useModalNaoConcluido = () => {
  const history = useHistory();

  const [url, setUrl] = useState("");

  const handleFechar = () => {
    ModalService.hide();
  };

  const _renderizar = () => {
    return (
      <div className="bg-white z-50 flex items-center justify-center rounded-3xl py-9 ">
        <div className="flex flex-col justify-center items-center px-9 md:px-12 py-8  md:py-10">
          <img
            data-testid="img-prepara"
            src={CompleteTudoDeclaracao}
            alt="Complete tudo para receber sua declaração de participação"
            className="h-[109.01px] w-[120px] md:h-[145.35px] md:w-[160px] mb-6"
          />
          <span className="text-center font-bold text-lg md:text-base text-[#004F92] leading-26 w-[250px] md:w-[350px] md:leading-22 mt-6">
            Complete tudo para receber sua declaração de participação!
          </span>

          <span className="text-center font-normal text-base md:text-sm text-[#313335] leading-22 w-[250px] md:w-[350px] md:leading-5 mt-3">
            Você está quase lá! Conclua todos os conteúdos e receba sua declaração de participação.
          </span>

          <div data-row data-flex-row-arround className="mt-6">
            <Button
              type="button"
              data-testid="btn-ver-agora-nao-concluido"
              className="!text-base"
              variant="primary"
              onClick={() => {
                ModalService.hide();
              }}
            >
              Continuar assistindo
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const mostrar = () => {
    ModalService.show({
      content: _renderizar(),
      closeOutsideModal: true,
      notFullScreenMobile: true
    });
  };

  return { mostrar };
};
