import { ModalService } from "../shared/components/Modal";
import { ModalPlayListDeleteItem } from "../shared/components/ModalPlaylistDeleteItem";

export const useModalDeleteItem = () => {
  const mostrar = ({
    idOe,
    idPlaylist,
    OeQuantidade,
  }: {
    idPlaylist: number;
    idOe: number;
    OeQuantidade: number;
  }) => {
    ModalService.show({
      content: (
        <ModalPlayListDeleteItem
          idPlaylist={idPlaylist}
          idOe={idOe}
          OeQuantidade={OeQuantidade}
        />
      ),
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
    });
  };

  return { mostrar };
};
