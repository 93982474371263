import React, { useState, useEffect } from "react";
import "./styles.scss";
import { ObjetoEducacional } from "../../../models/ObjetoEducacional";
import { DestaqueSemanaModel } from "../../../models/DestaqueSemana";
interface SmartLinkNovaHomeProps {
  oe: ObjetoEducacional | DestaqueSemanaModel;
  children?: any;
  onShowCertificate: () => void;
  isListCertificados?: boolean;
}
export const SmartLinkNovaHome: React.FC<SmartLinkNovaHomeProps> = (props) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    const oeType = props.oe.tipoObjetoId;
    let _url = formatarUrl(oeType, props.oe.id.toString()!);
    setUrl(_url);
  }, [props.oe]);

  const formatarUrl = (oeType: number, oeId: string, videoId?: string) => {
    if (props.isListCertificados) {
      return "";
    }
    switch (oeType) {
      case 3:
        return `/oe/${oeId}/podcast/`;
      case 2:
        return `/oe/${oeId}/imagem/preview`;
      case 5:
        return `/oe/${oeId}/quiz`;
      case 4:
        return `/oe/${oeId}/leitura`;
      case 7:
        return `/oe/${oeId}/live${!!videoId ? `/${videoId}` : ""}`;
      case 6:
        return `/oe/${oeId}/video${!!videoId ? `/${videoId}` : ""}`;
      case 8:
        return `/oe/${oeId}/pdf${!!videoId ? `/${videoId}` : ""}`;
      default:
        return "";
    }
  };
  return props.isListCertificados ? (
    <span
      style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
      onClick={() => {
        if (props.isListCertificados) {
          props.onShowCertificate();
        }
      }}
    >
      {props.children}
    </span>
  ) : (
    <a href={url} style={{ textDecoration: "none", color: "inherit" }}>
      {props.children}
    </a>
  );
};
