import React from "react";
import { Dialog, DialogContent } from "./ui/dialog";

export default function DialogPreview({
  children,
  setOpen,
  className
}: {
  children: React.ReactNode;
  setOpen: (open: boolean) => void;
  className?: string | undefined;
}) {
  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          setOpen(false);
        }
      }}
    >
      <DialogContent className={`m-0 h-auto max-h-[80vh] w-full max-w-[90vw]  overflow-y-auto border-0 p-0 md:max-h-[90vh] md:min-w-[1056px] md:max-w-[1056px] ${className}`}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
