export const useLocalStorage = () => {
  const getById = <T,>(id: string) => {
    return new Promise<T>((resolve) => {
      const value = localStorage.getItem(id);
      if (!value) {
        resolve("" as any);
        return;
      }

      const valueParsed = JSON.parse(value);

      resolve(valueParsed);
    });
  };

  const setValue = (id: string, value: any) => {
    if (!value) {
      throw new Error("Não é possível guardar dados inválidos.");
    }

    const valueStringify = JSON.stringify(value);
    localStorage.setItem(id, valueStringify);
  };

  const remove = (key: string) => {
    localStorage.removeItem(key);
  }

  return {
    getById,
    setValue,
    remove
  };
};
