import React from "react";
import {
  formatarNomeCategoria,
  getTagCategoriaPorTipo,
} from "../../../shared/services/categoria.sevice";

import "./styles.scss";

export interface RotuloCategoriaProps {
  data: string;
}

export const RotuloCategoria: React.FC<RotuloCategoriaProps> = (props) => {
  const getClassePorTipo = (tipo: string) => {
    const rotulo = getTagCategoriaPorTipo(tipo);
    if (!rotulo) {
      return "";
    }

    const className = `tag tag-${rotulo}`;
    return className;
  };

  return (
    <div className={getClassePorTipo(props.data) || "tag"}>
      {formatarNomeCategoria(props.data)}
    </div>
  );
};
