import React from "react";
import { useHistory } from "react-router-dom";
import {
  AreaConhecimento,
  AreaDeConhecimento,
} from "../../../../generated/graphql";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import { twMerge } from "tailwind-merge";

interface KnowlegdeAreaProps {
  areas: AreaConhecimento[];
  columns?: number;
  perfilUser?: boolean;
}

export const AreasDeInteresse: React.FC<KnowlegdeAreaProps> = (props) => {
  const history = useHistory();
  const gtm = useGtm();

  const handleNavigate = (item: any) => {
    history.push(
      `/area-de-interesse/${item.titulo
        .toString()
        .toLowerCase()
        .trim()
        .replace(/ /g, "-")}/${item.id}`,
      {
        id: item.id,
        titulo: item.titulo,
      },
    );
  };

  return (
    <div
      className={twMerge(
        !props.perfilUser &&
          "grid grid-cols-2 items-center gap-4 self-center md:grid-cols-6",
        props.perfilUser &&
          "grid grid-cols-2 items-center gap-4 self-center md:grid-flow-col-dense",
      )}
    >
      {props.areas.map(
        (item: any, i: number) =>
          item.icone && (
            <button
              key={i}
              className="border-1 bg-white flex h-full min-h-[9.625rem] w-auto min-w-[8.625rem]
              cursor-pointer
              flex-col items-center justify-center rounded-lg border-slate-100 py-1 text-center shadow-md
              transition-all duration-200 hover:!bg-[#F5F8FF] hover:!border-[#004F92] focus:!border-[#004F92] focus:!bg-[#F5F8FF] md:min-h-[10rem]"
              onClick={() => {
                handleNavigate(item);
                // GTM doc linha 65
                gtm.push(
                  EDataLayerEventCategory.ADE_AREAS_INTERESSE,
                  EDataLayerEventAction.CLIQUE,
                  {
                    label: item.titulo,
                  },
                );
              }}
              aria-label={`Navegar para ${item.titulo}`}
            >
              <img
                height="3.93rem"
                src={item.icone.url}
                alt=""
                aria-hidden="true"
                loading="lazy"
                style={{ height: "3.93rem" }}
              />
              <span className="mt-2 text-sm font-medium leading-normal text-[#000000]">
                {item.titulo}
              </span>
            </button>
          ),
      )}
    </div>
  );
};
