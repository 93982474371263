import React from "react";
import { Link, useHistory } from "react-router-dom";

import {
  ObjetoEducacionalEntity,
  ObjetoEducacionalHome,
} from "../../../generated/graphql";

import * as Icon from "../../../assets/svgs";

import "./styles.scss";
import CardSwiperHome from "../../../pages/_HomePage/components/CardSwiperHome";
import CardSwiper from "../CardSwiper";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import {
  Carousel,
  CarouselBullets,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import CardObjetoEducacionalGraphQL from "../oe-card-graphql";
import { ArrowRight } from "lucide-react";
interface CardSectionProps {
  title: string;
  icon?: string | any;
  linkUrl?: string;
  onClickVerMais?: () => void;
  items: ObjetoEducacionalEntity[];
  certificados?: boolean;
  showAllSee: boolean;
  notLimit?: boolean;
  limit?: number;
  loading?: boolean;
  patricinadores?: boolean;
  hasTitle?: boolean;
  isListCertificados?: boolean;
}

export const CardSection: React.FC<CardSectionProps> = (props) => {
  const history = useHistory();

  return (
    <>
      <div>
        {!props.hasTitle ? (
          <div
            className={`flex w-full flex-row ${
              props?.patricinadores ? "justify-end" : "justify-between"
            }  text-xxl font-semibold normal-case leading-none text-blue-200`}
          >
            {!!!props?.patricinadores && (
              <div className="flex items-center justify-between">
                {props.icon ? (
                  <img
                    src={props.icon}
                    alt={props.title}
                    className="mb-3 mr-3 mt-8 md:mt-14"
                  />
                ) : null}
                {props.linkUrl && (
                  <Link
                    to={`${props.linkUrl}`}
                    className="inline-block self-center leading-none hover:text-blue-200"
                  >
                    <h3 className="mb-3 mt-8 inline-block self-center whitespace-pre-line text-base font-semibold leading-none hover:text-blue-200 md:mt-14 md:text-xxl">
                      {props.title}
                    </h3>
                  </Link>
                )}
                {!props.linkUrl && (
                  <h3 className="mb-3 mt-8 inline-block self-center  whitespace-pre-line text-base font-semibold leading-none hover:text-blue-200 md:mt-14 md:text-xxl ">
                    {props.title}
                  </h3>
                )}
              </div>
            )}

            <div
              className={twMerge(
                "flex h-auto items-end justify-end text-[10px] font-bold leading-none text-[#575D63] md:text-ssm",
                true && "mb-3 mt-8 md:mt-4",
              )}
            >
              {(props.linkUrl || props.onClickVerMais) &&
                props.showAllSee &&
                props.items &&
                props.items.length > 3 && (
                  <span
                    onClick={() =>
                      props.onClickVerMais
                        ? props.onClickVerMais()
                        : history.push(props.linkUrl!)
                    }
                    className={classNames(
                      "flex cursor-pointer items-center justify-center gap-2 !font-opensans text-sm !font-semibold !text-[#626466] hover:text-slate-700",
                      {
                        "mb-3": !!props?.patricinadores,
                      },
                    )}
                  >
                    VER TUDO <ArrowRight size={20} />
                  </span>
                )}
            </div>
          </div>
        ) : null}
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {props.items.map((_: any, index: number) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <div className="">
                  <CardObjetoEducacionalGraphQL
                    objetoEducacional={_}
                    isListCertificados={props.isListCertificados}
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-conteudos-interesse"
            height="h-[0px] md:h-[227.66px]"
            className=" hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-conteudos-interesse"
            height="h-[0px] md:h-[227.66px]"
            className=" hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto " />
        </Carousel>
      </div>
    </>
  );
};
