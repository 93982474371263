import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { TOKEN_SECRECT } from "../constants/auth-types";
import {
  getTokenFromCookie,
  removeLocalStorageValue,
  removeTokenFromCookie,
} from "./localStorage.service";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

export const authLink = setContext((_, { headers }) => {
  const token = getTokenFromCookie();

  const options: any = {
    headers: { ...headers },
  };

  if (!!token) {
    options.headers["authorization"] = `Bearer ${token}`;
  }

  return options;
});

async function reportError({
  error,
  data,
}: {
  error: Error;
  data: Record<string, any>;
}) {
  try {
    const URL = `${process.env.REACT_APP_GRAPHQL_URL?.replace(
      "/graphql",
      "/api",
    )}/send-error`;

    await fetch(URL, {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json",
      }),
      body: btoa(JSON.stringify({ error, ...data })),
    });
  } catch (error) {
    console.error("Error while reporting error", error);
  }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.includes("Invalid token")) {
        // reportError({
        //   error: new Error("Invalid token"),
        //   data: { message },
        // });
      }
    });
  } else if (networkError) {
    if (
      (networkError as any).statusCode &&
      (networkError as any).statusCode === 401
    ) {
      removeLocalStorageValue(TOKEN_SECRECT);
      removeTokenFromCookie();
      // reportError({
      //   error: new Error("Forbidden"),
      //   data: { networkError },
      // });
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    resultCaching: true,
  }),
  headers: !!getTokenFromCookie()
    ? {
        authorization: getTokenFromCookie()!,
      }
    : ({} as Record<string, string>),
});

export default client;
