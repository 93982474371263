import React from "react";
import { MediaImagemVideoFeedBackQuiz } from "./MediaImagemVideoFeedBack";
import { PerguntaModel } from "../../../../models/PerguntaModel";
import { QuizModel } from "../../../../models/QuizModel";

export interface FeedbackDesktopQuizProps {
  showingQuestionFeedback: boolean;
  ultimaPerguntaQuiz: boolean;
  quizAtivo?: QuizModel;
  respostaAtiva: any;
  perguntaAtiva?: PerguntaModel;
  isMobile: boolean;
  nextRef: any;
  previousRef: any;
  onClickMobile: () => void;
}

export const FeedbackDesktopQuiz: React.FC<FeedbackDesktopQuizProps> = ({
  showingQuestionFeedback,
  ultimaPerguntaQuiz,
  quizAtivo,
  respostaAtiva,
  perguntaAtiva,
  isMobile,
  nextRef,
  previousRef,
  onClickMobile,
}) => {
  return (
    <>
      <small
        data-feedback
        data-line-height="1-2"
        style={{
          display: showingQuestionFeedback ? "block" : "none",
        }}
      >
        {perguntaAtiva?.feedbackQuiz || ultimaPerguntaQuiz ? (
          <h4 className="!mt-5 font-semibold leading-5 text-[#004F92]">
            Feedback:
          </h4>
        ) : (
          <></>
        )}
        {(() => {
          if (ultimaPerguntaQuiz) {
            return (
              <div
                className="mb-3 mt-3 text-sm font-normal leading-normal text-[#111316]"
                dangerouslySetInnerHTML={{
                  __html:
                    quizAtivo?.feedback.replace(
                      "<img",
                      '<img className="object-scale-down object-top mt-4" ',
                    ) || "",
                }}
              ></div>
            );
          }

          return (
            <div
              className="mb-3 mt-3 text-sm font-normal leading-normal text-[#111316]"
              dangerouslySetInnerHTML={{
                __html:
                  perguntaAtiva?.feedbackQuiz?.replace(
                    "<img",
                    '<img className="object-scale-down object-top mt-4" ',
                  ) || "",
              }}
            ></div>
          );
        })()}
        <MediaImagemVideoFeedBackQuiz
          className="mt-2 rounded-xl"
          isMobile={isMobile}
          nextRef={nextRef}
          previousRef={previousRef}
          quizAtivo={quizAtivo}
          onClickMobile={() => onClickMobile()}
        />
      </small>
    </>
  );
};
