import { ModalService } from "../shared/components/Modal";
import ModalPreparaEinstein from "../shared/components/ModalPreparaEinstein";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "./useGtm";

export const useModalPreparaEinstein = () => {
  const gtm = useGtm();
  const mostrar = () => {
    ModalService.show({
      content: <ModalPreparaEinstein />,
      showCloseButton: true,
      showComponentInFullScreen: false,
      notFullScreenMobile: true,
      closeOutsideModal: true,
      onClose: async () => {
        const data = await localStorage.getItem("PREPARA_EINSTEIN");
        localStorage.setItem(
          "PREPARA_EINSTEIN",
          JSON.stringify({
            ...JSON.parse(data || "{}"),
            data_acesso_modal: new Date().toLocaleDateString(),
            data_acesso_banner: new Date().toLocaleDateString(),
          }),
        );

        gtm.push(
          EDataLayerEventCategory.ADE_PREPARA_EINSTEIN_QUERO_COMECAR_INICIO,
          EDataLayerEventAction.CLIQUE,
          {
            label: "ir_para_inicio_prepara_einstein_inicio",
          },
        );
      },
    });
  };

  return { mostrar };
};
