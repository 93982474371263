import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import SearchWhite from "../../../assets/svgs/search-white.svg";

import "./styles.scss";
import { Input } from "../ui/input";
import { Search } from "lucide-react";

export interface InputSearchProps {
  placeHolder: string;
  handleChange(event: any): void;
  handleKeyDown(event: any): void;
  handleClick(value: any): void;
  suggestions?: string[];
}

export interface InputSearchRef {
  clearInput: () => void;
}

export const InputSearch = forwardRef<InputSearchRef, InputSearchProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const suggestionsRef = useRef<HTMLUListElement | null>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

    // Função para fechar as sugestões quando clicar fora do componente.
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    const handleSuggestionClick = (suggestion: string) => {
      // Atualize o valor do campo de pesquisa com a sugestão clicada.
      if (inputRef.current) {
        inputRef.current.value = suggestion;
      }

      // Esconda as sugestões após a seleção.
      setShowSuggestions(false);

      // Execute a função de clique passada como prop se necessário.
      props.handleClick(suggestion);
    };

    useImperativeHandle(ref, () => ({
      clearInput,
    }));

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.handleChange(event); // Chame a função handleChange passada como prop se necessário.

      setShowSuggestions(true);
    };

    const handleKeyDown = (event: any) => {
      if (props.suggestions) {
        if (event.key === "ArrowDown") {
          // Navegar para baixo na lista de sugestões.
          event.preventDefault();
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex < props.suggestions!.length - 1
              ? prevIndex + 1
              : prevIndex,
          );
        } else if (event.key === "ArrowUp") {
          // Navegar para cima na lista de sugestões.
          event.preventDefault();
          setSelectedSuggestionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex,
          );
        } else if (event.key === "Enter" && selectedSuggestionIndex !== -1) {
          // Se a tecla Enter for pressionada e uma sugestão estiver selecionada, preencha o campo com a sugestão.
          event.preventDefault();
          const selectedSuggestion =
            props.suggestions![selectedSuggestionIndex];
          inputRef.current!.value = selectedSuggestion;
          setShowSuggestions(false);
          setSelectedSuggestionIndex(-1);
        } else {
          setSelectedSuggestionIndex(-1);
        }
      }
      props.handleKeyDown(event);
    };

    // Controlar o scroll manualmente quando a sugestão selecionada mudar.
    useEffect(() => {
      if (suggestionsRef.current && selectedSuggestionIndex !== -1) {
        const selectedSuggestionElement =
          suggestionsRef.current.children[selectedSuggestionIndex];
        if (selectedSuggestionElement) {
          selectedSuggestionElement.scrollIntoView({
            behavior: "auto",
            block: "nearest",
          });
        }
      }
    }, [selectedSuggestionIndex]);

    const clearInput = () => {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    };

    // Adicione um evento de clique global quando o componente montar.
    useEffect(() => {
      document.addEventListener("click", handleClickOutside);

      // Remova o evento ao desmontar o componente para evitar vazamento de memória.
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

    return (
      <div data-search-area>
        <div onClick={() => props.handleClick(inputRef?.current?.value)}>
          <img src={SearchWhite} alt="search" className="icon-search" />
        </div>
        <Input
          label="Pesquisar"
          className="flex w-full text-gray-500"
          icon={<Search className="right-0 " color="#707070" />}
          type="text"
          name="search"
          onChangeCapture={handleInputChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          placeholder={props.placeHolder}
          ref={inputRef}
        />
        {showSuggestions && (
          <ul
            ref={suggestionsRef}
            className="bg-white max-w-11/12 font-mono absolute z-20 max-h-72 overflow-y-auto rounded-b-xl border text-sm sm:left-0 lg:left-7"
          >
            {props?.suggestions?.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={`p-2 font-sans text-xs hover:bg-gray-100 md:text-sm ${
                  index === selectedSuggestionIndex ? "bg-gray-100" : ""
                }`}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  },
);
