import fetchApi from "../../services/rest-dotnet.service";
import { useContext } from "react";
import { AlertContext } from "../../../context/UseAlert";
import { Button } from "../ui/button";
import * as Icon from "../../../assets/svgs";
import { ModalService } from "../Modal";
import { useHistory } from "react-router";

export function ModalPlayListDeleteItem({
  idPlaylist,
  idOe,
  OeQuantidade,
}: {
  idPlaylist: number;
  idOe: number;
  OeQuantidade: number;
}) {
  const { showMessage } = useContext(AlertContext);
  const history = useHistory();

  async function deletePlaylist() {
    fetchApi(`playlists/remove-playlist?playlistId=${idPlaylist}`, "POST")
      .then(async () => {
        showMessage("Playlist removida com sucesso!", "SUCESS");
        ModalService.hide();
        history.push("/playlists");
      })
      .catch(() => {
        showMessage("Erro ao remover a playlist.", "ERRO");
      });
  }

  async function deleteItemPlaylist() {
    fetchApi(
      `playlists/remove-item-playlist?playlistId=${idPlaylist}&oeId=${idOe}`,
      "POST",
    )
      .then(async (response) => {
        if (response.ok) {
          showMessage("Item removido da playlist com sucesso.", "SUCESS");
          if (OeQuantidade > 1) {
            ModalService.hide();
            window.location.reload();
          }
        } else {
          showMessage(await response.json(), "ERRO");
        }
      })
      .catch(() => {
        showMessage("Erro ao remover item da playlist.", "ERRO");
      });

    if (OeQuantidade === 1) {
      deletePlaylist();
    }
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-6 px-6 py-7 md:w-[450px]">
      <Icon.modalConfirm className="w-[160px]" />
      <div className="flex flex-col gap-3 text-center">
        <h2 className="text-base font-bold normal-case leading-22 text-[#004F92]">
          {OeQuantidade === 1
            ? "Você tem certeza que deseja apagar o último item da playlist?"
            : "Você tem certeza que deseja apagar o item esta playlist?"}
        </h2>
        <p className=" text-center text-sm font-normal leading-5 text-black-900">
          {OeQuantidade === 1
            ? "Ao apagar o último item da playlist, a playlist será removida permanentemente."
            : "Ao apagar este item, ele será removido permanentemente da playlist."}
        </p>
      </div>
      <div className="flex gap-3">
        <Button
          data-testid="btn-cancel-delete-playlist"
          variant="outline"
          onClick={(ev) => {
            ev.stopPropagation();
            ModalService.hide();
          }}
        >
          Cancelar
        </Button>
        <Button
          data-testid="btn-delete-playlist"
          onClick={() => deleteItemPlaylist()}
        >
          Apagar item
        </Button>
      </div>
    </div>
  );
}
