import Container from "../../../shared/components/Container";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselBullets,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useEffect, useState } from "react";
import VerTudoIcon from "../../../assets/svgs/icons/ver-tudo.svg";
import { useHistory } from "react-router";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../shared/components/ui/card";
import Rotulo from "../../../shared/components/rotulos";
import { Badge } from "../../../shared/components/ui/badge";
import { useModalPlaylistOnBoard } from "../../../hooks/useModalPlaylistOnboard";

async function getPlaylistsHome() {
  const response = await fetchApi("playlists/playlists-home", "GET").then(
    (res) => {
      if (res.ok) {
        return res.json();
      } else {
        return [];
      }
    },
  );

  return response;
}

export default function PlaylistsHome() {
  const [assistidos, setAssistidos] = useState<any[]>();
  const modalOnBoard = useModalPlaylistOnBoard();
  const history = useHistory();

  const loadAssistidos = async () => {
    setAssistidos(await getPlaylistsHome());
  };

  useEffect(() => {
    loadAssistidos();
    if (!localStorage.getItem("onBoardPlaylists")) modalOnBoard.mostrar();
  }, []);

  return (
    <Container className="flex flex-col gap-6 py-9 md:py-10">
      <div className="flex items-center  justify-between">
        <div className="">
          <div className="">
            <div className="flex flex-col gap-3">
              <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
                Playlists{" "}
              </h2>
              <span className="hidden text-sm font-normal text-[#111316] md:block md:text-base">
                Explore playlists sugeridas, crie suas próprias playlists e
                organize seus estudos de forma eficiente.{" "}
              </span>
            </div>
          </div>
        </div>
        <span
          className="flex cursor-pointer items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
          onClick={() => history.push("/playlists")}
        >
          VER TUDO
          <img
            src={VerTudoIcon}
            alt=""
            aria-hidden="true"
            width={24}
            height={24}
            className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
          />
        </span>
      </div>
      <span className="text-sm font-normal text-[#111316] md:!hidden md:text-base">
        Explore playlists sugeridas, crie suas próprias playlists e organize
        seus estudos de forma eficiente.{" "}
      </span>
      {assistidos && assistidos.length > 0 ? (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {assistidos?.map((playlist: any, index: number) => (
              <CarouselItem
                key={playlist.id}
                className="rounded-xl md:basis-1/2 lg:basis-1/3"
              >
                <Card
                  className="relative cursor-pointer shadow-none"
                  onClick={() => {
                    history.push(`/playlists/${playlist.id}/detalhe`);
                  }}
                  role="link"
                  aria-label={`Playlist ${playlist.titulo}, ${playlist.qtdVideos} videos, ${playlist.visualizado ? 'Visualizado' : ''} `}
                >
                  <CardHeader className="relative z-10 h-[227px] grow rounded-xl border p-6" aria-hidden="true">
                    <img
                      alt="imagem capa objeto educacional"
                      src={playlist.thumbnail}
                      width={300}
                      height={150}
                      className={
                        "absolute left-0 top-0 h-full w-full rounded-xl object-cover object-top "
                      }
                    />
                    <div className="absolute bottom-2 left-1/2 -z-10 flex h-full w-[calc(100%-6%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />
                    <div className="absolute bottom-4 left-1/2 -z-20 flex h-full w-[calc(100%-12%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />

                    <div
                      className={`absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6 `}
                    >
                      <Rotulo rotuloName={"Playlist"} id={1} />
                    </div>

                    <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
                      <span className="text-xxs font-bold text-white-200 md:text-xs">
                        {`${playlist.qtdVideos} Vídeos`}
                      </span>
                    </Badge>

                    <div className="absolute  left-1/2 top-1/2 flex h-[42px] w-[125px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-lg bg-black-900/95">
                      <span className="text-xs font-semibold text-white-200">
                        Reproduzir tudo
                      </span>
                    </div>

                    {playlist.visualizado && (
                      <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
                        Visualizado
                      </span>
                    )}
                  </CardHeader>
                  <CardContent className="mt-3 flex flex-col gap-[6px] p-0" aria-hidden="true">
                    <span className="text-base font-semibold leading-5 text-[#111316]">
                      {playlist.titulo.length > 30
                        ? `${playlist.titulo.substring(0, 30)}...`
                        : playlist.titulo}
                    </span>
                    <span className="text-xxs font-semibold leading-4 text-[#626466]">
                      Confira a playlist completa
                    </span>
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious
            data-testid="btn-previous-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselNext
            data-testid="btn-next-continue-assistindo"
            height="h-[0px] md:h-[227.66px]"
            className="hidden md:block"
          />
          <CarouselBullets className="absolute left-0 right-0 mx-auto" />
        </Carousel>
      ) : (
        <Carousel
          opts={{
            breakpoints: {
              "(min-width: 768px)": { slidesToScroll: 3 },
            },
          }}
          className="w-full"
        >
          <CarouselContent className="">
            {Array.from({ length: 3 }).map((_, index) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                <div className="min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                  <div className="flex h-[187.5px] w-full animate-[shimmer_2s_infinite] bg-gradient-to-r  from-transparent via-gray-400/20 to-transparent md:h-[227.66px]" />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      )}
    </Container>
  );
}
