import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Loader, PaginationGrid } from "../../../../shared/components";

import useOeHistory from "../../../../hooks/useOeHistory";

import "./styles.scss";

const HistoryContent: React.FC = () => {
  const { data: history, loading } = useOeHistory();

  const [load, setLoad] = useState(false);

  const getTitle = () => {
    const path = window.location.pathname;

    switch (true) {
      case path.indexOf("/historico/videos") !== -1:
        return "Vídeos";

      case path.indexOf("/historico/audios") !== -1:
        return "Áudios";

      case path.indexOf("/historico/quizzes-e-desafios") !== -1:
        return "Quizzes e Desafios";

      case path.indexOf("/historico/textos-e-artigos") !== -1:
        return "Textos e Artigos";

      default:
        return "";
    }
  };

  useEffect(() => {
    if (loading) {
      setLoad(true);
    } else {
      setTimeout(() => {
        setLoad(false);
      }, 600);
    }
  }, [loading])

  return (
    <>
      <Loader
        show={load === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      
      <main data-history-content>
        {history && !!history.length && (
          <PaginationGrid oes={history} title={getTitle()} />
        )}
        {(!history || !history.length) && (
          <section data-no-content>
            <div>
              <h3>{getTitle()}</h3>
              <Link data-link-primary to="/meu-perfil">
                voltar
              </Link>
            </div>
            <strong>Você ainda não tem conteúdo</strong>
          </section>
        )}
      </main>
    </>
  );
};

export default HistoryContent;
