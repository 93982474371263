import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import FieldOk from "../../../assets/svgs/field-ok.svg";

import {
  togglePreRegisterUserAction,
  toggleLoginAction,
} from "../../../redux/header/header.action";

import {
  PreRegisterUserProps,
  PreRegisterUserState,
} from "../../../shared/interfaces/pre-register-user.interface";

import { useConviteCadastro } from "../../../hooks/useConviteCadastro";

import "./styles.scss";
import { mostrarModalLogin } from "../../modals/ModalLogin";

const ModalPreRegisterUserHOC: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { persistirCache } = useConviteCadastro();
  const headerReducer = useSelector((state: any) => state.headerReducer);
  const [props, setProps] = useState<PreRegisterUserProps>({
    onClose: () => {
      const action = togglePreRegisterUserAction(false);
      dispatch(action);
    },

    onLogin: () => {
      const action = toggleLoginAction(true);
      dispatch(action);
    },

    redirect: () => {
      history.push("/novo-usuario");
      props.onClose();
    },
    persistirCache,
  });

  useEffect(() => {
    setProps({
      ...props,
      togglePreRegisterUser: headerReducer.togglePreRegisterUser,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerReducer]);

  return <ModalPreRegisterUserComponent {...props} />;
};

class ModalPreRegisterUserComponent extends Component<
  PreRegisterUserProps,
  PreRegisterUserState
> {
  render() {
    return (
      <>
        <div
          className={
            this.props.togglePreRegisterUser
              ? "overlay fade-in"
              : "overlay fade-out"
          }
        ></div>
        <div
          className={
            this.props.togglePreRegisterUser
              ? "modal-pre-register-user shadow__bottom show fade-in"
              : "modal-pre-register-user shadow__bottom hide fade-out has-fade"
          }
        >
          <div data-buttons className="flex-jc-c flex">
            <span
              data-testid="btn-entrar"
              className="button button-primary"
              onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                ev.stopPropagation();
                this.props.onClose();
                mostrarModalLogin();
              }}
            >
              Entrar
            </span>

            <span className="wrapper-button-secondary-blue">
              <button
                data-testid="btn-continuar-sem-login"
                type="button"
                className="button button-secondary-blue"
                onClick={() => {
                  this.props.persistirCache();
                  this.props.onClose();
                }}
              >
                Continuar sem login*
              </button>
            </span>
          </div>
          <div data-width="100" className="flex-jc-c flex">
            <div
              data-testid="btn-nao-possui-conta"
              className="link-primary link-redirect-register"
              onClick={(ev) => {
                ev.stopPropagation();
                this.props.redirect();
              }}
            >
              Não possui conta? Criar
            </div>
          </div>

          <div className="modal-pre-register-user__title flex-jc-c flex">
            Seja um membro da Academia Digital Einstein com vantagens
            exclusivas:
          </div>
          <div className="modal-pre-register-user__description flex-jc-c flex">
            <ul>
              <li>
                <img src={FieldOk} alt="Ok" />
                Conteúdos gratuitos
              </li>
              <li>
                <img src={FieldOk} alt="Ok" />
                Área de favoritos
              </li>
              <li>
                <img src={FieldOk} alt="Ok" />
                Histórico disponível para rever conteúdos sempre que quiser
              </li>
              <li>
                <img src={FieldOk} alt="Ok" />
                Declarações de Participação para conteúdos concluídos.
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    togglePreRegisterUser: state.headerReducer.togglePreRegisterUser,
  };
};

export default connect(mapStateToProps)(ModalPreRegisterUserHOC);
