import React from "react";
import { ObjetoEducacionalEntity } from "../../../../generated/graphql";
import { Favoritar, RotuloCategoria } from "../../../../shared/components";

export interface RotuloDesktopQuizProps {
  oe: ObjetoEducacionalEntity;
}

export const RotuloDesktopQuiz: React.FC<RotuloDesktopQuizProps> = ({ oe }) => {
  return (
    <>
      <div data-flex className=" mt-3 md:mt-7 justify-between">
        {oe.attributes?.Rotulos &&
          oe.attributes?.Rotulos.data.map((rotulo, idx) => (
            <RotuloCategoria key={idx} data={rotulo.attributes?.titulo || ""} />
          ))}
      </div>
    </>
  );
};
