import { useEffect, useState } from "react";
import {
  ObjetoEducacionalEntity,
  useGetOesFavoritadasByUserLazyQuery,
} from "../generated/graphql";

interface OeFavoritadoProps {
  userId: string;
  match?: any;
}

const useOeFavoritado = (props: OeFavoritadoProps) => {
  const [data, setData] = useState<ObjetoEducacionalEntity[]>([]);

  const [GetOesFavoritados] = useGetOesFavoritadasByUserLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      setData(
        response?.findMe?.attributes?.OesFavoritados
          ?.data as ObjetoEducacionalEntity[]
      );
    },
  });

  useEffect(() => {
    const allowsLoad = () => {
      let allows = false;
      switch (window.location.pathname) {
        case "/meu-perfil":
          allows = props.userId ? true : false;
          break;
      }
      return allows;
    };

    if (allowsLoad()) {
      GetOesFavoritados();
    }
  }, [GetOesFavoritados]);

  return { data };
};

export default useOeFavoritado;
