import React, { useState, useEffect, useContext, forwardRef } from "react";
import { useDispatch } from "react-redux";

import FavoriteYesIconBlue from "../../../assets/svgs/favorited-icon.svg";
import FavoriteNoIconBlue from "../../../assets/svgs/unfavorited-icon.svg";

import FavoriteYesIconYellow from "../../../assets/svgs/favorite-yellow.svg";
import FavoriteNoIconYellow from "../../../assets/svgs/unfavorited-yellow-icon.svg";

import FavoriteYesIconWhite from "../../../assets/svgs/favorited-white-icon.svg";
import FavoriteNoIconWhite from "../../../assets/svgs/unfavorited-white-icon.svg";

import FavoriteYesIconGreen from "../../../assets/svgs/favorited-green-icon.svg";
import FavoriteNoIconGreen from "../../../assets/svgs/unfavorited-green-icon.svg";

import { HEADER_ACTION_TYPE } from "../../constants/action-types";

import {
  ObjetoEducacionalEntity,
  useFavoritarMutation,
} from "../../../generated/graphql";

import { UserContext, updateUserContext } from "../../../context/UserContext";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";
import * as Icon from "../../../assets/svgs";
import "./styles.scss";

import { Star } from "lucide-react";

/**
 * Como a única função do componente é a de FAVORITAR, deixaremos neste mesmo
 * arquivo a mutation que executa a ação de curtir.
 */

/**
 * Para curtir um Objeto, este componete receberá
 * o ID do usuário e o ID da OE para que seja executada
 * a mutation de maneira adequada.
 * O componente também receberá um atributo 'favoritado', de maneira
 * a mudar sua apresentação caso o conteúdo já tenha sido curtido.
 */
interface ItemProps {
  id: string;
  titulo: string;
}

interface IFavoritarProps {
  idOe?: string;
  item: ItemProps;
  favoritado?: boolean;
  color?: "blue" | "green" | "white" | "yellow" | "gray";
  ref?: any;
  active?: boolean;
  className?: string;
}

export const Favoritar = forwardRef<HTMLButtonElement, IFavoritarProps>(
  (props, ref) => {
    const [favoritarMutation] = useFavoritarMutation();

    const { user, setUser } = useContext(UserContext);

    const [currentIconStyle, setCurrentIconStyle] = useState<any | null>(null);

    const dispatch = useDispatch();

    const [favoritado, setFavoritado] = useState<boolean>(!!props.active);

    const gtm = useGtm();

    const iconStyles: any = {
      blue: {
        iconTrue: FavoriteYesIconBlue,
        iconFalse: FavoriteNoIconBlue,
      },
      yellow: {
        iconTrue: FavoriteYesIconYellow,
        iconFalse: FavoriteNoIconYellow,
      },
      white: {
        iconTrue: FavoriteYesIconWhite,
        iconFalse: FavoriteNoIconWhite,
      },
      green: {
        iconTrue: FavoriteYesIconGreen,
        iconFalse: FavoriteNoIconGreen,
      },
      gray: {
        iconTrue: null,
        iconFalse: null,
      },
    };

    const ehOe = (oeUser: any, id: string | undefined) => {
      if (!oeUser || !id) {
        return false;
      }

      return oeUser.id.toString() === id.toString();
    };

    useEffect(() => {
      if (!props.idOe) {
        setIconStyle()
      }
    }, [props.idOe]);

    const isFavored = () => {
      if (!user || !props.idOe) return false;
      const uss = user.attributes?.OesFavoritados?.data.some(
        (oeUser) => oeUser?.id?.toString() === props?.idOe?.toString(),
      );
      return uss;
    };

    const setIconStyle = () => {
      const colorIcon = (props.color as any) || "blue";
      const icon = iconStyles[colorIcon];
      setFavoritado(isFavored() || false);
      setCurrentIconStyle(isFavored() ? icon["iconTrue"] : icon["iconFalse"]);
      // setFavoritado(!isFavored());
    };

    useEffect(() => {
      setIconStyle();
    }, [props.idOe, favoritado, user]);

    const toggleFavoritar = async (
      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      ev.preventDefault();
      ev.stopPropagation();

      if (!user) {
        dispatch({
          type: HEADER_ACTION_TYPE.PRE_REGISTER_USER,
          payload: true,
        });
        return;
      }

      if (!isFavored()) {
        gtm.push(
          EDataLayerEventCategory.ADE_OE_FAVORITAR,
          EDataLayerEventAction.CLIQUE,
          {
            label: props.item.titulo || "",
            id: props.item.id,
          },
        );
      }

      const otherFavorites = (
        user.attributes?.OesFavoritados?.data as ObjetoEducacionalEntity[]
      ).filter((oeUser) => !ehOe(oeUser, props.idOe));

      const newFavorites = !isFavored()
        ? [...otherFavorites, { id: props.idOe! }]
        : [...otherFavorites];

      const options = {
        variables: {
          id: user.id!,
          input: {
            OesFavoritados: (newFavorites as any[]).reduce((acc, item) => {
              acc.push(item.id);
              return acc;
            }, []),
          },
        },
      };

      await favoritarMutation(options).then((res) => {
        setFavoritado(!isFavored());

        const objs = newFavorites.reduce((acc: any[], item) => {
          acc.push({ id: item.id });
          return acc;
        }, []) as ObjetoEducacionalEntity[];

        setIconStyle();

        setUser({
          id: user.id,
          attributes: {
            ...user.attributes!,
            OesFavoritados: {
              data: objs,
            },
          },
        });

        updateUserContext({
          id: user.id,
          attributes: {
            ...user.attributes!,
            OesFavoritados: {
              data: objs,
            },
          },
        });
      });
    };

    const selectedColor = props.color === "yellow" ? "#FFD700" : "#004F92";

    return (
      <>
        <button
          data-testid="btn-favoritar"
          onClick={toggleFavoritar}
          ref={ref}
          title={favoritado ? "Desfavoritar" : "Favoritar"}
          className={props.className}
        >
          {currentIconStyle && (
            <Star
              size={28}
              aria-hidden="true"
              color={favoritado ? selectedColor : selectedColor}
              fill={favoritado ? selectedColor : "transparent"}
            />
          )}
        </button>
      </>
    );
  },
);
