import React, { useEffect, useState } from "react";

import {
  AreaConhecimento,
  AreaDeConhecimentoEntity,
  PerfilDeAutorEntity,
  useGetAreasInteresseAutorLazyQuery,
  useGetAuthorByIdLazyQuery,
} from "../../generated/graphql";

import {
  Breadcrumb,
  BreadcrumbItem,
  HeaderSection,
  Loader,
} from "../../shared/components";

import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { KnowledgeArea } from "../../shared/interfaces/area.interface";
import { useParams } from "react-router";
import { AreasDeInteresseAutor } from "./components/AreasDeInteresse";

export interface AreasInteresseLocationParams {
  profissao?: boolean;
}

const AreasInteresseAutorPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<AreaDeConhecimentoEntity[]>([]);
  const [author, setAuthor] = useState<PerfilDeAutorEntity | undefined>(
    undefined
  );
  const gtm = useGtm();
  const scrollTop = useScrollTop();

  const params = useParams<{ idAutor: string }>();

  const [GetAuthor] = useGetAuthorByIdLazyQuery({
    variables: {
      id: params.idAutor || "0",
    },
    onCompleted: (data: any) => {
      setLoading(false);
      setAuthor(data.perfilDeAutor?.data as PerfilDeAutorEntity);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const [GetAreasInteresseQuery] = useGetAreasInteresseAutorLazyQuery({
    onCompleted: (data: any) => {
      setLoading(false);
      setData(data.areaDeConhecimentos?.data as AreaDeConhecimentoEntity[]);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    scrollTop();
    GetAuthor();
    GetAreasInteresseQuery({
      variables: {
        autorId: params.idAutor,
      },
    });
    return () => {
      // GTM doc linha 64
      gtm.push(
        EDataLayerEventCategory.PAGE_VIEW,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: "area_interesse_autor",
        }
      );
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_ROOT}/api/area-de-conhecimentos/v2`, {
      method: "GET",
      headers: new Headers({ "content-type": "application/json" }),
    }).then(async (res: any) => {
      const areas = (await res.json()).results;
      //setData( areas as unknown as KnowledgeArea[]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <div data-area-conhecimento-page>
        <HeaderSection>
          <Breadcrumb>
            <BreadcrumbItem to="/">Home</BreadcrumbItem>
            <BreadcrumbItem to="/central-de-autorias">
              Central de Autoria
            </BreadcrumbItem>
            <BreadcrumbItem to={`/autor/${params.idAutor}/detalhes`}>
              {author?.attributes?.nome_exibicao}
            </BreadcrumbItem>
            <BreadcrumbItem
              to={`/autor/${params.idAutor}/detalhes/areas-interesse`}
              active
            >
              Áreas de interesse
            </BreadcrumbItem>
          </Breadcrumb>
          <h3>Áreas de interesse de {author?.attributes?.nome_exibicao}</h3>
        </HeaderSection>
        <section data-interest-area-container>
          <AreasDeInteresseAutor
            areas={
              data.map((a) => ({
                id: a.id,
                icone: {
                  url: a.attributes?.icone?.data?.attributes?.url!,
                },
                titulo: a.attributes?.titulo!,
              })) as unknown as AreaConhecimento[]
            }
            idAutor={params.idAutor}
          />
        </section>
      </div>
    </>
  );
};

export default AreasInteresseAutorPage;
