import { useEffect, useState } from "react";
import {
  Enum_Percepcaoquestionario_Percepcao,
  PercepcaoQuestionarioEntity,
  useCreatePercepcaoQuestionarioMutation,
  useGetPercepcaoQuestionarioLazyQuery,
  useUpdatePercepcaoQuestionarioMutation,
} from "../generated/graphql";

export interface ContentPercepcaoState {
  id: string | undefined;
  idUsuario?: string;
  idQuestionario?: string;
  percepcao?: Enum_Percepcaoquestionario_Percepcao;
}

const usePercepcaoQuestionario = (
  idUsuario: string,
  idQuestionario: string
) => {
  const [state, setState] = useState<ContentPercepcaoState>();

  const [GetPercepcao] = useGetPercepcaoQuestionarioLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      idUsuario,
      idQuestionario,
    },
    onCompleted: (response) => {
      if ((response.percepcaoQuestionarios?.data?.length || 0) > 0) {
        const percepcao = response.percepcaoQuestionarios
          ?.data[0]! as PercepcaoQuestionarioEntity;

        setState({
          id: percepcao.id,
          percepcao: percepcao.attributes?.percepcao!,
          idUsuario: percepcao.attributes?.usuario?.data?.id!,
          idQuestionario: percepcao.attributes?.questionario?.data?.id!,
        });
      } else {
        setState({
          id: undefined,
          percepcao: undefined,
          idUsuario: undefined,
          idQuestionario: undefined,
        });
      }
    },
  });

  useEffect(() => {
    GetPercepcao();
  }, []);

  const [createPercepcaoQuestionario] =
    useCreatePercepcaoQuestionarioMutation();
  const [updatePercepcaoQuestionario] =
    useUpdatePercepcaoQuestionarioMutation();

  const savePercepcaoQuestionario = async (
    dataState?: ContentPercepcaoState
  ) => {
    const _state: ContentPercepcaoState = {
      ...(state ? state : ({} as ContentPercepcaoState)),
      ...(dataState ? dataState : ({} as ContentPercepcaoState)),
    };

    if (!_state.id) {
      const response = await createPercepcaoQuestionario({
        variables: {
          input: {
            questionario: _state.idQuestionario,
            usuario: _state.idUsuario,
            percepcao: _state.percepcao,
          },
        },
      });
      _state.id = response.data?.createPercepcaoQuestionario?.data?.id;
      setState(_state);
    } else {
      await updatePercepcaoQuestionario({
        variables: {
          id: _state.id.toString(),
          input: {
            questionario: _state.idQuestionario,
            usuario: _state.idUsuario,
            percepcao: _state.percepcao,
          },
        },
      });
      setState(_state);
    }

    return true;
  };

  return {
    data: state,
    savePercepcaoQuestionario,
  };
};

export default usePercepcaoQuestionario;
