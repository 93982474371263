import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {
  Breadcrumb,
  BreadcrumbItem,
  Loader,
  HeaderSection,
} from "../../shared/components";

import TemasOferecidos from "./components/TemasOferecidos";
import CategoriasConteudo from "./components/CategoriasDeConteudo";

import "./styles.scss";

const SobrePage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [urlVideoInstitucional] = useState<string>(
    "https://vimeo.com/717651585/05bd6f3818"
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Loader
        show={loading === true}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <HeaderSection>
        <Breadcrumb>
          <BreadcrumbItem to="/">Home</BreadcrumbItem>
        </Breadcrumb>
        <h3 className="text-base md:text-xxl font-semibold leading-none text-white">
          Sobre a Academia
        </h3>
      </HeaderSection>
      <div data-sobre-page>
        <section className="sobre">
          <article>
            <h2>A ACADEMIA DIGITAL EINSTEIN</h2>
            <p>
              A velocidade de novos conhecimentos em Saúde, com publicações
              científicas e avanços tecnológicos constantes, demanda aos
              profissionais aprendizado ativo e permanente. Unindo a necessidade
              de criar um ambiente para compor uma grande comunidade de
              conhecimento à uma das missões da Sociedade Beneficente Israelita
              Brasileira Albert Einstein, de colaborar com a saúde da população
              brasileira, e à preocupação com a credibilidade do conhecimento
              dentro de um processo educacional efetivo surge a Academia Digital
              Einstein, com o propósito de partilhar toda a riqueza de
              informações produzidas diariamente na Instituição.
            </p>
            <Vimeo
              pip={true}
              controls={true}
              video={urlVideoInstitucional}
              className="player-video mt-10"
            />
          </article>
          <article>
            <h2>PARA QUEM É DESTINADA?</h2>
            <p>
              Voltada aos profissionais de Saúde, como Médicos, Profissionais de
              Enfermagem, Fisioterapeutas, Fonoaudiólogos, Dentistas e tantos
              outros, a Academia abrange mais de 60 áreas do conhecimento, além
              de outros temas relacionados ao setor, como Gestão em Saúde,
              Pesquisa, Educação e Ensino em Saúde, Responsabilidade Social,
              Transformação Digital e Inovação.
            </p>
          </article>
        </section>
        <section className="temas-oferecidos">
          <article>
            <h2>O QUE OFERECE?</h2>
            <TemasOferecidos></TemasOferecidos>
          </article>
        </section>
        <section className="categorias-conteudo">
          <h2>CATEGORIAS DE CONTEÚDO DISPONÍVEIS</h2>
          <p>
            Para facilitar a localização na plataforma, os conteúdos serão
            agrupados nas seguintes categorias:
          </p>
          <CategoriasConteudo></CategoriasConteudo>
        </section>
        <section className="faca-parte mt-10">
          <h2>
            TENHA ACESSO A UMA ATUALIZAÇÃO PROFISSIONAL CONSTANTE E DINÂMICA
          </h2>
          <Link
            to="/novo-usuario"
            className="button button-primary mt-10"
            style={{ display: "block" }}
          >
            Faça parte da Academia Digital Einstein
          </Link>
        </section>
      </div>
    </>
  );
};

export default SobrePage;
